//KRL optimisation date issue 
//KRL OPTIMISATION

// MPCHarts Merged DSTR with main

import React, { useEffect } from 'react'
import '../styles/styles.scss';
import { useState,useLayoutEffect,useRef,useMemo,useCallback } from 'react';
import * as d3 from 'd3';
import {CONSTANTS, SNACKBAR_AUTO_HIDE_DURATION_SHORT} from '../../../common/utility/constant';
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import ContextMenu from './contextMenu';
import { useWindowSize } from '../../../common/hooks/useWindowSize';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,AUTO_CENTER_CONFIG } from '../../../common/utility/constant';
import {Snackbar, Alert,CircularProgress} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import MergeFooter from './merge_footer';
import { TIME_FRAME_VALUES } from '../../../common/utility/constant';
import { index } from 'd3';
import { CHART_COLORS,DIMENSIONS ,CHART_FONT_DEFAULT,SPECIAL_TPO_CHAR_FONT, CHART_FONT_ITALIC_DEFAULT, CHART_SCREEN_TYPE, VA_TYPE,KRL_STYLING_CATEGORY,KRL_CATEOGRY_TYPE,MP_NPOC_MIN_PROFILE_DIFFERENCE,MP_CHART_LINE_WIDTH,MAX_PROFILE_TO_LOAD,CHART_FACTOR_MP, CHART_TYPE} from '../../../common/utility/mp_chart_constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { WATERMARK_CONFIG } from '../../../common/utility/constant';
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth';
import SaveBookmarkDialog from './create_bookmarg_dialog';
import { useSearchParams } from "react-router-dom";
import { BOOKMARK_CATEGORY_AUTOSAVE,ERROR_CODE_BOOKMARK_ALREADY_MODIFIED,BOOKMARK_AUTO_SAVE_DURATION } from '../../../common/utility/constant';
import ConfirmActionDialog from './confirm_dialog';
import { useInterval } from '../../../common/api/hooks/useInerval';
import PRICE_TABLE from './price_table';
import { TIME_FRAME } from '../../../common/utility/configData';
import Tooltip from './mptooltip';
import { getRandomHexColor,rgbToHex } from '../../../common/utility/helperFunc';
import YAxisContextMenu from './mpYaxisContextMenu';
import dayjs from 'dayjs';
import { TreeNode } from 'antd/es/tree-select';
import MPFABModal from './mp_fab_modal';
import { TransformRounded } from '@mui/icons-material';
import { data } from 'jquery';
import SaveKRL from './create_krl';
import KRLContextMenu from './krlContextMenu';
import MPKRLModal from './mp_krl_modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuth from '../../../common/hooks/useAuth';



//constants for MP chart
const PROFILE_RELOAD_FACTOR=1;
const DEFAULT_PROFILE_WIDTH=60;
const DEFAULT_PROFILE_WIDTH_WEEKLY=100;
const DEFAULT_PROFILE_WIDTH_MONTHLY=120;
const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const LIVE_PROFILE_MIN_WIDTH=220;
const TPO_WIDTH=14;
const TPO_HEIGHT=16;
const XSCALE_HEIGHT=24;
const YSCALE_WIDTH=60;
const PROFILE_LEFT_MARGIN=20;
const PROFILE_RIGHT_MARGIN=20;
const LOAD_MORE_PAN_PERCENT=0.8;  //i.e when 80% of total profile width reached, load more profiles
const LOAD_MORE_ZOOM_OUT_PERCENT=0.8; //i.e when 80% of zoom out value reached, load more profiles 
const ZOOM_OUT_EXTREME=0.25;
const ZOOM_OUT_EXTREME_LESS_PROFILE=0.4;
const CONTEXT_MENU_HEIGHT=200;
const CONTEXT_MENU_WIDTH=160;
const CHART_TOP_SPACE=104; //header+settings+xscale height
const PROFILE_MIN_WIDTH=160;
const PROFILE_LOADING_FACTOR_ZOOM_OUT=1;
const VOLUME_PROFILE_TPO_LIMIT=0.1; //recalculate vpList with this factor and vp_tpo
const TOOLTIP_HEIGHT=100;
const TOOLTIP_MENU_WIDTH=145;
const TOOLTIP_KRL_MENU_WIDTH=310;
const DEFAULT_LTP_POSITION=0.4;     //40% from the top
const LTP_POSITION_ALLOWED_CHANGE=0.2; //20% from top or bottom
const TOOLTIP_ITEM_HEIGHT=20;
const KRL_CONTEXT_MENU_HEIGHT=100;
const KRL_CONTEXT_MENU_WIDTH=70;


const compareDates = (dateStr1, dateStr2) => {
  const [day1, month1, year1] = dateStr1.split('-').map(Number);
  const [day2, month2, year2] = dateStr2.split('-').map(Number);

  // Compare years
  if (year1 !== year2) {
    return year1 - year2;
  }

  // Compare months
  if (month1 !== month2) {
    return month1 - month2;
  }

  // Compare days
  return day1 - day2;
};

// Function to filter and sort the objects based on id and type
const filterAndSort = (inputData, commonId, commonType) => {
 
  // Filter the data based on the common ID and type
  const filteredData = inputData.filter(obj => obj.id === commonId && obj.type === commonType);
  // Sort the filtered data based on the date property in "dd-mm-yyyy" format
  const sortedData = filteredData.sort((a, b) => {
    // Compare the dates
    return compareDates(a.start_date,b.start_date);
  });

  // console.log("filterAndSort",filteredData,sortedData)

  return sortedData;
};

// Function to get unique IDs from the data
const getUniqueIds = inputData => [...new Set(inputData.filter(obj => obj.id && obj.type).map(obj => obj.id))];

// Function to apply filterAndSort for all unique IDs and types
const getResultForAllIds = inputData => {
  const uniqueIds = getUniqueIds(inputData);

  // Map over unique IDs and get the result for each ID and type
  const results = uniqueIds.flatMap(id => {
    const uniqueTypesForId = [...new Set(inputData.filter(obj => obj.id === id && obj.type).map(obj => obj.type))];
    
    return uniqueTypesForId.map(type => ({
      id,
      type,
      data: filterAndSort(inputData, id, type),
    }));
  });

  return results;
};


function MPChart (props) {
  // console.log("props in mp chart=",props);
    const { auth } = useAuth();
    const location = useLocation();
    const from = "/login";
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({});
    const [isFirstLoad,setIsFirstLoad]=useState(true);
    const [chartMidTPO,setChartMidTPO]=useState(-1);
    

    const [historicalData, setHistoricalData] = useState();
    const [savedHistoricalData, setSavedHistoricalData] = useState();
    const historicalDataRef = useRef(historicalData);
    const [priceTableData, setPriceTableData] = useState();
    const [metaData, setMetaData] = useState();
    const [cachedlData, setCachedData] = useState([]);
    const [cachedlResponseData, setCachedResponseData] = useState([]);
    const [selectedInstrument,setSelectedInstrument]=useState(props.instrument)
    const selectedInstrumentRef=useRef(selectedInstrument)
    const [selectedTPO,setSelectedTPO]=useState(props.tpo);
    const selectedTPORef=useRef(selectedTPO);
    const [isInstrumentLive,setInstrumentLive]=useState(props.isInstrumentLive)
    const [transformData,setTransformData]=useState({x:0,y:0,k:1});
    const transformDataRef=useRef(transformData);
    const [transformDataAutoCenter,setTransformDataAutoCenter]=useState({x:0,y:0,k:1});
    const transformDataAutoCenterRef=useRef(transformDataAutoCenter);
    const [showContextMenu,setShowContextMenu]=useState(false);
    const [showTooltip,setShowTooltip]=useState(false);
    const [tooltipData,setTooltipData]=useState();
    const contextMenuRef=useRef(showContextMenu);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [anchorPointTooltip, setAnchorPointTooltip] = useState({ x: 0, y: 0 });
    const [selectedProfiles,setSelectedProfiles]=useState([]);
    const [selectedProfilesIndex,setSelectedProfilesIndex]=useState([]);
    const [currentIndex,setCurrentIndex]=useState();
    const [isLoadingMoreProfiles,setIsLoadingMoreProfiles]=useState(false);
    const isLoadingMoreProfilesRef=useRef(isLoadingMoreProfiles)
    const [cancel,responseData, error, loaded, reset,executeAPI]  = useAxiosWithAuth();
    const [cancelComposite,responseDataComposite, errorComposite, loadedComposite, resetComposite,executeAPIComposite]  = useAxiosWithAuth();
    const [cancelAutoSave,responseDataAutoSave, errorAutoSave, loadedAutoSave, resetAutoSave,executeAPIAutoSave]  = useAxiosWithAuth();
    const [cancelBookamrk,responseDataBookamrk, errorBookamrk, loadedBookamrk, resetBookamrk,executeAPIBookamrk]  = useAxiosWithAuth();
    const [cancelKRL,responseDataKRL,errorKRL,loadedKRL,resetKRL,executeAPIKRL] = useAxiosWithAuth();
    const [cancelDeleteKRL,responseDataDeleteKRL,errorDeleteKRL,loadedDeleteKRL,resetDeleteKRL,executeAPIDeleteKRL] = useAxiosWithAuth();
    const windowSize=useWindowSize();
    const [toggleRepaint,setToggleRepaint]=useState(true);
    const [chartInterationAllowed,setChartInteractionAllowed]=useState(true);
    const [showMergeFooter,setShowMergeFooter]=useState(false);
    const chartInterationAllowedRef=useRef(chartInterationAllowed);
    const [compositeLoading,setCompositeLoading]=useState(false);
    const [canvasWidth,setCanvasWidth]=useState();
    const [vpList, setProcessedVPList]=useState();
    const [refreshChart, setRefreshChart]=useState(false);
    const [crossHairCord, setCrossHairCord] = useState({ x: -1, y: -1,tpo:-1 });
    const [isMoreDataLoading,setIsMoreDataLoading]=useState(false);
    const [moreDataPresent,setMoreDataPresent]=useState(true);
    const [autoCenter,setAutocenter]=useState(props.autoCenter);
    const autoCenterRef=useRef(autoCenter);
    const [compositeData,setCompositeData]=useState({
      dstr: undefined,
      nDays:0 ,
      startDate:"",
      startIndex:-1,
      endIndex:-1,
      dstrMap:[],
      liveData:false,
      liveComposite:false
    });
    const [sessionActive,setSessionActive]=useState(props.isTradingSessionActive)
    const { dstr, nDays, startDate,startIndex,endIndex,dstrMap ,liveData,liveComposite} = compositeData;
    const [liveDataComposite,setLiveComposite]=useState(false);
    const [liveDataCompositeUnmerge,setLiveCompositeUnmerge]=useState(false);
    const [openBookmarkDialog,setOpenBookmarkDialog]=useState(false)
    const [bookmarkData,setBookmarkData]=useState(props.bookmarkData);
    const [bookmarkAPIData,setBookmarkAPIData]=useState(props.bookmarkData);
    const [openBookmarkOverwriteDialog,setOpenBookmarkOverwriteDialog]=useState(false)
    const bookmarkDataRef=useRef(bookmarkData);
    const [selectedInstrumentData,setSelectedInstrumentData]=useState(props.selectedInstrumentData);
    const selectedInstrumentDataRef=useRef(selectedInstrumentData);
    const [selectedTimeFrame,setSelectedTimeFrame]=useState(props.selectedTimeFrame)
    const selectedTimeFrameRef=useRef(selectedTimeFrame);
    const globalVolumeVisibleRef=useRef();
    const volumeNumberVisibleRef=useRef();
    const profileVolumeNumberVisibleRef=useRef();
    const vpTypeRef=useRef();
    const tpoBasedVARef=useRef();
    const isTradingSessionActiveRef=useRef();
    const [anchorPointYAxis, setAnchorPointYAxis] = useState({ x: 0, y: 0 });
    const [showYAxisContextMenu,setShowYAxisContextMenu]=useState(false);
    const yaxiscontextMenuRef=useRef(showYAxisContextMenu);
    const [priceVal,setPriceVal]=useState();
    const [levelData,setLevelData]=useState(props.levelData)
    const [openSetLeveleDialog,setOpenSetLevelDialog]=useState(false);
    const [openDeleteLeveleDialog,setOpenDeleteLevelDialog]=useState(false);
    const [krlData,setKRLData]=useState();
    const [anchoredKRLPresent,setAnchoredKRLPresent]=useState(false);
    const [isRecenterAllowed,setIsRecenterAllowed]=useState(true);
    const [isZooming,setIsZooming]=useState(false);
    const [savedLiveData,setSavedLiveData]=useState();
    const[dstrData,setDstrData]=useState(props.dstrData);
    const [currDstrIndex,setCurrDstrIndex]=useState(-1);
    const [dataLoaded,setDataLoaded]=useState(false);
    const dataLoadedRef=useRef(dataLoaded);
    const [openKRLDialog,setOpenKRLDialog]=useState(false);
    const [selectedKRL,setSelectedKRL]=useState(undefined);
    const [showKRLContextMenu,setShowKRLContextMenu]=useState(false);
    const krlcontextMenuRef=useRef(showKRLContextMenu);
    const [anchorPointKRL, setAnchorPointKRL] = useState({ x: 0, y: 0 });
    const [krlProfileData,setKrlProfileData]=useState(undefined);
    const [krlLoading,setKRLLoading]=useState(false);
    const [krlIDDeleted,setKRLIDDeleted]=useState(undefined);
    const [openDeleteKRLDialog,setOpenDeleteKRLDialog]=useState(false);
    const [ctxMenuLevel,setCtxMenuLevel]=useState(false);
    const matches = useMediaQuery('(min-width:640px)'); 
    const [hasFullAccess,setHasFullAccess]=useState(true); //tracks if user has full access on the chart. Can open other user bookmarks, which will not have edit access.
    const [rightContainerProfilesIndex,setRightContainerProfilesIndex]=useState([]);
   
  
   
    

    // const { userSettings, setUserSettings } = useContext(UserSettingsContext);
    // const [LOCALE_STRING, setLocaleString] = useState(userSettings.data.number_format_locale);

    // console.log(props.locale_string)
    
    //margins for chart and axis
    const margin = {top: 24, right: 60, bottom: 20, left: 0};
   
    const [msgState, setMsgState] = useState({
      open: false,
      msg:"" ,
      severity:"info"
    });
  
    const { open, msg, severity } = msgState;

    const theme=useTheme();

   useEffect(()=>{
    if(historicalData!=undefined && historicalData.profilesData!=undefined){
      const reversedArray=historicalData.profilesData.slice().reverse();
      console.log("Price Reversed array=======>",reversedArray);
      setPriceTableData(reversedArray);
      // saveChartState();
    }
   },[historicalData])

   useEffect(()=>{
    if(historicalData!=undefined && historicalData.profilesData!=undefined){
      console.log("MP Settings useEffect called MP chart",props.userSettings);
     setToggleRepaint(!toggleRepaint);
    }
   },[props.userSettings])

    useEffect(()=>{
      //is trading session goes true to false then reload the live profile data as historical data
      console.log("trading session changed")
      if(sessionActive==true &&props.isTradingSessionActive==false){
       console.log("refresh chart is called session change")
        setRefreshChart(true);
      }
     setSessionActive(props.isTradingSessionActive)
     
    },[props.isTradingSessionActive])


    useEffect(()=>{
      chartInterationAllowedRef.current=chartInterationAllowed;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[chartInterationAllowed])

    useEffect(()=>{
      dataLoadedRef.current=dataLoaded;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[dataLoaded])

    useEffect(()=>{
      isLoadingMoreProfilesRef.current=isLoadingMoreProfiles;
      // console.log("Ref for chart interaction val ",chartInterationAllowedRef.current)
    },[isLoadingMoreProfiles])

    useEffect(()=>{
      // bookmarkDataRef.current=bookmarkData;
    },[bookmarkData])

    useEffect(()=>{
      // bookmarkDataRef.current=bookmarkData;
      
      setRightContainerProfilesIndex(props.selectedProfilesIndex)
    },[props.selectedProfilesIndex])
    

    useEffect(()=>{
      historicalDataRef.current=historicalData;
      
      if(historicalData!=undefined){
        const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
        
        if(rightContainerProfilesIndex && rightContainerProfilesIndex.length>0)
        props.setRightContainerData(false,metaData,historicalData,rightContainerProfilesIndex,isLiveSession,true);
      saveChartState();
      }
    },[historicalData])

    useEffect(()=>{
      selectedInstrumentRef.current=selectedInstrument;
    },[selectedInstrument])

    useEffect(()=>{
      selectedTPORef.current=selectedTPO;
    },[selectedTPO])
    
    useEffect(()=>{
      selectedTimeFrameRef.current=selectedTimeFrame;
    },[selectedTimeFrame])

    useEffect(()=>{
      globalVolumeVisibleRef.current=props.globalVolumeVisible;
    },[props.globalVolumeVisible])

    useEffect(()=>{
      volumeNumberVisibleRef.current=props.volumeNumberVisible;
    },[props.volumeNumberVisible])
    
    useEffect(()=>{
      profileVolumeNumberVisibleRef.current=props.profileVolumeNumberVisible;
    },[props.profileVolumeNumberVisible])

    useEffect(()=>{
      vpTypeRef.current=props.vpType;
    },[props.vpType])

    useEffect(()=>{
      tpoBasedVARef.current=props.tpoBasedVA;
    },[props.tpoBasedVA])

    useEffect(()=>{
      isTradingSessionActiveRef.current=props.isTradingSessionActive;
    },[props.isTradingSessionActive])

   
    useEffect(()=>{
      selectedInstrumentDataRef.current=selectedInstrumentData;
    },[selectedInstrumentData])

    useEffect(()=>{
      setSelectedInstrumentData(props.selectedInstrumentData);
    },[props.selectedInstrumentData])

    

    
   
    const autosavetimeelapsed=()=>{
      
      // console.log("useinterval calling function, auto save 3 seconds===========--------===============>",selectedTPORef.current); 
      if(historicalDataRef!=undefined && selectedInstrumentDataRef.current.autosave) {
        console.log(" calling inside autosace")
        let data={};
        if(bookmarkDataRef.current!=undefined){
          console.log("save bookmark existing bokmark data=",bookmarkDataRef.current);
          if(bookmarkDataRef.current.category==BOOKMARK_CATEGORY_AUTOSAVE ){
            if(selectedTimeFrameRef.current==TIME_FRAME_VALUES.daily) {
              const dstrVal=getChartDstr();
              data={
                "name":selectedInstrumentRef.current,
                "id":bookmarkDataRef.current.id,
                "last_saved":bookmarkDataRef.current.last_saved,
                "data": {
                "instrument": selectedInstrumentRef.current,
                  "type": "mp",
                  "dstr": dstrVal.dstr,
                  "tpo": selectedTPORef.current,
                  "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
                  "tf": selectedTimeFrameRef.current,
                  "glob_vol": globalVolumeVisibleRef.current,
                  "glob_num": volumeNumberVisibleRef.current,
                  "prof_num": profileVolumeNumberVisibleRef.current,
                  "vp_type": vpTypeRef.current,
                  "va": tpoBasedVARef.current?"tpo":"vol"
                },
  
                // "category": "NIFTY"
              }
              setBookmarkAPIData(data);
              console.log("API auto save data for existing bookmark=",data);
              executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
            }else{
              //autosave not allowed for non daily time frame
              console.log("auto save not allowed for non daily profile")
            }
          }
        }else{
          if(selectedTimeFrameRef.current==TIME_FRAME_VALUES.daily) {
          //1st auto save 
            const dstrVal=getChartDstr();
            data={
              "name":selectedInstrumentRef.current,
              "data": {
              "instrument": selectedInstrumentRef.current,
                "type": "mp",
                "dstr": dstrVal.dstr,
                "tpo": selectedTPORef.current,
                "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
                "tf": selectedTimeFrameRef.current,
                "glob_vol": globalVolumeVisibleRef.current,
                "glob_num": volumeNumberVisibleRef.current,
                "prof_num": profileVolumeNumberVisibleRef.current,
                "vp_type": vpTypeRef.current,
                "va": tpoBasedVARef.current
              },

              // "category": "NIFTY"
            }
            
            setBookmarkAPIData(data);
            console.log("API: auto save data for 1st time bookmark=",data);
            executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
          }else{
            console.log("API: auto save data for 1st time bookmark: not allowed for non daily profile")
          }
      }
    }
     
    }

   //TODO: use this hook to avoid reference mgmt
    // useInterval(() => {
    //   autosavetimeelapsed();
    // }, 3000);

    useEffect(() => {
      bookmarkDataRef.current=bookmarkData;
      const autosave_interval=props.userSettings.data.autosave_interval;
      let timeout;
      if(autosave_interval!=undefined && autosave_interval>0){
      timeout= setInterval(() => {
      if(selectedInstrumentDataRef.current.autosave)
      console.log("Calling autosave=",autosave_interval)
        autosavetimeelapsed();
      
      }, autosave_interval*1000);
    }
    
      return () => {
        // just to clear the timeout when component unmounts
        if(timeout!=undefined)
        clearInterval(timeout);
      };
    }, [bookmarkData]);



    useEffect(()=>{
      autoCenterRef.current=props.autoCenter;
    },[props.autoCenter])

    useEffect(()=>{
      contextMenuRef.current=showContextMenu;
    },[showContextMenu])

    useEffect(()=>{
      yaxiscontextMenuRef.current=showYAxisContextMenu;
    },[showYAxisContextMenu])

    useEffect(()=>{
      krlcontextMenuRef.current=showKRLContextMenu;
    },[showKRLContextMenu])
   
    useEffect(()=>{
      transformDataRef.current=transformData;
      console.log("transformDataRef=",transformDataRef.current)
    },[transformData])
   
    useEffect(()=>{
      transformDataAutoCenterRef.current=transformDataAutoCenter;
      // console.log("transformDataRef=",transformDataRef.current)
    },[transformDataAutoCenter])
   
    useEffect(()=>{
      if(historicalData!=undefined){
      setTransformData({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
    },[props.resetChart])

    
    //handler for TPO change     
   useLayoutEffect(()=>{
    if(props.tpo!=selectedTPO){
      console.log("tpo changed received by MP Chart")
      setSelectedTPO(props.tpo);
    }
   },[props.tpo,props.chartData]);


   useLayoutEffect(()=>{
    
    if(historicalData!=undefined){
      console.log("TPO issue",selectedTPO,props.chartData,props.liveData);
     console.log("TPO issue 1 cachedResponse, props.chartdata ============================>",cachedlResponseData,props.chartData)
     let processedVPList=parseVPList(props.chartData);
    let processedData=parseData(props.chartData,true,processedVPList,isInstrumentLive,true);
    console.log("TPO issue 2 processed data ============================>",processedData)
  
    let liveProcessedData=undefined
    console.log("tpo change 2nd",props.liveData)
    if(props.liveData!=undefined){
      console.log("if of tpo change WARNING CONDITION-------------------?");
      // liveProcessedData=parseData(props.liveData,false,undefined,true);
      // mergeProcessedData(liveProcessedData,processedData)
    }
    else{
      console.log("else of tpo change");
      // setHistoricalData(processedData);
    }
    setHistoricalData(processedData);
    //TODO: test the following
    // setRefreshChart(true);
    }
   },[selectedTPO]);

   //handler for instrument change
   useLayoutEffect(()=>{
    console.log("Instrument changed received by MP Chart1")
     
    //reset the chart transform when we change the instrument
      if(selectedInstrument!=props.instrument){
      setTransformData({k:1,x:0,y:0});
      setCrossHairCord({x:-1,y:-1,tpo:-1});
      console.log("Live panel Instrument changed received by MP Chart2")
      console.log("parseData 3===============================================>")
      const processedVPList=parseVPList(props.chartData);
      const processedData=parseData(props.chartData,true,processedVPList);
      if(props.chartData.metadata!=null & props.chartData.metadata!=undefined)    //only use for 1st container
        setMetaData(props.chartData.metadata);
      
        const isLiveSession=( processedData.config.lastDataTime!=undefined && processedData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
       
        props.setRightContainerData(false,props.chartData.metadata,processedData,[processedData.profilesData.length-1],isLiveSession)



      setProcessedVPList(processedVPList);
      setHistoricalData(processedData);
      
      setSelectedInstrument(props.instrument);
      }
   },[props.chartData,props.instrument])

   //handler for live data
   useLayoutEffect(()=>{
   
    if(props.liveData!=undefined && props.isPollingAllowed&& historicalData!=undefined && historicalData.profilesData!=undefined && chartInterationAllowed){
      // if(!isZooming && isLoadingMoreProfilesRef.current==false){ //if chart is not being zoomed or panned, then only proceed
        if(!isZooming){ //if chart is not being zoomed or panned, then only proceed
      setSavedLiveData(undefined)
      // let processedVPList=parseVPList(props.liveData);
      // console.log("processed vpList = ",processedVPList);
      // setProcessedVPList(processedVPList);
      // const processedData = parseData(props.chartData,true,processedVPList);
      //TODO: keep this in check for avoiding race condition
      // setChartInteractionAllowed(false);
      // if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
      //   console.log("Live data:Before props.chartData=",props.chartData);
      //   props.chartData.vaMapList.splice(props.chartData.vaMapList.length-1,1);
      //   props.chartData.vaMapList=props.chartData.vaMapList.concat(props.liveData.vaMapList);
      //   console.log("Live data:After props.chartData=",props.chartData);
      // }else{
      //   console.log("Live data:Before11 props.chartData=",props.chartData);
      //   props.chartData.vaMapList=props.chartData.vaMapList.concat(props.liveData.vaMapList);
      //   console.log("Live data:After 1 props.chartData=",props.chartData);
      // }
      //TODO: check the need for the following
      // if(!isInstrumentLive){
      //   console.log("Live data:Before11 props.chartData=",props.chartData);
      //   props.chartData.vaMapList=props.chartData.vaMapList.concat(props.liveData.vaMapList);
      //   console.log("Live data:After 1 props.chartData=",props.chartData);
      // }
                
      // const processedLiveData=parseData(props.liveData,false,processedVPList,true);
      // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
      // setMetaData(props.liveData.metadata);

      // console.log("live data received data=",props.liveData,processedLiveData);
      // mergeLiveData(processedLiveData,isInstrumentLive);
      // // setChartInteractionAllowed(true);
      // setInstrumentLive(true);
      handleLiveData(props.liveData);
    }else{
      // console.log("Test Zoom handleLiveData ELSE ====>")
      // if(props.liveData.metadata!=null & props.liveData.metadata!=undefined && props.key==0)///only use for 1st container
      // setMetaData(props.liveData.metadata);
      
      // setInstrumentLive(true);
      setSavedLiveData(props.liveData)

    }
  }
   
   },[props.liveData])

   const handleLiveData=(liveData)=>{
    if(liveData!=undefined){
      console.log("Test Zoom handleLiveData====>")
      let processedVPList=parseVPList(liveData);
    // let processedVPList=parseVPList(liveData,true);
    const processedLiveData=parseData(liveData,false,processedVPList,true);
    if(liveData.metadata!=null & liveData.metadata!=undefined && props.key==0)///only use for 1st container
    setMetaData(liveData.metadata);

    console.log("live data received data=",liveData,processedLiveData);
    mergeLiveData(processedLiveData,isInstrumentLive);
    // setChartInteractionAllowed(true);
    setInstrumentLive(true);
    }

   }

   const processKRLData=()=>{
    // let krlData=props.krlData;
    //system KRL data
    let chartStartDate=dayjs(props.chartData.vaMapList[1]?.dateList[0].split("-").reverse().join("-"))
    let filteredData=krlData?.filter(item=>(item.type=="autogen" && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
    for(let i=0;i<filteredData?.length;i++){
      let level=parseFloat(((filteredData[i].level*10000)/10000).toFixed(4));
      let levelMod=parseFloat(((filteredData[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
      let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
      filteredData[i].levelTPO=levelTPO;
      let startDate=dayjs(filteredData[i].start_date.split("-").reverse().join("-"))
      filteredData[i].startDate=startDate;
      let found=false;

      for(let j=0;j<historicalData.profilesData.length;j++){
            
        
                if(filteredData[i].start_date==historicalData.profilesData[j].dateList[0] || filteredData[i].start_date==historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1]||
          (!(startDate.isBefore(historicalData.profilesData[j].profileStartDate))&& (startDate.isBefore(historicalData.profilesData[j].profileEndDate)))){
          
            filteredData[i].profileIndex=j;
            found=true;
            break;
         }
      }
      if(!found)
      filteredData[i].profileIndex=-1;

     
   }
   console.log("Drawsystemkrls Processed system KRL=",filteredData);
   return filteredData;
  }

  const checkAnchoredKRLToBeIncludedCustom=(data)=>{
 
    let startDate=dayjs(data.start_date.split("-").reverse().join("-"))
  for(let i=0;i<historicalData.profilesData.length;i++){
    if(data.start_date==historicalData.profilesData[i].dateList[0] || data.start_date==historicalData.profilesData[i].dateList[historicalData.profilesData[i].dateList.length-1]){
      // console.log("Found111 IF checkAnchoredKRLToBeIncludedCustom=",i,data,historicalData.profilesData.length)
      return i;
    }
    
    else if(!(startDate.isBefore(historicalData.profilesData[i].startDate)) &&(startDate.isBefore(historicalData.profilesData[i].endDate)) ){
      // console.log("Found111 ELSE checkAnchoredKRLToBeIncludedCustom=",i,data,historicalData.profilesData.length)
      return i;


    }
    
  }
  return -1;
}
  const checkAnchoredKRLToBeIncluded=(data)=>{
 
   
    for(let i=0;i<historicalData.profilesData.length;i++){
      if(data.start_date==historicalData.profilesData[i].dateList[0] && data.start_date==historicalData.profilesData[i].dateList[historicalData.profilesData[i].dateList.length-1])
      return i;
      
      
    }
    return -1;
  }

  const processCustomKRLData=()=>{
    // let krlData=props.krlData;
  let chartStartDate=dayjs(props.chartData.vaMapList[1]?.dateList[0].split("-").reverse().join("-"))
        // let filteredData=krlData?.filter(item=>(item.id && (item.type || item.category=="CUSTOM_KRL") && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
        // let filteredData=krlData?.filter(item=>(item.id && (item.type!="autogen" && (item.type!="custom"||item.category=="CUSTOM_KRL") && item.type!="bookmark_custom") && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
       
        let filteredData=krlData?.filter(item=>(item.id && (item.type!="autogen" && (item.type!="custom"||item.category=="CUSTOM_KRL")) && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
       
        let finalData=[];
        let i=0
        for(i=0;i<filteredData?.length;i++){
          let level=parseFloat(((filteredData[i].level*10000)/10000).toFixed(4));
          let levelMod=parseFloat(((filteredData[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
          filteredData[i].levelTPO=levelTPO;

          if(filteredData[i].category=="CUSTOM_KRL"){
            let index=checkAnchoredKRLToBeIncludedCustom(filteredData[i])
            if(index>=0){
              filteredData[i].profileIndex=index;
              finalData.push(filteredData[i])
            }
            else if( filteredData[i].end_date==""){
              filteredData[i].profileIndex=-1;
              finalData.push(filteredData[i])
            }
           
          }
          else{
            let index=checkAnchoredKRLToBeIncluded(filteredData[i])
            if(index>=0){
              filteredData[i].profileIndex=index;
              finalData.push(filteredData[i])
            }
            else{
              let index=historicalData.profilesData.findIndex(item=>item.dateList[item.dateList.length-1]==filteredData[i].end_date)
              // console.log("Test Composite index=",index,filteredData[i].start_date)
              if(index!=-1){
                filteredData[i].profileIndex=index;
                finalData.push(filteredData[i])
              // for(let k=i+1;k<filteredData.length;k++){
              //   if(filteredData[k].id== filteredData[i].id && filteredData[k].type== filteredData[i].type &&filteredData[k].end_date== historicalData.profilesData[index].dateList[historicalData.profilesData[index].dateList.length-1]){
              //     finalData.push(filteredData[i])
              //     i=k;
              //   }
              // }
            }  
          }

          }
        }
        console.log("processed custom KRL=",finalData)
        return finalData; 
      }

      const mergeAndUpdateLists = (list1, list2) => {
        // Helper function to find the index of the object in list1
        const findIndex = (list, obj) => {
          return list.findIndex(
            (item) =>
              item.id === obj.id &&
              item.category === obj.category &&
              item.type === obj.type&&
              (item.type=="autogen" ||item.type=="custom" ||item.type=="bookmark_custom")
          );
        };
      
        const findIndexAnchored = (list, obj) => {
          return list.findIndex(
            (item) =>
              item.id === obj.id &&
              item.category === obj.category &&
              item.type === obj.type&&
              (item.type!="autogen" && item.type!="custom" && item.type!="bookmark_custom")&&
              item.start_date === obj.start_date&&
              item.end_date === obj.end_date
          );
        };
        // Iterate through each object in list2
        list2.forEach((obj2) => {
          if(obj2.type=="autogen"|| obj2.type=="custom"|| obj2.type=="bookmark_custom"){
          const index = findIndex(list1, obj2);
      
          if (index !== -1) {
            // Object exists in list1, update it
            list1[index] = { ...list1[index], ...obj2 };
          } else {
            // Object does not exist in list1, add it
            list1.push(obj2);
          }
        }
        });
        list2.forEach((obj2) => {
          if(obj2.type!="autogen" && obj2.type!="custom"&& obj2.type!="bookmark_custom"){
          const index = findIndexAnchored(list1, obj2);
      
          if (index !== -1) {
            // Object exists in list1, update it
            list1[index] = { ...list1[index], ...obj2 };
          } else {
            // Object does not exist in list1, add it
            list1.push(obj2);
          }
        }
        });
        console.log("KRL TESTING Updated list",list1);
        return list1;
      }; 
   //handler for KRL data
   useLayoutEffect(()=>{
      console.log("KRL TESTING KRL Test MP chart 0",props.chartRendered,props.krlData);
    if(!krlLoading && props.krlData!=undefined && props.chartData!=undefined && props.chartData.vaMapList.length>1){
    console.log("KRL Test MP chart 1");
     let chartStartDate=dayjs(props.chartData.vaMapList[1].dateList[0].split("-").reverse().join("-"))
     let filteredData=props.krlData.filter(item=>(item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate)))
      
    
    //  setKRLData(filteredData);
    //if incremental krl comes merge and update existing krl data
    if(props.chartRendered && krlData){
      let copyList=JSON.parse(JSON.stringify(krlData));
      let finalKrlData=mergeAndUpdateLists(copyList,props.krlData)
      setKRLData(finalKrlData);
    }  
     else 
     setKRLData(props.krlData);
    //  console.log("KRL filtereddata=",filteredData);

      //TODO: Remove after testing
    //  let uniqueResultOne = props.krlData.filter(function(obj) {
    //     return !filteredData.some(function(obj2) {
    //         return obj.level == obj2.level;
    //     });
    //   });

    //   console.log("KRL rejected KRLS=",uniqueResultOne);
      
    // setToggleRepaint(!toggleRepaint);

    }
   
   },[props.krlData])

   const breakVPList=(vpList)=>{
    let processedVPList=[];
    if(vpList && vpList.length>0){
      let factor=Math.round(props.selectedInstrumentTPOData.vp_tpo/VOLUME_PROFILE_TPO_LIMIT);
      vpList.forEach((curr, index) => {
              
        // divide the array into a group of 0.1 tpo
        // if(total>max) return;
        for(let i=0;i<factor;i++){
          if(curr!=0){
           
            processedVPList.push((curr/factor))
          }
          else{
           
            processedVPList.push(0);
          }
          
        }

      })
      return processedVPList;
    }else{
      return processedVPList;;
    }
   }

      //process the vplist into 0.1 tpo size if available
      const parseVPList=(_data,isLive=false)=>{
        return [];

        console.log("parseVPList,tpo data MP Chart =",props.selectedInstrumentTPOData,props.selectedInstrumentTPOData.vp_tpo,_data)
        let processedVPList=[];
        if(_data.vaMapList!=undefined && _data.vaMapList.length>0){
          _data.vaMapList.forEach((item, mainIndex) => {
            if(props.selectedInstrumentTPOData.vp_tpo>VOLUME_PROFILE_TPO_LIMIT){  //then only do the processing
             let factor=Math.round(props.selectedInstrumentTPOData.vp_tpo/VOLUME_PROFILE_TPO_LIMIT);
             processedVPList[mainIndex]=[];
             if(item.vpList!=undefined || item.vpList.length>0) {
              let max=factor*item.vpList.length;
              let total=0;
              // if(isLive){
              // item.vpList.unshift(39);
              // item.vpList.unshift(39);
              // item.vpList.unshift(90);
              // }  
             item.vpList.forEach((curr, index) => {
              
              // divide the array into a group of 0.1 tpo
              if(total>max) return;
              for(let i=0;i<factor;i++){
                if(curr!=0){
                  total=total+1;
                  processedVPList[mainIndex].push(Math.round(curr/factor))
                }
                else{
                  total=total+1;
                  processedVPList[mainIndex].push(0);
                }
                
              }

            })
          }else{
            processedVPList[mainIndex].push([]);
            
          }
            // console.log("factor, lenth, mod length",factor,item.vpList.length,processedVPList[mainIndex].length)
          }
          else{
            processedVPList[mainIndex]=[];
            //return the vp list as it
            if(item.vpList!=undefined || item.vpList.length>0) {
             
            item.vpList.forEach((curr, index) => {
              if(curr!=0)
                processedVPList[mainIndex].push(curr);
              else
                processedVPList[mainIndex].push(0);
              
            })

            }
            else
            processedVPList[mainIndex].push([]);
          }
          })
          return processedVPList;
         
          }else{
          return [];
        }
       }  
    
    
  
  //processes the api response data 
  const parseData=(_data,isFirstLoad=false,processedVPList=undefined,isLive=false,isTPOChanged=false)=>{
    if(processedVPList==undefined)
    processedVPList=vpList;
    console.log("parseData= viewState=",props.viewState);
    // console.log("processedVPList in parseData =",processedVPList);
        const profileData=[];
        let tempDateList=[];
        const config={
          totalWidth:0,  
          dateList:_data.dateList,
          dateKeyList:_data.dateKeyList,
          max:Number.MIN_VALUE,
          min:Number.MAX_VALUE,
          lastDataTime:_data.lastDataTime,
          tpo:selectedTPO
        }
        let fakeTickPresent=false;
        _data.vaMapList.forEach((item, mainIndex) => {
          // if(isLive)
          // item.low=item.low-1.5;
          
          item.tpo=selectedTPO;
          fakeTickPresent=false;
          //keep tracks of max of min from all the profiles to keep contraint on y axis panning
          if(item.dateList.length==1){
            tempDateList.push(item.dateList[0])
          }
          else{
            // console.log("parse data dateList composite = ",item.dateList[0]+" - "+item.dateList[item.dateList.length-1]);
            if(props.selectedTimeFrame==TIME_FRAME_VALUES.yearly){
              tempDateList.push([item.dateList.length].toString()+"D: "+item.dateList[0]+"..."+item.dateList[item.dateList.length-1])
            }else{
              tempDateList.push([item.dateList.length].toString()+"D: "+(item.dateList[0].slice(0,5))+"..."+item.dateList[item.dateList.length-1])
           
            }
          }
          config.max=Math.max(config.max,item.high);
          config.min=Math.min(config.min,item.low);
            // console.log("isFirstload mainindex length = ",isFirstLoad,mainIndex,_data.dateList[mainIndex])
            //handling for pre open ticks
            let max=parseFloat(((item.high*10000)/10000).toFixed(4));
            let maxMod=parseFloat(((item.high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let min=parseFloat(((item.low*10000)/10000).toFixed(4));
            let minMod=parseFloat(((item.low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let maxTPO=parseFloat((max-maxMod).toFixed(4));
            let minTPO=parseFloat((min-minMod).toFixed(4));   //it may change due to d_open is lower than min tpo
            let actualMinTPO=parseFloat((min-minMod).toFixed(4)); //keep track actual low value tpo
            let open=parseFloat(((item.open*10000)/10000).toFixed(4));
            let openMod=parseFloat(((item.open*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let close=parseFloat(((item.close*10000)/10000).toFixed(4));
            let closeMod=parseFloat(((item.close*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let openTPO=parseFloat((open-openMod).toFixed(4));
            let closeTPO=parseFloat((close-closeMod).toFixed(4));
            
            let ibHigh=parseFloat(((item.ib_high*10000)/10000).toFixed(4));
            let ibHighMod=parseFloat(((item.ib_high*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibHighTPO=parseFloat(((ibHigh-ibHighMod)).toFixed(4));
           
            let ibLow=parseFloat(((item.ib_low*10000)/10000).toFixed(4));
            let ibLowMod=parseFloat(((item.ib_low*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let ibLowTPO=parseFloat((ibLow-ibLowMod).toFixed(4));

            let vpocTPO=undefined;
            if(item.vpoc!=undefined && item.vpoc!=null){
              let vpoc=parseFloat(((item.vpoc*10000)/10000).toFixed(4));
              let vpocMod=parseFloat(((item.vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vpocTPO=parseFloat((vpoc-vpocMod).toFixed(4));
            }
            
            let vwapTPO=undefined;
            if(item.vwap!=undefined && item.vwap!=null){
              let vwap=parseFloat(((item.vwap*10000)/10000).toFixed(4));
              let vwapMod=parseFloat(((item.vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vwapTPO=parseFloat((vwap-vwapMod).toFixed(4));
            }
            
            let vahTPO=undefined;
            if(item.vah!=undefined&& item.vah!=null){
              let vah=parseFloat(((item.vah*10000)/10000).toFixed(4));
              let vahMod=parseFloat(((item.vah*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vahTPO=parseFloat((vah-vahMod).toFixed(4));
            }
            
            let valTPO=undefined;
            if(item.val!=undefined && item.val!=null){
              let val=parseFloat(((item.val*10000)/10000).toFixed(4));
              let valMod=parseFloat(((item.val*10000)%(selectedTPO*10000))/10000).toFixed(4);
              valTPO=parseFloat((val-valMod).toFixed(4));
            }

            let vahTPOBased=undefined;
            if(item.tpo_vah!=undefined&& item.tpo_vah!=null){
              let vah=parseFloat(((item.tpo_vah*10000)/10000).toFixed(4));
              let vahMod=parseFloat(((item.tpo_vah*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vahTPOBased=parseFloat((vah-vahMod).toFixed(4));
            }
            
            let valTPOBased=undefined;
            if(item.tpo_val!=undefined && item.tpo_val!=null){
              let val=parseFloat(((item.tpo_val*10000)/10000).toFixed(4));
              let valMod=parseFloat(((item.tpo_val*10000)%(selectedTPO*10000))/10000).toFixed(4);
              valTPOBased=parseFloat((val-valMod).toFixed(4));
            }
            
            let vpocTPOBased=undefined;
            if(item.tpoc!=undefined&& item.tpoc!=null){
              let tpoc=parseFloat(((item.tpoc*10000)/10000).toFixed(4));
              let tpocMod=parseFloat(((item.tpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vpocTPOBased=parseFloat((tpoc-tpocMod).toFixed(4));
            }
            
            let vwapTPOBased=undefined;
            if(item.tpo_vwap!=undefined && item.tpo_vwap!=null){
              let val=parseFloat(((item.tpo_vwap*10000)/10000).toFixed(4));
              let valMod=parseFloat(((item.tpo_vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
              vwapTPOBased=parseFloat((val-valMod).toFixed(4));
            }
            let hvn_nodes=[];
            if(item.hvn_nodes!=undefined &&  item.hvn_nodes!=null && item.hvn_nodes.length>0){
              for(let i=0;i<item.hvn_nodes.length;i++){
                let hvn=parseFloat(((item.hvn_nodes[i]*10000)/10000).toFixed(4));
                let hvnMod=parseFloat(((item.hvn_nodes[i]*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let hvnTPO=parseFloat((hvn-hvnMod).toFixed(4));
                hvn_nodes.push(hvnTPO);
              }
            }
            let oiSpikesTPO=[];
            if(item.oi_spikes_tuples!=undefined &&  item.oi_spikes_tuples!=null && item.oi_spikes_tuples.length>0){
              for(let i=0;i<item.oi_spikes_tuples.length;i++){
                let oiSpike=parseFloat(((item.oi_spikes_tuples[i][0]*10000)/10000).toFixed(4));
                let oiSpikeMod=parseFloat(((item.oi_spikes_tuples[i][0]*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let val=parseFloat((oiSpike-oiSpikeMod).toFixed(4));
                oiSpikesTPO.push(val);
              }
            }
           
            let closingVWAPTPO=undefined;
            if(item.closing_vwap!=undefined && item.closing_vwap!=null){
              let val=parseFloat(((item.closing_vwap*10000)/10000).toFixed(4));
              let valMod=parseFloat(((item.closing_vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
              closingVWAPTPO=parseFloat((val-valMod).toFixed(4));
            }

            let halfBackVal=((item.high+item.low)/2).toFixed(2);
            let halfBack=parseFloat((((halfBackVal)*10000)/10000).toFixed(4));
            let halfBackMod=parseFloat((((halfBackVal)*10000)%(selectedTPO*10000))/10000).toFixed(4);
            let halfBackTPO=parseFloat((halfBack-halfBackMod).toFixed(4));

            let dOpenTPO=undefined;
            if(item.d_open){
              let dOpen=parseFloat(((item.d_open*10000)/10000).toFixed(4));
              let dOpenMod=parseFloat(((item.d_open*10000)%(selectedTPO*10000))/10000).toFixed(4);
              dOpenTPO=parseFloat((dOpen-dOpenMod).toFixed(4));
              console.log("DOPEN TPO=",dOpenTPO,openTPO, item.dateList[0])
            }
           
            // console.log("oiSpikesTPO =",oiSpikesTPO);

            //calculate volume quantity
            let volume=0;
            if(item.avg_vol != undefined)
              volume = item.avg_vol;
            else if(item.vol != undefined)
              volume = item.vol;
            else
              if(item.tpo_vol_map!=undefined && item.tpo_vol_map!=null){
                for (const key in item.tpo_vol_map) {
                  volume=volume+item.tpo_vol_map[key];
                }  
              }

              let profileStartDate=dayjs(item.dateList[0].split("-").reverse().join("-"))
              let profileEndDate=dayjs(item.dateList[item.dateList.length-1].split("-").reverse().join("-"))
            
            profileData[mainIndex]={
                maxTPO:maxTPO,
                minTPO:minTPO,
                data:[],
                tpoVaList:item.tpoVaList,
                stackedWidth:-1,
                splitWidth:-1,
                isStacked:((((isFirstLoad || isLive)&& !liveDataComposite && item.dateList.length==1) && mainIndex==_data.vaMapList.length-1) && props.isInstrumentLive)?false:true,
                isVolumeProfile:props.viewState.tpo_on_vol||props.viewState.only_vol||props.viewState.vol_tpo_side,
                isTPOonVolume:props.viewState.tpo_on_vol,
                isVolumeNumbers:props.profileVolumeNumberVisible,
                // volumeBasedVA:props.selectedInstrumentData.vp && !props.tpoBasedVA,
                // tpoBasedVA:!props.selectedInstrumentData.vp || props.tpoBasedVA,
                vaType:props.tpoBasedVA,
                showTPO:props.viewState.tpo_only,
                showOnlyVolumeProfile:props.viewState.only_vol,
                date:item.dateList[0],
                dateList:item.dateList,
                dateKeyList:item.dateKey,
                dstrMap:item.dateKey ,//will be used for caching lookup
                volumeData:[],
                volumeWidth:-1,
                volumeWidthSplit:-1,
                high:maxTPO,
                low:minTPO,
                maxVolPerc:-1,
                totalVolume:0,
                open:item.open,
                openTPO:openTPO,
                close:item.close,
                closeTPO:closeTPO,
                currentVal:item.close,
                latency:item.latency,
                vah:item.vah,
                val:item.val,
                vahTPO:vahTPO,
                valTPO:valTPO,
                vwap:item.vwap,
                sdClose:item.sd_close,
                vpoc:item.vpoc,
                vpoc_vol:item.vpoc_vol,
                vwapTPO:vwapTPO,
                vpocTPO:vpocTPO,
                hvn_nodes:hvn_nodes,
                hvnList:item.hvn_nodes,
                oi_spikes_tuples:item.oi_spikes_tuples,
                oiSpikesTPO:oiSpikesTPO,
                ibHighTPO:ibHighTPO,
                ibLowTPO:ibLowTPO,
                vahTPOBased:vahTPOBased,
                valTPOBased:valTPOBased,
                vpocTPOBased:vpocTPOBased,
                vwapTPOBased:vwapTPOBased,
                tpoc:item.tpoc,
                tpo_vwap:item.tpo_vwap,
                tpo_vah:item.tpo_vah,
                tpo_val:item.tpo_val,
                tpo:selectedTPO,
                closingVWAPTPO:closingVWAPTPO,
                closingVWAP:item.closing_vwap,
                oi:item.oi,
                coi:item.coi,
                day_type:item.day_type,
                fa_level:item.fa_level,
                volume:volume,
                tpo_vol_map:item.tpo_vol_map,
                selected_tf:props.selectedTimeFrame,
                ptype:item.ptype,
                ib_low:item.ib_low,
                ib_high:item.ib_high,
                ib:item.ib,
                range:item.range,
                oi:item.oi,
                doi:item.oi_change,
                coi:item.coi,
                dcoi:item.coi_change,
                ib_vol:item.ib_vol,
                highVal:item.high,
                lowVal:item.low,
                tooltipMap:new Map(),
                halfBack:halfBackVal,
                halfBackTPO:halfBackTPO,
                dOpen:item.d_open,
                dOpenTPO:dOpenTPO,
                startDate:profileStartDate,
                endDate:profileEndDate,
                actualMinTPO:actualMinTPO

          }
         
          //if tpo changed has triggered the recalculation, retrieve the previous state of the profile
          if(isTPOChanged){
            if(historicalData!=undefined && historicalData.profilesData!=undefined && historicalData.profilesData[mainIndex]!=undefined){
                profileData[mainIndex].isStacked=historicalData.profilesData[mainIndex].isStacked
                profileData[mainIndex].isVolumeProfile=historicalData.profilesData[mainIndex].isVolumeProfile
                profileData[mainIndex].isTPOonVolume=historicalData.profilesData[mainIndex].isTPOonVolume
                profileData[mainIndex].showTPO=historicalData.profilesData[mainIndex].showTPO
                profileData[mainIndex].showOnlyVolumeProfile=historicalData.profilesData[mainIndex].showOnlyVolumeProfile
                profileData[mainIndex].isVolumeNumbers=historicalData.profilesData[mainIndex].isVolumeNumbers
                // profileData[mainIndex].volumeBasedVA=historicalData.profilesData[mainIndex].volumeBasedVA
                // profileData[mainIndex].tpoBasedVA=historicalData.profilesData[mainIndex].tpoBasedVA
                profileData[mainIndex].vaType=historicalData.profilesData[mainIndex].vaType

            }
         }

          //TODO: fake tpo handling  
          if(_data.lastDataTime !==undefined && _data.lastDataTime!=null){
            if(mainIndex==_data.vaMapList.length-1){
              if(profileData[mainIndex].openTPO>profileData[mainIndex].maxTPO){
              profileData[mainIndex].maxTPO=profileData[mainIndex].openTPO
              }

              if(profileData[mainIndex].openTPO<profileData[mainIndex].minTPO){
              profileData[mainIndex].minTPO=profileData[mainIndex].openTPO
              }
            }
          }  
          // console.log("parseData ===>profileData[mainIndex]=",profileData[mainIndex]);
            let j=-1;
            let maxNoOfElement=-1;  //keep track the max number of element for a particular TPO for stacked profile
            if(item.d_open){
              if(dOpenTPO>profileData[mainIndex].maxTPO){
                // console.log("Hooooooooooooo MAX",dOpenTPO,minTPO,maxTPO,profileData[mainIndex].dateList)
                profileData[mainIndex].maxTPO=dOpenTPO;
              }
              if(dOpenTPO<profileData[mainIndex].minTPO){
                // console.log("Hooooooooooooo MAX",dOpenTPO,minTPO,maxTPO,profileData[mainIndex].dateList)
                profileData[mainIndex].minTPO=dOpenTPO;
              }
            }
            
            for(let i=profileData[mainIndex].minTPO;i<=profileData[mainIndex].maxTPO;i=parseFloat((i+selectedTPO).toFixed(4)))
            {   
                j=j+1;
                profileData[mainIndex].data[j]=[];
                // eslint-disable-next-line no-loop-func
                item.tpoVaList.forEach((perTPOProfile, index) => {
                //  console.log("openval=", parseFloat(perTPOProfile.open.toFixed(4)) ,i) 
               
                let max=((perTPOProfile.high*10000)/10000).toFixed(4);
                let maxMod=parseFloat(((perTPOProfile.high*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let min=((perTPOProfile.low*10000)/10000).toFixed(4);
                let minMod=parseFloat(((perTPOProfile.low*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let maxTPO=parseFloat((max-maxMod).toFixed(4))
                let minTPO=parseFloat((min-minMod).toFixed(4))
                let open=((perTPOProfile.open*10000)/10000).toFixed(4);
                let openMod=parseFloat(((perTPOProfile.open*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let close=((perTPOProfile.close*10000)/10000).toFixed(4);
                let closeMod=parseFloat(((perTPOProfile.close*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let openTPO=parseFloat((open-openMod).toFixed(4))
                let closeTPO=parseFloat((close-closeMod).toFixed(4))
                let vpoc=((perTPOProfile.vpoc*10000)/10000).toFixed(4);
                let vpocMod=parseFloat(((perTPOProfile.vpoc*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let vwap=((perTPOProfile.vwap*10000)/10000).toFixed(4);
                let vwapMod=parseFloat(((perTPOProfile.vwap*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let vpocTPO=parseFloat((vpoc-vpocMod).toFixed(4))
                let vwapTPO=parseFloat((vwap-vwapMod).toFixed(4))
                let halfBackVal=(perTPOProfile.high+perTPOProfile.low)/2;
                let halfBack=(((halfBackVal)*10000)/10000).toFixed(4);
                let halfBackMod=parseFloat((((halfBackVal)*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let halfBackTPO=parseFloat((halfBack-halfBackMod).toFixed(4))

               
                if(index==0 && dOpenTPO){
                  if(dOpenTPO>maxTPO){
                    // console.log("Hooooooooooooo MAX",dOpenTPO,minTPO,maxTPO,profileData[mainIndex].dateList)
                    maxTPO=dOpenTPO;
                  }
                  if(dOpenTPO<minTPO){
                    // console.log("Hooooooooooooo MAX",dOpenTPO,minTPO,maxTPO,profileData[mainIndex].dateList)
                    minTPO=dOpenTPO;
                  }
                }
                // console.log("Halfback tpo for index ",mainIndex,index,halfBackTPO)
                
                // console.log("per tpo val max min",i,minTPO,maxTPO);    
                if(i>=(minTPO) && i<=(maxTPO))
                 
                    {
                        let isOISpike=false;
                        let isNegativeOI=false;
                        // console.log("fOI spikes=",oiSpikesTPO,i);
                        let pos=oiSpikesTPO.findIndex(item => item == i)
                        if(pos!=-1){
                          if(index==item.oi_spikes_tuples[pos][3]){
                            // console.log("foind oi spike tpo index, val=",index,i);
                            isOISpike=true;
                            isNegativeOI=item.oi_spikes_tuples[pos][1]<0?true:false;
                          }
                        }
                        profileData[mainIndex].data[j].push({
                            index:index,
                            charText:item.tpoChars[index],
                            isOpen:(index==0 && i==openTPO)?true:false,
                            isClose:(index==(item.tpoChars.length-1) && i==closeTPO)?true:false,
                            isTPOOpen:(openTPO==i),
                            isTPOClose:(closeTPO==i),
                            isTPOVPOC:(vpocTPO==i),
                            isTPOVWAP:(vwapTPO==i),
                            isOISpikeTPO:isOISpike,
                            isNegativeOI:isNegativeOI,
                            isHalfBackTPO:(halfBackTPO==i),
                            isDOpenTPO:(index==0 && i==dOpenTPO)
                            // tpoChar:item.tpo
                        })
                       
                    }
                });
                //it will keep track max number of TPOs in a row for stacked profile
                maxNoOfElement=Math.max(maxNoOfElement,profileData[mainIndex].data[j].length)
            }
           
            // if(isLive)
            // console.log("Volume test processvol=",processedVPList[mainIndex])
            //calculation for volume profile
            // if(item.vpList!=undefined&&item.vpList!=null && item.vpList.length>0 && processedVPList!=undefined &&processedVPList[mainIndex]!=undefined&& processedVPList[mainIndex].length>0){
              if(item.vpList!=undefined&&item.vpList!=null && item.vpList.length>0){
           
              console.log("Roar Date====================>",item.dateKey);
              const isMultiple=(selectedTPO*1000)%(props.selectedInstrumentTPOData.vp_tpo*1000)===0
              if(props.selectedInstrumentTPOData.vp_tpo<=selectedTPO && isMultiple){
                let percSum=0
                let array=[...item.vpList];
                
                let total = array.reduce((pv, cv) => pv + cv, 0);
                profileData[mainIndex].totalVolume=total;
                let maxPerc=0;
                let sum=0;
                let aggrFactor=Math.round(selectedTPO/props.selectedInstrumentTPOData.vp_tpo);
                let lowDiff=item.low-profileData[mainIndex].actualMinTPO;
                let lowerIndexToSkip=0;
               
                if(lowDiff!=0){
                  let diff=selectedTPO-lowDiff;  //9
                  let number=Math.ceil(diff/props.selectedInstrumentTPOData.vp_tpo);
                  console.log("ROAR LOW TEST=",item.dateKey,lowDiff,lowerIndexToSkip,number,item.low,array.length);
                  if(number==0){
                    profileData[mainIndex].volumeData.push({
                      val:0,
                      perc:0,
                      })
                  }else{
                    lowerIndexToSkip=number;
                    for(let i=0;i<Math.abs(number);i++){
                      sum=sum+item.vpList[i]
                    }
                    profileData[mainIndex].volumeData.push({
                      val:sum,
                      perc:(parseFloat((sum*100/total).toFixed(4))),
                      })
                    maxPerc=Math.max(maxPerc,(parseFloat((sum*100/total).toFixed(4))));
                    percSum=parseFloat(percSum+(parseFloat((sum*100/total).toFixed(4))))

                  }
                }
                 //from 2nd lowest tpo to max tpo volume
                 let count=lowerIndexToSkip;
                 console.log("Roar Multiple",lowerIndexToSkip,array.length,aggrFactor);
                 for(let i=Math.abs(lowerIndexToSkip);i<(array.length);i=i+aggrFactor){
                  sum=0;
                  for(let j=0;j<aggrFactor;j++){
                   if(item.vpList[i+j]!=undefined){
                     sum=sum+item.vpList[i+j]
                     count++;
                   }
                  
                   }
                  
                   profileData[mainIndex].volumeData.push({
                                 val:sum,
                                 perc:(parseFloat((sum*100/total).toFixed(4))),
                   })
                   
                   maxPerc=Math.max(maxPerc,(parseFloat((sum*100/total).toFixed(4))));
                   percSum=parseFloat(percSum+(parseFloat((sum*100/total).toFixed(4))))
               
                 }
                 console.log("Roar low test count=",count, profileData[mainIndex].volumeData)
                 // //console.log("volume data length percSum maxPerc",profileData[mainIndex].volumeData,profileData[mainIndex].volumeData.length,percSum,maxPerc,array,processedVPList[mainIndex]);
                 profileData[mainIndex].maxVolPerc=maxPerc;


              }
              else{
                let vpList=breakVPList(item.vpList);
               
                let percSum=0
                let array=[...vpList];
                
                let total = array.reduce((pv, cv) => pv + cv, 0);
                profileData[mainIndex].totalVolume=total;
                let maxPerc=0;
                let sum=0;
                let divisionFactor=Math.round(props.selectedInstrumentTPOData.vp_tpo/VOLUME_PROFILE_TPO_LIMIT);
                let aggrFactor=Math.round(selectedTPO/VOLUME_PROFILE_TPO_LIMIT);
                let lowDiff=item.low-profileData[mainIndex].actualMinTPO;
                let vpLow=parseFloat(((item.low*10000)/10000).toFixed(4));
                let vpLowMod=parseFloat(((item.low*10000)%(props.selectedInstrumentTPOData.vp_tpo*10000))/10000).toFixed(4);
                let vpLowTPO=parseFloat((vpLow-vpLowMod).toFixed(4));
                let newLow=parseFloat(((item.low*10000)/10000).toFixed(4));
                let newLowMod=parseFloat(((item.low*10000)%(VOLUME_PROFILE_TPO_LIMIT*10000))/10000).toFixed(4);
                let newLowTPO=parseFloat((newLow-newLowMod).toFixed(4));
                let tpoDiff=profileData[mainIndex].actualMinTPO-vpLowTPO;
                let skip=Math.round((newLowTPO-vpLowTPO)/VOLUME_PROFILE_TPO_LIMIT);
                let totalTPOPerVP=Math.round(props.selectedInstrumentTPOData.vp_tpo/VOLUME_PROFILE_TPO_LIMIT);
                let newVolForLowestVP=(item.vpList[0]/(totalTPOPerVP-skip));
                 let startIndex=Math.round((tpoDiff)/VOLUME_PROFILE_TPO_LIMIT);
                console.log("ROAR test=",item.low,profileData[mainIndex].actualMinTPO,vpLow,vpLowTPO,skip,totalTPOPerVP,array.length,item.vpList[0],newVolForLowestVP)
                console.log("ROAR VPList=",processedVPList[mainIndex],vpList,skip,startIndex,vpLowTPO,profileData[mainIndex].actualMinTPO);
                
               
                if(skip>0){
                 
                  for(let i=0;i<skip;i++){
                    vpList[i]=0;
                  }
                  for (let i=skip;i<totalTPOPerVP;i++){
                    vpList[i]=newVolForLowestVP;
                  }
                }
                if(tpoDiff<0){
                 
                   for(let i=0;i<Math.abs(startIndex);i++){
                    vpList.unshift(0);
                  }
                   startIndex=0;
                }
                 console.log("ROAR=",item.dateKey,item.low,profileData[mainIndex].actualMinTPO,vpLowTPO,skip,tpoDiff,startIndex,item.vpList[0],item.vpList[1],vpList[0],vpList[1],vpList[2],vpList[3],vpList[4],vpList[5], newVolForLowestVP);
               
                 //Handling of high tpo values
                 let nextHigh=item.high+props.selectedInstrumentTPOData.vp_tpo;
                let vpHigh=parseFloat(((nextHigh*10000)/10000).toFixed(4));
                let vpHighMod=parseFloat(((nextHigh*10000)%(props.selectedInstrumentTPOData.vp_tpo*10000))/10000).toFixed(4);
                let vpHighTPO=parseFloat((vpHigh-vpHighMod).toFixed(4));
                let newHigh=parseFloat(((item.high*10000)/10000).toFixed(4));
                let newHighMod=parseFloat(((item.high*10000)%(VOLUME_PROFILE_TPO_LIMIT*10000))/10000).toFixed(4);
                let newHighTPO=parseFloat((newHigh-newHighMod).toFixed(4));
                let tpoDiffHigh=vpHighTPO-VOLUME_PROFILE_TPO_LIMIT-profileData[mainIndex].high;
                let skipHigh=Math.round((vpHighTPO-VOLUME_PROFILE_TPO_LIMIT-newHighTPO)/VOLUME_PROFILE_TPO_LIMIT);
               
                let startIndexHigh=Math.round((tpoDiffHigh)/VOLUME_PROFILE_TPO_LIMIT);
                let newVolForHighestVP=(item.vpList[item.vpList.length-1]/(totalTPOPerVP-skipHigh));
                console.log("Roart High Test=",item.high,profileData[mainIndex].high,vpHighTPO,newHighTPO,tpoDiffHigh,skipHigh,startIndexHigh,item.vpList[item.vpList.length-1],newVolForHighestVP,vpList.length);
                
                if(skipHigh>0){
                 
                  for (let i=skipHigh;i<totalTPOPerVP;i++){
                    vpList[vpList.length-1-i]=newVolForHighestVP;
                    console.log("ROAR HIGH Vol=",vpList[vpList.length-1-i]);
                  }
                  console.log("Roar , skipHigh",skipHigh);
                  for(let i=0;i<skipHigh;i++){
                    console.log("ROAR inside pop");
                    vpList.pop();
                  }
                }
                let finalLength=vpList.length;
                console.log("ROAR HIGH Vol 2=",startIndex,finalLength, vpList[vpList.length-1],vpList[vpList.length-2],vpList[vpList.length-3],vpList[vpList.length-4],vpList[vpList.length-5],vpList.length)
                console.log("ROAR HIGH Vol 3=",startIndex,finalLength, vpList[0],vpList[1],aggrFactor,vpList)
                  for(let i=startIndex;i<finalLength;i=i+aggrFactor){
                  sum=0;
                  for(let j=0;j<aggrFactor;j++){
                   if(vpList[i+j]!=undefined )
                     sum=sum+vpList[i+j]
                  
                   }
                  
                   profileData[mainIndex].volumeData.push({
                                 val:Math.floor(sum),
                                 perc:(parseFloat((sum*100/total).toFixed(4))),
                   })
                   
                   maxPerc=Math.max(maxPerc,(parseFloat((sum*100/total).toFixed(4))));
                   percSum=parseFloat(percSum+(parseFloat((sum*100/total).toFixed(4))))
               
                 }

                    // //console.log("volume data length percSum maxPerc",profileData[mainIndex].volumeData,profileData[mainIndex].volumeData.length,percSum,maxPerc,array,processedVPList[mainIndex]);
                 profileData[mainIndex].maxVolPerc=maxPerc;


                }
              }
            else{
              //console.log("processedVP is  undefined  in parseData ===========>",profileData[mainIndex].volumeData);

            }

            
            // if(isLive){
            //   console.log("Volume issue test=",profileData[mainIndex].lowVal,profileData[mainIndex].volumeData)
            // }
          
            profileData[mainIndex].stackedWidth=Math.max(PROFILE_MIN_WIDTH, maxNoOfElement*TPO_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN);
            profileData[mainIndex].splitWidth=Math.max(PROFILE_MIN_WIDTH,item.tpoChars.length*TPO_WIDTH+PROFILE_LEFT_MARGIN+PROFILE_RIGHT_MARGIN);
            profileData[mainIndex].volumeWidth=Math.round(profileData[mainIndex].stackedWidth);
            profileData[mainIndex].volumeWidthSplit=Math.round(profileData[mainIndex].splitWidth);
            //add widht according to the profile view
            if( profileData[mainIndex].isStacked)
            config.totalWidth=config.totalWidth+profileData[mainIndex].stackedWidth;
            else
            config.totalWidth=config.totalWidth+profileData[mainIndex].splitWidth;
            //add volume width if its side by side
            if( profileData[mainIndex].isVolumeProfile && !profileData[mainIndex].isTPOonVolume && !profileData[mainIndex].showOnlyVolumeProfile){
              config.totalWidth=config.totalWidth+profileData[mainIndex].volumeWidth 
            }
            

          });

          if(isLive &&profileData[profileData.length-1].isStacked==false && !isFirstLoad){
            config.totalWidth=config.totalWidth+profileData[0].splitWidth;
          
          }
          
          //handle livedata open tick and pre open values
          if(_data.lastDataTime !==undefined && _data.lastDataTime!=null){
            config.lastDataTime=_data.lastDataTime;
            // if(profileData[profileData.length-1].openTPO>profileData[profileData.length-1].maxTPO)
            // profileData[profileData.length-1].maxTPO=profileData[profileData.length-1].openTPO

            // if(profileData[profileData.length-1].openTPO<profileData[profileData.length-1].minTPO)
            // profileData[profileData.length-1].minTPO=profileData[profileData.length-1].openTPO

            //set the minimum width for the live profile: extra space for some info like lastDataTIme etc
            if(profileData[profileData.length-1].stackedWidth<LIVE_PROFILE_MIN_WIDTH){
            profileData[profileData.length-1].stackedWidth=LIVE_PROFILE_MIN_WIDTH;
            profileData[profileData.length-1].splitWidth=LIVE_PROFILE_MIN_WIDTH;
            config.totalWidth=config.totalWidth+LIVE_PROFILE_MIN_WIDTH- profileData[profileData.length-1].stackedWidth;
            }
            console.log("==============fake tick==========",profileData[profileData.length-1].openTPO,profileData[profileData.length-1].maxTPO)
          }

          config.dateList=tempDateList;
          // console.log("tempDateList = ",tempDateList);
        
        // return profileData;
        console.log("TPO issue parseData: profileData=",profileData,config);
        console.log("Handleviewstate parse props.viewState=",props.viewState);
        return {
          profilesData:profileData,
          config:config,
          
        }

    }

    //merges the profiles when more data is loaded while zooming out or panning
    const mergeProfileData=(_data)=>{
      console.log("More data before width=",historicalData.config.totalWidth);
      const mergedConfig={
        totalWidth:_data.config.totalWidth+historicalData.config.totalWidth,  
        dateList:(_data.config.dateList.concat(historicalData.config.dateList)),
        dateKeyList:(_data.config.dateKeyList.concat(historicalData.config.dateKeyList)),
        max:Math.max(_data.config.max,historicalData.config.max),
        min:Math.min(_data.config.min,historicalData.config.min),
        lastDataTime:historicalData.config.lastDataTime,
        tpo:selectedTPO
      }
      console.log("More data after width=",mergedConfig.totalWidth);
      // console.log("Merged config object = ",mergedConfig);
      const mergedProfilesData=_data.profilesData.concat(historicalData.profilesData);
      // console.log("merged profiles data = ",mergedProfilesData);

      const mergedProcessedData= {
        profilesData:mergedProfilesData,
        config:mergedConfig
      }
      console.log("Mergeprofile mergedProcessedData=",mergedProcessedData,_data);
      updateSelectedProfileIndexes(_data.profilesData.length);
      updateContainerdProfileIndexes(_data.profilesData.length);
      setHistoricalData(mergedProcessedData);
    }

    //update the previously selected index when more profiles is loaded
    const updateSelectedProfileIndexes=(updateVal)=>{
      if(selectedProfilesIndex.length>0){
        const updateIndexes = selectedProfilesIndex.map(index => index + updateVal);
        setSelectedProfilesIndex(updateIndexes);
      }
    }
    const updateContainerdProfileIndexes=(updateVal)=>{
      console.log("Mergeprofile mergedProcessedData test 1=",updateVal,rightContainerProfilesIndex);
      if(rightContainerProfilesIndex && rightContainerProfilesIndex.length>0){
        const updateIndexes = rightContainerProfilesIndex.map(index => index + updateVal);
        setRightContainerProfilesIndex(updateIndexes);
      }
    }

    //merge the live data with existing profiles
    const mergeLiveData=(_data,isLive)=>{
      if(_data!=undefined && historicalData!=undefined){
      let mergedConfig={};
      let mergedLiveData={};
      //if instrument is already live or date already exist(incase tradingsession active and instrumnet not live)
      //  then update the last profile with latest live data
      if(_data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date)
      console.log("Date matches for last profile and live profile");
      

      if(isInstrumentLive || _data.profilesData[0].date==historicalData.profilesData[historicalData.profilesData.length-1].date){
        const prevLiveProfile=historicalData.profilesData[historicalData.profilesData.length-1];
        let previousWidth=prevLiveProfile.stackedWidth;
        let currWidth=_data.profilesData[0].stackedWidth;
        if(!prevLiveProfile.isStacked){
          previousWidth=prevLiveProfile.splitWidth;
          currWidth=_data.profilesData[0].splitWidth
        }
        
        if(prevLiveProfile.isVolumeProfile && !prevLiveProfile.isTPOonVolume &&!prevLiveProfile.showOnlyVolumeProfile){
        previousWidth=previousWidth+prevLiveProfile.volumeWidth;
        currWidth=currWidth+_data.profilesData[0].volumeWidth;
        }

        // console.log("Prev life profile found =",prevLiveProfile);
        historicalData.config.max=Math.max(historicalData.config.max,prevLiveProfile.maxTPO);
        historicalData.config.min=Math.min(historicalData.config.min,prevLiveProfile.minTPO);
        historicalData.config.dateList.pop();
        historicalData.config.dateKeyList.pop()
        // console.log("dateList after pop live date",historicalData.config.dateList)  
        
        console.log("MergeLive::Total width before=============>",historicalData.config.totalWidth,_data.profilesData[0].stackedWidth,_data.profilesData[0].splitWidth,prevLiveProfile.stackedWidth,prevLiveProfile.splitWidth,prevLiveProfile.isStacked,_data.profilesData[0].isStacked?_data.profilesData[0].stackedWidth:_data.profilesData[0].splitWidth)
        _data.profilesData[0].isStacked=prevLiveProfile.isStacked;
        _data.profilesData[0].isVolumeProfile=prevLiveProfile.isVolumeProfile;
        _data.profilesData[0].showTPO=prevLiveProfile.showTPO;
        _data.profilesData[0].showOnlyVolumeProfile=prevLiveProfile.showOnlyVolumeProfile;
        // _data.profilesData[0].volumeBasedVA=prevLiveProfile.volumeBasedVA;
        // _data.profilesData[0].tpoBasedVA=prevLiveProfile.tpoBasedVA;
        _data.profilesData[0].vaType=prevLiveProfile.vaType;
        _data.profilesData[0].isTPOonVolume=prevLiveProfile.isTPOonVolume;
        _data.profilesData[0].isVolumeNumbers=prevLiveProfile.isVolumeNumbers;
        
        //remove live data and its width
        mergedConfig={
          // totalWidth:(((_data.profilesData[0].isStacked?_data.profilesData[0].stackedWidth:_data.profilesData[0].splitWidth) +historicalData.config.totalWidth)- 
          // (prevLiveProfile.isStacked?(prevLiveProfile.stackedWidth):
          // (prevLiveProfile.splitWidth))),
          totalWidth:historicalData.config.totalWidth+currWidth-previousWidth,
          dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
          dateKeyList:(historicalData.config.dateKeyList.concat(_data.config.dateKeyList)),
          max:Math.max(_data.config.max,historicalData.config.max),
          min:Math.min(_data.config.min,historicalData.config.min),
          lastDataTime:_data.config.lastDataTime,
          tpo:selectedTPO
        }
        console.log("MergeLive:Total width after=============>",mergedConfig.totalWidth)
        // console.log("live mergedconfig 1= ",mergedConfig,_data.config,historicalData.config.totalWidth,prevLiveProfile);
      
        _data.profilesData[0].isStacked=prevLiveProfile.isStacked;
        _data.profilesData[0].isVolumeProfile=prevLiveProfile.isVolumeProfile;
        _data.profilesData[0].showTPO=prevLiveProfile.showTPO;
        _data.profilesData[0].showOnlyVolumeProfile=prevLiveProfile.showOnlyVolumeProfile;
        // _data.profilesData[0].volumeBasedVA=prevLiveProfile.volumeBasedVA;
        // _data.profilesData[0].tpoBasedVA=prevLiveProfile.tpoBasedVA;
        _data.profilesData[0].vaType=prevLiveProfile.vaType;
        _data.profilesData[0].isTPOonVolume=prevLiveProfile.isTPOonVolume;
        _data.profilesData[0].isVolumeNumbers=prevLiveProfile.isVolumeNumbers;
        //  console.log("Live data after modification = ",_data);
        historicalData.profilesData.pop();
        historicalData.profilesData.push(_data.profilesData[0]);
         mergedLiveData= {
          profilesData:historicalData.profilesData,
          config:mergedConfig
        }
        // console.log("live merged data= ",mergedLiveData)
       
        if(!isZooming){
            setHistoricalData(mergedLiveData);
            setSavedHistoricalData(undefined)
        }
        else{
            setSavedHistoricalData(mergedLiveData)
            console.log("Test zoom: ignoring the live data as chart is zooming")
        }
       
        props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
        setMetaData(props.liveData.metadata);
      }
      //1st time live data received
      else{
        _data.profilesData[0].isStacked=false;

        let currWidth=_data.profilesData[0].splitWidth;
        //add volume width if current selection is tpo vol side by side
        if(_data.profilesData[0].isVolumeProfile && !_data.profilesData[0].isTPOonVolume &&!_data.profilesData[0].showOnlyVolumeProfile){
        currWidth=_data.profilesData[0].volumeWidth;
        }
        
        mergedConfig={
          totalWidth:currWidth+historicalData.config.totalWidth,  
          dateList:(historicalData.config.dateList.concat(_data.config.dateList)),
          dateKeyList:(historicalData.config.dateKeyList.concat(_data.config.dateKeyList)),
          max:Math.max(_data.config.max,historicalData.config.max),
          min:Math.min(_data.config.min,historicalData.config.min),
          lastDataTime:_data.config.lastDataTime,
          tpo:selectedTPO
        }
          console.log("live mergedconfig 2= ",mergedConfig,props.chartData.vaMapList,props.liveData.vaMapList)
 
        historicalData.profilesData.push(_data.profilesData[0]);
        if(props.chartData && props.chartData.vaMapList && props.chartData.vaMapList[props.chartData.vaMapList.length-1].dateList.length==1 && props.chartData.vaMapList[props.chartData.vaMapList.length-1].dateList[0]!=props.liveData.vaMapList[props.liveData.vaMapList.length-1].dateList[0] ) {
            props.chartData.dateList=props.chartData.dateList.concat(props.liveData.dateList)
            props.chartData.dateKeyList=props.chartData.dateKeyList.concat(props.liveData.dateKeyList) 
            props.chartData.vaMapList=props.chartData.vaMapList.concat(props.liveData.vaMapList)
            console.log("TPO issue mergelive 1st time=",props.chartData)
        }          
        mergedLiveData= {
          profilesData:historicalData.profilesData,
          config:mergedConfig
        }
        // console.log("megedlivedata = ",mergedLiveData)
       
        props.setRightContainerData(false,props.liveData.metadata,mergedLiveData,[mergedLiveData.profilesData.length-1],true)
        setHistoricalData(mergedLiveData);
        setMetaData(props.liveData.metadata);
      }
    }
    }

     //merges the profiles when more data is loaded while zooming out or panning
     const mergeProcessedData=(_dataL,_dataH)=>{
      if(_dataL!=undefined && _dataH!=undefined){
      let mergedConfig={};
      let mergedLiveData={};
      
      if(isInstrumentLive || _dataL.profilesData[0].date==_dataH.profilesData[_dataH.profilesData.length-1].date){
        const prevLiveProfile=_dataH.profilesData[_dataH.profilesData.length-1];
        console.log("TPO Prev life profile found =",prevLiveProfile,historicalData);
        // console.log("TPO Live data to merge and datelist =",_dataL,_dataH);
        _dataH.config.dateList.pop();
        _dataH.config.dateKeyList.pop()
        // console.log("TPO dateList after pop live date",_dataH.config.dateList)
        // console.log("TPO concat date list=",(_dataH.config.dateList.concat(_dataL.config.dateList)))  
        
        _dataL.profilesData[0].isStacked=prevLiveProfile.isStacked;
        _dataL.profilesData[0].isVolumeProfile=prevLiveProfile.isVolumeProfile;
        _dataL.profilesData[0].showTPO=prevLiveProfile.showTPO;
        _dataL.profilesData[0].showOnlyVolumeProfile=prevLiveProfile.showOnlyVolumeProfile;
        // _dataL.profilesData[0].volumeBasedVA=prevLiveProfile.volumeBasedVA;
        // _dataL.profilesData[0].tpoBasedVA=prevLiveProfile.tpoBasedVA;
        _dataL.profilesData[0].vaType=prevLiveProfile.vaType;
        _dataL.profilesData[0].isTPOonVolume=prevLiveProfile.isTPOonVolume;
        _dataL.profilesData[0].isVolumeNumbers=prevLiveProfile.isVolumeNumbers;

        
        //remove live data and its width
        mergedConfig={
          totalWidth:_dataL.config.totalWidth+(_dataH.config.totalWidth- 
            (prevLiveProfile.isStacked?(prevLiveProfile.stackedWidth):
            (prevLiveProfile.splitWidth)))+TPO_WIDTH,
          dateList:(_dataH.config.dateList.concat(_dataL.config.dateList)),
          dateKeyList:(_dataH.config.dateKeyList.concat(_dataL.config.dateKeyList)),
          max:Math.max(_dataL.config.max,_dataH.config.max),
          min:Math.min(_dataL.config.min,_dataH.config.min),
          lastDataTime:_dataL.config.lastDataTime
        }
        console.log("TPO live mergedconfig and live profile= ",mergedConfig,_dataL.profilesData[0]);
      
        
        // console.log("TPO Live data after modification = ",_dataL);
        _dataH.profilesData.pop();
        _dataH.profilesData.push(_dataL.profilesData[0]);
         mergedLiveData= {
          profilesData:_dataH.profilesData,
          config:mergedConfig
        }
        // console.log("TPO live merged data= ",mergedLiveData)
      
        setHistoricalData(mergedLiveData);
      }
    }
  }
  //TODO: Remove later
  useEffect(()=>{
    console.log("cachedData=",cachedlData,cachedlResponseData);
  },[cachedlData,cachedlResponseData]);


 //merges the profiles when more data is loaded while zooming out or panning
 const mergeCompositeData=(_data,responseDataComposite)=>{
  // console.log("compositedata =",compositeData,props.chartData);
  //for all the dates in _data, remove the profiles, get the current width of all the removed profiles 
  //reset the profile to stacked, volume, on tpo volume
  console.log(" cache =",cachedlData,cachedlResponseData);
 
  let removedProfiles=historicalData.profilesData.splice(startIndex,(endIndex-startIndex+1))
  let removedReponseData=props.chartData.vaMapList.splice(startIndex,(endIndex-startIndex+1))
  // console.log("dstrMap=",dstrMap,props.chartData);
  //reset removed profiles
  for(let i=0;i<removedProfiles.length;i++){
    //TODO: Check if we still need this
    removedProfiles[i].isStacked=true;
    removedProfiles[i].isVolumeProfile=false;
    // removedProfiles[i].isVloumeOnTPO=false;
    // removedProfiles[i].showTPO=true;
    removedProfiles[i].dstrMap=dstrMap[i];
    if(removedReponseData[i])
    removedReponseData[i].dstrMap=dstrMap[i]+selectedTPO.toString();
    // removedReponseData[i].tpo=selectedTPO;
  }
  console.log("removedProfiles and cache =",removedProfiles,cachedlData,removedReponseData,cachedlResponseData,cachedlData.size,cachedlData.length);

  let length=cachedlData.size!=undefined?cachedlData.size:0;
  const tempCache=new Set([...cachedlData ,...removedProfiles])
  setCachedData([...tempCache]);
  if(tempCache.size>length)
  setCachedResponseData([...cachedlResponseData ,...removedReponseData]);

  // setCachedData([...cachedlData, ...removedProfiles]);
  // setCachedResponseData([...new Set([...cachedlResponseData ,...removedReponseData])]);
  
  

  // console.log("removedProfiles =",removedProfiles,removedReponseData);

  // return;
  let width=0;
  for(let i=0;i<removedProfiles.length;i++){
    width=width+(removedProfiles[i].isStacked?removedProfiles[i].stackedWidth:removedProfiles[i].split);
    //TODO take care volume profiles width later
    width=width+(removedProfiles[i].isVolumeProfile&& !removedProfiles[i].isTPOonVolume && removedProfiles[i].showOnlyVolumeProfile?removedProfiles[i].volumeWidth:0)

  }
  // console.log("total width of removed profiles=",width,_data.config.dateList);

  //removed the old profiles date and add the new composite porfiles date simultaneously
  historicalData.config.dateList.splice(startIndex,(endIndex-startIndex+1),_data.config.dateList);
  historicalData.config.dateKeyList.splice(startIndex,(endIndex-startIndex+1),_data.config.dateKeyList);
  props.chartData.dateList.splice(startIndex,(endIndex-startIndex+1),responseDataComposite.dateList);
  props.chartData.dateList=props.chartData.dateList.flat();
  props.chartData.dateKeyList.splice(startIndex,(endIndex-startIndex+1),responseDataComposite.dateKeyList);
  props.chartData.dateKeyList=props.chartData.dateKeyList.flat();

  const mergedConfig={
    totalWidth:(_data.config.totalWidth+historicalData.config.totalWidth-width),  
    dateList:(historicalData.config.dateList.flat()),
    dateKeyList:(historicalData.config.dateKeyList.flat()),
    max:Math.max(_data.config.max,historicalData.config.max),
    min:Math.min(_data.config.min,historicalData.config.min),
    lastDataTime:(_data.config.lastDataTime!=null && _data.config.lastDataTime!=undefined?_data.config.lastDataTime:historicalData.config.lastDataTime),
    tpo:selectedTPO
  }
  console.log("Merged composite config = ",mergedConfig);
  
  // const mergedProfilesData=_data.profilesData.concat(historicalData.profilesData);
  // console.log("merged profiles data = ",mergedProfilesData);

  // historicalData.profilesData.splice(startIndex,(endIndex-startIndex+1),_data.profilesData);
  // console.log("chartData before composite= ",props.chartData,historicalData.profilesData);
  historicalData.profilesData.splice(startIndex,0,_data.profilesData);
  props.chartData.vaMapList.splice(startIndex,0,responseDataComposite.vaMapList);
  props.chartData.vaMapList=props.chartData.vaMapList.flat();
  // console.log("chartData after composite= ",props.chartData,historicalData.profilesData);
  
  const mergedProcessedData= {
    profilesData:(historicalData.profilesData.flat()),
    config:mergedConfig
  }
  // updateSelectedProfileIndexes(_data.profilesData.length);
  //TODO : Reset selected indexes
  console.log("Historical data with composite profiles and chartdata= ",mergedProcessedData,props.chartData)
  setSelectedProfilesIndex([]);
  setChartInteractionAllowed(true);
  setHistoricalData(mergedProcessedData);
  // props.setPollingAllowedVal(true);
}


  //1st loading of chart
    useEffect(()=>{
      console.log("live panel 1st load =======================>parseData",props.chartData.metadata);
        let processedVPList=parseVPList(props.chartData);
        // console.log("processed vpList = ",processedVPList);
        setProcessedVPList(processedVPList);
       
        const processedData = parseData(props.chartData,true,processedVPList);
        if(props.chartData.metadata!=null && props.chartData.metadata!=undefined){
          console.log("live panel 1st load metadata =======================>parseData");
          setMetaData(props.chartData.metadata);
         
          }
          
          props.setRightContainerData(false,props.chartData.metadata,processedData,[processedData.profilesData.length-1],true)
        // console.log("parseData return = ",processedData.profilesData);
        setHistoricalData(processedData);
        // saveChartState();
    },[]);

  //handles loading of more profiles data in case of zooming out or panning extremes reached
  useEffect(()=>{
    console.log("Load more Breakdstr useeffect=",isLoadingMoreProfiles,moreDataPresent,dstrData);
    let isDstrPresent=false;
    let dstrIndex=-1;
    if(dstrData){
      for(let i=0;i<dstrData.length;i++){
        if(dstrData[i].loaded==false){
          dstrIndex=i;
          isDstrPresent=true;
          break;
        }
      }
    }
    if(isLoadingMoreProfiles && moreDataPresent){
      if(!isDstrPresent){

      if(props.isPollingAllowed)
      props.setPollingAllowedVal(false);

    let load_factor=  PROFILE_RELOAD_FACTOR;
    if(transformData!=undefined && transformData.k<0.5)
    load_factor=load_factor*PROFILE_LOADING_FACTOR_ZOOM_OUT;

    if(props.chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS || props.chartScreenType==CHART_SCREEN_TYPE.GRID)
    load_factor=load_factor/1.8;
    
    let divisionFactor=1;
    //if volume profile is set to side by side then load less profiles
    if(props.viewState!=undefined && props.viewState.vol_tpo_side && !props.viewState.only_vol && !props.viewState.tpo_on_vol)
    divisionFactor=1.5;

    let defaultWidth=DEFAULT_PROFILE_WIDTH;
      if(props.selectedTimeFrame==TIME_FRAME_VALUES.weekly|| props.selectedTimeFrame==TIME_FRAME_VALUES.weekly_series){
        defaultWidth=DEFAULT_PROFILE_WIDTH_WEEKLY;
      }else if(props.selectedTimeFrame==TIME_FRAME_VALUES.monthly||props.selectedTimeFrame==TIME_FRAME_VALUES.monthly_series){
        defaultWidth=DEFAULT_PROFILE_WIDTH_MONTHLY;
      }else if(props.selectedTimeFrame==TIME_FRAME_VALUES.yearly){
        defaultWidth=DEFAULT_PROFILE_WIDTH_YEARLY;
      }  
    
    let data={
      "symbol": selectedInstrument,
      "nDays": 0,
      "nProf": Math.min(Math.round((load_factor*((window.innerWidth/defaultWidth)))/divisionFactor),MAX_PROFILE_TO_LOAD),
      "dstr": "",   //
      "liveData": false,
      "liveOnly": false,
      "liveDataComposite": false,
      "startDate": "",
      "endDate": historicalData.profilesData[0].dateList[0],
      "tf": props.selectedTimeFrame,
      "max_cmp_days":props.max_cmp_days,
      "contracts": props.contracts,
      "show_oi_spikes": props.userSettings.data.show_split_profile_oi_spikes!=undefined?props.userSettings.data.show_split_profile_oi_spikes:true,
      "show_tpova_markers": props.userSettings.data.show_split_profile_va_markers!=undefined?props.userSettings.data.show_split_profile_va_markers:true,
      "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
      "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined)
    }
     console.log("Breakdstr Loadmore useeffect No dstr=",isLoadingMoreProfiles,dstrData,data);
    setCurrDstrIndex(dstrIndex)
    setIsMoreDataLoading(true);
    // setChartInteractionAllowed(false);

    executeAPI(URL.MPCHART,"POST",data); 
  }else{
    if(props.isPollingAllowed)
      props.setPollingAllowedVal(false);

    let data={
      "symbol": selectedInstrument,
      "nDays": 0,
      "nProf":"",
      "dstr": dstrData[dstrIndex].dstr,   //
      "liveData": false,
      "liveOnly": false,
      "liveDataComposite": false,
      "startDate":  dstrData[dstrIndex].startDate,
      // "endDate": historicalData.profilesData[0].dateList[0],
      "tf": props.selectedTimeFrame,
      "max_cmp_days":props.max_cmp_days,
      "contracts": props.contracts,
      "show_oi_spikes": props.userSettings.data.show_split_profile_oi_spikes!=undefined?props.userSettings.data.show_split_profile_oi_spikes:true,
      "show_tpova_markers": props.userSettings.data.show_split_profile_va_markers!=undefined?props.userSettings.data.show_split_profile_va_markers:true,
      "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
      "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined),
      "getLatest":false
    }
    setIsMoreDataLoading(true);
    // setChartInteractionAllowed(false);
    setCurrDstrIndex(dstrIndex);
    executeAPI(URL.MPCHART,"POST",data); 
  }
   
  }
  },[isLoadingMoreProfiles]);

  useEffect(() => {
    // setHistoricalData(undefined);
    setSelectedTimeFrame(props.selectedTimeFrame)
  },[props.selectedTimeFrame]  )

  /**
  * API response handler for load more data  
  */  
    useEffect(() => {
      if(loaded){
        // console.log("More data loaded=",responseData);
        if(responseData!=null){
              if(responseData.vaMapList!==undefined && responseData.vaMapList.length>0){
                if(currDstrIndex!=-1){
                  let tempDstr=[...dstrData];
                  tempDstr[currDstrIndex].loaded=true;
                  setDstrData(tempDstr);
                }
                //enddate data comes with tf=daily only, so remove it 
                if(props.selectedTimeFrame=="daily" && currDstrIndex==-1){
                  //pop is done to remove the last profile as the end date data is already present in the loaded data
                  responseData.vaMapList.pop();
                  responseData.dateList.pop();
                  responseData.dateKeyList.pop();
                }
                // console.log("More data loaded modified=",responseData);
                if(responseData.vaMapList!=undefined && responseData.vaMapList.length==0){
                  setMoreDataPresent(false);
                  props.setChartRenderedState(true);
                  
                }else{
                  console.log("Load more setIsLoadingMoreProfiles =false now")
                  // setIsLoadingMoreProfiles(false);
                }
                //merge with existing chart data
                if(props.chartData!=undefined && responseData.dateList!=undefined && responseData.dateList.length>0){
                  // console.log("More data:date List before concat=",props.chartData.dateList);
                  props.chartData.dateList=responseData.dateList.concat(props.chartData.dateList)
                  props.chartData.dateKeyList=responseData.dateKeyList.concat(props.chartData.dateKeyList) 
                  props.chartData.vaMapList=responseData.vaMapList.concat(props.chartData.vaMapList)
                  // console.log("More data:date List after concat=",props.chartData.dateList);              
                }
                if(historicalData && historicalData.config && historicalData.config.dateList && responseData.dateList && responseData.dateList.length>0){
                  let lastdate=responseData.dateList[responseData.dateList.length-1];
                  let index=historicalData.config.dateList.findIndex(item => item == lastdate);
                  if(index==-1){
                    const processedVPList=parseVPList(responseData)
                    const processedData = parseData(responseData,false,processedVPList);
                    mergeProfileData(processedData);
                  }else{
                    console.log("MP DUPLICATE IN LOAD MORE DATA FOUND")
                  }
                }
               
                setIsMoreDataLoading(false);
                setIsLoadingMoreProfiles(false);
                // if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
                // if(!liveDataComposite){
                // props.setPollingAllowedVal(true);
                // }
                // setChartInteractionAllowed(true);
      
              }else{
                setMoreDataPresent(false);
                props.setChartRenderedState(true);
              }
              if(!liveDataComposite){
                props.setPollingAllowedVal(true);
                }
              if(responseData.upgrade_notify!=undefined && responseData.upgrade_notify){
                props.openSubscribeModal();
              }
              setIsMoreDataLoading(false);
              setChartInteractionAllowed(true);
        }
        else if(error!==null){
          // setChartInteractionAllowed(true);
          // if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
          if(!liveDataComposite){  
              props.setPollingAllowedVal(true);
           }
          console.log("Error data=",error);
          setIsMoreDataLoading(false);
          setChartInteractionAllowed(true);
          setIsLoadingMoreProfiles(false);
          props.setChartRenderedState(true);
         
         
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, error?.response?.data?.message);
            }
          }else 
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        reset();
      }
      },[loaded,responseData]);
  
    //save the existing transform(zoom, x,y pan values and trigger load more profiles)
    const loadMoreData=async(transform)=>{
      console.log("Loadmore called==...............................",moreDataPresent,isLoadingMoreProfiles)
      // setTransformData(transform);
      if(moreDataPresent){
        if(props.isPollingAllowed)
        props.setPollingAllowedVal(false);
      let val= setIsLoadingMoreProfiles(false);
        // setChartInteractionAllowed(false);
        // setIsLoadingMoreProfiles(true);
        // setIsLoadingMoreProfiles(true);
        const timeout = setTimeout(function () {

          setIsLoadingMoreProfiles(true);
      }, 300);
     
      
     
      }
    }

    //handles composite data fetching
    useEffect(()=>{
      console.log("compositeData= ",compositeData);
    if(dstr!="" && dstr!=undefined){
      let data={
        "symbol": selectedInstrument,
        "nDays": nDays,
        "nProf": "",
        "dstr": dstr,   //
        "liveData": liveData,
        "liveOnly": false,
        "liveDataComposite": liveComposite,
        "startDate": startDate,
        "tf": "daily",
        "max_cmp_days":props.max_cmp_days,
        "contracts": props.contracts,
        "show_oi_spikes": props.userSettings.data.show_split_profile_oi_spikes!=undefined?props.userSettings.data.show_split_profile_oi_spikes:true,
        "show_tpova_markers": props.userSettings.data.show_split_profile_va_markers!=undefined?props.userSettings.data.show_split_profile_va_markers:true,
        "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
        "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined),
      }
      console.log("Composite data api payload = ",data);
     
      setCompositeLoading(true);
      setRightContainerProfilesIndex([])
      if(props.val==0)
      props.resetRightPanel()

      executeAPIComposite(URL.MPCHART,"POST",data); 
    }
    },[compositeData]);


  /**
  * API response handler for composite data 
  */  
    useEffect(() => {
      if(loadedComposite){
        setCompositeLoading(false);
        
        if(liveComposite)
        setLiveComposite(liveComposite);

        setRefreshChart(false);
       
        console.log("Composite response=",responseDataComposite);
        if(responseDataComposite!=null){
              if(responseDataComposite.vaMapList!==undefined && responseDataComposite.vaMapList.length>0){
                const processedVPList=parseVPList(responseDataComposite)
                const processedData = parseData(responseDataComposite,false,processedVPList);
                
                console.log("Composite process data= ",processedData);  
                // mergeProfileData(processedData);
                mergeCompositeData(processedData,responseDataComposite);
                if(liveDataCompositeUnmerge){
                  setLiveCompositeUnmerge(false);
                  setChartInteractionAllowed(true);
                  props.setPollingAllowedVal(true);
                }
              }
              if(responseDataComposite.upgrade_notify!=undefined && responseDataComposite.upgrade_notify){
                props.openSubscribeModal();
              }
        }
        else if(errorComposite!==null){
          console.log("Error data=",errorComposite);
          // setIsLoadingMoreProfiles(true);
          setCompositeLoading(false)
          
          props.setPollingAllowedVal(true);
          if(errorComposite?.response?.status === 401  || error?.response?.status === 403){
            console.log("status received =",errorComposite?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, errorComposite.response?.data?.message);
            }
          }else 
          setMsgState({open:true,msg:errorComposite?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetComposite();
      }
      },[loadedComposite,responseDataComposite]);
  
    //Search the profile based on mouse clicked point
    const binarySearch=(sortedArray, key)=>{
      let start = 0;
      let end = sortedArray.length - 1;
  
      while (start <= end) {
          let middle = Math.floor((start + end) / 2);
  
          if (sortedArray[middle] === key) {
              // found the key
              return middle;
          } else if (sortedArray[middle] < key) {
              // continue searching to the right
              start = middle + 1;
          } else {
              // search searching to the left
              end = middle - 1;
          }
      }
    
      return start-1;
    }

    //opens the context menu for the profiles
    const openContextMenu=(xCord,yCord,selectedIndex,canvasWidth,canvasHeight,level)=>{
      if(selectedIndex!=undefined && selectedIndex>=0 && selectedIndex<historicalData.profilesData.length){
      setShowContextMenu(false);
      setShowTooltip(false);
      setShowKRLContextMenu(false);
      if(historicalData!=undefined){
        setCtxMenuLevel(level);
      //   console.log("selectedprofilesIndex =",selectedProfilesIndex);
      //   let tempIndex=[...selectedProfilesIndex];
      //   tempIndex.push(selectedIndex);
      //   console.log("temp array =",tempIndex);
      // // setSelectedProfilesIndex(tempIndex);
      // setSelectedProfilesIndex(oldArray => [...oldArray, selectedIndex]);

      // let tempProfiles=[...selectedProfiles];
      // console.log("selectedProfiles =",selectedProfiles);
      // tempProfiles.push(historicalData.profilesData[selectedIndex])
      // console.log("tempProfiles =",tempProfiles);
      
      // setSelectedProfiles(tempProfiles);
      setCurrentIndex(selectedIndex);
      //modify x and y cord for handling context menu at the extremes
      if(xCord+CONTEXT_MENU_WIDTH>canvasWidth)
        xCord=xCord-CONTEXT_MENU_WIDTH;
      if(yCord+CONTEXT_MENU_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-CONTEXT_MENU_HEIGHT;
      
      setAnchorPoint({ x: xCord, y: yCord });
      setShowTooltip(false); 
      setShowContextMenu(true);  
      }
    }

    }

      //opens the context menu for the profiles
      const openContextMenuKRL=(xCord,yCord,canvasWidth,canvasHeight,data)=>{
        if(data[0].id){
        setShowContextMenu(false);
        setShowTooltip(false);
        setShowKRLContextMenu(false);
        if(historicalData!=undefined){
       
        //modify x and y cord for handling context menu at the extremes
        if(xCord+KRL_CONTEXT_MENU_WIDTH>canvasWidth)
          xCord=xCord-KRL_CONTEXT_MENU_WIDTH;
        if(yCord+KRL_CONTEXT_MENU_HEIGHT>canvasHeight+CHART_TOP_SPACE)
          yCord=yCord-KRL_CONTEXT_MENU_HEIGHT;
        
          setSelectedKRL(data)
        setAnchorPointKRL({ x: xCord, y: yCord });
        setShowTooltip(false); 
        setShowKRLContextMenu(true);  
        }
      }
  
      }

    // const openTooltip=(xCord,yCord,canvasWidth,canvasHeight,isKRL,count)=>{
    
    //   setShowTooltip(false);
     
    //   //modify x and y cord for handling context menu at the extremes
    //   let menuWidth=isKRL?TOOLTIP_KRL_MENU_WIDTH:TOOLTIP_MENU_WIDTH;
    //   if(xCord+menuWidth>canvasWidth)
    //     xCord=xCord-menuWidth;
    //   else xCord=xCord+10;
      
    //   if(yCord+count*TOOLTIP_ITEM_HEIGHT>canvasHeight+CHART_TOP_SPACE)
    //     yCord=yCord-40;
    //     // /yCord=yCord-TOOLTIP_HEIGHT;
    //   console.log("KRL tooltip: open cords=",xCord,yCord,count)
    //   setAnchorPointTooltip({ x: xCord, y: yCord });
    //   setShowContextMenu(false); 
    //   setShowTooltip(true);  
    // }

    const openTooltip=(xCord,yCord,canvasWidth,canvasHeight,isKRL)=>{
    
      setShowTooltip(false);
     
      //modify x and y cord for handling context menu at the extremes
      let menuWidth=isKRL?TOOLTIP_KRL_MENU_WIDTH+50:TOOLTIP_MENU_WIDTH;
      if(xCord+menuWidth>canvasWidth)
        xCord=xCord-menuWidth;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      console.log("KRL tooltip: open cords=",xCord,yCord)
      setAnchorPointTooltip({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(true);  
    }

    const openYAxisContextMenu=(xCord,yCord,canvasWidth,canvasHeight)=>{
    
      setShowYAxisContextMenu(false);
     
      //modify x and y cord for handling context menu at the extremes
      if(xCord+TOOLTIP_MENU_WIDTH>canvasWidth)
        xCord=xCord-TOOLTIP_MENU_WIDTH;
      if(yCord+TOOLTIP_HEIGHT>canvasHeight+CHART_TOP_SPACE)
        yCord=yCord-TOOLTIP_HEIGHT;
      
      setAnchorPointYAxis({ x: xCord, y: yCord });
      setShowContextMenu(false); 
      setShowTooltip(false); 
     setShowYAxisContextMenu(true);
    
    }

  //  //1st loading of chart
  //  useEffect(()=>{
  //   console.log("selected =",selectedProfilesIndex);
  //   },[selectedProfilesIndex]);

  // useEffect(()=>{
  //   console.log("canvasWidth in MP Chart =",props.canvasWidth);
  // },[canvasWidth]);


  /**
   * Gets the volume profiles for the visible profiles
   */
  const getVolumeProfilesInRange=(leftIndex,rightIndex)=>{
    //get the max and min tpo for the profile range
    let totalVolume=0;
    let maxTPO=Number.NEGATIVE_INFINITY;
    let minTPO=Number.POSITIVE_INFINITY;
    let maxPerc=0;
    let currTPO=historicalData.config.tpo?historicalData.config.tpo:selectedTPO;
    for(let i=leftIndex;i<=rightIndex;i++){
      totalVolume=totalVolume+historicalData.profilesData[i].totalVolume;
      maxTPO=Math.max(maxTPO,historicalData.profilesData[i].maxTPO);
      minTPO=Math.min(minTPO,historicalData.profilesData[i].actualMinTPO)
    }
    console.log("totalV,minTPO, maxTPO =",totalVolume,minTPO,maxTPO);
    let totalPerc=0;
    let volumeData=[];
    let index=-1;
    let curr=0;
    
    for(let tpo=minTPO;tpo<=maxTPO;tpo=parseFloat((tpo+currTPO).toFixed(4))){
      let volume=0;
      for(let i=leftIndex;i<=rightIndex;i++){
        //if current tpo in the range of profile
        if(tpo>=historicalData.profilesData[i].actualMinTPO && tpo<=historicalData.profilesData[i].maxTPO &&historicalData.profilesData[i].volumeData!=undefined &&historicalData.profilesData[i].volumeData.length>0){
          // index=Math.round(tpo-historicalData.profilesData[i].minTPO);
          index=parseFloat((tpo-historicalData.profilesData[i].actualMinTPO).toFixed(4));
          if(index!=0){
            // index=Math.round(index/selectedTPO)
            index=parseFloat((index/currTPO).toFixed(4))
            if(historicalData.profilesData[i].volumeData[index]!=undefined){
              // console.log("if cond tpo,volume, profile volume=",tpo,volume,historicalData.profilesData[i].volumeData[index].val);
              volume=volume+historicalData.profilesData[i].volumeData[index].val
            }
          }
          else{
            if(historicalData.profilesData[i].volumeData[index]!=undefined){
              volume=volume+historicalData.profilesData[i].volumeData[index].val;
            }
          }
        }
      
      }
      // console.log("tpo, volume=",tpo,volume);
       //add the global volume at the current tpo
       if(volume!=0 && volume!=undefined){
        volumeData.push({
          val:volume,
          perc:(parseFloat((volume*100/totalVolume).toFixed(4))),
        })
        maxPerc=Math.max(maxPerc,(parseFloat((volume*100/totalVolume).toFixed(4))));
        totalPerc=totalPerc+(parseFloat((volume*100/totalVolume).toFixed(4)));
      }else{
        volumeData.push({
          val:0,
          perc:0,
        })
      }

      
     
    }
    
    // console.log("maxPerc totalPerc final volume data=",maxPerc, totalPerc,volumeData.length,volumeData);
    return{
      data:volumeData,
      minTPO:minTPO,
      maxTPO:maxTPO,
      maxPerc:maxPerc
    }
  }  

  useEffect(()=>{
    console.log("MP Chart Props changes",props.chartStateData)
  },[props.chartStateData])
  
    //handles all the drawing over canvas and svg axis
    useLayoutEffect(()=>{
      if(historicalData==undefined)
        return;
        let redraw=true;
        let randomR=1;
        let randomG=1;
        let randomB=1;
        // setIsLoadingMoreProfiles(false);
        // setChartInteractionAllowed(true);
      chartInterationAllowedRef.current=chartInterationAllowed;
      contextMenuRef.current=showContextMenu;
      transformDataRef.current=transformData;
      yaxiscontextMenuRef.current=showYAxisContextMenu;
      krlcontextMenuRef.current=showKRLContextMenu;
      // let currentDate=dayjs().day()!=5?dayjs().add(1,"day"):dayjs().add(3,"day");
      // let nextWeekday=currentDate.get('date')+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      let currentDate=dayjs();
     
      let nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      if(isInstrumentLive || nextWeekday==props.chartData.dateList[props.chartData.dateList.length-1]){
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(3,"day");
      }else if(!isInstrumentLive && nextWeekday!=props.chartData.dateList[props.chartData.dateList.length-1] && dayjs().day()<5){
        currentDate=dayjs();
      }else{
        currentDate=dayjs().day()<5?dayjs().add(1,"day"):dayjs().add(2,"day");

      }
      nextWeekday=(currentDate.get('date')>9?currentDate.get('date'):"0"+currentDate.get('date'))+"-"+((currentDate.get('month')+1)>9?(currentDate.get('month')+1):"0"+(currentDate.get('month')+1))+"-"+currentDate.get('year');
      console.log("SCALE ------------RANGE--------DATE--->",transformData,historicalData.config.totalWidth,historicalData.config.dateList,currentDate,dayjs().get('date'),dayjs().get('month'),nextWeekday,dayjs().day(),props.chartData.liveDate,props.isInstrumentLive);
      // console.log("SCALE ------------RANGE--------DATE--->",historicalData.config.totalWidth,historicalData.profilesData,historicalData.config,props.chartData);
      
     
       let systemKRLData=processKRLData();
      let customKRLData=processCustomKRLData();
      //retrieves the initial transform if already set  
      
      
      //calculate the height for the canvas
      // let canvasHeight=window.innerHeight - CONSTANTS.DASHBOARD_HEADER_HEIGHT-CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
      let canvasHeight=props.chartStateData.height -CONSTANTS.FIXED_HEADER_HEIGHT-CONSTANTS.FIXED_FOOTER_HEIGHT-XSCALE_HEIGHT;
      
      //calculate the width of canvas
      // let canvasWidth=window.innerWidth - CONSTANTS.FIXED_RIGHT_PANEL_WIDTH-YSCALE_WIDTH-20;
      let canvasWidth=props.chartStateData.width -YSCALE_WIDTH-0;
      
      // setCanvasWidth(canvasWidth);
      console.log("window  w,h,canvasWidth, canvasHeight = ",window.innerWidth,canvasWidth,window.innerHeight,canvasHeight);

      
     
      //set zoom out and pan extremes to keep track when to load more data during user interaction
      // const zoomOutMinVal=(Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth))/LOAD_MORE_ZOOM_OUT_PERCENT;
      const totalWidth=historicalData.config.totalWidth+5*canvasWidth;
      const endProfileExtreme=canvasWidth>totalWidth?(canvasWidth-historicalData.config.totalWidth)*LOAD_MORE_PAN_PERCENT:(historicalData.config.totalWidth-canvasWidth)*LOAD_MORE_PAN_PERCENT;
      const zoomOutChartBased=canvasWidth/historicalData.config.totalWidth;
    
      let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth>totalWidth? ZOOM_OUT_EXTREME_LESS_PROFILE:canvasWidth/totalWidth);
      const zoomOutMinVal=ZOOM_OUT_SCALE/LOAD_MORE_ZOOM_OUT_PERCENT;
      let initialTransform={k:1,x:0,y:0}
      if(transformData!=undefined){
        initialTransform=transformData;
        // initialTransform.x=canvasWidth/2*transformData.k
      }

        //handles the situation where all the profiles comes under the screen due to zoom out and profile width decrement on TPO change
      if(historicalData.config.totalWidth>canvasWidth && initialTransform.k<1 && (canvasWidth/historicalData.config.totalWidth)>=initialTransform.k){
        console.log("Extreme conditions met->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
        //loadmore data impolictly as all the profiles comes under the screen
        // loadMoreData(initialTransform);
        // return;
     }else{
       console.log("Condition not met >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",canvasWidth/historicalData.config.totalWidth,initialTransform.k);
     }
      // console.log("Zoom pan extremes =",zoomOutMinVal,endProfileExtreme)
      
      //chart construction using d3  canvas and svg
      // const container = d3.select('.chart-container_new');
      // let className="."+props.id;
      // console.log("classname=",className)
      const container = d3.select(`#${props.id}`);
              
      // Remove any existing tooltip and create the tooltips
      d3.selectAll(`#tooltipCrosshair${props.id}`).remove()
      d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).remove()
      d3.selectAll(`#tooltipLiveMarker${props.id}`).remove()
      d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove()
      d3.selectAll(`#tooltipUpperLevel${props.id}`).remove()
      d3.selectAll(`#tooltipLowerLevel${props.id}`).remove()
      
      
      //yaxis tooltip
      const tooltipCrossHair = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshair"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
      
      //date axis tooltip
      const tooltipCrossHairDateAxis = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipCrossHair")
      .attr("id", "tooltipCrosshairDateAxis"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none');
    
      //yaxis tooltip
      const tooltipLiveMarker = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveMarker")
      .attr("id", "tooltipLiveMarker"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 999)
      .style("left",50)
      .style('display','none');
      
      //tolltio for live profile close_vwap
      const tooltipLiveCloseVwap = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipLiveCloseVwap")
      .attr("id", "tooltipLiveCloseVwap"+props.id)
      .style("opacity", 1.0)
      .style("z-index", 99)
      .style("left",50)
      .style('display','none');

     //level boundary fixed tooltip
     const tooltipUpperLevel = d3.select(`#${props.id}`)
     .append("div")
     .attr("class", "tooltipFrozenLine")
     .attr("id", "tooltipUpperLevel"+props.id)
     .style("opacity", 0.9)
     .style("z-index", 9999)
     .style("left",50)
     .style('display','none'); 
    
      //level boundary fixed tooltip
      const tooltipLowerLevel = d3.select(`#${props.id}`)
      .append("div")
      .attr("class", "tooltipFrozenLine")
      .attr("id", "tooltipLowerLevel"+props.id)
      .style("opacity", 0.9)
      .style("z-index", 9999)
      .style("left",50)
      .style('display','none'); 
      
    //determine the range of profiles based on the canvas and profile width
    const xScaleRange=[];
    const dummyProfileWidth=(canvasWidth/0.2);
    xScaleRange[historicalData.profilesData.length+1]=dummyProfileWidth;
    xScaleRange[historicalData.profilesData.length]=canvasWidth;
    for(let i=historicalData.profilesData.length-1;i>=0;i=i-1){
      xScaleRange[i]=(xScaleRange[i+1]- (historicalData.profilesData[i].isVolumeProfile && !historicalData.profilesData[i].showOnlyVolumeProfile && !historicalData.profilesData[i].isTPOonVolume?historicalData.profilesData[i].volumeWidth:0)
        -(historicalData.profilesData[i].isStacked ?historicalData.profilesData[i].stackedWidth:historicalData.profilesData[i].splitWidth));
    }
    let modDateList=[... historicalData.config.dateList]
    modDateList.push(nextWeekday);
    console.log("xScaleRange=",xScaleRange)
    const xScale = d3.scaleOrdinal()
      .domain(modDateList)
      .range(xScaleRange);
    
    //determine the range of y axis based on latest profile
    // const maxDomain=historicalData.profilesData[historicalData.profilesData.length-1].maxTPO+2*selectedTPO;
    // const minDomain=maxDomain-(canvasHeight/TPO_HEIGHT)*selectedTPO;

    // const midTPO=historicalData.profilesData[historicalData.profilesData.length-1].closeTPO;
    // const totalTPOONCanvas=(canvasHeight/TPO_HEIGHT)*selectedTPO;
    // let maxDomain=midTPO+totalTPOONCanvas*2/5;
    // let minDomain=midTPO-totalTPOONCanvas*3/5;
    // // if(!isFirstLoad && (transformDataRef.current.k!=1 || transformDataRef.current.y!=0)){
    // if(!isFirstLoad){
    //   maxDomain=chartMidTPO+totalTPOONCanvas*2/5;
    //   minDomain=chartMidTPO-totalTPOONCanvas*3/5;
    // }else{
    //   setChartMidTPO(midTPO)
    //   setIsFirstLoad(false);
    // }
    const midTPO=historicalData.profilesData[historicalData.profilesData.length-1].closeTPO;
    const totalTPOONCanvas=(canvasHeight/TPO_HEIGHT)*selectedTPO;
    let maxDomain=midTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
    let minDomain=midTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
    // if(!isFirstLoad && (transformDataRefY.current.k!=1 || transformDataRefY.current.y!=0)){
      let forceRecenter=false;
      if(chartMidTPO!=-1){
        let maxDomainSaved=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        let minDomainSaved=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      let topRange=maxDomainSaved- (maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      let bottomRange=minDomainSaved+(maxDomainSaved-minDomainSaved)*(LTP_POSITION_ALLOWED_CHANGE);
      if(midTPO>topRange || midTPO<bottomRange){
        console.log("Recenter force recenter is true",maxDomainSaved,minDomainSaved,maxDomain,minDomain, chartMidTPO,midTPO,topRange,bottomRange)
        forceRecenter=true;
      }else
      console.log("Recenter force recenter is false",maxDomainSaved,minDomainSaved,chartMidTPO,midTPO,topRange,bottomRange)
     
      }
    if(!isRecenterAllowed){
     
      // maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      // minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      // // setChartMidTPO(midTPO)
      maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
      minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
      console.log("Recenter is not allowed 1", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
    }else{
      
      if(isFirstLoad){
        console.log("Recenter is allowed first load");
        setIsFirstLoad(false);
        setChartMidTPO(midTPO)
      }else if(forceRecenter){
        console.log("Recenter is allowed force recenter");
        setChartMidTPO(midTPO);
      }else if(!forceRecenter){
       
        
        maxDomain=chartMidTPO+totalTPOONCanvas*DEFAULT_LTP_POSITION;
        minDomain=chartMidTPO-totalTPOONCanvas*(1-DEFAULT_LTP_POSITION);
        console.log("Recenter is not allowed 2", isRecenterAllowed,forceRecenter,maxDomain,minDomain,chartMidTPO,midTPO,totalTPOONCanvas)
      }
    }
   
    const yScale = d3.scaleLinear()
        .domain([minDomain, maxDomain])
        .range([canvasHeight, 0])
        .nice();
    
       
    //remove the previous svgs for axes if present
    d3.select(`#svgChart${props.id}`).remove()
    d3.select(`#svgChartXAxis${props.id}`).remove()
           
    // Init SVG: Y axis
    const svgChart = container.append('svg')
    .attr("id","svgChart"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2]+YSCALE_WIDTH-2)    //2 to counter the right border else scroll bar is show horizontally
    .attr('height', canvasHeight+XSCALE_HEIGHT)
    .attr('class', 'svg-plot')

    // Init SVG: X axis
    const svgChartXAxis = container.append('svg')
    .attr("id","svgChartXAxis"+props.id)
    .attr('width', xScaleRange[xScaleRange.length-2])
    .attr('height', XSCALE_HEIGHT+10)
    .attr('class', 'svg-plot')
    // .style('margin-top', 300 + 'px')
   
    // Init Axis
    const xAxis = d3.axisTop(xScale);
    const yAxis = d3.axisRight(yScale)
                .tickFormat(function(d){
                  return d<0?"":d;
                });
    
    //Remove the previous axes and  add new one Axis
    d3.select(`#xaxis${props.id}`).remove()
    d3.select(`#yaxis${props.id}`).remove()
    const gxAxis = svgChartXAxis.append('g')
        .style("font", "12px sans-serif")
        .attr("transform", `translate(0,${margin.top})scale(${1})`)
        .attr('id','xaxis'+props.id)
        .call(xAxis);
    
      
        const gyAxis = svgChart.append('g')
        .style("font", "12px sans-serif")
        .attr('transform',`translate(${canvasWidth},${margin.top})`)
        .attr('id','yaxis'+props.id)
        .call(yAxis);

        // svgChart  
        // .style("color", "#65757E")
        // .on("mouseover", function (event) {
        //   // console.log(" testing event",event);
        //   // const mousePosition = d3.pointer(event);
        //   // const data = yScale.invert(mousePosition[1]);
        //   // console.log("pos", mousePosition);
        //   // console.log("data", data);
        // })
        // .on("mouseout", (event) => {
        //   // console.log("testing mouseout");
        // })
        // .on("mousemove", (event) => {
        //   // console.log("testing mousemove svg",event);
        // })
        // .on("contextmenu", (event) => {
        //   console.log("testing contextmenu");
        // }); 

      
        d3.selectAll(`#canvasChart${props.id}`).remove()
        d3.selectAll(`#dummyCanvas${props.id}`).remove()
        d3.selectAll(`#canvasOverlay${props.id}`).remove();
        d3.selectAll(`#canvasTooltip${props.id}`).remove();
        
        //Test Performance
        // d3.selectAll(`#krlAxisTooltip${props.id}`).remove();
        // d3.selectAll(`#krl_label${props.id}`).remove();
        // d3.selectAll(`#npoc_label${props.id}`).remove();
        
        
    
        
         // Invosoble Tooltip canvas
         const canvasTooltip= container.append('canvas')
         .attr("id",'canvasTooltip'+props.id)
         .attr('width', canvasWidth)
         .attr('height', canvasHeight)
         .style('margin-left', margin.left + 'px')
         .style('margin-top', margin.top + 'px')
         .attr('class', 'canvas-tooltip')
                    
    
        // Overlay Canvas
        const overlayCanvas = container.append('canvas')
        .attr("id",'canvasOverlay'+props.id)
        .attr('width', canvasWidth)
        .attr('height', canvasHeight)
        .style('margin-left', margin.left + 'px')
        .style('margin-top', margin.top + 'px')
        .attr('class', 'canvas-plot')
        
    
        // Init Canvas
        const canvasChart = container.append('canvas')
        .attr("id",'canvasChart'+props.id)
        .attr('width', canvasWidth)
        .attr('height', canvasHeight)
        .style('margin-left', margin.left + 'px')
        .style('margin-top', margin.top + 'px')
        .attr('class', 'canvas-plot')
        
        //dummy canvas for user interaction
        const dummyCanvas = container.append('canvas')
        .attr('id','dummyCanvas'+props.id)
        .attr('width', canvasWidth+YSCALE_WIDTH-2)
        .attr('height', canvasHeight)
        .style('margin-left', margin.left + 'px')
        .style('margin-top', margin.top + 'px')
        .attr('class', 'canvas-plot')
        .style('cursor',"all-scroll")
  


   

    
   
    
    // Prepare chart buttons: Reset
    const toolsList = container.select(`#tools${props.id}`)
    .style('margin-top', (canvasHeight-4) + 'px')
    .style('visibility', 'visible');
  
    const watermark = container.select(`#watermark${props.id}`)
    .style('margin-top', (canvasHeight/2-4) + 'px')
    .style('visibility', 'visible');
  
    
    toolsList.select(`#reset${props.id}`).on('click', () => {
    const t = d3.zoomIdentity.translate(-canvasWidth*ZOOM_OUT_SCALE, 0).scale(1);
    setTransformData(t);
    // setToggleRepaint(!toggleRepaint);
    dummyCanvas.transition()
      .duration(200)
      .ease(d3.easeLinear)
      .call(zoom_function.transform, t)

    });
    
    /**
     * Auto center the chart
     * @param {*} transform 
     */
    const autoCenter=(transform)=>{
      console.log("Autocenter transform=",transform)
      const t = d3.zoomIdentity.translate(transform.x, transform.y).scale(transform.k);
      setTransformData(transform);
      // setToggleRepaint(!toggleRepaint);
      dummyCanvas.transition()
        .duration(200)
        .ease(d3.easeLinear)
        .call(zoom_function.transform, t)
    
    }
    
    toolsList.select(`#zoomin${props.id}`).on('click', () => {
      let prevTransform=transformData;
      let prevK=prevTransform.k;
      if(prevTransform!=undefined)
      prevTransform.k=Math.min(1,prevTransform.k*1.1)
      
      if(prevK!=1 && prevK!=undefined){
        let t = d3.zoomIdentity.translate(canvasWidth*(1-prevTransform.k), canvasHeight*(1-prevTransform.k)).scale(prevTransform.k);
        setTransformData(t);
        setToggleRepaint(!toggleRepaint);
      }
    });

    toolsList.select(`#zoomout${props.id}`).on('click', () => {
      let translateExtentUpward=canvasHeight;
    if((yScale.domain()[0]!=historicalData.config.min))
    translateExtentUpward=(Math.abs(yScale.domain()[0]-historicalData.config.min)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight);
    
    let translateExtentDownward=-1*canvasHeight;
    if((yScale.domain()[1]!=historicalData.config.max))
    translateExtentDownward=-1*((Math.abs(yScale.domain()[1]-historicalData.config.max)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(1.5*canvasHeight));
    console.log("zoomout up down width",translateExtentUpward,translateExtentDownward,historicalData.config.totalWidth);
    
      if(canvasWidth<historicalData.config.totalWidth){
      let zoomOutMax=Math.max(ZOOM_OUT_EXTREME,canvasWidth/historicalData.config.totalWidth)
      let prevTransform=transformData;
      console.log("zoomout clicked ",prevTransform,zoomOutMinVal,prevTransform.k*0.9)
      if(prevTransform!=undefined)
      prevTransform.k=Math.max(zoomOutMax, (prevTransform.k*0.9))
      let t = d3.zoomIdentity.translate(canvasWidth*(1-prevTransform.k), canvasHeight*(1-prevTransform.k)).scale(prevTransform.k);
     
      setTransformData(t);
      if(prevTransform.k==(zoomOutMax/LOAD_MORE_ZOOM_OUT_PERCENT)){
        loadMoreData(t)
      } 
      else{
        setToggleRepaint(!toggleRepaint);
      }
    }
  });
    
    //TODO: Check for software cpu accelration performance instead of gpu for performance
    // const context = canvasChart.node().getContext('2d', {willReadFrequently: true}
    const context = canvasChart.node().getContext('2d');
    const contextDummy = dummyCanvas.node().getContext('2d');
    const contextOverlay = overlayCanvas.node().getContext('2d');
    const contextTooltip = canvasTooltip.node().getContext('2d');
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
    contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
    contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
        
    //keep track of previous selected profiles
    let selectedIndexes=[...selectedProfilesIndex]
    // Draw profiles on canvas
    let loading=false;
    let prevZI= d3.zoomIdentity;
    // setIsLoadingMoreProfiles(false);
    console.log("Load more before draw function===========================>>>>>>")
    const draw=(transform,isFirstLoad,xt)=> {
      randomR=1;
      randomG=1;
      randomB=1;
      prevZI=transform;
     
      console.log("<=======  DRAW FUNCTION CALLED ======>", transform,canvasWidth,canvasHeight,historicalData.config.totalWidth,historicalData.profilesData.length)
      // console.log("draw() transform =",transform)
      // if(canvasWidth>historicalData.config.totalWidth)
      // transform.x=1*transform.k*transform.x;
      //loads more data once the pan extreme is reached
      // if(historicalData.config.totalWidth>canvasWidth && transform.k<1){
       
        console.log("load more dataLoaded value=", loading,dataLoadedRef.current,isLoadingMoreProfilesRef.current,historicalData.config.totalWidth>canvasWidth,transform.k<=zoomOutMinVal,zoomOutChartBased>=ZOOM_OUT_EXTREME,transform.k,ZOOM_OUT_EXTREME)
        if(historicalData.config.totalWidth>canvasWidth && isLoadingMoreProfilesRef.current==false && loading==false &&  dataLoadedRef.current){
          console.log("load more dataLoaded value inside=", loading,dataLoadedRef.current,isLoadingMoreProfilesRef.current,historicalData.config.totalWidth>canvasWidth,transform.k<=zoomOutMinVal,zoomOutChartBased>=ZOOM_OUT_EXTREME,transform.k,ZOOM_OUT_EXTREME)
       
          // if(historicalData.config.totalWidth>canvasWidth){
      if(transform.x>=endProfileExtreme*transform.k){
        console.log("Load more data as pan extreme is reached ",endProfileExtreme,transform,historicalData.config.totalWidth,canvasWidth);
        loadMoreData(transform);
        loading=true;
      }

       //loads more data once the pan zoom is reached
      else if(transform.k<=zoomOutMinVal && zoomOutChartBased>=ZOOM_OUT_EXTREME){
        if(transform.k>ZOOM_OUT_EXTREME){
          console.log("Load more data as zoom out extreme is reached",zoomOutMinVal,zoomOutChartBased,transform);
          loadMoreData(transform);
         
          loading=true;
        }
      }
    }
      //handles the x scale rescaling and slicing the date exis text if required
      let scaleX=undefined;
      if(props.selectedTimeFrame==TIME_FRAME_VALUES.monthly || props.selectedTimeFrame==TIME_FRAME_VALUES.monthly_series ){
        if(transform.k>=0.75){
          scaleX =xScale.copy()
          .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
        }else{
          scaleX =xScale.copy()
          .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
          .domain((xScale.domain().map((date,i) => (date.length<13?date.slice(0,5):dayjs(date.slice(-10).split("-")[2]+"-"+date.slice(-10).split("-")[1]+date.slice(-10).split("-")[0]).format("MMM, YY")))))
      
        }
      }else{
        if(transform.k>=0.5){
          scaleX =xScale.copy()
          .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
        }else{
          scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
        .domain((xScale.domain().map((date,i) => (date.length<13?date.slice(0,5):date.slice(0,date.length-5)))))
        }
      }
      
  
        let leftIndex=binarySearch(scaleX.copy().range(),0-props.chartStateData.left);
        let rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-5);
        let scaleY = transform.rescaleY(yScale);
        // let transform1=transform;
        // transform.x=xt;
      
        setTransformData(transform);
         
      //rescale Y axis based on current transform
      // const scaleY = transform.rescaleY(yScale);
      // console.log("scale Y range",scaleY.range(),scaleY.domain(),yScale(scaleY.domain()[1]),yScale(scaleY.domain()[0]))
      
      //add the newly calculated scales
      gxAxis.call(xAxis.scale(scaleX));
      gyAxis.call(yAxis.scale(scaleY));
      
      //create the texts on the scale as per the current transform
      gxAxis.selectAll("text").each(function(d, i) {
        
        let xT=(scaleX.range()[i + 1] - scaleX(d))/2;
        if(i+1!=scaleX.range().length-1){
        if(transform.k<0.6)
          d3.select(this).attr("transform", `translate(${xT},4) scale(${1-((1-transform.k)/2)})`)
        else
          d3.select(this).attr("transform", `translate(${xT},4)`)
        }else{
          
         
           xT=50;
          if(transform.k<0.6)
            d3.select(this).attr("transform", `translate(${xT},4) scale(${1-((1-transform.k)/2)})`)
          else
            d3.select(this).attr("transform", `translate(${xT},4)`)
      


        }
      })
    
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
      const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;

      //performance improvement only drawing the profile under the screen view
      if(leftIndex==-1 || leftIndex==undefined)
      leftIndex=0;  
      if(rightIndex>historicalData.profilesData.length-1 || rightIndex==undefined)
      rightIndex=historicalData.profilesData.length-1; 
      
      console.log("leftIndex, rightIndex=",leftIndex,rightIndex,historicalData.profilesData.length);

        //draw volume profile
        if(props.selectedInstrumentData.vp==true){
          // for(let i=0;i<historicalData.profilesData.length;i=i+1){
            for(let i=leftIndex;i<=rightIndex;i=i+1){
            if(historicalData.profilesData[i].isVolumeProfile && (historicalData.profilesData[i].isTPOonVolume || historicalData.profilesData[i].showOnlyVolumeProfile)){
              let maxPlot=(historicalData.profilesData[i].maxTPO-historicalData.profilesData[i].actualMinTPO)/selectedTPO+1;
              // console.log("draw volume tpo ========>",historicalData.profilesData[i].data.length,historicalData.profilesData[i].data,historicalData.profilesData[i].minTPO);
              for(let j=0;j<Math.min(historicalData.profilesData[i].data.length,historicalData.profilesData[i].volumeData.length);j=j+1){
               if(historicalData.profilesData[i].isStacked){
                drawVolumes(j,xScaleRange[i]+PROFILE_LEFT_MARGIN,historicalData.profilesData[i].actualMinTPO+j*selectedTPO,historicalData.profilesData[i].volumeData[j],historicalData.profilesData[i].maxVolPerc,historicalData.profilesData[i].stackedWidth-PROFILE_LEFT_MARGIN-PROFILE_RIGHT_MARGIN,historicalData.profilesData[i].isVolumeNumbers && !historicalData.profilesData[i].isTPOonVolume,transform)
               }
               else{
                drawVolumes(j,xScaleRange[i]+PROFILE_LEFT_MARGIN,historicalData.profilesData[i].actualMinTPO+j*selectedTPO,historicalData.profilesData[i].volumeData[j],historicalData.profilesData[i].maxVolPerc,historicalData.profilesData[i].splitWidth-PROFILE_LEFT_MARGIN-PROFILE_RIGHT_MARGIN,historicalData.profilesData[i].isVolumeNumbers && !historicalData.profilesData[i].isTPOonVolume,transform)
               
               }
               }
            }
           }
          }
          let tooltipData={
            data:[]
          };
      // for(let i=0;i<historicalData.profilesData.length;i=i+1){
        for(let i=leftIndex;i<=rightIndex;i=i+1){
          let currTooltipData=[];
          tooltipData.data[i]=[];
        // draw special nodes i.e vpoc vwap hvn, va
        // console.log("profile TPO data",historicalData.profilesData[i].data);
        // console.log("--+metadata", metaData)
        drawNodes(scaleY,xScaleRange[i],historicalData.profilesData[i],i,isLiveSession,transform.k,tooltipData.data[i],metaData);
         console.log("profiles data=",historicalData.profilesData[i],tooltipData.data[i])
        if(historicalData.profilesData[i].showTPO){
         for(let j=0;j<historicalData.profilesData[i].data.length;j=j+1){
              if(historicalData.profilesData[i].isStacked)
              historicalData.profilesData[i].data[j].forEach( (tpoData,currIndex) => {
              drawTPO(scaleX,scaleY,xScaleRange[i]+PROFILE_LEFT_MARGIN+currIndex*TPO_WIDTH,
                historicalData.profilesData[i].minTPO+j*selectedTPO,tpoData.charText,tpoData.index,historicalData.profilesData[i],(historicalData.profilesData[i].data[j].length-1==currIndex),tpoData, tooltipData.data[i])
              });
            else
              historicalData.profilesData[i].data[j].forEach( (tpoData,currIndex) => {
                drawTPO(scaleX,scaleY,xScaleRange[i]+PROFILE_LEFT_MARGIN+tpoData.index*TPO_WIDTH,
                  historicalData.profilesData[i].minTPO+j*selectedTPO,tpoData.charText,tpoData.index,historicalData.profilesData[i],(historicalData.profilesData[i].data[j].length-1==currIndex),tpoData, tooltipData.data[i])
              });
            }
        
        }
        // tooltipData.push(currTooltipData)
        }  

        console.log("Final tooltip data=",tooltipData);

      
      
        //draw volume profile
        if(props.selectedInstrumentData.vp==true){
        // for(let i=0;i<historicalData.profilesData.length;i=i+1){
          for(let i=leftIndex;i<=rightIndex;i=i+1){
          if(historicalData.profilesData[i].isVolumeProfile && !historicalData.profilesData[i].isTPOonVolume && !historicalData.profilesData[i].showOnlyVolumeProfile){
            let maxPlot=(historicalData.profilesData[i].maxTPO-historicalData.profilesData[i].actualMinTPO)/selectedTPO+1;
            // console.log("draw volumet ========>",historicalData.profilesData[i].data.length,historicalData.profilesData[i].data,historicalData.profilesData[i].minTPO);
            for(let j=0;j<Math.min(historicalData.profilesData[i].data.length,historicalData.profilesData[i].volumeData.length);j=j+1){
             if(historicalData.profilesData[i].isStacked){
              drawVolumes(i,xScaleRange[i]+historicalData.profilesData[i].stackedWidth,historicalData.profilesData[i].actualMinTPO+j*selectedTPO,historicalData.profilesData[i].volumeData[j],historicalData.profilesData[i].maxVolPerc,(historicalData.profilesData[i].volumeWidth-10),historicalData.profilesData[i].isVolumeNumbers,transform)
             }
             else{
              drawVolumes(i,xScaleRange[i]+historicalData.profilesData[i].splitWidth,historicalData.profilesData[i].actualMinTPO+j*selectedTPO,historicalData.profilesData[i].volumeData[j],historicalData.profilesData[i].maxVolPerc,historicalData.profilesData[i].volumeWidth-10,historicalData.profilesData[i].isVolumeNumbers,transform)
             
             }
             }
          }
         }
        }  
        
        
      //draw previously selected profiles overlay
      contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight)
      for(let temp=0;temp<selectedIndexes.length;temp++)
      {
        // console.log("inside indexes loop 1=",yScale(0),yScale(canvasHeight),yScale(scaleY.domain()[1]),yScale(scaleY.domain()[0]),scaleY.domain()[1],scaleY.domain()[0])
        // console.log("inside indexes loop 2=",
        contextOverlay.fillStyle = `${theme.palette.primaryTheme.shade03}`;
        let minY=Math.round(yScale(scaleY.domain()[1]))+2;
        let maxY=Math.round(yScale(scaleY.domain()[0]));
        // console.log("min max Y =",minY,maxY,xScaleRange[selectedIndexes[temp]],xScaleRange[selectedIndexes[temp]+1])
        contextOverlay.fillRect(xScaleRange[selectedIndexes[temp]]+1,minY, xScaleRange[selectedIndexes[temp]+1]-xScaleRange[selectedIndexes[temp]]-1,(Math.abs(minY)+Math.abs(maxY)));
        contextOverlay.stroke();
      }
      
      if(props.globalVolumeVisible && props.selectedInstrumentData.vp==true){
        //TODO: check if 60% of the profile is visible
        let leftIndex=binarySearch(scaleX.copy().range(),40);
        const rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-30);
        
        //incase smaller chart set the left index to first profiles
        if(leftIndex===-1)
        leftIndex=0;

       
        // let volumeData=getVolumeProfilesInRange(leftIndex,rightIndex);
        let volumeData=getVolumeProfilesInRange(leftIndex,historicalData.profilesData.length-1);
        let width=Math.ceil(0.15*canvasWidth);
         console.log("G V id<=================> li, ri = ",props.id,leftIndex,rightIndex,volumeData,transform,width,canvasWidth);
        
        for(let i=0;i<volumeData.data.length;i++){
            drawVolumes(i,(-1*(transform.x/transform.k)),volumeData.minTPO+i*selectedTPO,volumeData.data[i],volumeData.maxPerc,width,props.volumeNumberVisible,transform)
        }
         
      
      } 

      if(selectedTimeFrameRef.current==TIME_FRAME_VALUES.daily && !props.basicUser){
     
      //plot upper and lower level if present
      if(levelData[0] && levelData[0]!=-1){
        context.save()
        context.beginPath()
        // context.setLineDash([2/transform.k, 2/transform.k]);
        // console.log("-1*(transform.x/transform.k)")
        if(transform.k<1){
          context.moveTo(-1*(transform.x/transform.k), yScale(levelData[0]))
          context.lineTo(canvasWidth+dummyProfileWidth, yScale(levelData[0]))
          
        }
        else{
          context.moveTo(-1*(transform.x/transform.k), yScale(levelData[0]))
          context.lineTo(canvasWidth+dummyProfileWidth, yScale(levelData[0]))
        }
        context.strokeStyle = CHART_COLORS.FROZEN_PRICE_LINE;

        context.lineWidth=2/transform.k;
        context.stroke()
        context.restore();
        
        d3.selectAll(`#tooltipLowerLevel${props.id}`).style("display", "block").style("cursor", "pointer").datum({isUpper:false}).on("click", function(event,i) {
          console.log("boundary onclick",event,i,i.isUpper);
          setPriceVal(levelData[0]);
          if(levelData[1]!=-1)
            setOpenDeleteLevelDialog(true);
          else
            saveDeleteLevelHandler();
          // d3.event.stopPropagation();
        });
        tooltipLowerLevel.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(levelData[0])+45 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(levelData[0].toFixed(2));
      }else{
        d3.selectAll(`#tooltipLowerLevel${props.id}`).style("display", "none");
        tooltipLowerLevel.style("left", (canvasWidth - 0) + "px")
            .style("top", "-99999x")   //10=height of tooltip/2 80=Headers add height
            .html("");
      
      }

      if(levelData[1] && levelData[1]!=-1){
        context.save()
        context.beginPath()
        // context.setLineDash([1/transform.k, 1/transform.k]);
        // console.log("-1*(transform.x/transform.k)")
        if(transform.k<1){
          context.moveTo(-1*(transform.x/transform.k), yScale(levelData[1]))
          context.lineTo(canvasWidth+dummyProfileWidth, yScale(levelData[1]))
          
        }
        else{
          context.moveTo(-1*(transform.x/transform.k), yScale(levelData[1]))
          context.lineTo(canvasWidth+dummyProfileWidth, yScale(levelData[1]))
        }
        context.strokeStyle = CHART_COLORS.FROZEN_PRICE_LINE;

        context.lineWidth=2/transform.k;
        context.stroke()
        context.restore();
        d3.selectAll(`#tooltipUpperLevel${props.id}`).style("display", "block").style("cursor", "pointer").datum({isUpper:true}).on("click", function(event,i) {
          console.log("boundary onclick",event,i,i.isUpper);
          setPriceVal(levelData[1]);
          if(levelData[0]!=-1)
            setOpenDeleteLevelDialog(true);
          else
            saveDeleteLevelHandler();
          // d3.event.stopPropagation();
        });
        tooltipUpperLevel.style("left", (canvasWidth - 0) + "px")
            .style("top", scaleY(levelData[1])+45 + "px")   //10=height of tooltip/2 80=Headers add height
            .html(levelData[1].toFixed(2));
      }else{
        d3.selectAll(`#tooltipUpperLevel${props.id}`).style("display", "none");
        tooltipUpperLevel.style("left", (canvasWidth - 0) + "px")
            .style("top", "-99999x")   //10=height of tooltip/2 80=Headers add height
            .html("");
      
      }
    }

      //live marker
      if( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive){
        // if(true){
        // console.log("inside live marker===================> val",scaleY(historicalData.profilesData[historicalData.profilesData.length-1].close),scaleY(historicalData.profilesData[historicalData.profilesData.length-1].close),(-1*(transform.y/transform.k)));
        context.save()
        context.beginPath()
        context.setLineDash([1/transform.k, 1/transform.k]);
        // console.log("-1*(transform.x/transform.k)")
        if(transform.k<1){
          context.moveTo(-1*(transform.x/transform.k), yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
          context.lineTo(6*canvasWidth, yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
          
        }
        else{
          context.moveTo(-1*(transform.x/transform.k), yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
          context.lineTo(6*canvasWidth/ZOOM_OUT_SCALE, yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
        }
        context.strokeStyle = CHART_COLORS.LIVE_PRICE_LINE;

        context.lineWidth=3/transform.k;
        context.stroke()
        context.restore();
        //live marker close value tooltip
        if(scaleY(historicalData.profilesData[historicalData.profilesData.length-1].close)>=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].close)<=canvasHeight){
          d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "block");
          tooltipLiveMarker.style("left", (canvasWidth - 0) + "px")
              .style("top", scaleY(historicalData.profilesData[historicalData.profilesData.length-1].close)+30 + "px")   //10=height of tooltip/2 80=Headers add height
              .html(historicalData.profilesData[historicalData.profilesData.length-1].close.toFixed(2)+"\n"+historicalData.config.lastDataTime);
        }else{
          d3.selectAll(`#tooltipLiveMarker${props.id}`).style("display", "none");
          // d3.selectAll(`#tooltipLiveMarker${props.id}`).remove();
          // tooltipLiveMarker.style("left", (canvasWidth - 0) + "px")
          //     .style("top", "-99999x")   //10=height of tooltip/2 80=Headers add height
          //     .html("");
        
        }
        //live profile closing_vwap tooltip
        if(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=undefined && historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP!=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)>=0 && scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)<=canvasHeight){
          d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "block");
          tooltipLiveCloseVwap.style("left", (canvasWidth - 0) + "px")
              .style("top", scaleY(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP)+45 + "px")   //10=height of tooltip/2 80=Headers add height
              .html(historicalData.profilesData[historicalData.profilesData.length-1].closingVWAP.toFixed(2));
        }else{
          d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).style("display", "none");
          // d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove();
          // tooltipLiveCloseVwap.style("left", (canvasWidth - 0) + "px")
          //     .style("top", "-99999x")   //10=height of tooltip/2 80=Headers add height
          //     .html("");
        
        }
      }else{
        d3.selectAll(`#tooltipLiveMarker${props.id}`).remove();
        d3.selectAll(`#tooltipLiveCloseVwap${props.id}`).remove();
      }

      
      let axisTooltipOffset=0;
      if(props.chartScreenType==CHART_SCREEN_TYPE.GRID|| props.chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS){
          if(props.val==0||props.val==2){
            axisTooltipOffset=props.chartStateData.width;
          }
      }
      //draw KRLs
      d3.selectAll(`#krl_label${props.id}`).remove()
      let krlTooltipData=[]; // contains chart level tooltip data based on tpo and cords(KRLS, NPOCs etc)
      
      d3.selectAll(`#krlAxisTooltip${props.id}`).remove();
      d3.selectAll(`#krl_label${props.id}`).remove();
      d3.selectAll(`#npoc_label${props.id}`).remove();

      if(props.userSettings.data.show_krl){
        // let chartStartDate=dayjs(props.chartData.vaMapList[1]?.dateList[0].split("-").reverse().join("-"))
        // let filteredData=krlData?.filter(item=>(!item.id && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
        // console.time("Benchmark_syskrl_old")
        // drawKRLS(transform,scaleY,krlTooltipData,axisTooltipOffset,filteredData,false);
        // console.timeEnd("Benchmark_syskrl_old")

        // console.log("drawSYSTEMRLS data=",systemKRLData)
        // console.time("Benchmark_krl_system_new")
        drawSystemKRLS(transform,scaleY,krlTooltipData,axisTooltipOffset,systemKRLData,false);
        // console.timeEnd("Benchmark_krl_system_new")
      }

      // const checkAnchoredKRLToBeIncluded=(data)=>{
      //   for(let i=0;i<historicalData.profilesData.length;i++){
      //     if(data.start_date==historicalData.profilesData[i].dateList[0] && data.start_date==historicalData.profilesData[i].dateList[historicalData.profilesData[i].dateList.length-1])
      //     return true
      //   }
      //   return false;
      // }
      
      if(props.userSettings.data.custom_krl_display){
        // console.time("Benchmark_custom_krl")
        // let chartStartDate=dayjs(props.chartData.vaMapList[1]?.dateList[0].split("-").reverse().join("-"))
        // let filteredData=krlData?.filter(item=>(item.id && (item.type || item.category=="CUSTOM_KRL") && (item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate))))
        // let finalData=[];
        // let i=0
        // for(i=0;i<filteredData?.length;i++){
        //   if(filteredData[i].category=="CUSTOM_KRL")
        //     finalData.push(filteredData[i])
        //   else{
        //     if(checkAnchoredKRLToBeIncluded(filteredData[i]))
        //       finalData.push(filteredData[i])
        //     else{
        //       let index=historicalData.profilesData.findIndex(item=>item.dateList[item.dateList.length-1]==filteredData[i].end_date)
        //       // console.log("Test Composite index=",index,filteredData[i].start_date)
        //       if(index!=-1){
        //         finalData.push(filteredData[i])
        //       // for(let k=i+1;k<filteredData.length;k++){
        //       //   if(filteredData[k].id== filteredData[i].id && filteredData[k].type== filteredData[i].type &&filteredData[k].end_date== historicalData.profilesData[index].dateList[historicalData.profilesData[index].dateList.length-1]){
        //       //     finalData.push(filteredData[i])
        //       //     i=k;
        //       //   }
        //       // }
        //     }  
        //   }

        //   }
        // } 


        // console.time("Benchmark_krl_custom_old")
        // drawKRLS(transform,scaleY,krlTooltipData,axisTooltipOffset,finalData,true);
        // console.timeEnd("Benchmark_krl_custom_old")

        // console.log("drawCustomKRLS data=",customKRLData)
        // console.time("Benchmark_krl_custom_new")
        drawCustomKRLS(transform,scaleY,krlTooltipData,axisTooltipOffset,customKRLData,true,leftIndex,rightIndex);
        // console.timeEnd("Benchmark_krl_custom_new")
       
      }

      

      d3.selectAll(`#npoc_label${props.id}`).remove()
      //draw NPOC - by default enabled if user setting is missing
      if(props.npocAllowed && (props.userSettings.data.show_ui_npoc_krl || props.userSettings.data.show_ui_npoc_krl == undefined))
        drawNPOC(transform,scaleY,krlTooltipData,rightIndex,axisTooltipOffset);
        
      contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
      
      const checkCustomKRLPresent=(event)=>{
        const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
           

        let col1 = contextTooltip.getImageData(event.offsetX, event.offsetY, 1, 1).data;
        let bgColor=rgbToHex(col1[0],col1[1],col1[2]) 
        let krlList=[];
        if(bgColor!="#000000"){
          for(let i=0;i<krlTooltipData.length;i++){
           
            let x1=krlTooltipData[i].x1*transform.k+transform.x;
            let x2=krlTooltipData[i].x2*transform.k+transform.x;
            if(priceVal==krlTooltipData[i].tpo && event.pageX-props.chartStateData.left>=x1 && event.pageX-props.chartStateData.left<x2){
             console.log("KRL TESTING obj=",krlTooltipData[i].obj,priceVal)
              
              if(krlTooltipData[i].obj.id) {
                // if(krlTooltipData[i].obj.category=="CUSTOM_KRL"||
                // krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VPOC_KRL"||
                // krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VWAP_KRL"||
                // krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL" && 
                // krlTooltipData[i].obj.id&&
                // !krlTooltipData[i].obj.type ) {
                console.log("Save KRL Custom KRL found=",krlTooltipData[i].obj)
                if(krlTooltipData[i].obj.type!="custom" && krlTooltipData[i].obj.type!="bookmark_custom"  && krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VWAP_KRL"||  krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_TPOC_KRL"||
                krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VPOC_KRL"|| krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL" || krlTooltipData[i].obj.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL"){
                  const index=krlData.findIndex(item=>(item.type=="custom" || item.type=="bookmark_custom") && item.category==krlTooltipData[i].obj.category && item.id==krlTooltipData[i].obj.id)
                  console.log("KRL TESTING index=",index,krlData, krlTooltipData[i].obj)
                  if(index!=-1){
                    const deepCopy = JSON.parse(JSON.stringify(krlData[index]));
                    deepCopy.level=krlTooltipData[i].obj.level;
                    // deepCopy.description=krlTooltipData[i].obj.description;

                  krlList.push(deepCopy)
                  }

                }else
                krlList.push(krlTooltipData[i].obj)
                // return krlTooltipData[i].obj
              }
             
             
            }
          }
        }
        return krlList;
      }


      dummyCanvas.on("contextmenu", function(event) {
          if(event.pageX-props.chartStateData.left>canvasWidth){
            event.preventDefault();
            handleYAxisContextMenu(event);
            return;
          }
          
          d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
          
          event.preventDefault();
          setSelectedKRL(undefined);
          let customKRL=checkCustomKRLPresent(event);
          console.log("Save KRL isCustomKRLClicked=",customKRL)
          if(customKRL.length>0){
            openContextMenuKRL(event.pageX,event.pageY,canvasWidth,canvasHeight,customKRL)
            return;
          }
          //searches for the date axis profiles where the right click is triggered
          const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
          // console.log("Search index =",index);
          
          //  console.log("contextmenu",index,historicalData.profilesData.length,event.pageX-props.chartStateData.left,canvasWidth)
           if(index==historicalData.profilesData.length) return; //return in case of dummy future date profile
           setTransformData(transform);
           let temp=[];
           if(!selectedIndexes.includes(index)){
            temp.push(index)
            selectedIndexes=[...selectedIndexes, ...temp]
          }
          setSelectedProfilesIndex(selectedIndexes);
              contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight)
                
              for(let temp=0;temp<selectedIndexes.length;temp++)
                {
                  // console.log("inside indexes loop =",temp,selectedIndexes[temp])
                contextOverlay.fillStyle = `${theme.palette.primaryTheme.shade03}`;
                contextOverlay.fillRect(scaleX.range()[selectedIndexes[temp]]+1,2, scaleX.range()[selectedIndexes[temp]+1]-scaleX.range()[selectedIndexes[temp]]-1,canvasHeight);
                contextOverlay.stroke();
                }
                const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            console.log("Ctx menu price=",priceVal)
           openContextMenu(event.pageX,event.pageY,index,canvasWidth,canvasHeight,priceVal)
        })

        const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
          const hex = x.toString(16)
          return hex.length === 1 ? '0' + hex : hex
        }).join('')

       const handleYAxisContextMenu=(event)=>{
        // console.log("contextmenu svg");
        event.preventDefault();
        const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
        if(priceVal>=0){
        d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
        tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
          .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
          .html(priceVal);
        }
        setPriceVal(priceVal);
        openYAxisContextMenu(event.pageX,event.pageY,canvasWidth,canvasHeight)
       }

       const handleYAxisMouseMove=(event)=>{
            setShowTooltip(false);
            if(!props.basicUser){
            if(!yaxiscontextMenuRef.current){
              
              const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
              if(priceVal>=0){
                d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
              d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
              tooltipCrossHair.style("left", (canvasWidth - 60) + "px")
                .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
                .html(priceVal);
              }
            }
          }
       }

        if(selectedTimeFrameRef.current==TIME_FRAME_VALUES.daily && !props.basicUser){
        svgChart.on("mousemove", function(event) {
         
          // console.log("testing1 mousemove show=",showYAxisContextMenu,yaxiscontextMenuRef.current);
        
        });
        svgChart.on("contextmenu", function(event) {
         
        });
      }
        
      //handles the mose move to display cross hairs and tooltips
      dummyCanvas.on("dblclick", function(event,d){
        event.preventDefault();
        console.log("double click on dummy canvas called event=",event )
      })

      dummyCanvas.on("mousemove", function(event,d){
        // console.log("testing mousemove canvas",event);
        setShowTooltip(false);
        // let col = context.getImageData(event.offsetX, event.offsetY, 1, 1).data;
        // console.log("mousemove event=, col",event,col,rgbToHex(col[0],col[1],col[2]));
        contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight)
        if(event.pageX-props.chartStateData.left>canvasWidth){
          d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
          handleYAxisMouseMove(event);
        return;
        }
        d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
        const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
       

          //Restrict the display if mouse position is outside the profiles
          // if(canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth){
          //   contextDummy.clearRect(0, 0, canvasWidth, canvasHeight)
          //   d3.selectAll("#tooltipCrosshair").style("display", "none");
          //   d3.selectAll("#tooltipCrosshairDateAxis").style("display", "none");
          //   console.log("Cross hair returning =====> cw, pw",canvasWidth,historicalData.config.totalWidth );
          //   return;
          // }
            // contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight)
            setCrossHairCord({x:event.pageX,y:event.pageY,tpo:(scaleY.invert(event.pageY-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-CHART_TOP_SPACE))%selectedTPO).toFixed(2)});
           
            // const dateVal=(index>=0 && index<historicalData.config.dateList.length)?historicalData.config.dateList[index]:undefined;
            const dateVal=(index>=0 && index<=modDateList.length-1)?modDateList[index]:undefined;
            const priceVal=(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE)-(scaleY.invert(event.pageY-props.chartStateData.top-CHART_TOP_SPACE))%selectedTPO).toFixed(2)
            // console.log("krl tooltip",dateVal,priceVal,krlTooltipData.length)
            if(dateVal!=undefined && contextMenuRef.current==false && krlcontextMenuRef.current==false){
                let col1 = contextTooltip.getImageData(event.offsetX, event.offsetY, 1, 1).data;
                // console.log("krl tooltip final tooltip data mousemove event==>>>>, col",event,col1,rgbToHex(col1[0],col1[1],col1[2]),priceVal,scaleX.copy().range()[index],scaleX.copy().range()[index+1],event.pageX-props.chartStateData.left);
                // console.log("final tooltip data",tooltipData.data[index])
                let bgColor=rgbToHex(col1[0],col1[1],col1[2]) 
                let data=tooltipData.data[index]?tooltipData.data[index].filter(item => bgColor in item):[];
                // console.log("final tooltip data",data[0]);
                let tempData=[];
                let found=false;
               
                if(data.length>0){
                  tempData=JSON.parse(JSON.stringify(data[0][bgColor]));
                  found=true;
                }

                  //check for special nodes
                  //VPOC
                  if(historicalData.profilesData[index]){
                  if(historicalData.profilesData[index].vaType==VA_TYPE.VOLUME_BASED){
                    if(priceVal==historicalData.profilesData[index].vpocTPO){
                      tempData.push({key:"VPOC",value:historicalData.profilesData[index].vpoc})
                      tempData.push({key:"VPOC Vol",value:historicalData.profilesData[index].vpoc_vol})
                      found=true;
                    }
                    if(priceVal==historicalData.profilesData[index].vwapTPO){
                      tempData.push({key:"VWAP",value:historicalData.profilesData[index].vwap})
                      found=true;
                    }
                  }else if(historicalData.profilesData[index].vaType==VA_TYPE.TPO_BASED){
                    if(priceVal==historicalData.profilesData[index].vpocTPOBased){
                      tempData.push({key:"TPOC",value:historicalData.profilesData[index].tpoc})
                      found=true;
                    }
                    if(priceVal==historicalData.profilesData[index].vwapTPOBased){
                      tempData.push({key:"TWAP",value:historicalData.profilesData[index].tpo_vwap})
                      found=true;
                    }
                  }else{
                    if(priceVal==historicalData.profilesData[index].vpocTPOBased){
                      tempData.push({key:"TPOC",value:historicalData.profilesData[index].tpoc})
                      found=true;
                    }
                    if(priceVal==historicalData.profilesData[index].vwapTPO){
                      tempData.push({key:"VWAP",value:historicalData.profilesData[index].vwap})
                      found=true;
                    }

                  }
                   //SD tooltip
                  if(index==historicalData.profilesData.length-1 && isLiveSession){
                    let profileData=historicalData.profilesData[historicalData.profilesData.length-1];
                    if(profileData.sdClose!=undefined && profileData.sdClose!=null && profileData.vwap!=undefined){
                      //2sd up
                      let sd=profileData.vwap+2*profileData.sdClose;
                      let sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
                      let sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
                      let sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
                      if(priceVal==sdTPO){
                      tempData.push({key:"2SD Up",value:sd.toFixed(2)})
                      found=true;
                      }
                    
                      //3sd up
                      sd=profileData.vwap+3*profileData.sdClose;
                      sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
                      sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
                      sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
                      if(priceVal==sdTPO){
                      tempData.push({key:"3SD Up",value:sd.toFixed(2)})
                      found=true;
                      }
                    
                      
                      //2sd down
                      sd=profileData.vwap-2*profileData.sdClose;
                      sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
                      sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
                      sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
                      if(priceVal==sdTPO){
                      tempData.push({key:"2SD Down",value:sd.toFixed(2)})
                      found=true;
                      }

                      //3sd down
                      sd=profileData.vwap-3*profileData.sdClose;
                      sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
                      sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
                      sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
                      if(sdTPO>=0){
                        if(priceVal==sdTPO){
                        tempData.push({key:"3SD Down",value:sd.toFixed(2)})
                        found=true;
                        }
                      }
                    
                    }
                  } 

                
                  //HVN 
                  if(historicalData.profilesData[index].hvnList!=undefined && historicalData.profilesData[index].hvnList.length>0){
                    for(let i=0;i<historicalData.profilesData[index].hvnList.length;i++){
                      let hvn=parseFloat(((historicalData.profilesData[index].hvnList[i]*10000)/10000).toFixed(4));
                      let hvnMod=parseFloat(((historicalData.profilesData[index].hvnList[i]*10000)%(selectedTPO*10000))/10000).toFixed(4);
                      let hvnTPO=parseFloat((hvn-hvnMod).toFixed(4));
                      if(hvnTPO==priceVal){
                        tempData.push({key:"HVN",value:historicalData.profilesData[index].hvnList[i]});
                      found=true;
                      }
                    }
                    
                  }

                  //HALF BACK
                  if(priceVal==historicalData.profilesData[index].halfBackTPO){
                    tempData.push({key:"Halfback",value:historicalData.profilesData[index].halfBack})
                    found=true;
                  }

                }
                //KRL tooltip
                let isKRL=false;
                if(bgColor!="#000000"){
                  for(let i=0;i<krlTooltipData.length;i++){
                   
                    let x1=krlTooltipData[i].x1*transform.k+transform.x;
                    let x2=krlTooltipData[i].x2*transform.k+transform.x;
                    // console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,krlTooltipData[i],transform,x1,x2)
                    // if((priceVal==krlTooltipData[i].tpo || priceVal==krlTooltipData[i].tpo-selectedTPO) && event.pageX-props.chartStateData.left>=x1 && event.pageX-props.chartStateData.left<x2){
                    if((priceVal==krlTooltipData[i].tpo) && event.pageX-props.chartStateData.left>=x1 && event.pageX-props.chartStateData.left<x2){
                      // console.log("krl tooltip final tooltip data mousemove krl tooltip priceval,tpo",priceVal,krlTooltipData[i])
                      tempData.push({key:"KRL",value:krlTooltipData[i].data,color:krlTooltipData[i].color})
                      // console.log("Save KRL tooltip data of KRL=",krlTooltipData[i])
                      //Highlight the krl logic. Y need to be transformed
                      // context.strokeStyle="#00FF00";
                      // context.beginPath();
                      // context.moveTo(x1, krlTooltipData[i].y);
                      // context.lineTo(x2, krlTooltipData[i].y);
                      // context.stroke();
                   
                      found=true;
                      isKRL=true;
                    }
                  }
                }

               
                  if(found && !props.basicUser){
                    setTooltipData(tempData)
                    // openTooltip(event.pageX,event.pageY,canvasWidth,canvasHeight,isKRL)
                    openTooltip(event.pageX,event.pageY,canvasWidth,canvasHeight,isKRL,tempData.length)
                  }
               
          }
            
            // console.log("mousemove: index, x,y,date",index,event.pageX,event.pageY,dateVal,historicalData.config.dateList)
              if(priceVal>=0){
              contextDummy.beginPath()
              contextDummy.setLineDash([2, 2]);
              contextDummy.moveTo(0, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.lineTo(canvasWidth, event.pageY-props.chartStateData.top-CHART_TOP_SPACE)
              contextDummy.strokeStyle = '#fff'
              contextDummy.stroke()
              }
              if(dateVal!=undefined){
                contextDummy.beginPath()
                contextDummy.setLineDash([2, 2]);
                contextDummy.moveTo(event.pageX-props.chartStateData.left,0)
                contextDummy.lineTo(event.pageX-props.chartStateData.left, canvasHeight)
                contextDummy.strokeStyle = '#fff'
                contextDummy.stroke()
              }
              contextDummy.closePath()
              
              if(priceVal>=0){
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                  .style("top", (event.pageY-12-48 -props.chartStateData.top) + "px")   //12=height of tooltip/2 80=Headers add height
                  .html(priceVal);
              }else{
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              }
              if(dateVal!=undefined && event.pageX-props.chartStateData.left<canvasWidth){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (event.pageX -props.chartStateData.left - (dateVal.length/4)*12 ) + "px")
                  .style("top", (XSCALE_HEIGHT+32) + "px")   //10=height of tooltip/2 80=Headers add height
                  .html(dateVal);
              }else{
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              }
             
          })
            
          .on("mouseover", function () {
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
          })
          .on("mouseout", (event, d) => {
            setCrossHairCord({x:-1,y:-1,tpo:-1});
            contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight)
            d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
            d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
            d3.selectAll(`#krlAxisTooltip${props.id}`).style("display", "none");
           })

           //click events for profile selection
           dummyCanvas.on("click", function(event) {
            
            console.log("click event=",event,chartInterationAllowedRef.current);
            // d3.selectAll("#shape").style("opacity",0);
            // if(chartInterationAllowedRef.current==false || (canvasWidth>historicalData.config.totalWidth && event.pageX<canvasWidth-historicalData.config.totalWidth)){
              if(chartInterationAllowedRef.current==false){
              contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight)
              d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "none");
              d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "none");
              console.log("click event return 1=",event);
              return;
            }
            if(event.pageX-props.chartStateData.left>canvasWidth){
              console.log("click event return 2=",event);
              return;
            }
            
            console.log("click event working=",event);
            if(contextMenuRef.current){
              setShowContextMenu(false);
              return;
             }
             if(krlcontextMenuRef.current){
              setShowKRLContextMenu(false);
              return;
             }
            
             //remove the index if already present
            function removeIndex(arr, value) {
      
              return arr.filter(function(index){
                  return index!=value;
              });
             
           }
          
           const index=binarySearch(scaleX.copy().range(),event.pageX-props.chartStateData.left);
            if(historicalData!=undefined && historicalData.profilesData[index]!=undefined){
              //if shift clicked then selec all the profiles from last selected index to this index
              //take care of duplicate indexes
              let temp=[];
                 
              if (event.shiftKey) {
                console.log("This is the shift function")
                if(selectedIndexes.length>0 &&  index!=selectedIndexes){
                  const minIndex=Math.min(...selectedIndexes);
                  const maxIndex=Math.max(...selectedIndexes);
                  if(index<minIndex){
                    for(let i=index;i<minIndex;i++){
                      temp.push(i);
                    }
                  }else{
                    for(let i=maxIndex+1;i<=index;i++){
                      temp.push(i);
                    }
                  }
                  console.log("temp = ",temp);
                  selectedIndexes=[...selectedIndexes, ...temp];

                }else if (index==selectedIndexes){ //same index, don't do anything
                  
                }else{
                  temp.push(index) 
                  selectedIndexes=[...selectedIndexes, ...temp];
                  console.log("else of shift ",)
                }
             }
             else{   
                if(!selectedIndexes.includes(index)){
                  temp.push(index)
                  selectedIndexes=[...selectedIndexes, ...temp]
                }else{
                 //deselect the already selected element
                 selectedIndexes = removeIndex(selectedIndexes, index);
                }
              
              }
              setSelectedProfilesIndex(selectedIndexes);
              contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight)
                
              for(let temp=0;temp<selectedIndexes.length;temp++)
                {
                  // console.log("inside indexes loop =",temp,selectedIndexes[temp])
                contextOverlay.fillStyle = `${theme.palette.primaryTheme.shade03}`;
                contextOverlay.fillRect(scaleX.range()[selectedIndexes[temp]]+1,2, scaleX.range()[selectedIndexes[temp]+1]-scaleX.range()[selectedIndexes[temp]]-1,canvasHeight);
                contextOverlay.stroke();
                }  
           }
        })

            //draw previous crossHair
            if(crossHairCord.x!=-1 && crossHairCord.y!=-1){
              // console.log("transform in crosshair=",transform.k,transformData.k);
              // d3.selectAll("#tooltipCrosshair").remove();
              // d3.selectAll("#tooltipCrosshairDateAxis").remove();
              contextDummy.clearRect(0,0,canvasWidth+YSCALE_WIDTH-2,canvasHeight);
              const index=binarySearch(scaleX.copy().range(), crossHairCord.x-props.chartStateData.left);
              const dateVal=(index>=0 && index<historicalData.config.dateList.length)?historicalData.config.dateList[index]:undefined;
              console.log("Crosshair redraw index,dateval=========>",index,dateVal);
              // context.moveTo(-1*(transform.x/transform.k), yScale(historicalData.profilesData[historicalData.profilesData.length-1].closeTPO))
              // context.lineTo(canvasWidth, yScale(historicalData.profilesData[historicalData.profilesData.length-1].close))
                contextDummy.save();
                contextDummy.beginPath()
                contextDummy.lineWidth=2/transform.k;
                contextDummy.setLineDash([Math.round(2/transform.k), Math.round(2/transform.k)]);
                contextDummy.moveTo(-1*(transform.x/transform.k), yScale(crossHairCord.tpo)-props.chartStateData.top-4)
                contextDummy.lineTo(6*canvasWidth, yScale(crossHairCord.tpo)-props.chartStateData.top-4)
                contextDummy.strokeStyle = '#999'
                contextDummy.stroke()
                if(dateVal!=undefined){
                  contextDummy.beginPath()
                  contextDummy.setLineDash([2/transform.k, 2/transform.k]);
                  contextDummy.moveTo((crossHairCord.x-transform.x)/transform.k-props.chartStateData.left,-1*(transform.y/transform.k))
                  // contextDummy.lineTo((crossHairCord.x-transform.x)/transform.k, canvasHeight/transform.k+Math.abs(transform.y));
                  contextDummy.lineTo((crossHairCord.x-transform.x)/transform.k-props.chartStateData.left, 99999);
                  // contextDummy.strokeStyle = '#999'
                  contextDummy.stroke()
                }
                contextDummy.closePath()
                contextDummy.restore();
                // console.log("cross hair========>",crossHairCord.x,transform.x/transform.k);
                d3.selectAll(`#tooltipCrosshair${props.id}`).style("display", "block");
                tooltipCrossHair.style("left", (canvasWidth - 0) + "px")
                .style("top", (crossHairCord.y-props.chartStateData.top-48-10) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(crossHairCord.tpo);
                if(dateVal!=undefined){
                d3.selectAll(`#tooltipCrosshairDateAxis${props.id}`).style("display", "block");
                tooltipCrossHairDateAxis.style("left", (crossHairCord.x-props.chartStateData.left-6 - (dateVal.length/4)*10) + "px")
                .style("top", (XSCALE_HEIGHT+24+8) + "px")   //10=height of tooltip/2 80=Headers add height
                .html(dateVal);
                }
            
            }
          
        
       
    }

   //plot tpos on canvas 
    function drawTPO(scaleX, scaleY, xCord,yCord,value,index,profileData,isLast,tpoData,tooltipData) {
      // context.font = "12px DM Sans"  // Bold
      // context.font = "600 12px sans-serif"
      context.font=CHART_FONT_DEFAULT;
      const px = (xCord);
      const py = yScale(yCord);
      // console.log("xcord, yCord = ",xCord,yCord,px,py,value);  
      // context.save();
      // contextTooltip.save();
      if(profileData.isStacked){
        let bSpecialTPO=false;
        if(profileData.dateList.length==1){
           if(index==0 || index==1){
             context.fillStyle = CHART_COLORS.INITIAL_TPO_COLOR;
            }
          else
            context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          }
        else{
          if(profileData.dateList.length>props.userSettings.data.max_ndays_for_composite){
          let colorIndex=Math.floor(index/52)
          context.fillStyle = CHART_COLORS.TPO_COLOR_LIST[colorIndex];
          }else{
            context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          }
        }

        // if(yCord==profileData.openTPO && index==0){
          if(tpoData.isOpen){
          context.beginPath();
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_BG;
          context.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          context.fill();
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
          // value="O"
          context.fillText("0", px, py- 1);
          bSpecialTPO=true;
          
        }
        // else if(yCord==profileData.closeTPO && isLast){
          else if(tpoData.isClose){
          // console.log("yCord,profileData.closetpo 22 =",yCord,profileData.closeTPO)
          context.beginPath();
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_BG;
          context.arc(px+TPO_WIDTH/2-2, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          context.fill();
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
          context.fillText("*", px+2, py+2);
          bSpecialTPO=true;
          // value="*"
        }
        else if(tpoData.isDOpenTPO){
          context.fillText("0", px+1, py- 1);
          bSpecialTPO=true;
        }
        else
        context.fillText(value, px, py);

        if(bSpecialTPO){
          let data=[];
          if(tpoData.isOpen){
            data.push({key:"Live Open",value:profileData.tpoVaList[tpoData.index].open})
          }else if(tpoData.isDOpenTPO){
            data.push({key:"Day Open",value:profileData.dOpen})
          }
          if(tpoData.isTPOClose){
            data.push({key:"Close",value:profileData.tpoVaList[tpoData.index].close})
          }
          
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          tooltipData.push({
            [randomColor]: data
          });
          contextTooltip.beginPath();
          contextTooltip.fillStyle=randomColor;
          contextTooltip.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          // contextTooltip.fillRect(px, (py-TPO_HEIGHT/2-2),12,12);
          contextTooltip.fill();

        }
        
      }else{  
        // console.log("isStacked false");
            // if(index==0 || index==1)
            //   context.fillStyle = CHART_COLORS.INITIAL_TPO_COLOR;
            // else
            //   context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
            let bSpecialTPO=false;
            

            if(profileData.dateList.length==1){
              if(index==0 || index==1){
                context.fillStyle = CHART_COLORS.INITIAL_TPO_COLOR;
               }
             else
               context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
             }
           else{
             let colorIndex=Math.floor(index/52)
             context.fillStyle = CHART_COLORS.TPO_COLOR_LIST[colorIndex];
           }
          
          if(tpoData.isOpen){
              context.beginPath();
              context.fillStyle=tpoData.isOISpikeTPO?(tpoData.isNegativeOI? CHART_COLORS.OI_SPIKE_NEG_BG: CHART_COLORS.OI_SPIKE_POS_BG): CHART_COLORS.SPECIAL_TPO_BG;
              context.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
              context.fill();
              context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
              context.fillText("0", px, py- 1);
              bSpecialTPO=true;
              
            } else if(tpoData.isDOpenTPO){
              context.fillText("0", px+1, py- 1);
              bSpecialTPO=true;
            }
            
          else if(tpoData.isTPOClose){
            // console
            context.beginPath();
            // context.fillStyle=tpoData.isOISpikeTPO?(tpoData.isNegativeOI? CHART_COLORS.OI_SPIKE_NEG_BG: CHART_COLORS.OI_SPIKE_POS_BG): CHART_COLORS.SPECIAL_TPO_BG;
            // context.arc(px+TPO_WIDTH/2-2, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
            // context.fill();
            context.fillStyle=CHART_COLORS.SPECIAL_TPO_BG;
            context.fillText("*", px+2, py+2);
            bSpecialTPO=true;
          }
          else if(tpoData.isTPOVWAP){
          context.beginPath();
          context.fillStyle=tpoData.isOISpikeTPO?(tpoData.isNegativeOI? CHART_COLORS.OI_SPIKE_NEG_BG: CHART_COLORS.OI_SPIKE_POS_BG): CHART_COLORS.VWAP;
          context.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          context.fill();
          // context.font = "bold 14px";
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
          context.fillText("=", px+1.5, py-1);
          bSpecialTPO=true;
          
         }
         else if(tpoData.isTPOVPOC){
          context.beginPath();
          context.fillStyle=tpoData.isOISpikeTPO?(tpoData.isNegativeOI? CHART_COLORS.OI_SPIKE_NEG_BG: CHART_COLORS.OI_SPIKE_POS_BG): CHART_COLORS.VPOC;
          context.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          context.fill();
          // context.font = "bold 14px";
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
          context.fillText("~", px+1.5, py- 1);
          bSpecialTPO=true;
         }
         
         
        else if(tpoData.isOISpikeTPO){
          context.beginPath();
          context.fillStyle=tpoData.isOISpikeTPO &&tpoData.isNegativeOI? CHART_COLORS.OI_SPIKE_NEG_BG: CHART_COLORS.OI_SPIKE_POS_BG;
          context.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          context.fill();
          context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
          context.fillText(value, px+1, py- 1);
          bSpecialTPO=true;
 
        }
        else if(tpoData.isHalfBackTPO){
          context.font=SPECIAL_TPO_CHAR_FONT;
          console.log("halfback tpo found")
          context.beginPath();
          // context.fillStyle=CHART_COLORS.HALF_BACK_BG;
          // context.arc(px+TPO_WIDTH/2-2, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          // context.fill();
          // context.fillStyle=CHART_COLORS.SPECIAL_TPO_COLOR;
         
          context.fillText("-", px+2, py);
          bSpecialTPO=true;
          context.font=CHART_FONT_DEFAULT;
        }
        else {
          // context.font = "bold";
          context.fillText(value, px, py);
        }

        //tooltip data
        if(bSpecialTPO){
          let data=[];
          let dateSet = false;
          // data.push({key:"Price",value:yCord});
          if(tpoData.isOpen){
            // data+="OPEN:"+profileData.tpoVaList[tpoData.index].open+"\n";
            data.push({key:"Live Open",value:profileData.tpoVaList[tpoData.index].open})
          }else if(tpoData.isDOpenTPO){
            // data+="OPEN:"+profileData.tpoVaList[tpoData.index].open+"\n";
            data.push({key:"Day Open",value:profileData.dOpen})
          }
          if(tpoData.isTPOClose){
            // data+="CLOSE:"+profileData.tpoVaList[tpoData.index].close+"\n";
            if(profileData.ptype == "m" && !dateSet){
              data.push({key:"("+value+") Date",value:profileData.dateList[tpoData.index]})
              dateSet = true;
            }
            data.push({key:"("+value+") Close",value:profileData.tpoVaList[tpoData.index].close})
          }
          if(tpoData.isHalfBackTPO){
            // data+="CLOSE:"+profileData.tpoVaList[tpoData.index].close+"\n";
            if(profileData.ptype == "m" && !dateSet){
              data.push({key:"("+value+") Date",value:profileData.dateList[tpoData.index]})
              dateSet = true;
            }
            data.push({key:"("+value+") Halfback",value:((profileData.tpoVaList[tpoData.index].high+profileData.tpoVaList[tpoData.index].low)/2).toFixed(2)})
          }

          if(tpoData.isTPOVWAP){
            // data+="VWAP:"+profileData.tpoVaList[tpoData.index].vwap+"\n";
            if(profileData.ptype == "m" && !dateSet){
              data.push({key:"("+value+") Date",value:profileData.dateList[tpoData.index]})
              dateSet = true;
            }
            data.push({key:"("+value+") VWAP",value:profileData.tpoVaList[tpoData.index].vwap})
          }
          if(tpoData.isTPOVPOC){
            // data+="VPOC:"+profileData.tpoVaList[tpoData.index].vpoc+"\n";
            if(profileData.ptype == "m" && !dateSet){
              data.push({key:"("+value+") Date",value:profileData.dateList[tpoData.index]})
              dateSet = true;
            }
            data.push({key:"("+value+") VPOC",value:profileData.tpoVaList[tpoData.index].vpoc})
            data.push({key:"("+value+") VPOC Vol",value:profileData.tpoVaList[tpoData.index].vpoc_vol})
          }
          if(tpoData.isOISpikeTPO){
            console.log("OI Spike=",profileData.oi_spikes_tuples)
            if(profileData.oi_spikes_tuples!=undefined &&  profileData.oi_spikes_tuples!=null && profileData.oi_spikes_tuples.length>0){
              for(let i=0;i<profileData.oi_spikes_tuples.length;i++){
                console.log("OI Spike 2=",profileData.oi_spikes_tuples)
                if(profileData.oi_spikes_tuples[i][3]==tpoData.index){
                let oiSpike=parseFloat(((profileData.oi_spikes_tuples[i][0]*10000)/10000).toFixed(4));
                let oiSpikeMod=parseFloat(((profileData.oi_spikes_tuples[i][0]*10000)%(selectedTPO*10000))/10000).toFixed(4);
                let val=parseFloat((oiSpike-oiSpikeMod).toFixed(4));
                let vol=profileData.oi_spikes_tuples[i][1];
                let spike_time=profileData.oi_spikes_tuples[i][2];
                if(val==yCord){
                  data.push({key:"OI Spike",value:oiSpike})
                  data.push({key:"Spike Qty",value:vol})
                  data.push({key:"Spike Time",value:spike_time})
                }
                }
              }
            }
          }

          console.log("Tooltip data=",data);
          // let randomColor ="#"+ Math.floor(Math.random()*16777215).toString(16);
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          tooltipData.push({
            [randomColor]: data
          });
          contextTooltip.beginPath();
          contextTooltip.fillStyle=randomColor;
          contextTooltip.arc(px+TPO_WIDTH/2-2.5, py-TPO_HEIGHT/2+2, 6, 0, 2 * Math.PI);
          // contextTooltip.fillRect(px, (py-TPO_HEIGHT/2-2),12,12);
          contextTooltip.fill();
          // contextTooltip.restore();

        }
      }

      // context.restore();
    }

    function drawNodes(scaleY,xCord,profileData,index,isLiveSession,zoomLevel,tooltipData,metadata){
      let width=profileData.stackedWidth-8;
      
      if(!profileData.isStacked)
        width=profileData.splitWidth-8;

        const px = (xCord)+4;
        
      
      //draw volume area
      let opacity=1.0;
      if(profileData.isTPOonVolume || profileData.showOnlyVolumeProfile)
      opacity=0.3;
      context.save();
      contextTooltip.save();
      context.globalAlpha = opacity;
      // console.log("vah profileData.volumeBasedVA",profileData.volumeBasedVA,profileData.volumeBasedVA? profileData.vahTPO:profileData.vahTPOBased)
      let isVolumeBasedVA=(profileData.vaType==VA_TYPE.VOLUME_BASED);
      context.beginPath();
      context.moveTo(px, yScale((isVolumeBasedVA? profileData.vahTPO+selectedTPO:profileData.vahTPOBased+selectedTPO))+2);
      context.lineTo(px+width, yScale((isVolumeBasedVA? profileData.vahTPO+selectedTPO:profileData.vahTPOBased+selectedTPO))+2);
      context.lineTo(px+width, yScale(isVolumeBasedVA? profileData.valTPO:profileData.valTPOBased)+2);
      context.lineTo(px, yScale(isVolumeBasedVA? profileData.valTPO:profileData.valTPOBased)+2);
      context.lineWidth = 1;
      
      context.fillStyle=CHART_COLORS.VA_BG;
      context.fill();

     
     
      let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      let randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.fillStyle=randomColor;
      contextTooltip.fillRect(px, yScale((isVolumeBasedVA? profileData.vahTPO+selectedTPO:profileData.vahTPOBased+selectedTPO))+2,width,3);
      contextTooltip.stroke();
      let tempData=[]
      tempData.push({key:"VAH",value:isVolumeBasedVA? profileData.vah:profileData.tpo_vah})
      tooltipData.push({
        [randomColor]:tempData
      })
      
      val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
      randomColor=rgbToHex(randomR,randomG,randomB);
      contextTooltip.fillStyle=randomColor;
      contextTooltip.fillRect(px, yScale((isVolumeBasedVA? profileData.valTPO:profileData.valTPOBased)),width,3);
      contextTooltip.stroke();
      tempData=[]
      tempData.push({key:"VAL",value:isVolumeBasedVA? profileData.val:profileData.tpo_val})
      tooltipData.push({
        [randomColor]:tempData
      })

      // let height=yScale((profileData.volumeBasedVA? profileData.vahTPO:profileData.vahTPOBased)+selectedTPO)-yScale(profileData.volumeBasedVA? profileData.valTPO:profileData.valTPOBased)
      // context.fillRect(px, yScale((profileData.volumeBasedVA? profileData.vahTPO:profileData.vahTPOBased)+selectedTPO)+2,width,-1*height)
      // context.stroke();
      // context.closePath();
      context.restore();
      context.save();
      contextTooltip.restore();
      contextTooltip.save();      
      let py;
      //draw hvn_node
      // console.log("prfileData hv_nodes=",profileData.hvn_nodes)
      if(isVolumeBasedVA && profileData.hvn_nodes!=undefined && profileData.hvn_nodes!=null && profileData.hvn_nodes.length>0){
          // context.save()
          for(let i=0;i<profileData.hvn_nodes.length;i++){
          py = (yScale(profileData.hvn_nodes[i]));
          // console.log("prfileData hv_nodes i py=",profileData.hvn_nodes[i],i,py)
          context.fillStyle=CHART_COLORS.HVN;
          context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
          context.stroke();
          }
          // context.restore();
      }

      //draw vpoc
      
      if(profileData.vpocTPO!=undefined && isVolumeBasedVA){
      // context.save()
        py = (yScale(profileData.vpocTPO));
        context.fillStyle=CHART_COLORS.VPOC;
        context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
        context.stroke();
        // context.restore();
      }
      if(profileData.vpocTPOBased!=undefined && !isVolumeBasedVA){
        // context.save()
          py = (yScale(profileData.vpocTPOBased));
          context.fillStyle=CHART_COLORS.VPOC;
          context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
          context.stroke();
          // context.restore();
        }
      
       let isSessionActive=(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)
           
      //draw IB high and low for single profiles only
      if(profileData.dateList.length==1){
        if(profileData.ibHighTPO!=undefined){
        // context.save()
          py = (yScale(profileData.ibHighTPO+selectedTPO));
          context.fillStyle=CHART_COLORS.IB_LINE;
          context.fillRect(px, (py+2),width,1.5);
          context.stroke();
          // context.restore();
          contextTooltip.fillStyle=CHART_COLORS.IB_LINE;
          contextTooltip.fillRect(px, (py),width,5);
          contextTooltip.stroke();
          let tempData=[]
          tempData.push({key:"IB High",value:profileData.ib_high})
          tooltipData.push({
            [CHART_COLORS.IB_LINE]:tempData
          })
        }
       
        if(profileData.ibLowTPO!=undefined){
          // context.save()
            py = (yScale(profileData.ibLowTPO));
            context.fillStyle=CHART_COLORS.IB_LINE;
            context.fillRect(px, (py+2),width,1.5);
            context.stroke();
            // context.restore();
            contextTooltip.fillStyle=CHART_COLORS.IB_LINE_LOW;
            contextTooltip.fillRect(px, (py+2),width,5);
            contextTooltip.stroke();
            let tempData=[]
            tempData.push({key:"IB Low",value:profileData.ib_low})
            tooltipData.push({
              [CHART_COLORS.IB_LINE_LOW]:tempData
            })  
        }

        if(profileData.halfBackTPO!=undefined){
          // context.save()
            py = (yScale(profileData.halfBackTPO));
            context.lineWidth=1;
            context.strokeStyle =CHART_COLORS.HALF_BACK_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py-1);
            context.lineTo(px+width,py-1);
            context.stroke();

            // context.restore();
            // let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
            // let randomColor=rgbToHex(randomR,randomG,randomB);
            // contextTooltip.fillStyle=randomColor;
            // contextTooltip.fillRect(px, (py-2),width,5);
            // contextTooltip.stroke();
            // let tempData=[]
            // tempData.push({key:"Halfback",value:profileData.halfBack})
            // tooltipData.push({
            //   [randomColor]:tempData
            // })  
        }
        
        if(index!=historicalData.profilesData.length-1 || isSessionActive==false){
        //draw nIB markers for historical data
          if(profileData.ibHighTPO!=undefined &&profileData.ibLowTPO!=undefined){
            // context.save()

            let ibHighPresent=true;
            let ibLowPresent=true;
            let count=1;
            const tpoDiff=profileData.ibHighTPO-profileData.ibLowTPO;

            while(ibHighPresent){
              if((profileData.ibHighTPO+(count*tpoDiff))<=(profileData.maxTPO+2*selectedTPO)){
                console.log("ib high is found=======>",profileData.ibHighTPO+(count*tpoDiff)+selectedTPO,profileData.date)
                let ib_val=profileData.ibHighTPO+(count*tpoDiff)+selectedTPO;
                py = (yScale(profileData.ibHighTPO+(count*tpoDiff)+selectedTPO));
                context.lineWidth=1;
                context.strokeStyle =CHART_COLORS.IB_LINE;
                context.beginPath()
                context.setLineDash([4, 4]);
                context.moveTo(px,py+2);
                context.lineTo(px+width,py+2);
                context.stroke();
                count=count+1;
                let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                let randomColor=rgbToHex(randomR,randomG,randomB);
                contextTooltip.fillStyle=randomColor;
                contextTooltip.fillRect(px, py+2,width,6);
                contextTooltip.stroke();
                tempData=[]
                tempData.push({key:count+" IB",value:ib_val})
                tooltipData.push({
                  [randomColor]:tempData
                })
                // console.log(count);
                if(count > 10)ibHighPresent=false;
              }
              else
              ibHighPresent=false;  
            }
            count=1;
            while(ibLowPresent){
              if((profileData.ibLowTPO-(count*tpoDiff))>=(profileData.minTPO-2*selectedTPO)){
                if(profileData.ibLowTPO-(count*tpoDiff)+selectedTPO>=0){
                // console.log("ib low is found=======>",profileData.ibLowTPO-(count*tpoDiff)+selectedTPO,profileData.date);
                let ib_val=profileData.ibLowTPO-(count*tpoDiff)+selectedTPO;
                  py = (yScale(profileData.ibLowTPO-(count*tpoDiff)+selectedTPO));
                  context.lineWidth=1;
                  context.strokeStyle =CHART_COLORS.IB_LINE;
                  context.beginPath()
                  context.setLineDash([4, 4]);
                  context.moveTo(px,py+2);
                  context.lineTo(px+width,py+2);
                  context.stroke();
                  count=count+1;
                  let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                  let randomColor=rgbToHex(randomR,randomG,randomB);
                  contextTooltip.fillStyle=randomColor;
                  contextTooltip.fillRect(px, py+2,width,6);
                  contextTooltip.stroke();
                  tempData=[]
                  tempData.push({key:count+" IB",value:ib_val})
                  tooltipData.push({
                    [randomColor]:tempData
                  })
                  if(count > 10) ibLowPresent=false;
                }
                else
                  ibLowPresent=false; 
              
              }
              else
              ibLowPresent=false;  
            }
            // context.restore()
                
          }
      }else{
        if(profileData.ibHighTPO!=undefined &&profileData.ibLowTPO!=undefined){
          //if instrument is live and not a live composite
          
          //draw 2ib/3ib up and down
            const tpoDiff=profileData.ibHighTPO-profileData.ibLowTPO;
            //2IB up
            let ib_val=profileData.ibHighTPO+tpoDiff+selectedTPO;
            py = (yScale(profileData.ibHighTPO+tpoDiff+selectedTPO));
            context.lineWidth=1;
            context.strokeStyle =CHART_COLORS.IB_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py+2);
            context.lineTo(px+width,py+2);
            context.stroke();

            let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
            let randomColor=rgbToHex(randomR,randomG,randomB);
                contextTooltip.fillStyle=randomColor;
                contextTooltip.fillRect(px, py+2,width,6);
                contextTooltip.stroke();
                tempData=[]
                tempData.push({key:"2IB",value:ib_val})
                tooltipData.push({
                  [randomColor]:tempData
                })

            //3IB up
            ib_val=profileData.ibHighTPO+(2*tpoDiff)+selectedTPO;
            py = (yScale(profileData.ibHighTPO+(2*tpoDiff)+selectedTPO));
            context.lineWidth=1;
            context.strokeStyle =CHART_COLORS.IB_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py+2);
            context.lineTo(px+width,py+2);
            context.stroke();
            
            val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
            randomColor=rgbToHex(randomR,randomG,randomB);
            contextTooltip.fillStyle=randomColor;
            contextTooltip.fillRect(px, py+2,width,6);
            contextTooltip.stroke();
            tempData=[]
            tempData.push({key:"3IB",value:ib_val})
            tooltipData.push({
              [randomColor]:tempData
            })
           
            //2ib down
            if((profileData.ibLowTPO-tpoDiff+selectedTPO)>=0){
              ib_val=profileData.ibLowTPO-tpoDiff+selectedTPO;
              py = (yScale(profileData.ibLowTPO-tpoDiff+selectedTPO));
              context.lineWidth=1;
              context.strokeStyle =CHART_COLORS.IB_LINE;
              context.beginPath()
              context.setLineDash([4, 4]);
              context.moveTo(px,py+2);
              context.lineTo(px+width,py+2);
              context.stroke();

              let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
              let randomColor=rgbToHex(randomR,randomG,randomB);
              contextTooltip.fillStyle=randomColor;
              contextTooltip.fillRect(px, py+2,width,6);
              contextTooltip.stroke();
              tempData=[]
              tempData.push({key:"2IB",value:ib_val})
              tooltipData.push({
                [randomColor]:tempData
              })
            }
           
            //3IB down
            if((profileData.ibLowTPO-(2*tpoDiff)+selectedTPO)>=0){
              ib_val=profileData.ibLowTPO-(2*tpoDiff)+selectedTPO;
              py = (yScale(profileData.ibLowTPO-(2*tpoDiff)+selectedTPO));
              context.lineWidth=1;
              context.strokeStyle =CHART_COLORS.IB_LINE;
              context.beginPath()
              context.setLineDash([4, 4]);
              context.moveTo(px,py+2);
              context.lineTo(px+width,py+2);
              context.stroke();

              let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
              let randomColor=rgbToHex(randomR,randomG,randomB);
              contextTooltip.fillStyle=randomColor;
              contextTooltip.fillRect(px, py+2,width,6);
              contextTooltip.stroke();
              tempData=[]
              tempData.push({key:"3IB",value:ib_val})
              tooltipData.push({
                [randomColor]:tempData
              })
            }

          
      
        } 
      }
      }

      //draw sd for live profile
      if(index==historicalData.profilesData.length-1 && isSessionActive){
        if(profileData.sdClose!=undefined && profileData.sdClose!=null && profileData.vwap!=undefined){
          //2sd up
          let sd=profileData.vwap+2*profileData.sdClose;
          let sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
          let sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
          let sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
          py = (yScale(sdTPO));
          context.lineWidth=1;
          context.strokeStyle =CHART_COLORS.SD_LINE;
          context.beginPath()
          context.setLineDash([4, 4]);
          context.moveTo(px,py);
          context.lineTo(px+width,py);
          context.stroke(); 

          //3sd up
          sd=profileData.vwap+3*profileData.sdClose;
          sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
          sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
          sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
          py = (yScale(sdTPO));
          context.lineWidth=1;
          context.strokeStyle =CHART_COLORS.SD_LINE;
          context.beginPath()
          context.setLineDash([4, 4]);
          context.moveTo(px,py);
          context.lineTo(px+width,py);
          context.stroke();
          context.strokeStyle =CHART_COLORS.SD_LINE;
          context.beginPath()
          context.setLineDash([4, 4]);
          context.moveTo(px,py-3);
          context.lineTo(px+width,py-3);
          context.stroke();
        
          
          //2sd down
          sd=profileData.vwap-2*profileData.sdClose;
          sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
          sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
          sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
          if(sdTPO>=0){
             py = (yScale(sdTPO));
            context.lineWidth=1;
            context.strokeStyle =CHART_COLORS.SD_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py);
            context.lineTo(px+width,py);
            context.stroke();
          }

          //3sd down
          sd=profileData.vwap-3*profileData.sdClose;
          sdVal=parseFloat(((sd*10000)/10000).toFixed(4));
          sdMod=parseFloat(((sd*10000)%(selectedTPO*10000))/10000).toFixed(4);
          sdTPO=parseFloat(((sdVal-sdMod)).toFixed(4));
          if(sdTPO>=0){
            py = (yScale(sdTPO));
            context.lineWidth=1;
            context.strokeStyle =CHART_COLORS.SD_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py);
            context.lineTo(px+width,py);
            context.stroke();
            context.strokeStyle =CHART_COLORS.SD_LINE;
            context.beginPath()
            context.setLineDash([4, 4]);
            context.moveTo(px,py-3);
            context.lineTo(px+width,py-3);
            context.stroke();
          }
        
        }
      } 

      //draw closing vwap for live profile only
      if(index==historicalData.profilesData.length-1 && isSessionActive){
        if(profileData.close!=profileData.closingVWAP){
          // console.log("closing vwap=",profileData.closingVWAP);
          py = (yScale(profileData.closingVWAP));
          context.lineWidth=1.5;
          context.strokeStyle =CHART_COLORS.CLOSING_VWAP_TPO;
          context.beginPath()
          context.setLineDash([4, 4]);
          context.moveTo(px,py+2);
          context.lineTo(px+width,py+2);
          context.stroke();

         
        }
      }

      //draw vwap
      if(profileData.vwapTPO!=undefined && (isVolumeBasedVA || profileData.vaType==VA_TYPE.HYBRID)){
        // context.save()
        py = (yScale(profileData.vwapTPO));
        context.fillStyle=CHART_COLORS.VWAP;
        context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
        context.stroke();
        // context.fillStyle=CHART_COLORS.VWAP;
        // context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
        // context.stroke();
       
        // context.restore();
      }
      if(profileData.vwapTPOBased!=undefined && profileData.vaType==VA_TYPE.TPO_BASED){
        // context.save()
        py = (yScale(profileData.vwapTPOBased));
        context.fillStyle=CHART_COLORS.VWAP;
        context.fillRect(px, (py-TPO_HEIGHT/2-2),width,12);
        context.stroke();
       
        // context.restore();
      }  
      //write below profile informartion
      const liveProfile=(isLiveSession && index==historicalData.profilesData.length-1)?true:false
      let pos=yScale(profileData.low-2*selectedTPO)
      const isComposite=(historicalData.profilesData[index].dateList>1)?true:false;
      const margin=Math.abs(57*zoomLevel)
      context.font=CHART_FONT_DEFAULT;
      const px2=px+Math.abs(zoomLevel*(historicalData.profilesData[index].isStacked?historicalData.profilesData[index].stackedWidth/2:historicalData.profilesData[index].splitWidth/2))
      // console.log("++++metadata", metadata, liveProfile, isComposite);
      if(zoomLevel>=0.9){
        if(liveProfile && !isComposite){
          //
        }
        
        // latency
        if(profileData.latency!=undefined && !props.basicUser) {
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("Latency ", px, pos);
          // console.log("++++metadata", metadata, liveProfile, isComposite,metadata.vol5_percent);
          if(liveProfile && !isComposite){
              context.fillText(profileData.latency + " sec", px+margin, pos);
          }
          pos=pos+20
        }

         //vol qty
        if(profileData.volume!=undefined && profileData.volume>0) {
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("Vol Qty ", px, pos);
          if(!props.basicUser)
          {
            // console.log("++++metadata", metadata, liveProfile, isComposite,metadata.vol5_percent);
            if(liveProfile && !isComposite && metadata && metadata != undefined && metadata.vol5_percent != undefined){
                let vdata = profileData.volume.toLocaleString(props.locale_string) + " (" + metadata.vol5_percent + "% / " + metadata.vol14_percent + "%)"
                // &nbsp;{metadata.vol5_percent}%&nbsp;/&nbsp;{metadata.vol14_percent}%
                context.fillText(vdata, px+margin, pos);
            }
            else{
              let vdata = profileData.volume.toLocaleString(props.locale_string)
              // &nbsp;{metadata.vol5_percent}%&nbsp;/&nbsp;{metadata.vol14_percent}%
              context.fillText(vdata, px+margin, pos);

            }
          }
          else
            context.fillText(profileData.volume.toLocaleString(props.locale_string), px+margin, pos);
          pos=pos+20
        }
        if(props.selectedInstrumentData.vp){
            //vpoc
            if(profileData.vpoc!=undefined){
              context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
              context.fillText("V:vpoc ", px, pos);
              context.fillStyle = CHART_COLORS.VPOC_TEXT;
              context.fillText(profileData.vpoc, px+margin, pos);
              pos=pos+20
            }

            //vwap
            if(profileData.vwap!=undefined){
              context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
              context.fillText("V:vwap", px, pos);
              context.fillStyle = CHART_COLORS.VWAP_TEXT;
              context.fillText(profileData.vwap, px+margin, pos);
              pos=pos+20;
            }        
        }
        //tpoc
        if(profileData.tpoc!=undefined){
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("T:tpoc ", px, pos);
          context.fillStyle = CHART_COLORS.VPOC_TEXT;
          context.fillText(profileData.tpoc, px+margin, pos);
          pos=pos+20
        }

        //vwap
        if(profileData.tpo_vwap!=undefined){
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("T:twap", px, pos);
          context.fillStyle = CHART_COLORS.VWAP_TEXT;
          context.fillText(profileData.tpo_vwap, px+margin, pos);
          pos=pos+20;
        }

        //OI
        if(!isComposite && profileData.oi!=undefined){
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("OI: ", px, pos);
          context.fillText(profileData.oi.toLocaleString(props.locale_string), px+margin, pos);
          pos=pos+20
        }

        //COI
        if(!isComposite && profileData.coi!=undefined){
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("COI: ", px, pos);
          context.fillText(profileData.coi.toLocaleString(props.locale_string), px+margin, pos);
          pos=pos+20
        }

        //Day type
        if(!props.basicUser && !isComposite && profileData.day_type!=undefined){
          
          context.fillStyle = CHART_COLORS.TPO_COLOR_DEFAULT;
          context.fillText("Day Type ", px, pos);
          if(liveProfile)
            context.font=CHART_FONT_ITALIC_DEFAULT;
          context.fillText(profileData.day_type, px+margin, pos+1);
          pos=pos+20
        }
      }
      context.restore();
      contextTooltip.restore();
      
    }
    //plot tpos on canvas 
    function drawVolumes(j,xCord,yCord,data,max,width,bShowProfileVolumeNumbers,transform) {
      const px = (xCord);
      const py = (yScale(yCord));
      // console.log("drawVol = ",j,bShowProfileVolumeNumbers); 
     
      let val=Math.round((data.perc/max)*width);
      let opacity=1.0;
      context.save()
      context.globalAlpha = opacity; 
      // context.fillStyle = "#2F5CDB";
      if(val/width>0.96)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_96;
      else if(val/width>0.7)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_70;
      else if(val/width>0.4)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_40;
      else if(val/width>0.28)
      context.fillStyle = CHART_COLORS.VOLUME_BAR_ABOVE_28;
      else 
      context.fillStyle = CHART_COLORS.VOLUME_BAR_BELOW_28;

      context.fillRect(px, py-TPO_HEIGHT/2-2,val,14);
      context.stroke();
      // context.globalAlpha = 1.0; 
      context.restore();
      // context.globalAlpha = 0.9; 
      // if((!props.volumeNumberVisible && bShowProfileVolumeNumbers==true && transform.k>=0.6) ){
      //   context.fillStyle = "#ffffff";
      //   context.font = "10px DM Sans"
      //   context.fillText("["+data.val.toLocaleString()+"]", px, py);
      // }else if(props.volumeNumberVisible && bShowProfileVolumeNumbers==undefined && transform.k>=0.6){
      //   context.fillStyle = "#ffffff";
      //   context.font = "10px DM Sans"
      //   context.fillText("["+data.val.toLocaleString()+"]", px, py);
     
      // }
      if(bShowProfileVolumeNumbers && transform.k>=0.6){
            context.fillStyle = "#ffffff";
        context.font = "10px DM Sans"
        context.fillText("["+data.val.toLocaleString()+"]", px, py);
      }
      // context.fillText("A", px, py);
      
    }
    
    const drawSystemKRLS=(transform,scaleY,krlTooltipData,offset,filteredData,isCustom)=>{
      if(krlData!=undefined && props.chartData.vaMapList.length>1){
        let rightMostKRLList=[];
        let plottedKRL=[];
        
        // console.log("drawSystemKRLS 0 data=",filteredData,filteredData.length,scaleY.domain()[1],scaleY.domain()[0])
        for(let i=0;i<filteredData.length;i++){
          // console.log("drawSystemKRLS 1 data=",filteredData[i])
          let found=false;

          
          if(filteredData[i].level>scaleY.domain()[1] ||filteredData[i].level<scaleY.domain()[0])
          continue;

         plottedKRL.push(filteredData[i])
          
          for(let j=0;j<historicalData.profilesData.length;j++){
            // console.log("drawSystemKRLS loop data=",j)
            
            if(filteredData[i].profileIndex>=0){
             
              let j=filteredData[i].profileIndex;
              // console.log("drawSystemKRLS 2 data=",filteredData[i],j)
              found=true;
                let levelTPO=filteredData[i].levelTPO;
                let index=(levelTPO-historicalData.profilesData[j].minTPO)/selectedTPO;
                // console.log("KRL found index",j,index,filteredData[i],historicalData.profilesData[j]);
                // console.log("drawSystemKRLS 3 data=",j,index)
                if(index>=0 && historicalData.profilesData[j].data[index] && index<historicalData.profilesData[j].data.length){
                    if(historicalData.profilesData[j].isStacked){
                      let lastTPO=historicalData.profilesData[j].data[index].length;
                      let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+lastTPO*TPO_WIDTH
                      // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord);
                      plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
                      // break;

                    
                    }else{
                      if(historicalData.profilesData[j].dateList.length==1 ||  historicalData.profilesData[j].dateList.length<=props.max_cmp_days){
                       
                          let lastIndex=historicalData.profilesData[j].data[index].length-1;
                          if(historicalData.profilesData[j]?.data[index][lastIndex]?.index>=0)
                          {
                            let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+historicalData.profilesData[j].data[index][lastIndex].index*TPO_WIDTH+TPO_WIDTH; //index starts with zero
                            // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord,lastIndex,historicalData.profilesData[j].data[index]);
                            plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
                          }else{
                            console.log("KRL live data issue=",historicalData.profilesData[j],index,lastIndex,filteredData[i],j);
                          }
                      }else{
                        //get the start date position inside the split profile
                        let pos=0;
                        for(let k=0;k< historicalData.profilesData[j].dateList.length;k++){
                          if(filteredData[i].start_date==historicalData.profilesData[j].dateList[k]){
                            pos=k;
                            break;
                          }
                        }
                        // console.log("KRL drawKRL filteredData split cmp=",filteredData[i],pos)
                        let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+pos*TPO_WIDTH+TPO_WIDTH;
                        plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
                        // console.log("KRL cmp days=",filteredData[i], historicalData.profilesData[j],pos)
                      }

                    }
                    
                }else{
                  let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN;
                  plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);

                }
                
                if(filteredData[i].end_date=="")
                rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
                break;
            }
           
           
          }
             //if KRL start_date is not present in the loaded chart, then plot it from the leftmost part to the end_date
             if(!found){
              // console.log("drawSystemKRLSAS 4 data")
              let levelTPO=filteredData[i].levelTPO;
              // console.log("drawSystemKRLS 4 data",i,levelTPO,filteredData[i])
              // plotKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
              if(filteredData[i].end_date==""){
                plotKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
                rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
              }
          }
           
          
         
        }
       

        // console.log("KRL drawKRL filteredData plottedKRL=",plottedKRL)
        // console.log("KRL rightmost list=",rightMostKRLList);
        // d3.selectAll(`#krl_label${props.id}`).remove()
        // d3.selectAll(`#krlAxisTooltip${props.id}`).remove()
        
        let tooltip = d3.select("body")
        .append("div")
        .attr("id", "krlAxisTooltip"+props.id)
        .style("position", "absolute")
        .style("z-index", "10")
        .style('display','none')
        // .style("visibility", "hidden")
        .text("a simple tooltip");

        let fontSize="12px"
        // if(transform.k<=0.8 && transform.k>=0.6)
        //   fontSize="10px"
        // else if(transform.k<0.6)
        //   fontSize="8px"
        //   console.log("chart data",props.val,props.chartStateData,props.chartData);
         
        const getTooltipData=(value)=>{
          let tooltipStr="";
          let count=0;
          for(let i=0;i<rightMostKRLList.length;i++){
            let tpo=rightMostKRLList[i].levelTPO;
            let level=rightMostKRLList[i].level;
            let desc=rightMostKRLList[i].desc;
            if(value==rightMostKRLList[i].levelTPO){
              if(count==0){
               tooltipStr= "["+level.toFixed(2)+"] "+desc;
               count=count+1;
              }else{
                tooltipStr= tooltipStr+"<br/>["+level.toFixed(2)+"] "+desc;
                count=count+1;
              }
            }
          }
          return tooltipStr;
        }
        for(let i=0;i<rightMostKRLList.length;i++){
          let tpo=rightMostKRLList[i].levelTPO;
          let level=rightMostKRLList[i].level;
          let desc=rightMostKRLList[i].desc;
          
        container.append("div")
             .style("left", (canvasWidth + 4) + "px")
            .attr("id", "krl_label"+props.id)
            .style("top", scaleY(tpo)+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
            .style("position","absolute")
            .style("font-size",fontSize)
            .style("font-weight",500)
            .style("font-family","sans-serif")
            .style("background","#022D5A")
            .style("color",`${CHART_COLORS.MP_KRL_LABEL}`)
            .html(tpo.toFixed(2))
            // .on("mouseover", function(d){(tooltip.html("["+level.toFixed(2)+"] "+desc)); return tooltip.style("visibility", "visible");})
            .on("mouseover", function(d){(tooltip.html(getTooltipData(tpo))); return tooltip.style('display','block');})
            .on("mousemove", function(){return tooltip.style("top", (scaleY(tpo)+24+TPO_HEIGHT+40+props.chartStateData.top)+"px")
            // .style("margin-left")
            // .style("left",props.chartStateData.left+props.chartStateData.width-115-200+"px")
            .style("right",(115+offset)+"px")
            .style("background", "#1e293b").style("font-size", "12px")
            .style("width","auto")
            .style("height","auto")
            .style("border","2px solid #475569")
            .style("padding","10px")
            .style("border-radius","8px")})
            .on("mouseout", function(){return tooltip.style('display','none');});
            }
      }

    }
    const drawCustomKRLS=(transform,scaleY,krlTooltipData,offset,filteredData,isCustom,leftIndex,rightIndex)=>{
      // console.time("Benchmark_krl_custom_new1")
      if(krlData!=undefined && props.chartData.vaMapList.length>1){
        let rightMostKRLList=[];
        let plottedKRL=[];
        let scaleMax=scaleY.domain()[1];
        let scaleMin=scaleY.domain()[0]
        
        // console.log("drawSystemKRLS 0 data=",filteredData,filteredData.length,scaleY.domain()[1],scaleY.domain()[0])
        const lastProfileEndDate=historicalData.profilesData[historicalData.profilesData.length-1].profileEndDate;
        for(let i=0;i<filteredData.length;i++){
          // console.log("drawSystemKRLS 1 data=",filteredData[i])
          let found=false;
          let isFutureDate=false;
          // if(filteredData[i].level==22232)
          // console.log("TESTING M KRL found111=",filteredData[i],historicalData.profilesData.length)
          // else continue;

          if(filteredData[i].category=="CUSTOM_KRL"){
            if(filteredData[i].level>scaleMax ||filteredData[i].level<scaleMin)
            continue;
            else if(filteredData[i].end_date!=""){
              let endDate=dayjs(filteredData[i].end_date.split("-").reverse().join("-"))
              isFutureDate=endDate.isAfter(lastProfileEndDate)
            }
          
          }else if(filteredData[i].profileIndex<leftIndex || filteredData[i].profileIndex>rightIndex){
            // console.log("drawCustomKRLS ignore due to index=",filteredData[i].profileIndex,leftIndex,rightIndex,filteredData[i].start_date)
            continue;
          }else{
            if(filteredData[i].level>scaleMax ||filteredData[i].level<scaleMin){
              // console.log("drawCustomKRLS ignore due to level=",filteredData[i].level)
              plottedKRL.push(filteredData[i])
              continue;
            }
          }
       
         plottedKRL.push(filteredData[i])
          
          for(let j=0;j<historicalData.profilesData.length;j++){
            // console.log("drawCustomKRLS loop data=",j)
            
            if(filteredData[i].profileIndex>=0 && filteredData[i].category=="CUSTOM_KRL"){
              // console.log("Found111 draw0")
              let j=filteredData[i].profileIndex;
              // console.log("drawCustomKRLS 2 data=",filteredData[i],j)
              found=true;
                let levelTPO=filteredData[i].levelTPO;
                let index=(levelTPO-historicalData.profilesData[j].minTPO)/selectedTPO;
                // console.log("KRL found index",j,index,filteredData[i],historicalData.profilesData[j]);
                // console.log("drawCustomKRLS 3 data=",j,index)
                if(index>=0 && historicalData.profilesData[j].data[index] && index<historicalData.profilesData[j].data.length){
                    if(historicalData.profilesData[j].isStacked){
                      let lastTPO=historicalData.profilesData[j].data[index].length;
                      let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+lastTPO*TPO_WIDTH
                      // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord);
                      plotCustomKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);
                      // break;

                    
                    }else{
                      if(historicalData.profilesData[j].dateList.length==1 ||  historicalData.profilesData[j].dateList.length<=props.max_cmp_days){
                       
                          let lastIndex=historicalData.profilesData[j].data[index].length-1;
                          if(historicalData.profilesData[j]?.data[index][lastIndex]?.index>=0)
                          {
                            let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+historicalData.profilesData[j].data[index][lastIndex].index*TPO_WIDTH+TPO_WIDTH; //index starts with zero
                            // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord,lastIndex,historicalData.profilesData[j].data[index]);
                            plotCustomKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);
                          }else{
                            console.log("KRL live data issue=",historicalData.profilesData[j],index,lastIndex,filteredData[i],j);
                          }
                      }else{
                        //get the start date position inside the split profile
                        let pos=0;
                        for(let k=0;k< historicalData.profilesData[j].dateList.length;k++){
                          if(filteredData[i].start_date==historicalData.profilesData[j].dateList[k]){
                            pos=k;
                            break;
                          }
                        }
                        // console.log("KRL drawKRL filteredData split cmp=",filteredData[i],pos)
                        let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+pos*TPO_WIDTH+TPO_WIDTH;
                        plotCustomKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);
                        // console.log("KRL cmp days=",filteredData[i], historicalData.profilesData[j],pos)
                      }

                    }
                    
                }else{
                  let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN;
                  plotCustomKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);

                }
                
                if(filteredData[i].end_date=="" ||isFutureDate)
                rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
                break;

            }else if(filteredData[i].profileIndex>=0){
              let j=filteredData[i].profileIndex;
              let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN;
              let levelTPO=filteredData[i].levelTPO;
             
              plotCustomKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);
              break;
            }
           
           
          }
             //if KRL start_date is not present in the loaded chart, then plot it from the leftmost part to the end_date
             if(!found){
              // console.log("drawSystemKRLSAS 4 data")
              let levelTPO=filteredData[i].levelTPO;
              // console.log("drawSystemKRLS 4 data",i,levelTPO,filteredData[i])
              // plotKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
              if(filteredData[i].end_date=="" || isFutureDate){
                plotCustomKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate);
                rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
              }
          }
           
          
         
        }
        // console.timeEnd("Benchmark_krl_custom_new1")
        // console.time("Benchmark_krl_custom_new2")
        if(isCustom){
          const resultForAllIds = getResultForAllIds(plottedKRL);
          const finalData=resultForAllIds.filter(item=>item.data.length>1)
        
          // console.log("Save KRL unique results",resultForAllIds,finalData);
          if(finalData && finalData.length>0){
           for(let i=0;i<finalData.length;i++){
            // console.log("Issue==============================>")
            drawCustomKRLConnectingLines(finalData[i].data,transform,yScale,scaleMax,scaleMin)
           }
          }

        }
        // console.timeEnd("Benchmark_krl_custom_new2")

        // console.log("KRL drawKRL filteredData plottedKRL=",plottedKRL)
        // console.log("KRL rightmost list=",rightMostKRLList);
        // d3.selectAll(`#krl_label${props.id}`).remove()
        // d3.selectAll(`#krlAxisTooltip${props.id}`).remove()
        
        let tooltip = d3.select("body")
        .append("div")
        .attr("id", "krlAxisTooltip"+props.id)
        .style("position", "absolute")
        .style("z-index", "10")
        .style('display','none')
        // .style("visibility", "hidden")
        .text("a simple tooltip");

        let fontSize="12px"
        // if(transform.k<=0.8 && transform.k>=0.6)
        //   fontSize="10px"
        // else if(transform.k<0.6)
        //   fontSize="8px"
        //   console.log("chart data",props.val,props.chartStateData,props.chartData);
         
        const getTooltipData=(value)=>{
          let tooltipStr="";
          let count=0;
          for(let i=0;i<rightMostKRLList.length;i++){
            let tpo=rightMostKRLList[i].levelTPO;
            let level=rightMostKRLList[i].level;
            let desc=rightMostKRLList[i].desc;
            if(value==rightMostKRLList[i].levelTPO){
              if(count==0){
               tooltipStr= "["+level.toFixed(2)+"] "+desc;
               count=count+1;
              }else{
                tooltipStr= tooltipStr+"<br/>["+level.toFixed(2)+"] "+desc;
                count=count+1;
              }
            }
          }
          return tooltipStr;
        }
        for(let i=0;i<rightMostKRLList.length;i++){
          let tpo=rightMostKRLList[i].levelTPO;
          let level=rightMostKRLList[i].level;
          let desc=rightMostKRLList[i].desc;
          
        container.append("div")
             .style("left", (canvasWidth + 4) + "px")
            .attr("id", "krl_label"+props.id)
            .style("top", scaleY(tpo)+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
            .style("position","absolute")
            .style("font-size",fontSize)
            .style("font-weight",500)
            .style("font-family","sans-serif")
            .style("background","#022D5A")
            .style("color",`${CHART_COLORS.MP_KRL_LABEL}`)
            .html(tpo.toFixed(2))
            // .on("mouseover", function(d){(tooltip.html("["+level.toFixed(2)+"] "+desc)); return tooltip.style("visibility", "visible");})
            .on("mouseover", function(d){(tooltip.html(getTooltipData(tpo))); return tooltip.style('display','block');})
            .on("mousemove", function(){return tooltip.style("top", (scaleY(tpo)+24+TPO_HEIGHT+40+props.chartStateData.top)+"px")
            // .style("margin-left")
            // .style("left",props.chartStateData.left+props.chartStateData.width-115-200+"px")
            .style("right",(115+offset)+"px")
            .style("background", "#1e293b").style("font-size", "12px")
            .style("width","auto")
            .style("height","auto")
            .style("border","2px solid #475569")
            .style("padding","10px")
            .style("border-radius","8px")})
            .on("mouseout", function(){return tooltip.style('display','none');});
            }
      }

    }

    // const drawCustomKRLS=(transform,scaleY,krlTooltipData,offset,filteredData,isCustom,leftIndex,rightIndex)=>{
    //   if(krlData!=undefined && props.chartData.vaMapList.length>1){
    //     let rightMostKRLList=[];
    //     let plottedKRL=[];
        
        
    //     for(let i=0;i<filteredData.length;i++){
    //       let found=false;

    //       if(filteredData[i].category=="CUSTOM_KRL"){
    //         if(filteredData[i].level>scaleY.domain()[1] ||filteredData[i].level<scaleY.domain()[0])
    //         continue;
    //       }
         

    //      plottedKRL.push(filteredData[i])
          
    //       for(let j=0;j<historicalData.profilesData.length;j++){
            
    //         if(filteredData[i].profileIndex>=0){
    //           found=true;
    //             let levelTPO=filteredData[i].levelTPO;
    //             let index=(levelTPO-historicalData.profilesData[j].minTPO)/selectedTPO;
    //             // console.log("KRL found index",j,index,filteredData[i],historicalData.profilesData[j]);
    //             if(index>=0 && index<historicalData.profilesData[j].data.length){
    //                 if(historicalData.profilesData[j].isStacked){
    //                   let lastTPO=historicalData.profilesData[j].data[index].length;
    //                   let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+lastTPO*TPO_WIDTH
    //                   // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord);
    //                   plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);

                    
    //                 }else{
    //                   if(historicalData.profilesData[j].dateList.length==1 ||  historicalData.profilesData[j].dateList.length<=props.max_cmp_days){
                       
    //                       let lastIndex=historicalData.profilesData[j].data[index].length-1;
    //                       if(historicalData.profilesData[j]?.data[index][lastIndex]?.index>=0)
    //                       {
    //                         let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+historicalData.profilesData[j].data[index][lastIndex].index*TPO_WIDTH+TPO_WIDTH; //index starts with zero
    //                         // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord,lastIndex,historicalData.profilesData[j].data[index]);
    //                         plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //                       }else{
    //                         console.log("KRL live data issue=",historicalData.profilesData[j],index,lastIndex,filteredData[i],j);
    //                       }
    //                   }else{
    //                     //get the start date position inside the split profile
    //                     let pos=0;
    //                     for(let k=0;k< historicalData.profilesData[j].dateList.length;k++){
    //                       if(filteredData[i].start_date==historicalData.profilesData[j].dateList[k]){
    //                         pos=k;
    //                         break;
    //                       }
    //                     }
    //                     // console.log("KRL drawKRL filteredData split cmp=",filteredData[i],pos)
    //                     let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+pos*TPO_WIDTH+TPO_WIDTH;
    //                     plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //                     // console.log("KRL cmp days=",filteredData[i], historicalData.profilesData[j],pos)
    //                   }

    //                 }
                    
    //             }else{
    //               let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN;
    //               plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);

    //             }if(filteredData[i].end_date=="")
    //             rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
    //             break;
    //         }
           
    //       }
    //       //if KRL start_date is not present in the loaded chart, then plot it from the leftmost part to the end_date
    //       if(!found){
    //         let level=parseFloat(((filteredData[i].level*10000)/10000).toFixed(4));
    //         let levelMod=parseFloat(((filteredData[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
    //         let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
    //         plotKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //         if(filteredData[i].end_date=="")
    //           rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
    //     }
    //     }
    //     if(isCustom){
    //       const resultForAllIds = getResultForAllIds(plottedKRL);
    //       const finalData=resultForAllIds.filter(item=>item.data.length>1)
        
    //       console.log("Save KRL unique results",resultForAllIds,finalData);
    //       if(finalData && finalData.length>0){
    //        for(let i=0;i<finalData.length;i++){
    //         drawCustomKRLConnectingLines(finalData[i].data,transform,yScale)
    //        }
    //       }

    //     }

    //     // console.log("KRL drawKRL filteredData plottedKRL=",plottedKRL)
    //     console.log("KRL rightmost list=",rightMostKRLList);
    //     // d3.selectAll(`#krl_label${props.id}`).remove()
    //     // d3.selectAll(`#krlAxisTooltip${props.id}`).remove()
        
    //     let tooltip = d3.select("body")
    //     .append("div")
    //     .attr("id", "krlAxisTooltip"+props.id)
    //     .style("position", "absolute")
    //     .style("z-index", "10")
    //     .style('display','none')
    //     // .style("visibility", "hidden")
    //     .text("a simple tooltip");

    //     let fontSize="12px"
    //     // if(transform.k<=0.8 && transform.k>=0.6)
    //     //   fontSize="10px"
    //     // else if(transform.k<0.6)
    //     //   fontSize="8px"
    //     //   console.log("chart data",props.val,props.chartStateData,props.chartData);
         
    //     const getTooltipData=(value)=>{
    //       let tooltipStr="";
    //       let count=0;
    //       for(let i=0;i<rightMostKRLList.length;i++){
    //         let tpo=rightMostKRLList[i].levelTPO;
    //         let level=rightMostKRLList[i].level;
    //         let desc=rightMostKRLList[i].desc;
    //         if(value==rightMostKRLList[i].levelTPO){
    //           if(count==0){
    //            tooltipStr= "["+level.toFixed(2)+"] "+desc;
    //            count=count+1;
    //           }else{
    //             tooltipStr= tooltipStr+"<br/>["+level.toFixed(2)+"] "+desc;
    //             count=count+1;
    //           }
    //         }
    //       }
    //       return tooltipStr;
    //     }
    //     for(let i=0;i<rightMostKRLList.length;i++){
    //       let tpo=rightMostKRLList[i].levelTPO;
    //       let level=rightMostKRLList[i].level;
    //       let desc=rightMostKRLList[i].desc;
          
    //     container.append("div")
    //          .style("left", (canvasWidth + 4) + "px")
    //         .attr("id", "krl_label"+props.id)
    //         .style("top", scaleY(tpo)+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
    //         .style("position","absolute")
    //         .style("font-size",fontSize)
    //         .style("font-weight",500)
    //         .style("font-family","sans-serif")
    //         .style("background","#022D5A")
    //         .style("color",`${CHART_COLORS.MP_KRL_LABEL}`)
    //         .html(tpo.toFixed(2))
    //         // .on("mouseover", function(d){(tooltip.html("["+level.toFixed(2)+"] "+desc)); return tooltip.style("visibility", "visible");})
    //         .on("mouseover", function(d){(tooltip.html(getTooltipData(tpo))); return tooltip.style('display','block');})
    //         .on("mousemove", function(){return tooltip.style("top", (scaleY(tpo)+24+TPO_HEIGHT+40+props.chartStateData.top)+"px")
    //         // .style("margin-left")
    //         // .style("left",props.chartStateData.left+props.chartStateData.width-115-200+"px")
    //         .style("right",(115+offset)+"px")
    //         .style("background", "#1e293b").style("font-size", "12px")
    //         .style("width","auto")
    //         .style("height","auto")
    //         .style("border","2px solid #475569")
    //         .style("padding","10px")
    //         .style("border-radius","8px")})
    //         .on("mouseout", function(){return tooltip.style('display','none');});
    //         }
    //   }

    // }
    // const drawKRLS=(transform,scaleY,krlTooltipData,offset,filteredData,isCustom)=>{
    //   if(krlData!=undefined && props.chartData.vaMapList.length>1){
    //     let rightMostKRLList=[];
    //     // let chartStartDate=dayjs(props.chartData.vaMapList[1].dateList[0].split("-").reverse().join("-"))
    //     // let filteredData=krlData.filter(item=>(item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate)))
    //     // console.log("KRL drawKRL filteredData=",filteredData)
    //     let plottedKRL=[];
        
    //     for(let i=0;i<filteredData.length;i++){
    //       // if(i==16){
    //       // console.log("KRL TESTING =",filteredData[i],i)
    //       // filteredData[i].end_date=filteredData[i].start_date;
    //       // }
    //       let found=false;

    //       if(!isCustom)
    //       if(filteredData[i].level>scaleY.domain()[1] ||filteredData[i].level<scaleY.domain()[0])
    //       continue;

    //      plottedKRL.push(filteredData[i])
          
    //       for(let j=0;j<historicalData.profilesData.length;j++){
            
    //         let startDate=dayjs(filteredData[i].start_date.split("-").reverse().join("-"))
    //         let profileStartDate=dayjs(historicalData.profilesData[j].dateList[0].split("-").reverse().join("-"))
    //         let profileEndDate=dayjs(historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1].split("-").reverse().join("-"))

    //         // if(filteredData[i].start_date=='25-08-2023' && filteredData[i].category=='HVN' &&  filteredData[i].level==19274)
    //         //   console.log("KRL drawKRL filteredData start_date 25th Aug 1=",filteredData[i].start_date,startDate,profileStartDate,profileEndDate,filteredData[i],!dayjs(startDate).isBefore(profileStartDate),!startDate.isBefore(profileStartDate),startDate.isBefore(profileEndDate))
            
    //           if(filteredData[i].start_date==historicalData.profilesData[j].dateList[0] || filteredData[i].start_date==historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1]
    //             ||(!(startDate.isBefore(profileStartDate))&& (startDate.isBefore(profileEndDate)))
    //           ){
    //           found=true;
              
    //           // if(filteredData[i].start_date=='25-08-2023')
    //           // console.log("KRL drawKRL filteredData start_date 25th Aug 2=",filteredData[i].start_date,filteredData[i])

    //             let level=parseFloat(((filteredData[i].level*10000)/10000).toFixed(4));
    //             let levelMod=parseFloat(((filteredData[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
    //             let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
               
    //             let index=(levelTPO-historicalData.profilesData[j].minTPO)/selectedTPO;
    //             // console.log("KRL found index",j,index,filteredData[i],historicalData.profilesData[j]);
    //             if(index>=0 && index<historicalData.profilesData[j].data.length){
    //                 if(historicalData.profilesData[j].isStacked){
    //                   let lastTPO=historicalData.profilesData[j].data[index].length;
    //                   let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+lastTPO*TPO_WIDTH
    //                   // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord);
    //                   plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //                   break;

                    
    //                 }else{
    //                   if(historicalData.profilesData[j].dateList.length==1 ||  historicalData.profilesData[j].dateList.length<=props.max_cmp_days){
                       
    //                       let lastIndex=historicalData.profilesData[j].data[index].length-1;
    //                       if(historicalData.profilesData[j]?.data[index][lastIndex]?.index>=0)
    //                       {
    //                         let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+historicalData.profilesData[j].data[index][lastIndex].index*TPO_WIDTH+TPO_WIDTH; //index starts with zero
    //                         // console.log("KRL found profile index,profile,level ",j,historicalData.profilesData[j],level,levelTPO,index,xCord,lastIndex,historicalData.profilesData[j].data[index]);
    //                         plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //                         break;
    //                       }else{
    //                         console.log("KRL live data issue=",historicalData.profilesData[j],index,lastIndex,filteredData[i],j);
    //                       }
    //                   }else{
    //                     //get the start date position inside the split profile
    //                     let pos=0;
    //                     for(let k=0;k< historicalData.profilesData[j].dateList.length;k++){
    //                       if(filteredData[i].start_date==historicalData.profilesData[j].dateList[k]){
    //                         pos=k;
    //                         break;
    //                       }
    //                     }
    //                     // console.log("KRL drawKRL filteredData split cmp=",filteredData[i],pos)
    //                     let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN+pos*TPO_WIDTH+TPO_WIDTH;
    //                     plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //                     break;
    //                     // console.log("KRL cmp days=",filteredData[i], historicalData.profilesData[j],pos)
    //                   }

    //                 }
                    
    //             }else{
    //               let xCord=xScaleRange[j]+PROFILE_LEFT_MARGIN;
    //               plotKRL(transform,xCord, yScale(levelTPO),filteredData[i],j,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //               break;

    //             }if(filteredData[i].end_date=="")
    //             rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})
    //             break;
    //         }
           
    //       }
    //       //if KRL start_date is not present in the loaded chart, then plot it from the leftmost part to the end_date
    //       if(!found){
    //         let level=parseFloat(((filteredData[i].level*10000)/10000).toFixed(4));
    //         let levelMod=parseFloat(((filteredData[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
    //         let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
    //         plotKRL(transform,xScaleRange[0], yScale(levelTPO),filteredData[i],-1,filteredData[i].level,levelTPO,rightMostKRLList,krlTooltipData,isCustom);
    //         if(filteredData[i].end_date=="")
    //           rightMostKRLList.push({levelTPO:levelTPO,desc:filteredData[i].description,level:filteredData[i].level})

    //     }
    //     }
    //     if(isCustom){
    //       const resultForAllIds = getResultForAllIds(plottedKRL);
    //       const finalData=resultForAllIds.filter(item=>item.data.length>1)
        
    //       console.log("Save KRL unique results",resultForAllIds,finalData);
    //       if(finalData && finalData.length>0){
    //        for(let i=0;i<finalData.length;i++){
    //         drawCustomKRLConnectingLines(finalData[i].data,transform,yScale)
    //        }
    //       }

    //     }

    //     // console.log("KRL drawKRL filteredData plottedKRL=",plottedKRL)
    //     console.log("KRL rightmost list=",rightMostKRLList);
    //     // d3.selectAll(`#krl_label${props.id}`).remove()
    //     // d3.selectAll(`#krlAxisTooltip${props.id}`).remove()
        
    //     let tooltip = d3.select("body")
    //     .append("div")
    //     .attr("id", "krlAxisTooltip"+props.id)
    //     .style("position", "absolute")
    //     .style("z-index", "10")
    //     .style('display','none')
    //     // .style("visibility", "hidden")
    //     .text("a simple tooltip");

    //     let fontSize="12px"
    //     // if(transform.k<=0.8 && transform.k>=0.6)
    //     //   fontSize="10px"
    //     // else if(transform.k<0.6)
    //     //   fontSize="8px"
    //     //   console.log("chart data",props.val,props.chartStateData,props.chartData);
         
    //     const getTooltipData=(value)=>{
    //       let tooltipStr="";
    //       let count=0;
    //       for(let i=0;i<rightMostKRLList.length;i++){
    //         let tpo=rightMostKRLList[i].levelTPO;
    //         let level=rightMostKRLList[i].level;
    //         let desc=rightMostKRLList[i].desc;
    //         if(value==rightMostKRLList[i].levelTPO){
    //           if(count==0){
    //            tooltipStr= "["+level.toFixed(2)+"] "+desc;
    //            count=count+1;
    //           }else{
    //             tooltipStr= tooltipStr+"<br/>["+level.toFixed(2)+"] "+desc;
    //             count=count+1;
    //           }
    //         }
    //       }
    //       return tooltipStr;
    //     }
    //     for(let i=0;i<rightMostKRLList.length;i++){
    //       let tpo=rightMostKRLList[i].levelTPO;
    //       let level=rightMostKRLList[i].level;
    //       let desc=rightMostKRLList[i].desc;
          
    //     container.append("div")
    //          .style("left", (canvasWidth + 4) + "px")
    //         .attr("id", "krl_label"+props.id)
    //         .style("top", scaleY(tpo)+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
    //         .style("position","absolute")
    //         .style("font-size",fontSize)
    //         .style("font-weight",500)
    //         .style("font-family","sans-serif")
    //         .style("background","#022D5A")
    //         .style("color",`${CHART_COLORS.MP_KRL_LABEL}`)
    //         .html(tpo.toFixed(2))
    //         // .on("mouseover", function(d){(tooltip.html("["+level.toFixed(2)+"] "+desc)); return tooltip.style("visibility", "visible");})
    //         .on("mouseover", function(d){(tooltip.html(getTooltipData(tpo))); return tooltip.style('display','block');})
    //         .on("mousemove", function(){return tooltip.style("top", (scaleY(tpo)+24+TPO_HEIGHT+40+props.chartStateData.top)+"px")
    //         // .style("margin-left")
    //         // .style("left",props.chartStateData.left+props.chartStateData.width-115-200+"px")
    //         .style("right",(115+offset)+"px")
    //         .style("background", "#1e293b").style("font-size", "12px")
    //         .style("width","auto")
    //         .style("height","auto")
    //         .style("border","2px solid #475569")
    //         .style("padding","10px")
    //         .style("border-radius","8px")})
    //         .on("mouseout", function(){return tooltip.style('display','none');});
    //         }
    //   }

    // }

    const plotKRL=(transform,x1,y1,data,startIndex,level,levelTPO,rightMostKRLList,krlTooltipData,isCustom)=>{
      // console.log("Plotkrl called =",isCustom,data)
      // context.lineWidth = OF_CHART_LINE_WIDTH.VWAP/zY.k;
      context.save();
      // console.log("KRL drawKR KRL tooltip: category=",KRL_STYLING_CATEGORY[data.styling_category],krlTooltipData);
      const krlCategory=KRL_STYLING_CATEGORY[data.styling_category]?KRL_STYLING_CATEGORY[data.styling_category]:KRL_STYLING_CATEGORY["category_default"];
      const lineWidth=krlCategory.width;
      context.strokeStyle=data.marking_color;
      context.lineWidth = lineWidth/transform.k;
      if(krlCategory.type==KRL_CATEOGRY_TYPE.DOTTED_LINE)
        context.setLineDash([3/transform.k, 3/transform.k]);
      else if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
        context.setLineDash([9/transform.k, 5/transform.k]);
      // else
      // context.lineWidth = lineWidth/transform.k;

      if(data.end_date==""){
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(xScaleRange[historicalData.profilesData.length+1], y1);
        context.stroke();
        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
        let randomColor=rgbToHex(randomR,randomG,randomB);
        contextTooltip.strokeStyle=randomColor;
        contextTooltip.lineWidth = 4*lineWidth/transform.k;
        contextTooltip.beginPath();
        contextTooltip.moveTo(x1, y1);
        contextTooltip.lineTo(xScaleRange[historicalData.profilesData.length+1], y1);
        contextTooltip.stroke();
        // krlTooltipData.push({x1:x1,x2:xScaleRange[historicalData.profilesData.length+1],tpo:levelTPO,data:"["+level+"]: "+data.description+" ["+data.start_date+"]"+data.styling_category})
        krlTooltipData.push({x1:x1,x2:xScaleRange[historicalData.profilesData.length+1],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,y:y1,color:data.marking_color})
       

      }else{
        for(let j=0;j<historicalData.profilesData.length;j++){
          // console.log("Plotkrl called loop=",j,isCustom,data)
           let endDate=dayjs(data.end_date.split("-").reverse().join("-"))
          //  profileStartDate
          //  let profileStartDate=dayjs(historicalData.profilesData[j].dateList[0].split("-").reverse().join("-"))
          //  let profileEndDate=dayjs(historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1].split("-").reverse().join("-"))
           let profileStartDate=historicalData.profilesData[j].profileStartDate;
           let profileEndDate=historicalData.profilesData[j].profileEndDate;
          if(data.end_date==historicalData.profilesData[j].dateList[0]||data.end_date==historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1]||
            // (!dayjs(endDate.isBefore(profileStartDate))&& dayjs(endDate.isBefore(profileEndDate)))){
              (!(endDate.isBefore(profileStartDate))&& (endDate.isBefore(profileEndDate)))){
              if(startIndex!=j){
              // console.log("KRL with end date=",data,historicalData.profilesData[j],startIndex,j)
              let textHidden=transform.k<0.9;
              if(textHidden || isCustom){
                context.beginPath();
                context.moveTo(x1, y1);
                context.lineTo(xScaleRange[j], y1);
                context.stroke();
               
              }else{
                context.beginPath();
                context.moveTo(x1, y1);
                context.lineTo(xScaleRange[j]-75, y1);
                context.stroke();
                context.beginPath();
                context.moveTo(xScaleRange[j]-20, y1);
                context.lineTo(xScaleRange[j], y1);
                context.stroke();
                let boldFont="600 12px sans-serif";
                // context.font=CHART_FONT_DEFAULT;
                context.font=boldFont;
                context.fillStyle=CHART_COLORS.MP_NPOC_LABEL;
                context.fillText(data.level.toFixed(2),xScaleRange[j]-73, y1+4)
              }

              let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
              let randomColor=rgbToHex(randomR,randomG,randomB);
              contextTooltip.strokeStyle=randomColor;
              contextTooltip.lineWidth = 4*lineWidth/transform.k;
              contextTooltip.beginPath();
              contextTooltip.moveTo(x1, y1);
              contextTooltip.lineTo(xScaleRange[j], y1);
              contextTooltip.stroke();
              // krlTooltipData.push({x1:x1,x2:xScaleRange[j],tpo:levelTPO,data:"["+level+"]: "+data.description+" ["+data.start_date+","+data.end_date+"]"+data.styling_category})
              krlTooltipData.push({x1:x1,x2:xScaleRange[j],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,color:data.marking_color})
              }else{  //when startdate and end date are same
                if(xScaleRange[j+1]!=undefined){
                console.log("KRL same date=",x1,xScaleRange[j])
                context.beginPath();
                context.moveTo(xScaleRange[j], y1);
                context.lineTo(xScaleRange[j+1], y1);
                context.stroke();
                let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                let randomColor=rgbToHex(randomR,randomG,randomB);
                contextTooltip.strokeStyle=randomColor;
                contextTooltip.lineWidth = 4*lineWidth/transform.k;
                contextTooltip.beginPath();
                contextTooltip.moveTo(xScaleRange[j], y1);
                contextTooltip.lineTo(xScaleRange[j+1], y1);
                contextTooltip.stroke();
                krlTooltipData.push({x1:xScaleRange[j],x2:xScaleRange[j+1],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,color:data.marking_color})
                }
              }
              break;

          }
          
        }

      }
      context.restore();
    }

    const plotCustomKRL=(transform,x1,y1,data,startIndex,level,levelTPO,rightMostKRLList,krlTooltipData,isCustom,isFutureDate=false)=>{
      // console.log("Plotkrl called =",isCustom,data)
      // context.lineWidth = OF_CHART_LINE_WIDTH.VWAP/zY.k;
      context.save();
      // console.log("KRL drawKR KRL tooltip: category=",KRL_STYLING_CATEGORY[data.styling_category],krlTooltipData);
      const krlCategory=KRL_STYLING_CATEGORY[data.styling_category]?KRL_STYLING_CATEGORY[data.styling_category]:KRL_STYLING_CATEGORY["category_default"];
      const lineWidth=krlCategory.width;
      context.strokeStyle=data.marking_color;
      context.lineWidth = lineWidth/transform.k;
      if(krlCategory.type==KRL_CATEOGRY_TYPE.DOTTED_LINE)
        context.setLineDash([3/transform.k, 3/transform.k]);
      else if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
        context.setLineDash([9/transform.k, 5/transform.k]);
      // else
      // context.lineWidth = lineWidth/transform.k;
      
     

      if(data.end_date=="" || isFutureDate){
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(xScaleRange[historicalData.profilesData.length+1], y1);
        context.stroke();
        let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
        let randomColor=rgbToHex(randomR,randomG,randomB);
        contextTooltip.strokeStyle=randomColor;
        contextTooltip.lineWidth = 4*lineWidth/transform.k;
        contextTooltip.beginPath();
        contextTooltip.moveTo(x1, y1);
        contextTooltip.lineTo(xScaleRange[historicalData.profilesData.length+1], y1);
        contextTooltip.stroke();
        // krlTooltipData.push({x1:x1,x2:xScaleRange[historicalData.profilesData.length+1],tpo:levelTPO,data:"["+level+"]: "+data.description+" ["+data.start_date+"]"+data.styling_category})
        krlTooltipData.push({x1:x1,x2:xScaleRange[historicalData.profilesData.length+1],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,y:y1,color:data.marking_color})
       

      }else{
        if(data.category!="CUSTOM_KRL"){
        let j=startIndex;
        if(xScaleRange[j+1]!=undefined){
          console.log("KRL same date=",x1,xScaleRange[j])
          context.beginPath();
          context.moveTo(xScaleRange[j], y1);
          context.lineTo(xScaleRange[j+1], y1);
          context.stroke();
          let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
          let randomColor=rgbToHex(randomR,randomG,randomB);
          contextTooltip.strokeStyle=randomColor;
          contextTooltip.lineWidth = 4*lineWidth/transform.k;
          contextTooltip.beginPath();
          contextTooltip.moveTo(xScaleRange[j], y1);
          contextTooltip.lineTo(xScaleRange[j+1], y1);
          contextTooltip.stroke();
          krlTooltipData.push({x1:xScaleRange[j],x2:xScaleRange[j+1],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,color:data.marking_color})
          }
        }else{
          let endDate=dayjs(data.end_date.split("-").reverse().join("-"))
          for(let j=0;j<historicalData.profilesData.length;j++){
            // console.log("Plotkrl called loop=",j,isCustom,data)
             
            //  profileStartDate
            //  let profileStartDate=dayjs(historicalData.profilesData[j].dateList[0].split("-").reverse().join("-"))
            //  let profileEndDate=dayjs(historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1].split("-").reverse().join("-"))
             let profileStartDate=historicalData.profilesData[j].profileStartDate;
             let profileEndDate=historicalData.profilesData[j].profileEndDate;
            if(data.end_date==historicalData.profilesData[j].dateList[0]||data.end_date==historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1]||
              // (!dayjs(endDate.isBefore(profileStartDate))&& dayjs(endDate.isBefore(profileEndDate)))){
                (!(endDate.isBefore(profileStartDate))&& (endDate.isBefore(profileEndDate)))){
                if(startIndex!=j){
                // console.log("KRL with end date=",data,historicalData.profilesData[j],startIndex,j)
                let textHidden=transform.k<0.9;
                if(textHidden || isCustom){
                  context.beginPath();
                  context.moveTo(x1, y1);
                  context.lineTo(xScaleRange[j], y1);
                  context.stroke();
                 
                }else{
                  context.beginPath();
                  context.moveTo(x1, y1);
                  context.lineTo(xScaleRange[j]-75, y1);
                  context.stroke();
                  context.beginPath();
                  context.moveTo(xScaleRange[j]-20, y1);
                  context.lineTo(xScaleRange[j], y1);
                  context.stroke();
                  let boldFont="600 12px sans-serif";
                  // context.font=CHART_FONT_DEFAULT;
                  context.font=boldFont;
                  context.fillStyle=CHART_COLORS.MP_NPOC_LABEL;
                  context.fillText(data.level.toFixed(2),xScaleRange[j]-73, y1+4)
                }
  
                let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                let randomColor=rgbToHex(randomR,randomG,randomB);
                contextTooltip.strokeStyle=randomColor;
                contextTooltip.lineWidth = 4*lineWidth/transform.k;
                contextTooltip.beginPath();
                contextTooltip.moveTo(x1, y1);
                contextTooltip.lineTo(xScaleRange[j], y1);
                contextTooltip.stroke();
                // krlTooltipData.push({x1:x1,x2:xScaleRange[j],tpo:levelTPO,data:"["+level+"]: "+data.description+" ["+data.start_date+","+data.end_date+"]"+data.styling_category})
                krlTooltipData.push({x1:x1,x2:xScaleRange[j],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,color:data.marking_color})
                }else{  //when startdate and end date are same
                  if(xScaleRange[j+1]!=undefined){
                  console.log("KRL same date=",x1,xScaleRange[j])
                  context.beginPath();
                  context.moveTo(xScaleRange[j], y1);
                  context.lineTo(xScaleRange[j+1], y1);
                  context.stroke();
                  let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                  let randomColor=rgbToHex(randomR,randomG,randomB);
                  contextTooltip.strokeStyle=randomColor;
                  contextTooltip.lineWidth = 4*lineWidth/transform.k;
                  contextTooltip.beginPath();
                  contextTooltip.moveTo(xScaleRange[j], y1);
                  contextTooltip.lineTo(xScaleRange[j+1], y1);
                  contextTooltip.stroke();
                  krlTooltipData.push({x1:xScaleRange[j],x2:xScaleRange[j+1],tpo:levelTPO,data:"["+level+"]: "+data.description,obj:data,color:data.marking_color})
                  }
                }
                context.restore();
                return;
  
            }
            
          }

        }

      }
      context.restore();
    }

    const drawNPOC=(transform,scaleY,krlTooltipData,rightIndex,offset)=>{
       // console.log("NPOC Test=",scaleY.domain()[0],scaleY.domain()[1]);
       context.save();
       let rightMostNPOCList=[];
       let textHidden=transform.k<0.9;
       const krlCategory=KRL_STYLING_CATEGORY["category_npoc"]
       const lineWidth=krlCategory.width;
       context.strokeStyle=CHART_COLORS.MP_NPOC;
       context.lineWidth = lineWidth/transform.k;

       if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
         context.setLineDash([3/transform.k, 3/transform.k]);
       for(let i=0;i<=rightIndex;i=i+1){
         // console.log("NPOC Test outside tooltip=",tooltipData.data[i])

         //if vpoc of the profile is not within the scale range, dont draw it
         let currPOC=!selectedInstrumentData.vp|| (props.tpoBasedVA==VA_TYPE.HYBRID||props.tpoBasedVA==VA_TYPE.TPO_BASED) ?historicalData.profilesData[i].tpoc:historicalData.profilesData[i].vpoc;
         let currPOCTPO=!selectedInstrumentData.vp|| (props.tpoBasedVA==VA_TYPE.HYBRID||props.tpoBasedVA==VA_TYPE.TPO_BASED)?historicalData.profilesData[i].vpocTPOBased:historicalData.profilesData[i].vpocTPO;
         if(currPOC>=scaleY.domain()[1] || currPOC<=scaleY.domain()[0])
         continue;

         let isRightMostNPOC=false;
         if(currPOC){
           
           
          let count=0;
          
          for(let j=i+1;j<=historicalData.profilesData.length-1;j++){
            // console.log("NPOC=",i,j,historicalData.config.data[i].vpoc,historicalData.config.data[j].highVal,historicalData.config.data[j].lowVal)
            let tempPOC=!selectedInstrumentData.vp|| (props.tpoBasedVA==VA_TYPE.HYBRID||props.tpoBasedVA==VA_TYPE.TPO_BASED)?historicalData.profilesData[j].tpoc:historicalData.profilesData[j].vpoc;
            if((currPOC>historicalData.profilesData[j].highVal && currPOC>historicalData.profilesData[j].lowVal)||
            (currPOC<historicalData.profilesData[j].highVal && currPOC<historicalData.profilesData[j].lowVal)){
              // console.log("NPOC= inside count")
              count=count+1;
            }else{
              break;
            }
          }
          
          if(count>=MP_NPOC_MIN_PROFILE_DIFFERENCE){
            // console.log("NPOC found",currPOC,count,historicalData.profilesData[i],xScaleRange[i],count,props.tpoBasedVA)
            // context.lineWidth =krlCategory.width/transform.k;
            // context.strokeStyle=CHART_COLORS.MP_NPOC;
            if(i+count==historicalData.profilesData.length-1){
              // console.log("NPOC Test= Extended to right most",historicalData.config.data[i].vpoc.toFixed(2))
              rightMostNPOCList.push(currPOC.toFixed(2))
              isRightMostNPOC=true;
            }
            let width=historicalData.profilesData[i].isStacked?historicalData.profilesData[i].stackedWidth:historicalData.profilesData[i].splitWidth;
            let endwidth=historicalData.profilesData[i+count].isStacked?historicalData.profilesData[i+count].stackedWidth:historicalData.profilesData[i+count].splitWidth;
            if( historicalData.profilesData[i+count].isVolumeProfile && !historicalData.profilesData[i+count].isTPOonVolume && !historicalData.profilesData[i+count].showOnlyVolumeProfile){
              endwidth=endwidth+historicalData.profilesData[i+count].volumeWidth 
            }
            if(!textHidden && !isRightMostNPOC){
             
              context.beginPath();
              context.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
              context.lineTo(xScaleRange[i+count]+endwidth-75, yScale(currPOCTPO));
              context.stroke();

              context.beginPath();
              context.moveTo(xScaleRange[i+count]+endwidth-20, yScale(currPOCTPO));
              context.lineTo(xScaleRange[i+count]+endwidth+5, yScale(currPOCTPO));
              context.stroke();

              let boldFont="600 12px sans-serif";
             
              context.font=boldFont;
              context.fillStyle=CHART_COLORS.MP_NPOC_LABEL;
              context.fillText(currPOC.toFixed(2),xScaleRange[i+count]+endwidth-73, yScale(currPOCTPO)+4)
              
              let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
              let randomColor=rgbToHex(randomR,randomG,randomB);
              contextTooltip.strokeStyle=randomColor;
              contextTooltip.lineWidth =2*krlCategory.width/transform.k;
              contextTooltip.beginPath();
              contextTooltip.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
              contextTooltip.lineTo(xScaleRange[i+count]+endwidth+5, yScale(currPOCTPO));
              contextTooltip.stroke();
              krlTooltipData.push({x1:xScaleRange[i]+width-2,x2:xScaleRange[i+count]+endwidth+5,tpo:currPOCTPO,data:"["+currPOC.toFixed(2)+"]: "+"Unvisited VPOC",color:CHART_COLORS.MP_NPOC})
        


              
              }else{
                if(isRightMostNPOC){
                  context.beginPath();
                  context.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
                  context.lineTo(xScaleRange[i+count]+endwidth+dummyProfileWidth, yScale(currPOCTPO));
                  context.stroke();

                  let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                  let randomColor=rgbToHex(randomR,randomG,randomB);
                  contextTooltip.strokeStyle=randomColor;
                  contextTooltip.lineWidth =2*krlCategory.width/transform.k;
                  contextTooltip.beginPath();
                  contextTooltip.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
                  contextTooltip.lineTo(xScaleRange[i+count]+endwidth+dummyProfileWidth, yScale(currPOCTPO));
                  contextTooltip.stroke();
                  krlTooltipData.push({x1:xScaleRange[i]+width-2,x2:xScaleRange[i+count]+endwidth+dummyProfileWidth,tpo:currPOCTPO,data:"["+currPOC.toFixed(2)+"]: "+"Unvisited VPOC",color:CHART_COLORS.MP_NPOC})
                }else{
                  context.beginPath();
                  context.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
                  context.lineTo(xScaleRange[i+count]+endwidth, yScale(currPOCTPO));
                  context.stroke();
                  
                  let val=randomR<255?randomR=randomR+1:(randomG<255?randomG=randomG+1:randomB=randomB+1);
                  let randomColor=rgbToHex(randomR,randomG,randomB);
                  contextTooltip.strokeStyle=randomColor;
                  contextTooltip.lineWidth =2*krlCategory.width/transform.k;
                  contextTooltip.beginPath();
                  contextTooltip.moveTo(xScaleRange[i]+width-2, yScale(currPOCTPO));
                  contextTooltip.lineTo(xScaleRange[i+count]+endwidth+5, yScale(currPOCTPO));
                  contextTooltip.stroke();
                  krlTooltipData.push({x1:xScaleRange[i]+width-2,x2:xScaleRange[i+count]+endwidth+5,tpo:currPOCTPO,data:"["+currPOC.toFixed(2)+"]: "+"Unvisited VPOC",color:CHART_COLORS.MP_NPOC})
                }

              }
              
              
            
          }

        }
       }

      //  d3.selectAll(`#npoc_label${props.id}`).remove()
      let tooltip = d3.select("body")
      .append("div")
      .attr("id", "krlAxisTooltip"+props.id)
      .style("position", "absolute")
      .style("z-index", "10")
      // .style("visibility", "hidden")
     .style('display','none')
      .text("a simple tooltip");

    
        
       let fontSize="12px"
      //  if(transform.k<=0.8 && transform.k>=0.6)
      //    fontSize="10px"
      //  else if(transform.k<0.6)
      //    fontSize="8px"
       
       for(let i=0;i<rightMostNPOCList.length;i++){
       container.append("div")
            .style("left", (canvasWidth + 4) + "px")
           .attr("id", "npoc_label"+props.id)
           .style("top", scaleY(rightMostNPOCList[i])+24+TPO_HEIGHT+7 + "px")   //10=height of tooltip/2 80=Headers add height
           .style("position","absolute")
           .style("font-size",fontSize)
           .style("font-weight",500)
           .style("font-family","sans-serif")
           .style("background","#022D5A")
           .style("color",`${CHART_COLORS.MP_KRL_LABEL}`)
           .html(rightMostNPOCList[i])
           .on("mouseover", function(d){(tooltip.text("VPOC: "+rightMostNPOCList[i])); return tooltip.style("display", "block");})
            .on("mousemove", function(){return tooltip.style("top", (scaleY(rightMostNPOCList[i])+24+TPO_HEIGHT+40+props.chartStateData.top)+"px")
            // .style("margin-left")
            // .style("left",props.chartStateData.left+props.chartStateData.width-115-200+"px")
            .style("right",(115+offset)+"px")
            .style("background", "#1e293b").style("font-size", "12px")
            .style("width","auto")
            .style("height","auto")
            .style("border","2px solid #475569")
            .style("padding","10px")
            .style("border-radius","8px")})
            .on("mouseout", function(){return tooltip.style("display", "none");});
            
           }
           context.restore();
    }
    const drawCustomKRLConnectingLines=(data,transform,yScale,scaleMax,scaleMin)=>{
      context.save();
      const krlCategory=KRL_STYLING_CATEGORY[data[0].styling_category]?KRL_STYLING_CATEGORY[data[0].styling_category]:KRL_STYLING_CATEGORY["category_default"];
      const lineWidth=krlCategory.width;
      context.strokeStyle=data[0].marking_color;
      context.lineWidth = lineWidth/transform.k;
      if(krlCategory.type==KRL_CATEOGRY_TYPE.DOTTED_LINE)
        context.setLineDash([3/transform.k, 3/transform.k]);
      else if(krlCategory.type==KRL_CATEOGRY_TYPE.DASHED_LINE)
        context.setLineDash([9/transform.k, 5/transform.k]);
      
      //   for(let i=0;i<data.length;i++){
      //   for(let j=0;j<historicalData.profilesData.length;j++){
      //     let endDate=dayjs(data[i].end_date.split("-").reverse().join("-"))
      //     let profileStartDate=dayjs(historicalData.profilesData[j].dateList[0].split("-").reverse().join("-"))
      //     let profileEndDate=dayjs(historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1].split("-").reverse().join("-"))
      //    if(data[i].end_date==historicalData.profilesData[j].dateList[0]||data[i].end_date==historicalData.profilesData[j].dateList[historicalData.profilesData[j].dateList.length-1]||
      //        (!(endDate.isBefore(profileStartDate))&& (endDate.isBefore(profileEndDate)))){
      //         if(i<data.length-1){
      //         let level=parseFloat(((data[i].level*10000)/10000).toFixed(4));
      //         let levelMod=parseFloat(((data[i].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
      //         let levelTPO=parseFloat(((level-levelMod)).toFixed(4));
      //         let levelNext=parseFloat(((data[i+1].level*10000)/10000).toFixed(4));
      //         let levelNextMod=parseFloat(((data[i+1].level*10000)%(selectedTPO*10000))/10000).toFixed(4);
      //         let levelNextTPO=parseFloat(((levelNext-levelNextMod)).toFixed(4));
      //         context.beginPath();
      //         context.moveTo(xScaleRange[j+1], yScale(levelTPO));
      //         context.lineTo(xScaleRange[j+1], yScale(levelNextTPO));
      //         context.stroke();
      //         }
      //        }
      //       }

      // }
      let count=0;
      for(let i=0;i<data.length;i++){
        // if(i<data.length-1 && !((data[i].levelTPO>=scaleMin && data[i].levelTPO<=scaleMax)||(data[i+1].levelTPO>=scaleMin && data[i+1].levelTPO<=scaleMax)))
        // console.log("Issue scale max, min, level1, level2=",scaleMax,scaleMin,data[i].levelTPO,data[i+1].levelTPO)
        
        if(i<data.length-1){
          if((data[i].levelTPO>=scaleMax && data[i+1].levelTPO>=scaleMax)||(data[i].levelTPO<=scaleMin && data[i+1].levelTPO<=scaleMin))
          {
            // count=count+1;
          }
          else{
            context.beginPath();
              context.moveTo(xScaleRange[data[i].profileIndex+1], yScale(data[i].levelTPO));
              context.lineTo(xScaleRange[data[i].profileIndex+1], yScale(data[i+1].levelTPO));
              context.stroke();
          }
        }
      }
      // console.log("Issue count ignored=",count)

      context.restore();

    }
    
    const getYTransform=(transform,prevTransform)=>{
      let scaleX=undefined;
      if(transform.k>=0.4)
        scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
      else
       scaleX =xScale.copy()
        .range((xScale.range().map((d,i) => (d*transform.k+transform.x))))
        .domain((xScale.domain().map((date,i) => (date.length<13?date.slice(0,5):date.slice(0,date.length-5)))))

        let leftIndex=binarySearch(scaleX.copy().range(),0-props.chartStateData.left);
        let rightIndex=binarySearch(scaleX.copy().range(),canvasWidth-5);
        let prevY=transform.y;
        let trans = d3.zoomIdentity  
        .translate(transform.x, 0)
        .scale(transform.k)
        // JSON.parse(JSON.stringify(transform));
        // trans.y=0;
        let scaleY = trans.rescaleY(yScale);
        let yTransform=transform.y
        console.log(transformDataRef.current,prevTransform,transform,Math.round((leftIndex+rightIndex)/2))
        if(transform.x!=0){
        if(Math.abs(prevTransform.x-transform.x)>AUTO_CENTER_CONFIG.DELTA_X && Math.abs(prevTransform.y-transform.y)<AUTO_CENTER_CONFIG.DELTA_Y){
           yTransform=-1*((scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)].maxTPO)+scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)].minTPO))/2);
          // yTransform=-1*((scaleY(historicalData.profilesData[Math.round(rightIndex)].maxTPO)+scaleY(historicalData.profilesData[Math.round(leftIndex)].minTPO))/2);
          // yTransform=-1*(scaleY(historicalData.profilesData[Math.round((leftIndex+rightIndex)/2)-1].minTPO));
          
          console.log("returnning auto center yTransform=========>",yTransform);
          return yTransform;
        }else{
          return transform.y;
        }
      }else
      return transform.y; 
    }
    //handles 1st draw on the canvas  
    function zoomed(transform) { 
      if(chartInterationAllowedRef.current){
          // console.log("zoom event 1 =",transform);
          // let xt=transform.x;
          // if(transform.x!=0 && redraw){
          //   transform.x=transform.x;
          //   redraw=false;
          // }
          context.save();
          context.clearRect(0, 0, canvasWidth, canvasHeight);
          context.translate(transform.x, transform.y);
          context.scale(transform.k, transform.k);
          contextDummy.save();
          contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
          contextDummy.translate(transform.x, transform.y);
          contextDummy.scale(transform.k, transform.k);
          contextOverlay.save();
          contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
          contextOverlay.translate(transform.x, transform.y);
          contextOverlay.scale(transform.k, transform.k);
          contextTooltip.save();
          contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
          contextTooltip.translate(transform.x, transform.y);
          contextTooltip.scale(transform.k, transform.k);
        
          // draw(transform,true,transform.x);
          draw(transform,true);
          context.restore();
          contextDummy.restore();
          contextOverlay.restore();
          contextTooltip.restore();
      }
    }
   
    
    let t = d3.zoomIdentity.translate(initialTransform.x, initialTransform.y).scale(initialTransform.k);
    
    // console.log("translateextent and zoom out scale=>",t,historicalData.config.totalWidth,(canvasWidth-historicalData.config.totalWidth),canvasWidth,canvasWidth/historicalData.config.totalWidth)
    // console.log("yScale range=",(yScale.range()[0]/TPO_HEIGHT)*selectedTPO,yScale.domain(),historicalData.config.max,historicalData.config.min,canvasHeight);
  

    let translateExtentUpward=canvasHeight;
    if((yScale.domain()[0]!=historicalData.config.min))
    translateExtentUpward=(Math.abs(yScale.domain()[0]-historicalData.config.min)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(4*canvasHeight);
    
    let translateExtentDownward=-1*canvasHeight;
    if((yScale.domain()[1]!=historicalData.config.max))
    translateExtentDownward=-1*((Math.abs(yScale.domain()[1]-historicalData.config.max)/(yScale.domain()[1]-yScale.domain()[0]))*canvasHeight+(4*canvasHeight));
  
    let translateExtentRight=canvasWidth-totalWidth;
    // if(canvasWidth>historicalData.config.totalWidth)
    // translateExtentRight=(historicalData.config.totalWidth-canvasWidth);

    if(canvasWidth>historicalData.config.totalWidth)
    translateExtentRight=-1*(canvasWidth-(0));
  //  translateExtentRight=-1*(canvasWidth);
   else
   translateExtentRight=canvasWidth-totalWidth;
  //  translateExtentRight=-1*canvasWidth;
  //  translateExtentRight=-1*historicalData.config.totalWidth;

    // translateExtentRight=-1*canvasWidth/ZOOM_OUT_SCALE;
    // translateExtentRight=-1*canvasWidth+historicalData.profilesData[0].stackedWidth;
    // translateExtentRight=historicalData.config.totalWidth+canvasWidth-totalWidth;
    // translateExtentRight=-1*historicalData.config.totalWidth/ZOOM_OUT_SCALE;
    // translateExtentRight=historicalData.config.totalWidth+canvasWidth-totalWidth+200;
    // translateExtentRight=historicalData.config.totalWidth+canvasWidth-totalWidth;

    // translateExtentRight=canvasWidth-totalWidth+historicalData.config.totalWidth;
    
    // translateExtentRight=historicalData.config.totalWidth-totalWidth+canvasWidth;
 
    // translateExtentRight=canvasWidth-totalWidth+historicalData.config.totalWidth;

    // if(canvasWidth>historicalData.config.totalWidth)
    // translateExtentRight=-1*historicalData.config.totalWidth+200;
    // translateExtentRight=canvasWidth+historicalData.config.totalWidth-totalWidth;
    // translateExtentRight=0;
    // let translateExtentRight=canvasWidth-totalWidth;
    // if(canvasWidth>historicalData.config.totalWidth)
    // translateExtentRight=-1*canvasWidth;
   
    console.log("translateExtentUpward,translateExtentDownward =", translateExtentUpward,translateExtentDownward);
    
    // let ZOOM_OUT_SCALE=Math.max(ZOOM_OUT_EXTREME, canvasWidth>historicalData.config.totalWidth? ZOOM_OUT_EXTREME_LESS_PROFILE:canvasWidth/historicalData.config.totalWidth);
    // if(canvasWidth>=historicalData.config.totalWidth)
    // ZOOM_OUT_SCALE=1;
    console.log("ZOOM_OUT_SCALE=",ZOOM_OUT_SCALE)
    function constrain(transform, extent, translateExtent) {
      // let dx0 = transform.invertX(extent[0][0]) - (translateExtent[0][0]+historicalData.config.totalWidth),
      // dx1 = transform.invertX(extent[1][0]) - (translateExtent[1][0]+canvasWidth/transformDataRef.current.k-historicalData.config.totalWidth),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]))/transformDataRef.current.k,
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0])),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]+canvasWidth/transformDataRef.current.k-historicalData.config.totalWidth/transformDataRef.current.k+200/transformDataRef.current.k)),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]+canvasWidth/transformDataRef.current.k-historicalData.config.totalWidth/transformDataRef.current.k+100/transformDataRef.current.k)),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]-historicalData.config.totalWidth/transformDataRef.current.k+canvasWidth/transformDataRef.current.k+100/transformDataRef.current.k)),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]+historicalData.config.totalWidth)),
      // let dx0 = (transform.invertX(extent[0][0]) - (translateExtent[0][0]-historicalData.config.totalWidth/transformDataRef.current.k+canvasWidth/transformDataRef.current.k)),
      //  let dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0]*ZOOM_OUT_SCALE)/transformDataRef.current.k+(historicalData.profilesData[0].stackedWidth)/transformDataRef.current.k)),
       
      // let dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0]/transformDataRef.current.k)+(historicalData.profilesData[0].stackedWidth)/ZOOM_OUT_SCALE)),
      // let dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0]/transformDataRef.current.k)+(canvasWidth-historicalData.config.totalWidth)/ZOOM_OUT_SCALE)),
      let dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0])+(canvasWidth+200)/ZOOM_OUT_SCALE)),
         dx1 = transform.invertX(extent[1][0]) - (translateExtent[1][0]+canvasWidth/transformDataRef.current.k-historicalData.profilesData[historicalData.profilesData.length-1].stackedWidth),
          dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
          dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
          console.log("constrain=",canvasWidth,transformDataRef.current, dx0,dx1,translateExtent[0][0],translateExtent[1][0],translateExtentRight,canvasWidth/ZOOM_OUT_SCALE,translateExtent,extent)
      if(canvasWidth>historicalData.config.totalWidth)
      dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0]/transformDataRef.current.k)+(historicalData.profilesData[0].stackedWidth)/ZOOM_OUT_SCALE));

          return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
      );
    }

    function constrainNew(transform, extent, translateExtent) {
      let dx0 = transform.invertX(extent[0][0]) -((translateExtent[0][0])+(canvasWidth)/ZOOM_OUT_SCALE),
          dx1 = transform.invertX(extent[1][0]) - (translateExtent[1][0]+canvasWidth/transformDataRef.current.k-historicalData.profilesData[historicalData.profilesData.length-1].stackedWidth),
          dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
          dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
          console.log("constrain=",ZOOM_OUT_SCALE,transformDataRef.current.k,dx0,dx1,translateExtent[0][0],translateExtent[1][0],translateExtentRight,canvasWidth/ZOOM_OUT_SCALE,translateExtent,extent)
          if(canvasWidth>historicalData.config.totalWidth)
            dx0 = (transform.invertX(extent[0][0]) - ((translateExtent[0][0]/transformDataRef.current.k)+(historicalData.profilesData[0].stackedWidth)/ZOOM_OUT_SCALE));

          return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
      );
    }
    const zoom_function = d3.zoom()
    .scaleExtent([ZOOM_OUT_SCALE, 1])
    .constrain(constrainNew)
    // .translateExtent([[translateExtentRight, translateExtentDownward], [1*canvasWidth/ZOOM_OUT_SCALE,translateExtentUpward]])
    .translateExtent([[translateExtentRight, translateExtentDownward], [canvasWidth,translateExtentUpward]])
    .on('start', (event) => {
      console.log("Test zoom start=====>",event.transform)
      setIsZooming(true);
      setTransformDataAutoCenter(event.transform);
      // container.select(`#dummyCanvas${props.id}`).style('cursor',"all-scroll")
    })
    .on('zoom', (event) => {
            // if((Math.round(event.transform.x))%2==0){
            if(chartInterationAllowedRef.current){
            // setToggleRepaint(false);
            console.log("zoom event 2 =",event.transform);
            let transform = event.transform;
            // transform.y=getYTransform(transform);
            // if(canvasWidth>historicalData.config.totalWidth && transform.k<1)
            //   // transform.x=-1*transform.x*transform.k;
            //   transform.x=1/transform.k*transform.x;
            // else
            transform.k=parseFloat(transform.k.toFixed(4));
            transform.x=parseFloat(transform.x.toFixed(2));
            transform.y=parseFloat(transform.y.toFixed(2));

            // transform.x=canvasWidth/2+(transform.x-canvasWidth/2)/transform.k*prevZI.k;
            // let xt=transform.x;
            // if(transform.x!=0 && !redraw){
            // transform.x=transform.x*transform.k;
            // // redraw=false;
            // }
            if(transformDataRef.current.k==transform.k &&(transformDataRef.current.x!=transform.x)){
              console.log("Recenter X set to false");
              setIsRecenterAllowed(false)
             }
            
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.translate(transform.x, transform.y);
            context.scale(transform.k, transform.k);
            contextDummy.save();
            contextDummy.clearRect(0, 0, canvasWidth+YSCALE_WIDTH-2, canvasHeight);
            contextDummy.translate(transform.x, transform.y);
            contextDummy.scale(transform.k, transform.k);
            contextOverlay.save();
            contextOverlay.clearRect(0, 0, canvasWidth, canvasHeight);
            contextOverlay.translate(transform.x, transform.y);
            contextOverlay.scale(transform.k, transform.k);
            contextTooltip.save();
            contextTooltip.clearRect(0, 0, canvasWidth, canvasHeight);
            contextTooltip.translate(transform.x, transform.y);
            contextTooltip.scale(transform.k, transform.k);
        
            draw(transform,false);
            // draw(transform,false,xt);
            context.restore();
            contextDummy.restore();
            contextOverlay.restore();
            contextTooltip.restore();
          }
        })
       
        .on('end', (event) => {
          setIsZooming(false);
          // container.select(`#dummyCanvas${props.id}`).style('cursor',"default")
          console.log("Test zoom END=====>",event.transform,transformDataAutoCenterRef.current,autoCenterRef.current);
          if(savedLiveData!=undefined){
            // handleLiveData(savedLiveData);
            console.log("Test zoom handlelive END=====> rendering last live profile now");
            // setHistoricalData(savedHistoricalData);
            // setSavedHistoricalData(undefined)
           
          }
            
          if(autoCenterRef.current){
            let transform=event.transform;
            if(transform.k !=transformDataAutoCenterRef.current.k || transform.x !=transformDataAutoCenterRef.current.x || transform.y !=transformDataAutoCenterRef.current.y){
              let newY=getYTransform(transform,transformDataAutoCenterRef.current);
              console.log("zoom END=====>",transform.y,newY)
              if(transform.y!=newY){
                transform.y=newY;
                autoCenter(transform)
              }    
              // setTransformDataAutoCenter(transform);
             
            }
          }
        })
       
        dummyCanvas.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        dummyCanvas.call(zoom_function) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function.transform, t) .on("dblclick.zoom", null);
        // canvasTooltip.call(zoom_function) .on("dblclick.zoom", null);
        zoomed(t);
    
        // setIsLoadingMoreProfiles(false);
        
        if((CHART_FACTOR_MP*canvasWidth)>historicalData.config.totalWidth && moreDataPresent && !props.chartRendered){
          // setIsLoadingMoreProfiles(false);
          // loadMoreData(transform);
          // setIsLoadingMoreProfiles(false);
          console.log("Load more end===>",moreDataPresent,isLoadingMoreProfiles,historicalData,windowSize,props.chartStateData,toggleRepaint,metaData,canvasWidth,historicalData.config.totalWidth);
          // setIsLoadingMoreProfiles(true);
          loadMoreData();
         
        }else{
          // setIsLoadingMoreProfiles(false);
          // console.log("Load more end===> true props=",props.setChartRenderedState,props);
          props.setChartRenderedState(true);
          setDataLoaded(true);
          //props.setChartRenderedState(true);
        }
        
    },[historicalData,windowSize,props.chartStateData,toggleRepaint])

    //toggles stacked and split profiles
    const updateDisplayType=(profileData,currentIndex)=>{
      setShowContextMenu(false);
      
      console.log("profile data =",profileData)
      profileData.isStacked=!(profileData.isStacked);
      console.log("profile data after =",profileData)
      historicalData.profilesData[currentIndex]=profileData
      // historicalData.profilesData[currentIndex].isStacked=!historicalData.profilesData[currentIndex].isStacked;
      historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                    (profileData.isStacked?(profileData.stackedWidth-profileData.splitWidth):
                                    (profileData.splitWidth-profileData.stackedWidth));
      let data = JSON.parse(JSON.stringify(historicalData));
      setSelectedProfilesIndex([]);
      setHistoricalData(data);
    }

     //toggles stacked and split profiles
     const toggleStackedProfiles=(show)=>{
      setShowContextMenu(false);
      const is_Monthly_Yearly=(props.selectedTimeFrame==TIME_FRAME_VALUES.monthly || props.selectedTimeFrame==TIME_FRAME_VALUES.monthly_series || props.selectedTimeFrame==TIME_FRAME_VALUES.yearly)
      for(let i=0;i<selectedProfilesIndex.length;i++){
        if( is_Monthly_Yearly || historicalData.profilesData[selectedProfilesIndex[i]].dateList.length==1 ||historicalData.profilesData[selectedProfilesIndex[i]].dateList.length>props.max_cmp_days){
         
        let prevVal=historicalData.profilesData[selectedProfilesIndex[i]].isStacked;
        historicalData.profilesData[selectedProfilesIndex[i]].isStacked=show;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        //change the width only when previuos and current values are different
       
       if(prevVal!=show)
          historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                (!show?(historicalData.profilesData[selectedProfilesIndex[i]].stackedWidth-historicalData.profilesData[selectedProfilesIndex[i]].splitWidth):
                                 (historicalData.profilesData[selectedProfilesIndex[i]].splitWidth-historicalData.profilesData[selectedProfilesIndex[i]].stackedWidth));
        }   
      }  
      console.log("Stacked profile after width",historicalData.config.totalWidth);
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    //toggles volume profiles
    const toggleVolumeProfile=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let prevVal=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume;
       
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=show;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        historicalData.profilesData[selectedProfilesIndex[i]].showOnlyVolumeProfile=false;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=false;
        //change the width only when previuos and current values are different
        if(prevVal!=show && !tpoOnVolume)
        historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                        (show?historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth:-1*historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth)     
      }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
     }else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        let prevVal=historicalData.profilesData[i].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
       
        historicalData.profilesData[i].isVolumeProfile=show;
        historicalData.profilesData[i].showTPO=true;
        historicalData.profilesData[i].showOnlyVolumeProfile=false;
        historicalData.profilesData[i].isTPOonVolume=false;
        //change the width only when previuos and current values are different
        if(prevVal!=show && !tpoOnVolume)
        historicalData.config.totalWidth=historicalData.config.totalWidth+ 
                                        (show?historicalData.profilesData[i].volumeWidth:-1*historicalData.profilesData[i].volumeWidth)     
      }
    }
    setSelectedProfilesIndex([]); 
                                 
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const showTPOonVolume=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let isVolumeVisible=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=show;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=show;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeNumbers=false;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=true;
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth;     
                                     
        }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
      }
      else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
        let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
        historicalData.profilesData[i].isVolumeProfile=show;
        historicalData.profilesData[i].isTPOonVolume=show;
        historicalData.profilesData[i].isVolumeNumbers=false;
        historicalData.profilesData[i].showTPO=true;
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
                                     
        }  
      console.log("Volume profile after width",historicalData.config.totalWidth);
      }
    
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const toggleVolumeOnlyProfile=(showOnlyVolumeProfile,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
      for(let i=0;i<selectedProfilesIndex.length;i++){
        let isVolumeVisible=historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].isVolumeProfile=showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].showOnlyVolumeProfile=showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].isTPOonVolume=!showOnlyVolumeProfile;
        historicalData.profilesData[selectedProfilesIndex[i]].showTPO=!showOnlyVolumeProfile;
        
        console.log("Volume profile before width",historicalData.config.totalWidth);
       
        if(isVolumeVisible)
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[selectedProfilesIndex[i]].volumeWidth;     
                                     
        }  
      }else{
        for(let i=0;i<historicalData.profilesData.length;i++){
          let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
          historicalData.profilesData[i].isVolumeProfile=showOnlyVolumeProfile;
          historicalData.profilesData[i].showOnlyVolumeProfile=showOnlyVolumeProfile;
          historicalData.profilesData[i].isTPOonVolume=!showOnlyVolumeProfile;
          historicalData.profilesData[i].showTPO=!showOnlyVolumeProfile;
          console.log("Volume profile before width",historicalData.config.totalWidth);
         
          if(isVolumeVisible)
          historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
                                       
          
      }
    }
      console.log("Volume profile after width",historicalData.config.totalWidth);
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
   
    }
    //toggles volume numbers
    const toggleVolumeNumbers=(show,all=false)=>{
      setShowContextMenu(false);
      if(all==false){
       for(let i=0;i<selectedProfilesIndex.length;i++){
          historicalData.profilesData[selectedProfilesIndex[i]].isVolumeNumbers=show;
        }  
      }else{
        for(let i=0;i<historicalData.profilesData.length;i++){
          historicalData.profilesData[i].isVolumeNumbers=show;
        }
      }
      setSelectedProfilesIndex([]);                              
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    const toggleTPOBasedVA=(val,all=false)=>{
      console.log("tpggleTPOBaed va=",val)
      setShowContextMenu(false);
      if(all==false){
        for(let i=0;i<selectedProfilesIndex.length;i++){
          // historicalData.profilesData[selectedProfilesIndex[i]].tpoBasedVA=showTPOBasedVolume;
          // historicalData.profilesData[selectedProfilesIndex[i]].volumeBasedVA=!showTPOBasedVolume;
          historicalData.profilesData[selectedProfilesIndex[i]].vaType=val;
        }
        setSelectedProfilesIndex([]);                              
    }
    
    else{
      for(let i=0;i<historicalData.profilesData.length;i++){
        // historicalData.profilesData[i].tpoBasedVA=showTPOBasedVolume;
        //   historicalData.profilesData[i].volumeBasedVA=!showTPOBasedVolume;
        historicalData.profilesData[i].vaType=val;
      }  
    }
      console.log("Volume profile after width",historicalData.config.totalWidth);
      let data = JSON.parse(JSON.stringify(historicalData));
      setHistoricalData(data);
    }

    //closes the context menu
    const exitMenu=()=>{
      setShowContextMenu(false);
     }

     const exitMenuKRL=()=>{
      setShowKRLContextMenu(false);
     }


     /**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
  }
},[props.volumeNumberVisible,props.globalVolumeVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    console.log("MP chart per profile volume visible=",props.profileVolumeVisible)
   toggleVolumeProfile(props.profileVolumeVisible,true)
  }
},[props.profileVolumeVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    toggleVolumeNumbers(props.profileVolumeNumberVisible,true)
  }
},[props.profileVolumeNumberVisible])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    showTPOonVolume(props.tpoOnVolume,true)
  }
},[props.tpoOnVolume])

useEffect(()=>{
  if(historicalData!=undefined && historicalData.profilesData.length>0){
    toggleVolumeOnlyProfile(props.onlyVolumeProfile,true)
  }
},[props.onlyVolumeProfile])

//composite creation
  const mergeProfiles=()=>{
    props.handleDirty(true);
    setShowContextMenu(false);
    function compareNumbers(a, b) {
      return a - b;
    }
    let sortedArray=[...selectedProfilesIndex]
    sortedArray.sort(compareNumbers);
    let result=[];
    let temp=[];
    for(let i=0;i<sortedArray.length;i++){
      temp=[];
      temp.push(sortedArray[i]);
      for(let j=i+1;j<sortedArray.length;j++){
        if(sortedArray[j]-sortedArray[i]==1){
          temp.push(sortedArray[j])
          i=j;
        }
        else{
          i=j-1;
          break
        } 

      }
      
      result=[...result,temp];
    }
   
    let isAdjacantProfileFound=false
    let startIndex=-1;
    let lastIndex=0;
    let dstrMap=[];
    for(let i=0;i<result.length;i++){
      if(result[i].length>1){
        if(startIndex==-1){
          startIndex=i;
        }
          lastIndex=i;
        
        isAdjacantProfileFound=true;
        // break;
      }
    }

    if(isAdjacantProfileFound==false){
      console.log("Adjacent profile not found")
      setMsgState({open:true,msg:`${TEXT_MSGS.MERGE_ERROR}`,severity:"warning"});
      return;
    }
    //TODO: is trading session active and live date selected thendecrement the live index and setlive composite true

    // console.log("start and last index=",startIndex,lastIndex)
    //create dstr for start to last index
    let bIsFirst=true;
    let dstr="";
    let nDays=0;
    for(let i=startIndex;i<=lastIndex;i++){
      let val=0;
      if(i!=startIndex){
        // console.log("between ",result[i][0],result[i-1][result[i-1].length-1])
        if((result[i][0]-result[i-1][result[i-1].length-1])!=1){
          //for index between current array and previous construct the dstr
          let tempdstr="";
          val=0;
          for(let k=result[i-1][result[i-1].length-1]+1;k<result[i][0];k++){
            
          
            nDays=nDays+historicalData.profilesData[k].dateList.length;
            // console.log("between k , ndays= ",k, nDays);
            if(historicalData.profilesData[k].dateList.length==1){
              val=val+1;
              dstrMap.push(historicalData.profilesData[k].dateKeyList)
              // console.log()
            }else{
              if(val!=0){
                tempdstr=tempdstr+"._"+val.toString()
                val=0;
              }else{
                val=0;
                dstrMap.push(historicalData.profilesData[k].dateKeyList)
                // tempdstr=tempdstr+"."+historicalData.profilesData[k].dateList.length
                dstr=dstr+"."+historicalData.profilesData[k].dateList.length
              }
            }
          }
          if(val!=0){
            tempdstr=tempdstr+"._"+val.toString()
          
          // console.log("tempdstr = ",tempdstr)  
          dstr=dstr+tempdstr;  
          // console.log("tempdstr and dstr = ",tempdstr.dstr)  
        }
      }
    }
      val=0;
      // console.log("result[i] ",result[i])
      let tempDays=0;
      for(let j=0;j<result[i].length;j++){
        val=val+historicalData.profilesData[result[i][j]].dateList.length;
        nDays=nDays+historicalData.profilesData[result[i][j]].dateList.length;
        tempDays=tempDays+historicalData.profilesData[result[i][j]].dateList.length;
         }
        //  if(!bInBetweenProfiles){
         let t1=historicalData.profilesData[result[i][0]].dateList[0]+":"+tempDays.toString();
         for(let j=0;j<result[i].length;j++){
         dstrMap.push(t1)
        //  console.log("dstrMap = ",dstrMap);
         }
      // console.log("days val composite  = ",val);
      if(bIsFirst){
        dstr=val.toString()
        bIsFirst=false;
      }else{
        dstr=dstr+"."+val.toString()
        
      }
    
    }

    console.log("final dstr ndays startDate= ",dstr,nDays,historicalData.profilesData[result[startIndex][0]].dateList[0]);
    console.log("dstrMap Final= ",dstrMap);
    // return;
    console.log("last selected profile ",result[lastIndex][result[lastIndex].length-1])
    if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive && result[lastIndex][result[lastIndex].length-1]==historicalData.profilesData.length-1){
      // console.log("live profile selected for composite",(dstr.split(".")));
      let dstrSplit=dstr.split(".");
      // console.log("dstrSPlit last=",dstrSplit[dstrSplit.length-1]);
      let lastVal=parseInt(dstrSplit[dstrSplit.length-1])-1;
      // console.log("dstrSPlit last, val=",dstrSplit[dstrSplit.length-1],lastVal);
      let modifiedDstr="";
      for(let i=0;i<dstrSplit.length;i++){
        if(i==dstrSplit.length-1){
          if(i==0)
          modifiedDstr=modifiedDstr+lastVal;
          else
          modifiedDstr=modifiedDstr+"."+lastVal;
        }
        else
        modifiedDstr=modifiedDstr+"."+dstrSplit[i];
      }
      // console.log("modified dstr=",modifiedDstr)
      props.setPollingAllowedVal(false);
      
      setCompositeData({
        dstr:modifiedDstr,
        nDays:(nDays-1),
        startDate:historicalData.profilesData[result[startIndex][0]].dateList[0],
        startIndex:result[startIndex][0],
        endIndex:result[lastIndex][result[lastIndex].length-1],
        dstrMap:dstrMap,
        liveData:true,
        liveComposite:true
      })
    
    
      return;
    }else{
      
      setCompositeData({
        dstr:dstr,
        nDays:nDays,
        startDate:historicalData.profilesData[result[startIndex][0]].dateList[0],
        startIndex:result[startIndex][0],
        endIndex:result[lastIndex][result[lastIndex].length-1],
        dstrMap:dstrMap,
        liveData:false,
        liveComposite:false
 
      })
    
    }
    
  }

  const unmergeProfiles=()=>{
    setRightContainerProfilesIndex([])
    if(props.val==0)
      props.resetRightPanel();

    props.handleDirty(true);
    setSelectedProfilesIndex([]);
    setShowContextMenu(false);
    // console.log("unmerge profiles called chartData= ",props.chartData);
    if(!liveDataComposite)
    setChartInteractionAllowed(false);

    props.setPollingAllowedVal(false);
    console.log("unmergeProfiles cahced data and response=",cachedlData,cachedlResponseData);
    let unmergeLiveCompostie=false;
    if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive && selectedProfilesIndex[0]==historicalData.profilesData.length-1){
      console.log("unmerging live profile")
      unmergeLiveCompostie=true;
    }
    if(props.selectedTimeFrame==TIME_FRAME_VALUES.daily){
      console.log("Daily profile unmerge");
      //TODO Handle live unmerging separately
      //if trading session live and live date selected:


      if(cachedlData!=undefined && cachedlData.length>0){
        let removedProfile=historicalData.profilesData.splice(selectedProfilesIndex[0],1);
        let removedResponseData=props.chartData.vaMapList.splice(selectedProfilesIndex[0],1)
        console.log("removed composite profile=",removedProfile,removedResponseData);
        let numProfilesPresent=0;
        let addedProfiles=[];
        let addedResponseData=[];
        let width=0;
        
        for(let i=0;i<removedProfile[0].dateList.length;i++){
          for(let j=0;j<cachedlData.length;j++){
            if(removedProfile[0].dateList[i]==cachedlData[j].dateList[0]){
              // console.log("profile foound at index = ",j,cachedlResponseData,cachedlResponseData[j]);
              if(cachedlData[j].tpo==selectedTPO){
                console.log("selected TPO and cached tpo")
                cachedlData[j].isVolumeProfile=props.viewState.tpo_on_vol||props.viewState.only_vol||props.viewState.vol_tpo_side
                cachedlData[j].isVolumeNumbers=props.profileVolumeNumberVisible
                // cachedlData[j].volumeBasedVA=props.selectedInstrumentData.vp && !props.tpoBasedVA
                // cachedlData[j].tpoBasedVA=!props.selectedInstrumentData.vp || props.tpoBasedVA
                cachedlData[j].vaType=props.tpoBasedVA
                cachedlData[j].showTPO=props.viewState.tpo_only
                cachedlData[j].showOnlyVolumeProfile=props.viewState.only_vol
               
              width=width+cachedlData[j].splitWidth+(cachedlData[j].isVolumeProfile && !cachedlData[j].isTPOonVolume && !cachedlData[j].showOnlyVolumeProfile?cachedlData[j].volumeWidth:0);
              numProfilesPresent=numProfilesPresent+1;
              addedProfiles.push(cachedlData[j]);
              addedResponseData.push(cachedlResponseData[j]);
              break;
              }
              
            }
          }
        }
        if(numProfilesPresent==removedProfile[0].dateList.length){

        console.log("num, width ,added profiles =",numProfilesPresent,width, addedProfiles,addedResponseData); 
        let dateListTemp=addedProfiles.map(o=>o.dateList[0]);
        let dateKeyList=addedProfiles.map(o=>o.dateKeyList);
        let dateListTempResponse=addedResponseData.map(o=>o.dateList[0]);
        console.log("dateListTemp =",dateListTemp,dateKeyList,dateListTempResponse);
      
        //removed the old profiles date and add the new composite porfiles date simultaneously
        historicalData.config.dateList.splice(selectedProfilesIndex[0],1,dateListTemp);
        historicalData.config.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
        props.chartData.dateList.splice(selectedProfilesIndex[0],removedResponseData[0].dateList.length,dateListTempResponse);
        props.chartData.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
        props.chartData.dateList=props.chartData.dateList.flat();
        props.chartData.dateKeyList=props.chartData.dateKeyList.flat();
        const mergedConfig={
          totalWidth:(historicalData.config.totalWidth+width-removedProfile[0].splitWidth - (removedProfile[0].isVolumeProfile && !removedProfile[0].isTPOonVolume && !removedProfile[0].showOnlyVolumeProfile?removedProfile[0].volumeWidth:0)),  
          dateList:(historicalData.config.dateList.flat()),
          dateKeyList:(historicalData.config.dateKeyList.flat()),
          max:historicalData.config.max,
          min:historicalData.config.min,
          lastDataTime:historicalData.config.lastDataTime,
          tpo:selectedTPO
        }
        console.log("Unmerge: Merged composite config = ",mergedConfig);
        
  
      historicalData.profilesData.splice(selectedProfilesIndex[0],0,addedProfiles);
      // console.log("chart data before unmerge= ", props.chartData);
      props.chartData.vaMapList.splice(selectedProfilesIndex[0],0,addedResponseData);
      props.chartData.vaMapList=props.chartData.vaMapList.flat();
      console.log("chart data after unmerge= ", props.chartData);
      const mergedProcessedData= {
        profilesData:(historicalData.profilesData.flat()),
        config:mergedConfig
      }
     setSelectedProfilesIndex([]);
     setHistoricalData(mergedProcessedData);
     setChartInteractionAllowed(true);
     if(unmergeLiveCompostie){
       setLiveComposite(false);
      props.setPollingAllowedVal(true);
     }
     
     if(!liveDataComposite)
     props.setPollingAllowedVal(true);
   

        
}else{
  console.log("Unmerge: all profiles not found in cache=====> ");
  let dateKeyListTemp=[];
  for(let i=0;i<removedProfile[0].dateList.length;i++){
    for(let j=0;j<cachedlData.length;j++){
      if(removedProfile[0].dateList[i]==cachedlData[j].dateList[0]){
        width=width+cachedlData[j].splitWidth+(cachedlData[j].isVolumeProfile && !cachedlData[j].isTPOonVolume && !cachedlData[j].showOnlyVolumeProfile?cachedlData[j].volumeWidth:0);
        numProfilesPresent=numProfilesPresent+1;
        addedProfiles.push(cachedlData[j]);
        dateKeyListTemp.push(cachedlResponseData[j].dateKey)
        addedResponseData.push(cachedlResponseData[j]);
        break;
      }
    }
  }
  console.log("Unmerge: addedResponseData=",addedResponseData);
  const data={
    vaMapList:addedResponseData,
    dateKeyList:dateKeyListTemp

  }
  const processedVPList=parseVPList(data);
  
  const processedData=parseData(data,false,processedVPList,false)
  console.log("Unmerge: processedData= ",processedData);
  
  console.log("num, width ,added profiles =",numProfilesPresent,width, addedProfiles,addedResponseData); 
  let dateListTemp=addedProfiles.map(o=>o.dateList[0]);
  let dateKeyList=addedProfiles.map(o=>o.dateKeyList);
  let dateListTempResponse=addedResponseData.map(o=>o.dateList[0]);
  console.log("dateListTemp =",dateListTemp,dateKeyList,dateListTempResponse);

  //removed the old profiles date and add the new composite porfiles date simultaneously
  historicalData.config.dateList.splice(selectedProfilesIndex[0],1,dateListTemp);
  historicalData.config.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
  props.chartData.dateList.splice(selectedProfilesIndex[0],removedResponseData[0].dateList.length,dateListTempResponse);
  props.chartData.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
  props.chartData.dateList=props.chartData.dateList.flat();
  props.chartData.dateKeyList=props.chartData.dateKeyList.flat();

  const mergedConfig={
    totalWidth:(historicalData.config.totalWidth+processedData.config.totalWidth-removedProfile[0].splitWidth - (removedProfile[0].isVolumeProfile && !removedProfile[0].isTPOonVolume && !removedProfile[0].showOnlyVolumeProfile?removedProfile[0].volumeWidth:0)),  
    dateList:(historicalData.config.dateList.flat()),
    dateKeyList:(historicalData.config.dateKeyList.flat()),
    max:historicalData.config.max,
    min:historicalData.config.min,
    lastDataTime:historicalData.config.lastDataTime,
    tpo:selectedTPO
  }
  console.log("Unmerge: Merged composite config = ",mergedConfig,removedProfile[0]);
  

historicalData.profilesData.splice(selectedProfilesIndex[0],0,processedData.profilesData);
// console.log("chart data before unmerge= ", props.chartData);
props.chartData.vaMapList.splice(selectedProfilesIndex[0],0,addedResponseData);
props.chartData.vaMapList=props.chartData.vaMapList.flat();
console.log("chart data after unmerge= ", props.chartData);
const mergedProcessedData= {
  profilesData:(historicalData.profilesData.flat()),
  config:mergedConfig
}
setSelectedProfilesIndex([]);
setHistoricalData(mergedProcessedData);
setChartInteractionAllowed(true);
if(unmergeLiveCompostie){
 setLiveComposite(false);
props.setPollingAllowedVal(true);
}
if(!liveDataComposite)
     props.setPollingAllowedVal(true);
   
}
        
      }else{
        console.log("cache miss for daily profile");
        let removedProfile=[];
      let removedResponseData=[];
      removedProfile.push(historicalData.profilesData[selectedProfilesIndex[0]])
      removedResponseData.push(props.chartData.vaMapList[selectedProfilesIndex[0]])
        if(unmergeLiveCompostie){
          setLiveCompositeUnmerge(true);
        }
        //retrieve from server
       
        let dstrMap=[];
        dstrMap.push(removedProfile[0].dateList[0]+":"+removedProfile[0].dateList.length);
        setCompositeData({
          dstr:("_"+removedProfile[0].dateList.length),
          // nDays:0,
          nDays:removedProfile[0].dateList.length,
          startDate:removedProfile[0].dateList[0],
          startIndex:selectedProfilesIndex[0],
          endIndex:selectedProfilesIndex[0],
          dstrMap:dstrMap,
          liveComposite:false,
          liveData:false
        })
      }
    }
  else {
      let removedProfile=[];
      let removedResponseData=[];
      removedProfile.push(historicalData.profilesData[selectedProfilesIndex[0]])
      removedResponseData.push(props.chartData.vaMapList[selectedProfilesIndex[0]])

      console.log("removed composite profile weekly=",removedProfile,removedResponseData);
      console.log("Unemrge Weekly:Merged Historical data = ",historicalData);
          
      //find all the profiles with the dstrmap as datekey of removed profiles:
      let numProfilesPresent=0;
      let numProfilesPresentInAPIResponse=0;
      let addedProfiles=[];
      let addedResponseData=[];
      let width=0;
      let dateListTemp=[];
      let dateKeyList=[];
      for(let i=0;i<cachedlData.length;i++){
        if(cachedlData[i].dstrMap==removedProfile[0].dateKeyList){
          if(cachedlData[i].tpo==selectedTPO){
            console.log("found  matching profile");
          //if some other tpo profile for same dstrmap is found before then reset the previous data
          if(numProfilesPresentInAPIResponse>0){
             numProfilesPresent=0;
             numProfilesPresentInAPIResponse=0;
            addedProfiles=[];
            addedResponseData=[];
            width=0;
            dateListTemp=[];
            dateKeyList=[];
           
          }
            
          cachedlData[i].isVolumeProfile=props.viewState.tpo_on_vol||props.viewState.only_vol||props.viewState.vol_tpo_side
          cachedlData[i].isVolumeNumbers=props.profileVolumeNumberVisible
          // cachedlData[i].volumeBasedVA=props.selectedInstrumentData.vp && !props.tpoBasedVA
          // cachedlData[i].tpoBasedVA=!props.selectedInstrumentData.vp || props.tpoBasedVA
          cachedlData[i].vaType=props.tpoBasedVA
          cachedlData[i].showTPO=props.viewState.tpo_only
          cachedlData[i].showOnlyVolumeProfile=props.viewState.only_vol
         
       
          addedProfiles.push(cachedlData[i]);
          addedResponseData.push(cachedlResponseData[i]);
          width=width+cachedlData[i].splitWidth+(cachedlData[i].isVolumeProfile && !cachedlData[i].isTPOonVolume && !cachedlData[i].showOnlyVolumeProfile?cachedlData[i].volumeWidth:0);
          numProfilesPresent=numProfilesPresent+cachedlData[i].dateList.length
         
          if(cachedlData[i].dateList.length>1)
            dateListTemp=[...dateListTemp,cachedlData[i].dateList.length.toString()+"D:"+cachedlData[i].dateList[0].slice(0,5)+"..."+cachedlData[i].dateList[cachedlData[i].dateList.length-1]];
          else
            dateListTemp=[...dateListTemp,cachedlData[i].dateList[0]];
          
            dateKeyList=[...dateKeyList,cachedlData[i].dateKeyList];
          
          if(numProfilesPresent==removedProfile[0].dateList.length)
            break;
        }
        else{
          console.log("found  matching profilewith different tpo cachedResponseData=",cachedlResponseData[i]);
          
          addedProfiles.push(cachedlData[i]);
          addedResponseData.push(cachedlResponseData[i]);
          numProfilesPresentInAPIResponse=numProfilesPresentInAPIResponse+cachedlResponseData[i].dateList.length
          if(cachedlData[i].dateList.length>1)
            dateListTemp=[...dateListTemp,cachedlData[i].dateList.length.toString()+"D:"+cachedlData[i].dateList[0].slice(0,5)+"..."+cachedlData[i].dateList[cachedlData[i].dateList.length-1]];
          else
            dateListTemp=[...dateListTemp,cachedlData[i].dateList[0]];
        
          dateKeyList=[...dateKeyList,cachedlData[i].dateKeyList];
        }
      }
      }
      
        console.log("profiles from cached data=",addedProfiles);
        console.log("Weekly TF dateListTemp =",dateListTemp,dateKeyList);
        if(numProfilesPresent==removedProfile[0].dateList.length){
     
        historicalData.config.dateList.splice(selectedProfilesIndex[0],1,dateListTemp);
        historicalData.config.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);;
        props.chartData.dateList.splice(selectedProfilesIndex[0],removedResponseData[0].dateList.length,dateListTemp);
        props.chartData.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
        props.chartData.dateList=props.chartData.dateList.flat();
        props.chartData.dateKeyList=props.chartData.dateKeyList.flat();

        const mergedConfig={
          totalWidth:(historicalData.config.totalWidth+width-removedProfile[0].splitWidth-(removedProfile[0].isVolumeProfile && !removedProfile[0].isTPOonVolume && !removedProfile[0].showOnlyVolumeProfile?removedProfile[0].volumeWidth:0)),  
          dateList:(historicalData.config.dateList.flat()),
          dateKeyList:(historicalData.config.dateKeyList.flat()),
          max:historicalData.config.max,
          min:historicalData.config.min,
          lastDataTime:historicalData.config.lastDataTime,
          tpo:selectedTPO
        }
        console.log("Merged composite config = ",mergedConfig);
      
        historicalData.profilesData.splice(selectedProfilesIndex[0],1,addedProfiles);
        props.chartData.vaMapList.splice(selectedProfilesIndex[0],1,addedResponseData);
        props.chartData.vaMapList=props.chartData.vaMapList.flat();
        console.log("chart data after unmerge= ", props.chartData);

        const mergedProcessedData= {
          profilesData:(historicalData.profilesData.flat()),
          config:mergedConfig
        }
        setSelectedProfilesIndex([]);
        setHistoricalData(mergedProcessedData);
        setChartInteractionAllowed(true);       
        if(unmergeLiveCompostie){
          setLiveComposite(false);
          props.setPollingAllowedVal(true);
        }
        if(!liveDataComposite)
        props.setPollingAllowedVal(true);
   
        }else if(numProfilesPresentInAPIResponse==removedProfile[0].dateList.length){
          console.log("Unmerge: Weekly tpo different");
          const data={
            vaMapList:addedResponseData,
            dateKeyList:dateKeyList
        
          }
          const processedVPList=parseVPList(data);
          
          const processedData=parseData(data,false,processedVPList,false)
          console.log("Unmerge Weekly: processedData= ",processedData);
         
          historicalData.config.dateList.splice(selectedProfilesIndex[0],1,dateListTemp);
          historicalData.config.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);;
          props.chartData.dateList.splice(selectedProfilesIndex[0],removedResponseData[0].dateList.length,dateListTemp);
          props.chartData.dateKeyList.splice(selectedProfilesIndex[0],1,dateKeyList);
          props.chartData.dateList=props.chartData.dateList.flat();
          props.chartData.dateKeyList=props.chartData.dateKeyList.flat();

            const mergedConfig={
              totalWidth:(historicalData.config.totalWidth+processedData.config.totalWidth-removedProfile[0].splitWidth-(removedProfile[0].isVolumeProfile && !removedProfile[0].isTPOonVolume && !removedProfile[0].showOnlyVolumeProfile?removedProfile[0].volumeWidth:0)),  
              dateList:(historicalData.config.dateList.flat()),
              dateKeyList:(historicalData.config.dateKeyList.flat()),
              max:historicalData.config.max,
              min:historicalData.config.min,
              lastDataTime:historicalData.config.lastDataTime,
              tpo:selectedTPO
            }
            console.log("Unemrge Weekly:Merged composite config = ",mergedConfig);
          
            historicalData.profilesData.splice(selectedProfilesIndex[0],1,processedData.profilesData);
            props.chartData.vaMapList.splice(selectedProfilesIndex[0],1,addedResponseData);
            props.chartData.vaMapList=props.chartData.vaMapList.flat();
            console.log("chart data after unmerge= ", props.chartData);

            const mergedProcessedData= {
              profilesData:(historicalData.profilesData.flat()),
              config:mergedConfig
            }
            console.log("Unemrge Weekly:mergedProcessedData = ",mergedProcessedData);
          
            setSelectedProfilesIndex([]);
            setHistoricalData(mergedProcessedData);
            setChartInteractionAllowed(true);       
            if(unmergeLiveCompostie){
              setLiveComposite(false);
              props.setPollingAllowedVal(true);
            }
            if(!liveDataComposite)
            props.setPollingAllowedVal(true);
   

        }

      else{
        console.log("Profiles not found in cache")
        if(unmergeLiveCompostie){
          setLiveCompositeUnmerge(true);
        }
        //retrieve from server
        if(props.selectedTimeFrame==TIME_FRAME_VALUES.weekly ||props.selectedTimeFrame==TIME_FRAME_VALUES.weekly_series){
        let dstrMap=[];
        dstrMap.push(removedProfile[0].dateList[0]+":"+removedProfile[0].dateList.length);
        setCompositeData({
          dstr:("_"+removedProfile[0].dateList.length),
          nDays:removedProfile[0].dateList.length,
          startDate:removedProfile[0].dateList[0],
          startIndex:selectedProfilesIndex[0],
          endIndex:selectedProfilesIndex[0],
          dstrMap:dstrMap,
          liveComposite:false,
          liveData:false
        })
      }else{
         //time frame higher than weekly. can't unmerge
         setMsgState({open:true,msg:`${TEXT_MSGS.UNMERGE_NOT_ALLOWED}`,severity:"info"});
         setChartInteractionAllowed(true);
         if(!liveDataComposite)
         props.setPollingAllowedVal(true);
         
         return;
      }
    }
    }
  }

  const chartRefresh=()=>{
    console.log("refresh chart is called")
    setRefreshChart(true);
  }

  useEffect(()=>{
    if(refreshChart){
      //get the chart start date, dstr, liveData, and liveDataComposite state
      let liveData=false;
      let liveComposite=false;
      if(historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null){
        liveData=true;
        if(historicalData.profilesData[historicalData.profilesData.length-1].dateList.length>1)
        liveComposite=true;
      }
      //if live composite is present then only refresh call
      // if(liveData==true && liveComposite==true){
        let dstrMap=[];
        dstrMap.push(historicalData.profilesData[historicalData.profilesData.length-1].dateKeyList);
        setCompositeData({
          dstr:((historicalData.profilesData[historicalData.profilesData.length-1].dateList.length-1).toString()),
          nDays:(historicalData.profilesData[historicalData.profilesData.length-1].dateList.length-1),
          startDate:historicalData.profilesData[historicalData.profilesData.length-1].dateList[0],
          startIndex:(historicalData.profilesData.length-1),
          endIndex:(historicalData.profilesData.length-1),
          dstrMap:dstrMap,
          liveData:liveData,
          liveComposite:liveComposite
        })
      // }

      // let sum=0;
      // for(let i=0;i<historicalData.profilesData.length;i++){
      //  if(historicalData.profilesData[i].dateList.length==1){
      //   sum=sum+1;
      //  }else{
      //   if(dstr==""){
      //     if(sum!=0){
      //         dstr=dstr+sum.toString();
      //     }else{
      //       dstr=dstr+historicalData.profilesData[i].dateList.length.toString();
      //     }
      //   }else{
      //     if(sum!=0){
      //       dstr=dstr+sum.toString();
      //   }else{
      //     dstr=dstr+historicalData.profilesData[i].dateList.length.toString();
      //   }
      //   }
      //  }
      // }

    }
  },[refreshChart])

  const unselectProfiles=()=>{
    setShowContextMenu(false);
    setSelectedProfilesIndex([]);
  }
  
  useEffect(()=>{
   
    if(selectedProfilesIndex!=undefined && selectedProfilesIndex.length==0){
      setShowContextMenu(false);
      setToggleRepaint(!toggleRepaint)    
    }
  },[selectedProfilesIndex])

  //unselect profiles from bottom panel button
  useEffect(()=>{
    if(selectedProfilesIndex!=undefined && selectedProfilesIndex.length>0){
      setSelectedProfilesIndex([]);
      console.log("unselect profile mp chart",selectedProfilesIndex.length,selectedProfilesIndex);
      setShowContextMenu(false);
      setToggleRepaint(!toggleRepaint)    
    }
  },[props.unselectProfiles])

  useEffect(()=>{
    if(historicalData!=undefined){
    console.log("view state received in MP chart = ",props.viewState);
    handleViewState(props.viewState);
    }
  },[props.viewState])


  const handleViewState=(state)=>{
    //{tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false}
    console.log("handleViewState Volume profile before width",state,props.viewState,historicalData.config.totalWidth);
    for(let i=0;i<historicalData.profilesData.length;i++){
      let isVolumeVisible=historicalData.profilesData[i].isVolumeProfile;
      let tpoOnVolume=historicalData.profilesData[i].isTPOonVolume;
      let onlyVOl=historicalData.profilesData[i].showOnlyVolumeProfile;
      let showTPO=historicalData.profilesData[i].showTPO;
      
      let isVolumeVisibleNew=state.vol_tpo_side ||state.tpo_on_vol ||state.only_vol;
      let tpoOnVolumeNew=state.tpo_on_vol;
      let onlyVOlNew=state.only_vol;
      let showTPONew=state.tpo_only;

      console.log("handleViewState Volume profile index old new",i,isVolumeVisible,tpoOnVolume,onlyVOl,showTPO,isVolumeVisibleNew,tpoOnVolumeNew,onlyVOlNew,showTPONew);
      if(isVolumeVisible==isVolumeVisibleNew && tpoOnVolume==tpoOnVolumeNew && onlyVOl==onlyVOlNew && showTPO==showTPONew){
       
                                     
      }else{
        console.log("handleViewState Volume profile before width 1",historicalData.config.totalWidth);
        historicalData.profilesData[i].isVolumeProfile=state.vol_tpo_side ||state.tpo_on_vol ||state.only_vol;
        historicalData.profilesData[i].isTPOonVolume=state.tpo_on_vol;
        historicalData.profilesData[i].showOnlyVolumeProfile=state.only_vol;
        historicalData.profilesData[i].showTPO=state.tpo_only;
       
        if(isVolumeVisible && !tpoOnVolume && !onlyVOl && ((state.tpo_on_vol|| state.only_vol ||state.tpo_only)))
        historicalData.config.totalWidth=historicalData.config.totalWidth-historicalData.profilesData[i].volumeWidth;     
        
        else if((showTPO||tpoOnVolume ||onlyVOl) && state.vol_tpo_side)
        historicalData.config.totalWidth=historicalData.config.totalWidth+ historicalData.profilesData[i].volumeWidth;
        console.log("handleViewState Volume profile after width 2",historicalData.config.totalWidth);
      }  
    }
      console.log("handleViewState Volume profile after width",historicalData.config.totalWidth);
   
  
    // setSelectedProfilesIndex([]);                              
    let data = JSON.parse(JSON.stringify(historicalData));
    setHistoricalData(data);
 
  }
  useEffect(()=>{
    if(historicalData!=undefined){
    toggleTPOBasedVA(props.tpoBasedVA,true);
    }
  },[props.tpoBasedVA])

  const showInfoInRightPanel=()=>{
    const isLiveSession=( historicalData.config.lastDataTime!=undefined && historicalData.config.lastDataTime!=null && props.isTradingSessionActive)?true:false;
    console.log("Metadata in mpchart",metaData)
    
    setRightContainerProfilesIndex(selectedProfilesIndex);
    props.setRightContainerData(true,metaData,historicalData,selectedProfilesIndex,isLiveSession);
    setShowContextMenu(false);
  }

  useEffect(()=>{
    console.log("Bookmark data in MP chart=",props.bookmarkData,props?.bookmarkData?.user_email,auth?.email_id);
    setBookmarkData(props.bookmarkData);
    
    if(!props.bookmarkData)
      setHasFullAccess(true);
    else{
      if(props.bookmarkData.category=="AUTO_SAVED"){
        setHasFullAccess(true);
      }else if(props.bookmarkData.user_email==auth.email_id){
        setHasFullAccess(true);
      }else{
        setHasFullAccess(false);
      }

    }
  },[props.bookmarkData])
  //Bookmarks and auto saved related functions

   const autoSaveChart=()=>{
    if(historicalData!=undefined && props.selectedInstrumentData.autosave) {
      let data={};
      if(bookmarkData!=undefined){
        console.log("save bookmark existing bokmark data=",bookmarkData);
        if(bookmarkData.category==BOOKMARK_CATEGORY_AUTOSAVE ){
          if(props.selectedTimeFrame==TIME_FRAME_VALUES.daily) {
            const dstrVal=getChartDstr();
            data={
              "name": props.instrument,
              "id":bookmarkData.id,
              "last_saved":bookmarkData.last_saved,
              "data": {
              "instrument": props.instrument,
                "type": "mp",
                "dstr": dstrVal.dstr,
                "tpo": props.tpo,
                "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
                "tf": props.selectedTimeFrame,
                "glob_vol": props.globalVolumeVisible,
                "glob_num": props.volumeNumberVisible,
                "prof_num": props.profileVolumeNumberVisible,
                "vp_type": props.vpType,
                "va": props.tpoBasedVA
              },

              // "category": "NIFTY"
            }
            setBookmarkAPIData(data);
            console.log("API auto save data for existing bookmark=",data);
            executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
          }else{
            //autosave not allowed for non daily time frame
            console.log("auto save not allowed for non daily profile")
          }
        }else if(bookmarkData.category!=BOOKMARK_CATEGORY_AUTOSAVE ){
          if(!hasFullAccess){
            setMsgState({open:true,msg:TEXT_MSGS.SAVE_EDIT_BOOKMARK_NOT_ALLOWED_FROM_OTHER_USER,severity:"info"});
            return;
          }

            console.log("saving existing bookmark data=")
            const dstrVal=getChartDstr();
            data={
              "name": props.instrument,
              "id":bookmarkData.id,
              "category": bookmarkData.category,
              "last_saved":bookmarkData.last_saved,
              "data": {
              "instrument": props.instrument,
                "type": "mp",
                "dstr": dstrVal.dstr,
                "tpo": props.tpo,
                "startDate":dstrVal.startDate?dstrVal.startDate: historicalDataRef.current.profilesData[0].dateList[0],
                "tf": props.selectedTimeFrame,
                "glob_vol": props.globalVolumeVisible,
                "glob_num": props.volumeNumberVisible,
                "prof_num": props.profileVolumeNumberVisible,
                "vp_type": props.vpType,
                "va": props.tpoBasedVA,
                "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
                "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined)

              },

              
            }
            setBookmarkAPIData(data);
            console.log("API custom bookmark data for existing bookmark=",data);
            executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
         
        }
      }else{
        if(props.selectedTimeFrame==TIME_FRAME_VALUES.daily) {
        //1st auto save 
          const dstrVal=getChartDstr();
          data={
            "name": props.instrument,
            "data": {
            "instrument": props.instrument,
              "type": "mp",
              "dstr": dstrVal.dstr,
              "tpo": props.tpo,
              "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
              "tf": props.selectedTimeFrame,
              "glob_vol": props.globalVolumeVisible,
              "glob_num": props.volumeNumberVisible,
              "prof_num": props.profileVolumeNumberVisible,
              "vp_type": props.vpType,
              "va": props.tpoBasedVA
            },

        
          }
          setBookmarkAPIData(data);
          console.log("API: auto save data for 1st time bookmark=",data);
          executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
        }else{
          console.log("API: auto save data for 1st time bookmark: not allowed for non daily profile")
        }
    }
  }
   }

   const removeIdProperty = (obj) => {
    const { id, ...rest } = obj;
    return rest;
  };


   const saveAsBookamrk=(name,category,copyKRL)=>{
    // let filteredData=undefined
    // if(copyKRL)
    // filteredData=krlData.filter(item=>(item.id && !item.type ))
    // const krlListToCopy = filteredData.map(removeIdProperty);

    console.log("save chart in mp chart ",props.vpType)
    const dstrVal=getChartDstr();
    let data={
      "name": name,
      "user_email":auth.email_id,
      "data": {
        "instrument": props.instrument,
        "type": "mp",
        "dstr": dstrVal.dstr,
        "tpo": props.tpo,
        "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
        "tf": props.selectedTimeFrame,
        "glob_vol": props.globalVolumeVisible,
        "glob_num": props.volumeNumberVisible,
        "prof_num": props.profileVolumeNumberVisible,
        "vp_type": props.vpType,
        "va": props.tpoBasedVA,
        "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
        "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined),
        "init_global_custom_krl":copyKRL?true:undefined,
       
        // "custom_krl_list":copyKRL && krlListToCopy && krlListToCopy.length>0? krlListToCopy:undefined
      },
      // "init_global_custom_krl":copyKRL?true:undefined,
      "category": category,
      
    }
    console.log("data for autosave=",data);
    setBookmarkAPIData(data);
    // executeAPIAutoSave(`${URL.GET_BOOKMARK_DATA}?id=933f2f7ce99a4982b8995dbefb216a70`,"GET",data);
    executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",data);
  }

   useEffect(()=>{
    if(historicalData!=undefined && historicalData.profilesData!=undefined){ 
       if(props.isTradingSessionActive && historicalData.profilesData[historicalData.profilesData.length-1].dateList.length>1){
         setMsgState({open:true,msg:`${TEXT_MSGS.CHART_SAVE_NOT_ALLOWED_LIVE_COMPOSITE}`,severity:"info"});
         return;
       }
       autoSaveChart();
    }
   },[props.saveChart])

   useEffect(()=>{
     if(historicalData!=undefined){
      if(props.isTradingSessionActive && historicalData.profilesData[historicalData.profilesData.length-1].dateList.length>1){
        setMsgState({open:true,msg:`${TEXT_MSGS.BOOKMARK_SAVE_NOT_ALLOWED_LIVE_COMPOSITE}`,severity:"info"});
        return;
      }
         setOpenBookmarkDialog(true);
     }
    
   },[props.saveBookmark])

  const cancelSaveHandler=()=>{
    setOpenBookmarkDialog(false)
  }

  const saveBookmark=(title,category,copyKRL)=>{
    console.log("save bookmark title, category=",title,category,dstr);
    setOpenBookmarkDialog(false);
    saveAsBookamrk(title,category,copyKRL);
  }

  useEffect(() => {
    if(loadedAutoSave){
      console.log("Autosave  loaded=",responseDataAutoSave);
      if(responseDataAutoSave!=null){
            console.log("Autosave success")
            let newData=bookmarkAPIData;
            newData.id=responseDataAutoSave.id;
            newData.category=bookmarkAPIData.category!=undefined?bookmarkAPIData.category:BOOKMARK_CATEGORY_AUTOSAVE;
            newData.last_saved=responseDataAutoSave.last_saved;
            console.log("Autosave success new API new data=",newData);
            setBookmarkData(newData)
            setBookmarkAPIData(newData);
            if(newData.category==BOOKMARK_CATEGORY_AUTOSAVE){
              props.handleDirty(false);
              setMsgState({open:true,msg:`${TEXT_MSGS.CHART_SAVED_SUCCESS}`,severity:"info"});
              let auto_saved_links = JSON.parse(localStorage.getItem('auto_saved_links'));
              if(auto_saved_links[newData.data.instrument]==undefined){
                auto_saved_links[newData.data.instrument]=newData.id
              }
              localStorage.setItem('auto_saved_links', JSON.stringify(auto_saved_links));
              props.updateBookmarkData(newData);
            }else{
              props.handleDirty(false);
              props.updateBookmarkData(newData);
              let bookmark_list=JSON.parse(localStorage.getItem("bookmark_list"));
              if (!bookmark_list.find(e => e.id === newData.id)) {
                bookmark_list.push(newData);
                localStorage.setItem('bookmark_list', JSON.stringify(bookmark_list));
              }
              setMsgState({open:true,msg:`${TEXT_MSGS.BOOKMARK_SAVED_SUCCESS}`,severity:"info"});
              if(props.val==0)
              setSearchParams({"bookmarkID": newData.id})

              executeAPIKRL(URL.GET_KRL_LIST,"POST",{"symbol":newData.data.instrument,"bookmark_id":newData.id})
            }
           

            // setSearchParams({ id: responseDataAutoSave.id });
      }
      else if(errorAutoSave!==null){
        setOpenBookmarkOverwriteDialog(false);
        console.log("Error data=",errorAutoSave);
       if(errorAutoSave?.response?.status === ERROR_CODE_BOOKMARK_ALREADY_MODIFIED){
        setOpenBookmarkOverwriteDialog(true);
       }
       
        else if(errorAutoSave?.response?.status === 401 || errorAutoSave?.response?.status === 403){
          console.log("status received =",errorAutoSave?.response?.status)
          // navigate(from, { replace: true });
          if(props.showLoginPopup){
            // console.log("TEST LOGIN chart container 2")
            props.showLoginPopup(true, errorAutoSave?.response?.data?.message);
          }
        }else{
          setMsgState({open:true,msg:errorAutoSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
      }
      resetAutoSave();
    }
    },[loadedAutoSave,responseDataAutoSave]);


  const getChartDstr=()=>{
    let dstr="";
     let sum=0;
     const length=isTradingSessionActiveRef.current?(historicalDataRef.current.profilesData.length-1):historicalDataRef.current.profilesData.length;
      for(let i=0;i<length;i++){
       if(historicalDataRef.current.profilesData[i].dateList.length==1){
        sum=sum+1;
       }else{
        if(dstr==""){
          if(sum!=0){
              dstr=dstr+"_"+sum.toString();
              dstr=dstr+"."+historicalDataRef.current.profilesData[i].dateList.length.toString();
              sum=0;
          }else{
            
            dstr=historicalDataRef.current.profilesData[i].dateList.length.toString();
          }
        }else{
          if(sum!=0){
            dstr=dstr+"._"+sum.toString();
            dstr=dstr+"."+historicalDataRef.current.profilesData[i].dateList.length.toString();
            sum=0;
        }else{
          dstr=dstr+"."+historicalDataRef.current.profilesData[i].dateList.length.toString();
        }
        }
       }
      }

      if(sum!=0 && dstr!="")
        dstr=dstr+"._"+sum.toString()
      else if(sum!=0 && dstr=="")
        dstr="_"+sum.toString()
  
      console.log("getChartDstr dstr before=",dstr);
      let dstrIndex=-1;
      let isDstrPresent=false;
      let startDate=undefined;
      if(dstrData && dstrData.length>0){
        for(let i=0;i<dstrData.length;i++){
          if(dstrData[i].loaded==false){
            dstrIndex=i;
            isDstrPresent=true;
            dstr=dstrData[i].dstr+"."+dstr;
            startDate=dstrData[i].startDate
            // break;
          }
        }
      }
      console.log("getChartDstr dstr after",dstr,startDate)
      return {
        "dstr":dstr,
        "startDate":startDate
        // "dstr":"_9.2._1",
        // "startDate":"05-10-2023"
      }
  }

  const cancelBookmarkOverwrite=()=>{
    setOpenBookmarkOverwriteDialog(false);
  }

  const overwriteHandler=()=>{
    setOpenBookmarkOverwriteDialog(false);
    if(bookmarkAPIData!=undefined){
      let data=bookmarkAPIData;
      data.overwrite=true;
      executeAPIAutoSave(URL.SAVE_BOOKMARK,"POST",bookmarkAPIData);
      props.handleDirty(false);
    }
   
  }

  useEffect(()=>{
    console.log("props.showTable=",props.showTable);
    if(props.showTable){
      const container= d3.select(`#${props.id}`);
      console.log("props.showTable container=",container);
      d3.select(`#${props.id}`).style("opacity", 0);
    }else{
      d3.select(`#${props.id}`).style("opacity", 1);

    }
  },[props.showTable])

  const exitYAxisMenu=()=>{
    setShowYAxisContextMenu(false);
   }

  const setLowerLevel=(val)=>{
    console.log("Testing1 set lower level=",val)
  }

  const deleteLowerLevel=(val)=>{
    console.log("Testing1 delete lower level=",val)
  }

  const setUpperLevel=(val)=>{
    console.log("Testing1 set upper level=",val)
  }

  const deleteUpperLevel=(val)=>{
    console.log("Testing1 delete upper level=",val)
  }

  const setBoundary=(val)=>{
    console.log("Testing1 set lower level=",val)
    let boundary=[];
    boundary.push(levelData[0]);
    boundary.push(levelData[1]);
    
    if(levelData[0]==-1)
    boundary[0]=parseFloat(val);
    else if(levelData[1]==-1)
    boundary[1]=parseFloat(val);
    else{
      // if(level)
    }

    if(boundary[0]==-1 || boundary[1]==-1)
    setLevelData(boundary.sort());

    //plot chart between level only when both levels are set
    if(boundary[0]!=-1 && boundary[1]!=-1){
      setOpenSetLevelDialog(true);
      // props.setlevelBoundary(boundary.sort());
    }
    exitYAxisMenu();
    

    console.log("Boundary level data=",levelData,boundary);
  }

  const cancelSetLevelHandler=()=>{
    setOpenSetLevelDialog(false);
  }

  const saveLevelHandler=()=>{
    setOpenSetLevelDialog(false);
    let boundary=[];
    boundary.push(levelData[0]);
    boundary.push(levelData[1]);

    if(levelData[0]==-1)
    boundary[0]=parseFloat(priceVal);
    else if(levelData[1]==-1)
    boundary[1]=parseFloat(priceVal);

    props.setlevelBoundary(boundary.sort());
  }

  const cancelDeleteLevelHandler=()=>{
    setOpenDeleteLevelDialog(false);
  }

  const saveDeleteLevelHandler=()=>{
    setOpenDeleteLevelDialog(false);
    let boundary=[];
   
    if(levelData[0]==priceVal){
      boundary.push(-1);
      boundary.push(levelData[1]);
    }else{
      boundary.push(levelData[0]);
      boundary.push(-1);
    }
    props.resetlevelBoundary(boundary.sort());
    setLevelData(boundary.sort());
  }

  useEffect(()=>{
   console.log("Boundary levelData useeffect=",levelData);
   setToggleRepaint(!toggleRepaint);
  //  props.setlevelBoundary(levelData);
  },[levelData])
  
  

  const recenterChart=()=>{
    if(historicalData!=undefined){
      setIsFirstLoad(true);
      setIsRecenterAllowed(true);
      setTransformData({k:1,x:0,y:0});
      setToggleRepaint(!toggleRepaint);
      }
     
  }
  useEffect(()=>{
    if(krlData){
    // let filteredData=krlData.filter(item=>(item.category=="CUSTOM_ANCHORED_VPOC_KRL"|| item.category=="CUSTOM_ANCHORED_TPOC_KRL"|| item.category=="CUSTOM_ANCHORED_VWAP_KRL"||item.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"|| item.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")
    //   && (item.type="custom" || item.type=="bookmark_custom"))
    let filteredData=krlData.filter(item=>((item.category=="CUSTOM_ANCHORED_VPOC_KRL"||item.category=="CUSTOM_ANCHORED_TPOC_KRL" || item.category=="CUSTOM_ANCHORED_VWAP_KRL"||item.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"|| item.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")&& (item.type=="custom" || item.type=="bookmark_custom")))
    
    if(filteredData.length>0)  
      setAnchoredKRLPresent(true);
      else setAnchoredKRLPresent(false);
    }
    console.log("KRL TESTING drawCustomKRLS loadedKRLdata=",krlData)
    setToggleRepaint(!toggleRepaint);
  },[krlData])


  const createKRL=(level)=>{
    setShowContextMenu(false);
    setOpenKRLDialog(true);
  }

  const cancelKRLHandler=()=>{
    setSelectedProfilesIndex([]);
    setOpenKRLDialog(false);

  }

  const saveKRLHandler=(data)=>{
    setSelectedProfilesIndex([]);
    setOpenKRLDialog(false);
    setKRLLoading(true);
    executeAPIKRL(URL.GET_KRL_LIST,"POST",{"symbol":props.instrument,"bookmark_id":bookmarkData && bookmarkData.category!="AUTO_SAVED"?bookmarkData.id:undefined})
  }
  useEffect(() => {
    if(loadedKRL){
      if(responseDataKRL!=null){
            // if(responseDataKRL!=undefined && responseDataKRL[selectedInstrument] && responseDataKRL[selectedInstrument].length>0){
              if(responseDataKRL!=undefined && responseDataKRL[props.instrument]){
                if(props.chartData!=undefined && props.chartData.vaMapList.length>1){
                   let chartStartDate=dayjs(props.chartData.vaMapList[1].dateList[0].split("-").reverse().join("-"))
                   let filteredData=responseDataKRL[props.instrument].filter(item=>(item.end_date=="" || !dayjs(item.end_date.split("-").reverse().join("-")).isBefore(chartStartDate)))
                   console.log("Save KRL filterddata=",filteredData);
                   setKRLData(responseDataKRL[props.instrument]);
                  //  setToggleRepaint(!toggleRepaint);
                  }
            }
            setKRLLoading(false);
           
      }
      else if(errorKRL!==null){
        console.log("Error data=",errorKRL);
        setKRLLoading(false);
        
         
          //if unauthorized then redirec it to login page
          if(errorKRL?.response?.status === 401 || errorKRL?.response?.status === 403){
            // console.log("status received =",errorKRL?.response?.status)
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, errorKRL?.response?.data?.message);
            }
           
          }else 
          setMsgState({open:true,msg:errorKRL?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetKRL();
    }
    },[loadedKRL,responseDataKRL]);

  const editKRL=(data)=>{
    setSelectedKRL(undefined);
    console.log("Save krl edit krl data=",data)
    setShowKRLContextMenu(false);
    setSelectedKRL(data);
    let index=-1;
    for(let i=0;i<historicalData.profilesData.length;i++){
      if(historicalData.profilesData[i].dateList.includes(data.start_date)){
        index=i;
        setKrlProfileData(historicalData.profilesData[i])
        break;
      }
    }
    if(index==-1)
    setKrlProfileData(undefined)
    // const index=historicalData.profilesData.findIndex(data=>data.dateList.includes(data.start_date));
    console.log("Save KRL index in edit=",index);
    setOpenKRLDialog(true);

  }

  const deleteKRL=(data)=>{
    setSelectedKRL(undefined);
    console.log("Save krl Delete krl data=",data)
    setKRLIDDeleted(data);
    setShowKRLContextMenu(false);
    setOpenDeleteKRLDialog(true);
    // setKRLLoading(false);

    // executeAPIDeleteKRL(URL.DELETE_KRL,"POST",{"id":data.id});
    
  }

  const cancelDeleteKRLHandler=()=>{
    setOpenDeleteKRLDialog(false);
  }

  const saveDeleteKRLHandler=()=>{
    setOpenDeleteKRLDialog(false);
    setKRLLoading(false);

    executeAPIDeleteKRL(URL.DELETE_KRL,"POST",{"id":krlIDDeleted.id,"bookmark_id":bookmarkData && bookmarkData.category!="AUTO_SAVED"?bookmarkData.id:undefined});
    
    
  }
  useEffect(() => {
    if(loadedDeleteKRL){
      if(responseDataDeleteKRL!=null){
            let newKRLData=krlData.filter(item=>item.id==undefined||item.id!=krlIDDeleted.id);
            newKRLData=newKRLData.filter(item=>item.base_id!=krlIDDeleted.id);
            setKRLData(newKRLData);
            setKRLLoading(false);
           
      }
      else if(errorDeleteKRL!==null){
        console.log("Error data=",errorKRL);
        setKRLLoading(false);
        
         
          //if unauthorized then redirec it to login page
          if(errorDeleteKRL?.response?.status === 401 || errorDeleteKRL?.response?.status === 403){
            console.log("status received =",errorDeleteKRL?.response?.status)
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, errorDeleteKRL?.response?.data?.message);
            }
           
          }else 
          setMsgState({open:true,msg:errorKRL?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetDeleteKRL();
    }
    },[loadedDeleteKRL,responseDataDeleteKRL]);

    const saveChartState=()=>{
      if(props.saveChartState)
        props.saveChartState(getChartStateData());
    }

    const getChartStateData = () => {
      const dstrVal=getChartDstr();
      let data = {
        "key":props.val,
        "type": CHART_TYPE.MARKET_PROFILE,
        "category": BOOKMARK_CATEGORY_AUTOSAVE,
        "data": {
          "category": BOOKMARK_CATEGORY_AUTOSAVE,
          "data":{
            "category": BOOKMARK_CATEGORY_AUTOSAVE,
            "instrument": selectedInstrumentRef.current,
            "type": "mp",
            "dstr": dstrVal.dstr,
            "tpo": selectedTPORef.current,
            "startDate":dstrVal.startDate?dstrVal.startDate:historicalDataRef.current.profilesData[0].dateList[0],
            "tf": selectedTimeFrameRef.current,
            "glob_vol": globalVolumeVisibleRef.current,
            "glob_num": volumeNumberVisibleRef.current,
            "prof_num": profileVolumeNumberVisibleRef.current,
            "vp_type": vpTypeRef.current,
            "va": tpoBasedVARef.current
          }
        },
      
      }
      return data;
    }
  

    return (
    // <div className="chart-container"  style={{height:`calc(${props.chartStateData.height - 32 - 44}px)`,width:`calc(${props.chartStateData.width})`,marginTop:`calc(${props.chartStateData.top})`,marginLeft:`calc(${props.chartStateData.left})`}} >
    <>
    {true?  
    
    <div id={props.id} className={props.id} style={{height:"100%",width:"100%"}}>
    <div id={"watermark"+props.id} className="watermark">
    <text>{WATERMARK_CONFIG.TEXT}</text>
   
    </div>
   
    <MPFABModal recenterChart={recenterChart}chartStateData={props.chartStateData} boundClassName={props.boundClass} bottomMargin={4} liveComposite={liveDataComposite} chartRefresh={()=>chartRefresh()}></MPFABModal>


    <div id={"tools"+props.id} className="tools">
    {/* <button className="buttonTool" id="zoomin"> + </button>
    <button className="buttonTool" id="zoomout"> - </button>
         */}
        {/* <button className="buttonTool" id={"reset"+props.id}>Reset</button> */}
        
        {/* {selectedProfilesIndex!=undefined && selectedProfilesIndex.length>0?
        <button className="buttonTool" onClick={()=>unselectProfiles()} id="unselect">Unselect Profiles</button>:
        <></> 
        } */}
        
        {/* <button className="buttonTool" id="recenter">Recenter</button> */}

        {/* {liveDataComposite?
        <button className="buttonTool" onClick={()=>chartRefresh()} id={"refresh"+props.id}>Refresh</button>:
        <></>
        }  */}

    </div>
    {showContextMenu?
    <ContextMenu 
      xPosition={anchorPoint.x}
      yPosition={anchorPoint.y} 
      profileData={historicalData.profilesData}
      selectedProfilesIndex={selectedProfilesIndex}
      currentIndex={currentIndex}
      updateDisplayType={updateDisplayType}
      toggleStackedProfiles={toggleStackedProfiles}
      toggleVolumeProfile={toggleVolumeProfile}
      toggleVolumeNumbers={toggleVolumeNumbers}
      selectedInstrumentData={props.selectedInstrumentData}
      mergeProfiles={mergeProfiles}
      unmergeProfile={unmergeProfiles}
      exitMenu={exitMenu}
      showTPOonVolume={showTPOonVolume}
      toggleTPOBasedVA={toggleTPOBasedVA}
      toggleVolumeOnlyProfile={toggleVolumeOnlyProfile}
      showInfoInRightPanel={showInfoInRightPanel}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      createKRL={createKRL}
      isTradingSessionActive={props.isTradingSessionActive }
      level={ctxMenuLevel}
      hasFullAccess={hasFullAccess}
      />:
    <></>}

{showTooltip?
    <Tooltip 
      xPosition={anchorPointTooltip.x}
      yPosition={anchorPointTooltip.y} 
      data={tooltipData}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      />:
    <></>}

{showYAxisContextMenu?
    <YAxisContextMenu 
      xPosition={anchorPointYAxis.x}
      yPosition={anchorPointYAxis.y} 
      value={priceVal}
      key={props.key}
      chartScreenType={props.chartScreenType}
      selectedTimeFrame={props.selectedTimeFrame}
      setLowerLevel={setLowerLevel}
      removeLowerLevel={deleteLowerLevel}
      setUpperLevel={setUpperLevel}
      removeUpperLevel={deleteUpperLevel}
      exitMenu={exitYAxisMenu}
      setBoundary={setBoundary}
      levelData={levelData}
      />:
    <></>}  

    {showKRLContextMenu && hasFullAccess?
    <KRLContextMenu 
      xPosition={anchorPointKRL.x}
      yPosition={anchorPointKRL.y} 
      krlData={selectedKRL}
      exitMenu={exitMenuKRL}
      key={props.key}
      editKRL={editKRL}
      deleteKRL={deleteKRL}
      />:
    <></>}

    {isMoreDataLoading? 
    <div className="loading" >
      Loading additional data...
      </div>:
    <></>}

      {selectedProfilesIndex.length>1?
        <MergeFooter width={canvasWidth} chartStateData={props.chartStateData} unselectAll={unselectProfiles} mergeProfiles={mergeProfiles} selectedIndex={selectedProfilesIndex} profileData={historicalData.profilesData}unmergeProfiles={unmergeProfiles}/>:
        <></>
      }
      {selectedProfilesIndex.length==1 && historicalData.profilesData[selectedProfilesIndex[0]].dateList.length>1?
        <MergeFooter width={canvasWidth} chartStateData={props.chartStateData}   mergeProfiles={mergeProfiles} selectedIndex={selectedProfilesIndex} profileData={historicalData.profilesData}unmergeProfiles={unmergeProfiles}/>:
        <></>
      }
      {compositeLoading?
        <div  style={{textAlign:"center", width:"100%",height:"100%"}}>
          <CircularProgress sx={{marginTop:"20%"}}/>
        </div>:<></>
        }
 <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%"}}}>
            {msg}
          </Alert>  
       </Snackbar>

    {openBookmarkDialog?
     <SaveBookmarkDialog open={true} cancelHandler={cancelSaveHandler} saveHandler={saveBookmark} ></SaveBookmarkDialog>    :
     <></>
    }
    
    {openBookmarkOverwriteDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelBookmarkOverwrite} saveHandler={overwriteHandler} 
        title={TEXT_MSGS.BOOKMARK_OVERWRITTEN_TITLE} description={TEXT_MSGS.BOOKMARK_OVERWRITTEN_DESC}></ConfirmActionDialog>    :
     <></>
    }

    {openKRLDialog && hasFullAccess?
     <SaveKRL open={true} tpo={selectedTPO} bookmarkData={bookmarkData} level={ctxMenuLevel} krlData={selectedKRL} proUser={props.proUser} selectedInstrumentData={props.selectedInstrumentData} instrument={props.instrument} profileData={selectedKRL?krlProfileData: historicalData.profilesData[selectedProfilesIndex]}cancelHandler={cancelKRLHandler} saveHandler={saveKRLHandler} tradeDates={props.tradeDates} ></SaveKRL>    :
     <></>
    }

  {openSetLeveleDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelSetLevelHandler} saveHandler={saveLevelHandler} 
        title={TEXT_MSGS.SET_LEVEL_TITLE} description={TEXT_MSGS.SET_LEVEL_DESC}></ConfirmActionDialog>    :
     <></>
    }

{openDeleteLeveleDialog?
     <ConfirmActionDialog open={true} cancelHandler={cancelDeleteLevelHandler} saveHandler={saveDeleteLevelHandler} 
        title={TEXT_MSGS.DELETE_LEVEL_TITLE} description={TEXT_MSGS.DELETE_LEVEL_DESC}></ConfirmActionDialog>    :
     <></>
    }
    
    {openDeleteKRLDialog && hasFullAccess? 
     <ConfirmActionDialog open={true}  data={krlIDDeleted} cancelHandler={cancelDeleteKRLHandler} saveHandler={saveDeleteKRLHandler} 
        title={TEXT_MSGS.DELETE_KRL_TITLE} description={krlIDDeleted.category=="CUSTOM_KRL"?TEXT_MSGS.DELETE_KRL_DESC+krlIDDeleted.level+"? \n KRL Details: "+krlIDDeleted.description?.split("#T+")[0]+". On: " + krlIDDeleted.start_date:TEXT_MSGS.DELETE_KRL_DESC_ANCHORED+" \n KRL Details: "+krlIDDeleted.description+". Starting: " + krlIDDeleted.start_date}></ConfirmActionDialog>    :
     <></>

    }

    {matches && krlData && anchoredKRLPresent?
    <MPKRLModal boundClassName={props.boundClass} krlData={krlData} editKRL={editKRL} deleteKRL={deleteKRL} hasFullAccess={hasFullAccess}>

    </MPKRLModal>
    :
    <></>
  }


</div>:
<></>
}
{props.showTable?
  <div style={{height:props.chartStateData.height,width:props.chartStateData.width-10}}>

<PRICE_TABLE tableData={priceTableData} locale_string={props.locale_string}></PRICE_TABLE>
</div>:
<></>}
</>
  )
}

export default MPChart
