import * as React from 'react';
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container} from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {useTheme} from "@mui/material";
import { styled } from '@mui/material/styles';
import GridViewIcon from '@mui/icons-material/GridView';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS, VA_TYPE } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dayjs from 'dayjs';


export default function MPChartRightPanelContainer(props) {
  const theme=useTheme();  
  const [value, setValue] = React.useState(0);
  const [screenId, setScreenId] = React.useState(props.chartScreenType);

  React.useEffect(()=>{
    // console.log("Panel container data",props.historicalData.profilesData[props.selectedIndexes[0]],props.metadata,props.metadata.tpovols,props.metadata.tpovols["A"][1]);
    // props.metadata.tpoVols
    // console.log("Testing: props.metadata.intra_vol_stats_tstr=",props.metadata.intra_vol_stats_tstr)
  },[])

  // console.log("locale: " + props.localeString)
 
  React.useEffect(()=>{
    // console.log("Updated: Panel container data",props.historicalData.profilesData[props.selectedIndexes[0]],props.metadata,props.metadata.tpovols,props.metadata.tpovols["A"][1]);
    // props.metadata.tpoVols
  },[props.metadata])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScreenChange = (event, newValue) => {
    if(props.proUser){
      setScreenId(newValue);
      props.setChartScreenType(newValue)
    }else{
      props.openSubscribeModal();
    }
  };

  return (
    <div>
    <Box
   
    sx={{flexGrow: 1, bgcolor: ` ${theme.palette.primaryTheme.shade02}`,borderLeft:'1px solid #ffffff', display: 'flex',flexDirection:"column",
      width:`${RIGHT_PANEL_CONTAINER_WIDTH}px`,
      position:"absolute",
      top:"48px",
      left:`calc(100% - ${RIGHT_PANEL_CONTAINER_WIDTH+50}px)`,
      overflow: "hidden",
      overflowY:'auto',
      height: `calc(100% - 32px)`}}>
      
      <CancelOutlinedIcon
        onClick={()=>props.closeModal()}
        sx={{ml:0,mt:0  ,height:24,width:26,cursor:'pointer',position:"fixed"}}
        />

      {/* show relevant data */}
      {props.historicalData!=undefined && props.selectedIndexes!=undefined && props.selectedIndexes.length>0?
      
      <Box sx={{display:"flex",flexDirection:'column',mt:3}}> 
        {/* {props.selectedIndexes.map((item,index) => ( */}
        {props.selectedIndexes.filter(index => index >=0 && index< props.historicalData.profilesData.length).map((item,index) => (
          <>
          
          {/* // vpoc, vwap, vah and val section */}
          <Box sx={{ml:1,mt:1,mr:1,marginTop:"1px"}}> 
            <Typography sx={{ml:1,fontSize:16}}>
              {props.historicalData.profilesData[item].dateList.length==1?props.historicalData.profilesData[item].dateList[0]:props.historicalData.profilesData[item].dateList.length+"D: "+props.historicalData.profilesData[item].dateList[0]}
            </Typography>
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
            <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"3px",color:CHART_COLORS.VPOC}}></FiberManualRecordIcon>
              {/* <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VPOC
              </Typography> */}
              <Tooltip title="VPOC">
              <Typography sx={{fontSize:14,ml:1}}>
                  {(props.historicalData.profilesData[item].vaType==VA_TYPE.TPO_BASED || props.historicalData.profilesData[item].vaType==VA_TYPE.HYBRID || props.historicalData.profilesData[item].vpoc==undefined)? props.historicalData.profilesData[item].tpoc : props.historicalData.profilesData[item].vpoc}
              </Typography>
              </Tooltip>
              </Box>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"3px",color:CHART_COLORS.VWAP}}></FiberManualRecordIcon>
              {/* <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VWAP
              </Typography> */}
              <Tooltip title="VWAP">
              <Typography sx={{fontSize:14,ml:1}}>
                  {(props.historicalData.profilesData[item].vaType==VA_TYPE.TPO_BASED || props.historicalData.profilesData[item].vwap==undefined)? props.historicalData.profilesData[item].tpo_vwap : props.historicalData.profilesData[item].vwap}
              </Typography>
              </Tooltip>
            </Box>  

            {/* <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.historicalData.profilesData[item].vpoc}
              </Typography>
              </Box>
              <Typography sx={{fontSize:14,ml:1}}>
              {props.historicalData.profilesData[item].vpoc}
              </Typography>
            </Box>  */}

              
            <Box sx={{display:"flex",mt:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.VA_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VAH
              </Typography>
              </Box>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.VA_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  VAL
              </Typography>
            </Box>  

            <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:14,ml:1}}>
                {(props.historicalData.profilesData[item].vaType==VA_TYPE.TPO_BASED || props.historicalData.profilesData[item].vaType==VA_TYPE.HYBRID || props.historicalData.profilesData[item].vah == undefined)?props.historicalData.profilesData[item].tpo_vah:props.historicalData.profilesData[item].vah}
              </Typography>
              </Box>
              <Typography sx={{fontSize:14,ml:1}}>
                {props.historicalData.profilesData[item].vaType==VA_TYPE.TPO_BASED || props.historicalData.profilesData[item].vaType==VA_TYPE.HYBRID || props.historicalData.profilesData[item].val == undefined?props.historicalData.profilesData[item].tpo_val:props.historicalData.profilesData[item].val}
              </Typography>
            </Box> 

            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>


            {/* open, close, high, low section */}
            {props.historicalData.profilesData[item].dateList.length==1?
            <>
            <Box sx={{display:"flex",mt:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              {/* <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.OPEN}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  Open
              </Typography> */}
              <Tooltip title="Open">
              <Typography sx={{fontSize:14,ml:0}}>
                  O:&nbsp;{props.historicalData.profilesData[item].open}
              </Typography>
              </Tooltip>
              </Box>
              {/* <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.CLOSE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  Close
              </Typography> */}
              <Tooltip title="Close">
              <Typography sx={{fontSize:14,ml:0}}>
              C:&nbsp;{props.historicalData.profilesData[item].close}
              </Typography>
              </Tooltip>
            </Box>  

            {/* <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.historicalData.profilesData[item].open}
              </Typography>
              </Box>
              <Typography sx={{fontSize:14,ml:1}}>
              {props.historicalData.profilesData[item].close}
              </Typography>
            </Box>  */}
            </>:<></>}
            <Box sx={{display:"flex",mt:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              {/* <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.HIGH}}/> */}
              {/* <Typography sx={{fontSize:12,marginLeft:"-2px"}}>
              {props.historicalData.profilesData[item].dateList.length==1?"Day High":"Comp. High"}
              </Typography> */}
              <Tooltip title="High">
              <Typography sx={{fontSize:14,ml:0}}>
                  H:&nbsp;{props.historicalData.profilesData[item].highVal}
              </Typography>
              </Tooltip>
              </Box>
              {/* <ArrowDropDownIcon sx={{width:24,height:24,marginTop:"-2px",color:CHART_COLORS.LOW}}/> */}
              {/* <Typography sx={{fontSize:12,marginLeft:"-2px"}}>
              {props.historicalData.profilesData[item].dateList.length==1?"Day Low":"Comp.Low"}
              </Typography> */}
              <Tooltip title="Low">
               <Typography sx={{fontSize:14,ml:0}}>
               L:&nbsp;{props.historicalData.profilesData[item].lowVal}
              </Typography>
              </Tooltip>
            </Box>  

            {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
            <>
              <Box sx={{display:"flex",mt:1}}> 
                <Box sx={{display:"flex",width:"100%"}}>
                  
                {/* <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.HIGH}}/> */}
                {/* <Typography sx={{fontSize:12,marginLeft:"-2px"}}>
                {props.historicalData.profilesData[item].dateList.length==1?"Day High":"Comp. High"}
                </Typography> */}
                <Tooltip title="Change in points (percent)">
                <Typography sx={{fontSize:14,ml:0}}>
                    Change:&nbsp;
                      {props.historicalData.profilesData[item].close>=props.metadata.pclose?
                        <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-4px",marginBottom:"-6px",marginLeft:"-2px",marginRight:"-2px",color:CHART_COLORS.HIGH}}/>:
                        <ArrowDropDownIcon sx={{width:24,height:24,marginTop:"-4px",marginBottom:"-8px",marginLeft:"-2px",color:CHART_COLORS.LOW}}/> }
                    &nbsp;{(props.historicalData.profilesData[item].close-props.metadata.pclose).toFixed(2)}
                    &nbsp;({((props.historicalData.profilesData[item].close-props.metadata.pclose)*100/props.metadata.pclose).toFixed(2)}%)
                </Typography>
                </Tooltip>
                </Box>

              </Box>
              <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            </>:
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            }

            {!props.isBasicUser && props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession 
              && props.historicalData.profilesData[item].dateList.length==1 && props.historicalData.profilesData[item].closingVWAP!=undefined
              && props.historicalData.profilesData[item].closingVWAP > 0?
            <>
              <Box sx={{display:"flex",mt:1}}> 
                <Box sx={{display:"flex",width:"100%"}}>
                  
                {/* <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.HIGH}}/> */}
                {/* <Typography sx={{fontSize:12,marginLeft:"-2px"}}>
                {props.historicalData.profilesData[item].dateList.length==1?"Day High":"Comp. High"}
                </Typography> */}
                <Tooltip title="Avg Closing value. Approximate calculations.">
                <Typography sx={{fontSize:14,ml:0}}>
                    Avg Close (approx):&nbsp;{props.historicalData.profilesData[item].closingVWAP}&nbsp;
                      {/* {props.historicalData.profilesData[item].closing_vwap>=props.metadata.pclose?
                        <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-4px",marginBottom:"-6px",marginLeft:"-2px",marginRight:"-2px",color:CHART_COLORS.HIGH}}/>:
                        <ArrowDropDownIcon sx={{width:24,height:24,marginTop:"-4px",marginBottom:"-8px",marginLeft:"-2px",color:CHART_COLORS.LOW}}/> }
                    &nbsp;{(props.historicalData.profilesData[item].closing_vwap-props.metadata.pclose).toFixed(2)}
                    &nbsp;({((props.historicalData.profilesData[item].closing_vwap-props.metadata.pclose)*100/props.metadata.pclose).toFixed(2)}%) */}
                </Typography>
                </Tooltip>
                </Box>

              </Box>
              <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            </>:
            <></>
            }

            {props.proUser && props.historicalData.profilesData.length-1==item && props.historicalData.profilesData[item].dateList.length==1 && props.metadata!=undefined?
            <>
              <Box sx={{display:"flex",mt:1}}> 
                <Box sx={{display:"flex",width:"50%"}}>
                {/* <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.IB_LINE}}></FiberManualRecordIcon> */}
                <Tooltip title="Gamma Wall High">
                <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                    GWH: {props.metadata?.gres}
                </Typography>
                </Tooltip>
                </Box>
                {/* <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.IB_LINE}}></FiberManualRecordIcon> */}
                <Tooltip title="Gamma Wall Low">
                <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                    GWL: {props.metadata?.gsup}
                </Typography>
                </Tooltip>
              </Box>
              {props.pro2User?
              <Box sx={{display:"flex",mt:1}}> 
                <Box sx={{display:"flex",width:"50%"}}>
                <Tooltip title="Inventory resistance (for active weekly series inventory)">
                <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                    InvH: {props.metadata?.gres_ext}
                </Typography>
                </Tooltip>
                </Box>
                <Tooltip title="Inventory support (for active weekly series inventory)">
                <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                    InvL: {props.metadata?.gsup_ext}
                </Typography>
                </Tooltip>
              </Box>
              :<></>}
              <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            </>:
            <></>
            }

            {/* <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.historicalData.profilesData[item].high}
              </Typography>
              </Box>
              <Typography sx={{fontSize:14,ml:1}}>
              {props.historicalData.profilesData[item].low}
              </Typography>
            </Box>  */}

            {/* <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> */}
            {props.historicalData.profilesData[item].dateList.length==1?
            <>
            <Box sx={{display:"flex",mt:1}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.IB_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  IBH
              </Typography>
              </Box>
              <FiberManualRecordIcon sx={{width:14,height:14,marginTop:"2px",color:CHART_COLORS.IB_LINE}}></FiberManualRecordIcon>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  IBL
              </Typography>
            </Box>  

            <Box sx={{display:"flex"}}> 
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:14,ml:1}}>
                  {props.historicalData.profilesData[item].ib_high}
              </Typography>
              </Box>
              <Typography sx={{fontSize:14,ml:1}}>
              {props.historicalData.profilesData[item].ib_low}
              </Typography>
            </Box> 

            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
            </>
            :<></>}
            {/* {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
            <>
                <Tooltip title={"True Range (Average True Range of past 5 / 14 days)"}>
                <Typography sx={{fontSize:14,ml:0}}>
                TR (ATR 5/14):&nbsp;{props.metadata.tr}&nbsp;({props.metadata.atr5}&nbsp;/&nbsp;{props.metadata.atr14})
                </Typography>
                </Tooltip>
                <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
            </>:
            <></>}
            <Box sx={{display:"flex",mt:1}}> 
              {props.historicalData.profilesData[item].ib!=undefined?
              <Box sx={{display:"flex",width:"50%"}}>
              <Tooltip title="Initial Balance Range (avg per day range in case of Composites)">
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  IB_R
              </Typography>
              </Tooltip>
              </Box>:
              <></>
            }
             {props.historicalData.profilesData[item].range!=undefined?
              <Tooltip title="Day Range (avg per day range in case of Composites)">
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  Day_R
              </Typography>
              </Tooltip>
              :
              <></>}
            </Box>  

            <Box sx={{display:"flex"}}> 
              {props.historicalData.profilesData[item].ib!=undefined?
                <Box sx={{display:"flex",width:"50%"}}>
                <Typography sx={{fontSize:14,ml:0}}>
                    {props.historicalData.profilesData[item].ib}
                </Typography>
                </Box>:
                <></>
              }
              {props.historicalData.profilesData[item].range!=undefined?
                <Typography sx={{fontSize:14,ml:0}}>
                {props.historicalData.profilesData[item].range}
                </Typography>:
                <></>
              }
            </Box> 
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> */}
            {props.historicalData.profilesData[item].dateList.length==1 && props.historicalData.profilesData[item].oi > 0?
            <>
            <Box sx={{display:"flex",mt:1}}> 
              {props.historicalData.profilesData[item].oi!=undefined?
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  OI
              </Typography>
              </Box>:
              <></>
            }
             {props.historicalData.profilesData[item].doi!=undefined?
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  dOI
              </Typography>:
              <></>}
            </Box>  

            <Box sx={{display:"flex"}}> 
              {props.historicalData.profilesData[item].oi!=undefined?
                <Box sx={{display:"flex",width:"50%"}}>
                <Typography sx={{fontSize:14,ml:0}}>
                    {props.historicalData.profilesData[item].oi.toLocaleString(props.localeString)}
                </Typography>
                </Box>:
                <></>
              }
              {props.historicalData.profilesData[item].doi!=undefined?
              <>
               {props.historicalData.profilesData[item].doi>=0?
                <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.HIGH}}/>:
                <ArrowDropDownIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.LOW}}/> }
                
                <Typography sx={{fontSize:14,ml:0}}>
                {props.historicalData.profilesData[item].doi.toLocaleString(props.localeString)}
                </Typography>
                </>:
                <></>
              }
            </Box> 

            <Box sx={{display:"flex",mt:1}}> 
              {props.historicalData.profilesData[item].coi!=undefined?
              <Box sx={{display:"flex",width:"50%"}}>
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  COI
              </Typography>
              </Box>:
              <></>
            }
             {props.historicalData.profilesData[item].dcoi!=undefined?
             <>
            
              <Typography sx={{fontSize:12,marginLeft:"2px"}}>
                  dCOI
              </Typography>
              </>:
              <></>}
            </Box>  

            <Box sx={{display:"flex"}}> 
              {props.historicalData.profilesData[item].coi!=undefined?
                <Box sx={{display:"flex",width:"50%"}}>
                <Typography sx={{fontSize:14,ml:0}}>
                    {props.historicalData.profilesData[item].coi.toLocaleString(props.localeString)}
                </Typography>
              
                </Box>:
                <></>
              }
              {props.historicalData.profilesData[item].dcoi!=undefined?
              <>
               {props.historicalData.profilesData[item].dcoi>=0?
                <ArrowDropUpIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.HIGH}}/>:
                <ArrowDropDownIcon sx={{width:24,height:24,marginTop:"-2px",marginLeft:"-4px",color:CHART_COLORS.LOW}}/> }
                
                <Typography sx={{fontSize:14,ml:0}}>
                {props.historicalData.profilesData[item].dcoi.toLocaleString(props.localeString)}
                </Typography>
                </>:
                <></>
              }
            </Box> 
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            </>:<></>}


            {!props.isBasicUser && props.historicalData.profilesData[item].day_type!=undefined ?
                <>
                <Typography sx={{fontSize:14,ml:0}}>
                  Day Type:&nbsp;{props.historicalData.profilesData[item].day_type}
                </Typography>
                {props.historicalData.profilesData[item].fa_level!=undefined ?
                  <>
                  <Typography sx={{fontSize:14,ml:0}}>
                    FA&nbsp;{props.historicalData.profilesData[item].fa_level == props.historicalData.profilesData[item].highVal ? "at Highs" : "at Lows"}:&nbsp;{props.historicalData.profilesData[item].fa_level}
                  </Typography>
                  </>:
                  <></>
                }
                <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
                </>:
                <></>
              }
            <Tooltip title={"Various Ranges. ATR: Average True Range, IB: Initial Balance Range, Day: Range of profile"}>
              <Typography sx={{fontSize:14,ml:0}}>
                Various Ranges
                {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
                ", ATR 5 / 14"
                :""}:
              </Typography>
            </Tooltip>
            {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
            <>
            <Box sx={{display:"flex",mt:1}}> 
            <Tooltip title="True Range">
              <Box sx={{display:"flex",minWidth:"30px"}}>
              <Typography sx={{fontSize:14,marginLeft:"2px"}}>
                  TR:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,textAlign:"right",minWidth:"20px"}}>
                &nbsp;{props.metadata.tr}&nbsp;/&nbsp;
              </Typography>
              </Box>
              </Tooltip>
              <Tooltip title="Average True Range of past 5 days">
              <Box sx={{display:"flex",minWidth:"40px"}}>
              <Typography sx={{fontSize:14,marginLeft:"2px"}}>
                  5D:
              </Typography>
              <Typography sx={{fontSize:14,ml:0,textAlign:"right",minWidth:"20px"}}>
              &nbsp;{props.metadata.atr5}&nbsp;/&nbsp;
              </Typography>
              </Box>
              </Tooltip>
             
            <Tooltip title="Average True Range of past 14 days">
             <Box sx={{display:"flex",minWidth:"40px"}}>
              <Typography sx={{fontSize:14,marginLeft:"2px"}}>
                  14D:
              </Typography>
              
              <Typography sx={{fontSize:14,ml:0,textAlign:"right"}}>
              &nbsp;{props.metadata.atr14}
              </Typography>
              </Box>
            </Tooltip>
            </Box>
            </>:
            <></>}
            <Box sx={{display:"flex",mt:1}}> 
              {props.historicalData.profilesData[item].ib!=undefined?
              <Box sx={{display:"flex",minWidth:"100px"}}>
              <Tooltip title="Initial Balance Range (avg per day range in case of Composites)">
              <Typography sx={{fontSize:14,marginLeft:"2px"}}>
                  IB:
              </Typography>
              </Tooltip>
              <Typography sx={{fontSize:14,ml:1,textAlign:"right",minWidth:"20px"}}>
                {parseFloat(props.historicalData.profilesData[item].ib).toFixed(1)}
              </Typography>
              </Box>:
              <></>
            }
             {props.historicalData.profilesData[item].range!=undefined?
             <Box sx={{display:"flex",minWidth:"110px"}}>
              <Tooltip title="Day Range (avg per day range in case of Composites)">
              <Typography sx={{fontSize:14,marginLeft:"2px"}}>
                  Day:
              </Typography>
              </Tooltip>
              <Typography sx={{fontSize:14,ml:1,textAlign:"right"}}>
              {parseFloat(props.historicalData.profilesData[item].range).toFixed(1)}
            </Typography>
              </Box>:
              <></>}
            </Box>
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
            {!props.isBasicUser && props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
            <>
            <Box sx={{display:"flex",mt:1,flexDirection:"column"}}> 
              {/* <Tooltip title={"Today's Volumes (Volumes as % past 5 / 14 days average volumes till same time of day)"}>
                <Typography sx={{fontSize:14,ml:0,marginTop:"4px"}}>
                Total Vol % (5/14):&nbsp;{props.metadata.vol5_percent}%&nbsp;/&nbsp;{props.metadata.vol14_percent}%
                </Typography>
              </Tooltip> */}
              <Tooltip title={"Relative Volumes in percent as compared to avg 14 days volumes in same time during prev 5 mins, current 5 mins, 15 mins and 30 mins TimeFrame"}>
              <Typography sx={{fontSize:14,ml:0}}>
                Relative Volumes across TF:
              </Typography>
              </Tooltip>

              {props.metadata.intra_vol_stats_data != undefined?
              <Box sx={{display:"flex",width:"100%"}}>
                <Tooltip title={"Previous 5 Mins"}>
                  <Typography sx={{fontSize:12,marginLeft:"4px",minWidth:"45px",textAlign:"left"}}>
                      {props.metadata.intra_vol_stats_tstr_data[0]}
                  </Typography>
                </Tooltip>
                <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[1]+" minutes remaining Time"}>
                  <Typography sx={{fontSize:12,marginLeft:"0px",minWidth:"45px",textAlign:"right"}}>
                      {props.metadata.intra_vol_stats_tstr_data[1]}
                  </Typography>
                </Tooltip>
                <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[2]+" minutes remaining Time"}>
                  <Typography sx={{fontSize:12,marginLeft:"0px",minWidth:"45px",textAlign:"right"}}>
                      {props.metadata.intra_vol_stats_tstr_data[2]}
                  </Typography>
                </Tooltip>
                <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[3]+" minutes remaining Time / TPO"}>
                  <Typography sx={{fontSize:12,marginLeft:"4px",minWidth:"60px",textAlign:"right"}}>
                      {props.metadata.intra_vol_stats_tstr_data[3]}
                  </Typography>
                </Tooltip>
              </Box>:
              <></>}

              {/* <Tooltip title={"Timeframes: " + props.metadata.intra_vol_stats_title}>
              <Typography sx={{fontSize:14,marginLeft:"2px",marginTop:"4px"}}>
                {props.metadata.intra_vol_stats_data != undefined?
                  <>
                  ({props.metadata.intra_vol_stats_data[0]})&nbsp;{props.metadata.intra_vol_stats_data[1]}&nbsp;/&nbsp;{props.metadata.intra_vol_stats_data[2]}&nbsp;/&nbsp;{props.metadata.intra_vol_stats_data[3]}
                  </>
                  : <></>
                }
              </Typography>
              </Tooltip> */}
              {props.metadata.intra_vol_stats_data != undefined?
              <Box sx={{display:"flex",width:"100%"}}>
                <Tooltip title={"Previous 5 Mins relative volume % as compared with 14D avg"}>
                  <Typography sx={{fontSize:14,marginTop:"4px",marginLeft:"4px",minWidth:"45px",textAlign:"left"}}>
                      {parseInt(props.metadata.intra_vol_stats_data[0])}%
                  </Typography>
                </Tooltip>
                <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[1]+" Mins relative volume % as compared with 14D avg"}>
                  <Typography sx={{fontSize:14,marginTop:"4px",marginLeft:"2px",minWidth:"45px",textAlign:"right"}}>
                      {parseInt(props.metadata.intra_vol_stats_data[1])}%
                  </Typography>
                </Tooltip>
               <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[2]+" Mins relative volume % as compared with 14D avg"}>
                  <Typography sx={{fontSize:14,marginTop:"4px",marginLeft:"2px",minWidth:"45px",textAlign:"right"}}>
                      {parseInt(props.metadata.intra_vol_stats_data[2])}%
                  </Typography>
                </Tooltip>
                <Tooltip title={"Active "+props.metadata.intra_vol_stats_title_data[3]+" Mins relative volume % as compared with 14D avg"}>
                  <Typography sx={{fontSize:14,marginTop:"4px",marginLeft:"2px",minWidth:"60px",textAlign:"right"}}>
                      {parseInt(props.metadata.intra_vol_stats_data[3])}%
                  </Typography>
                </Tooltip>
              </Box>:
              <></>}
            </Box>  
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            </>:
            <></>
            }  
            {props.historicalData.profilesData[item].ib_vol!=undefined?
            <>
             <Box sx={{display:'flex',justifyContent:"column"}}>
                <Tooltip title={"IB Volumes (Avg per day volumes in case of Composites) (% As compared to average IB volumes in 5D / 14D period)"}>
                <Typography sx={{fontSize:14,ml:0,minWidth:"85px"}}>
                IB Volumes:
                </Typography>
                </Tooltip>
               
                <Tooltip title={"IB Volumes (Avg per day volumes in case of Composites) (% As compared to average IB volumes in 5D / 14D period)"}>
                  <Typography sx={{fontSize:14,ml:0,minWidth:"60",textAlign:"right"}}>
                  {props.historicalData.profilesData[item].ib_vol.toLocaleString(props.localeString)}
                  </Typography>
                </Tooltip>
                
                {props.metadata!=undefined && props.metadata.ib_vol_percent!=undefined &&props.historicalData.profilesData.length-1==item && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
                  <>
                    <Tooltip title={"% As compared to average IB volumes in 5D"}>
                      <Typography sx={{fontSize:14,ml:0,minWidth:"40px",textAlign:"right"}}>
                        {props.metadata.ib_vol_percent[0]}%
                      </Typography>
                    </Tooltip>
                    <Tooltip title={"I% As compared to average IB volumes in 14D period"}>
                      <Typography sx={{fontSize:14,ml:0,minWidth:"40px",textAlign:"right"}}>
                       {props.metadata.ib_vol_percent[1]}%
                      </Typography>
                    </Tooltip>
                  </>
                  : 
                  <></>
                }
                
                </Box>
                <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
                </>
                :
                <></>
              }
              
              {props.metadata != undefined && props.metadata.vol5_percent!=undefined && props.historicalData.profilesData[item].volume!=undefined?
              <Box sx={{display:'flex',justifyContent:"row"}}>
                <Tooltip title={"Today's total Volumes  (Avg per day volumes in case of Composites) (Volumes as % past 5 / 14 days average volumes till same time of day)"}>
                  <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"65px"}}>
                    Volumes:&nbsp;&nbsp;
                  </Typography>
                </Tooltip>
               
                  <Tooltip title={"Today's total Volumes (Avg per day volumes in case of Composites)"}>
                    <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"60px",textAlign:"right"}}>
                    {parseInt(props.historicalData.profilesData[item].volume).toLocaleString(props.localeString)}
                    </Typography>
                  </Tooltip>
                  {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1?
                  <>
                  <Tooltip title={"Today's total Volumes (Volumes as % past 5 / 14 days average volumes till same time of day)"}>
                    <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"40px",textAlign:"right"}}>
                    {props.metadata.vol5_percent}%
                    </Typography>
                  </Tooltip>
                  <Tooltip title={"Today's total Volumes (Volumes as % past 5 / 14 days average volumes till same time of day)"}>
                    <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"40px",textAlign:"right"}}>
                    {props.metadata.vol14_percent}%
                    </Typography>
                  </Tooltip>
                  </>
                  : <></>
                  }
                </Box>:
                <>
                <Box sx={{display:'flex',justifyContent:"row"}}>
                  <Tooltip title={"Total Volumes (Avg per day volumes in case of Composites)"}>
                  <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"65px"}}>
                    Volumes:&nbsp;&nbsp;
                  </Typography>
                </Tooltip>
                <Tooltip title={"Total Volumes (Avg per day volumes in case of Composites)"}>
                 <Typography sx={{fontSize:14,ml:0,marginTop:"4px",marginBottom:"2px",minWidth:"60px",textAlign:"right"}}>
                {parseInt(props.historicalData.profilesData[item].volume).toLocaleString(props.localeString)}
                </Typography>
                </Tooltip>
                </Box>
                </>
              }
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider> 
            {props.historicalData.profilesData.length-1==item && props.metadata!=undefined && props.isLiveSession && props.historicalData.profilesData[item].dateList.length==1 && props.historicalData.profilesData[item].tpo_vol_map!=undefined?
              <>
                <Box sx={{display:'flex',flexDirection:"row"}}>
                 <Tooltip title={"TPO"}>
                <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:30}}>
                TPO
                </Typography>
                </Tooltip>
                  <Tooltip title={"TPO [Range]"}>
                <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:40,textAlign:"right"}}>
                Range
                </Typography>
                </Tooltip>
              <Tooltip title={"Volumes"}>  
               <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"55px",textAlign:"right"}}>
                  Volume
                </Typography> 
              </Tooltip>
              <Tooltip title={"5D Avg Volumes %"}>  
                <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"40px",textAlign:"right"}}>
                 5D%
                </Typography>
                </Tooltip>
                <Tooltip title={"14D Avg Volumes %"}>  
                <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"40px",textAlign:"right"}}>
                    14D%
                </Typography>
                </Tooltip>
                </Box>
              {Object.keys(props.historicalData.profilesData[item].tpo_vol_map).map((key, index) => ( 
              <Box sx={{display:'flex',flexDirection:"row"}}>
                 <Tooltip title={key + " TPO"}>
                <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:20}}>
                {key}
                </Typography>
                </Tooltip>
                  <Tooltip title={key + "TPO [Range]"}>
                <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:40,textAlign:"right"}}>
                [{Math.floor(props.historicalData.profilesData[item].tpoVaList[index].high-props.historicalData.profilesData[item].tpoVaList[index].low)}]
                </Typography>
                </Tooltip>
              <Tooltip title={key + ": Volumes"}>  
               <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"65px",textAlign:"right"}} key={index}>  {props.historicalData.profilesData[item].tpo_vol_map[key].toLocaleString(props.localeString)}</Typography> 
              </Tooltip>
              <Tooltip title={key + ": 5D Avg Volumes %"}>  
                <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"40px",textAlign:"right"}} key={key}>
                  {props.metadata.tpovols[key] != undefined? <>
                &nbsp;{props.metadata.tpovols[key].length > 1 ? ((props.historicalData.profilesData[item].tpo_vol_map[key]/props.metadata.tpovols[key][0])*100).toFixed(0) + "%" : ""}
                </> : <></>}
                </Typography>
                </Tooltip>
                <Tooltip title={key + ": 14D Avg Volumes %"}>  
                <Typography sx={{fontSize:14,marginLeft:"0px",minWidth:"40px",textAlign:"right"}} key={key}>
                  {props.metadata.tpovols[key] != undefined? <>
                &nbsp;{props.metadata.tpovols[key].length > 1 ?((props.historicalData.profilesData[item].tpo_vol_map[key]/props.metadata.tpovols[key][1])*100).toFixed(0)+"%" : ""}
                </> : <></>}
                </Typography>
                </Tooltip>
                </Box>
            ))}
            <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
            </>:
            <>
            {props.historicalData.profilesData[item].dateList.length==1 
                    || ((props.historicalData.profilesData[item].selected_tf == "daily" 
                        || props.historicalData.profilesData[item].selected_tf == "weekly" 
                        || props.historicalData.profilesData[item].selected_tf == "weekly_s")
                        && props.historicalData.profilesData[item].ptype == "d")
                  ? 
                  <>
                    {props.historicalData.profilesData[item].tpo_vol_map!=undefined?
                      <>
                      <Box sx={{display:'flex',flexDirection:"row"}}>
                        <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:"25px"}}>TPO</Typography>
                        {props.historicalData.profilesData[item].dateList.length>1 ? 
                          <></>
                          :
                          <Typography sx={{fontSize:14,marginLeft:"5px",minWidth:"50px"}}>[Range]</Typography>
                        }
                        <Typography sx={{fontSize:14,marginLeft:"10px",minWidth:"80px"}}>{props.historicalData.profilesData[item].dateList.length>1 ? "Avg Volumes" : "Volumes"}</Typography> 
                      </Box>
                      {Object.keys(props.historicalData.profilesData[item].tpo_vol_map).map((key, index) => ( 
                        <Box sx={{display:'flex',flexDirection:"row"}}>
                          <Tooltip title={props.historicalData.profilesData[item].dateList.length==1 ? key + " TPO" : key + " TPO"}>  
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"25px"}} key={index}>
                                {key}
                          </Typography> 
                          </Tooltip>

                          {props.historicalData.profilesData[item].dateList.length==1 ?
                          <Tooltip title={props.historicalData.profilesData[item].dateList.length==1 ? key + " TPO [Range]" : key + " TPO [Range]"}>  
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"40px",textAlign:"right"}} key={index}>
                              [{Math.floor(props.historicalData.profilesData[item].tpoVaList[index].high-props.historicalData.profilesData[item].tpoVaList[index].low)}]
                          </Typography> 
                          </Tooltip>
                          : <></>
                          }

                          <Tooltip title={props.historicalData.profilesData[item].dateList.length==1 ? key + " TPO Volumes" : key + " TPO - Per TPO Average volumes in composite"}>  
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"80px",textAlign:"right"}} key={index}>
                               {parseInt(props.historicalData.profilesData[item].tpo_vol_map[key]).toLocaleString(props.localeString)}
                          </Typography> 
                          </Tooltip>
                        </Box>
                        ))}
                        <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
                      </>
                      : 
                      <></>
                    }
                  </>
                  :
                  <>
                  {/* {console.log(props.historicalData.profilesData[item].tpoVaList)}
                  {console.log(props.historicalData.profilesData[item])}
                  {console.log(props)} */}
                    {props.historicalData.profilesData[item].tpo_vol_map!=undefined && props.historicalData.profilesData[item].ptype == "m"?
                      <>
                      <Typography sx={{fontSize:14,marginLeft:"2px"}}>Day wise Volumes per TPO:</Typography> 
                      <Box sx={{display:'flex',flexDirection:"row"}}>
                        <Typography sx={{fontSize:14,marginLeft:"2px",minWidth:"14px"}}></Typography>
                        <Typography sx={{fontSize:14,marginLeft:"5px",minWidth:"50px"}}>[Range]</Typography>
                        <Typography sx={{fontSize:14,marginLeft:"10px",minWidth:"80px"}}>Volumes</Typography>
                        <Typography sx={{fontSize:14,marginLeft:"10px",minWidth:"60px"}}>Date</Typography>
                      </Box>
                      {Object.keys(props.historicalData.profilesData[item].tpo_vol_map).map((key, index) => ( 
                        <Box sx={{display:'flex',flexDirection:"row"}}>
                          <Tooltip title={key + ": " + props.historicalData.profilesData[item].dateList[index]}>
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"16px"}} key={index}>
                                {key}
                          </Typography>
                          </Tooltip>
                          <Tooltip title={key + ": " + props.historicalData.profilesData[item].dateList[index] + ": Day Range"}>
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"30px",textAlign:"right"}} key={index}>
                               [{Math.floor(props.historicalData.profilesData[item].tpoVaList[index].high-props.historicalData.profilesData[item].tpoVaList[index].low)}]
                          </Typography>
                          </Tooltip>
                          <Tooltip title={key + ": " + props.historicalData.profilesData[item].dateList[index] + ": Volumes"}>
                          <Typography sx={{fontSize:14,marginLeft:"4px",minWidth:"90px",textAlign:"right"}} key={index}>
                                {parseInt(props.historicalData.profilesData[item].tpo_vol_map[key]).toLocaleString(props.localeString)}
                               
                          </Typography>
                          </Tooltip>
                          <Tooltip title={key + ": " + props.historicalData.profilesData[item].dateList[index]}>
                          <Typography sx={{fontSize:12,marginLeft:"0px",minWidth:"60px",textAlign:"right"}} key={index}>
                               ({dayjs(props.historicalData.profilesData[item].dateList[index],"DD-MM-YYYY").format("D.M.YY")})
                          </Typography>
                          </Tooltip>
                        </Box>
                        ))}
                        <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
                        </>
                        :
                        <></>
                      }
                  </>
            }

            </>}

            
              
          </Box>
          
          </>            
        ))}
      </Box>
      :
      <></>
      }
      
    </Box>
    </div>
  );
}
