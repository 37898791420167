import {
  Alert,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
// import LineChartHeader from './components/line_chart_header';
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import { useLocation, useNavigate,useSearchParams } from "react-router-dom"
import { useAxiosWithAuth } from "../../common/api/hooks/useAxiosWithAuth"
import { URL } from "../../common/api/urls"
import useAuth from "../../common/hooks/useAuth"
import {
  CHART_RELOAD_TIME,
  MIN_CHART_WIDTH,
  MIN_SNAKE_CHART_WIDTH,
  SNACKBAR_AUTO_HIDE_DURATION,
  TEXT_MSGS,
  USER_ROLE_FIELDS,
} from "../../common/utility/constant"
import { useQuery } from "../../common/utility/helperFunc"
import { UserSettingsContext } from "../../setup/routes-manager/RequireAuth"
import LineChartFooter from "./components/LineChartFooter"
import FutSpotOptLineChartContainer from "./components/futspotopt_line_chart_container"
import FuturesLineChartContainer from "./components/futures_line_chart_container"
import FuturesStockChartContainer from "./components/futures_stock_chart_container"
import OptLineChartContainer from "./components/opt_line_chart_container"
import OptSnakesLineChartContainer from "./components/opt_snakes_chart_container"
import LoginModal from './components/login_popups';
import { CHART_TYPE } from "../../common/utility/mp_chart_constants"

const LineChart = (props) => {
  const [cancel, responseData, error, loaded, reset, executeAPI] =
    useAxiosWithAuth()
  const [
    cancelStatus,
    responseDataStatus,
    errorStatus,
    loadedStatus,
    resetStatus,
    executeAPIStatus,
  ] = useAxiosWithAuth()
  const [
    cancelUIData,
    responseDataUIData,
    errorUIData,
    loadedUIData,
    resetUIData,
    executeAPIUIData,
  ] = useAxiosWithAuth()
  
  const { userSettings, setUserSettings } = useContext(UserSettingsContext)
  const navigate = useNavigate()
  const from = "/login"
  const { auth } = useAuth()
  const [expiryList, setExpiryList] = useState()
  const [activeStrikes, setActiveStrikes] = useState()
  const [selectedStrikeList, setSelectedStrikeList] = useState([])
  const [liveDataDelayMsgShown, setLiveDataDelayMsgShown] = useState(false)
  const [uiDataResponse, setUIDataResponse] = useState()
  // const [selectedInstrumentData, setSelectedInstrumentData] = React.useState()
  const [selectedInstrument, setSelectedInstrument] = React.useState(props.selectedInstrument)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  // const [instrumentsData, setInstrumentsData] = useState()
 
  // const [options, setOptions] = useState()

  const [state, setState] = useState({
    isLoading: true,
    chartData: undefined,
    selected_strikes: undefined,
    isIncrementalData: false,
    lastLoadTime: -1,
    isInstrumentLive: false,
    reloadInerval: CHART_RELOAD_TIME.LC_DATA,
    isPollingAllowed: false,
    plotParams: new Map(),
    chartLoaded: false,
    isStrikesPollingAllowed: false,
    strikesReloadInterval: CHART_RELOAD_TIME.STRIKES_RELOAD_TIME,
    isError: false,
    isTradingSessionActive: false,
    isStatusCheckAllowed: false,
    statusCheckPollingInterval: CHART_RELOAD_TIME.POLLING_TIME,
  })

  const {
    isLoading,
    chartData,
    selected_strikes,
    isIncrementalData,
    isInstrumentLive,
    lastLoadTime,
    reloadInerval,
    plotParams,
    isPollingAllowed,
    chartLoaded,
    isStrikesPollingAllowed,
    strikesReloadInterval,
    isError,
    isTradingSessionActive,
    isStatusCheckAllowed,
    statusCheckPollingInterval,
  } = state

  const type = useQuery("type")
  const tempSymbol = useQuery("symbol")
  // const symbol = (props.symbol?props.symbol:"NIFTY");
  const symbol = props.symbol
    ? props.symbol
    : tempSymbol && props.val==0 && window.location.pathname.includes("/line-chart")
    ? tempSymbol
    : "NIFTY"
  const startDate = useQuery("startDate")
  const expiry = useQuery("expiry")
  const q_nDays = useQuery("nDays")
  const nDays = q_nDays == undefined ? props.nDays : q_nDays
  const premType = useQuery("prem") // default type of synthetic premium, else "norm" premium for spot based
  const plot = useQuery("plot")
  const gf = useQuery("gf") // yellow lines
  const gr = useQuery("gr") // cyan lines
  const gm = useQuery("gm") // cyan lines
  const oiPlot = useQuery("oiPlot") // oi vs doi
  const doix = useQuery("doix")
  const tb = useQuery("tb") // trend bias
  const trp = useQuery("trp") // trend push
  const fvol = useQuery("fvol") // futures vol and oi
  const selectedStrikes = useQuery("selectedStrikes") // _ separated values
  const midStrike = useQuery("midStrike") // should be ignored if selectedStrikes is set
  const jump = useQuery("jump")

  // let isOpt = false;

  // var isIncrementalDataFetched = false;
  const [initialData,setInitialData]=useState(props?.initialData?.data??undefined)
  
  // const [queryParams, setQueryParams] = useState({
    const [queryParams, setQueryParams] = useState((props.initialData && props?.initialData?.type==CHART_TYPE.LINE_CHART)?props?.initialData?.data:{
 
    type: type ? type : "SINGLE",
    symbol: symbol ? symbol : "NIFTY",
    startDate: startDate ? startDate : "",
    expiry: expiry ? expiry : "",
    // nDays: nDays ? nDays : 0,
    nDays: userSettings.data.default_lc_ndays,
    prem: premType ? premType : "",
    plot: plot
      ? plot
      : symbol != undefined && symbol.startsWith("O_")
      ? "opt"
      : "fut",
    gf: gf ? gf === "1" : false,
    gr: gr ? gr === "1" : false,
    gm: gm ? gm === "1" : false,
    doix: doix ? doix === "1" : false,
    tb: tb ? tb === "1" : false,
    trp: trp ? trp === "1" : false,
    fvol: fvol ? fvol === "0" : true,
    oiPlot: oiPlot ? oiPlot : "",
    selectedStrikes: selectedStrikes ? selectedStrikes : "",
    midStrike: midStrike ? midStrike : "",
    jump: jump ? jump : "",
  })
  const [paramsLoaded, setParamsLoaded] = useState(false)

  const [isOpt, setIsOpt] = useState(queryParams.symbol.startsWith("O_"))
  const [isOtherFut, setIsOtherFut] = useState(
    queryParams.plot === "all_doi" || queryParams.plot === "all_doix" || queryParams.plot === "all_vol" || queryParams.plot === "all_volx" || queryParams.plot === "all_dvolx"
      ? true
      : false
  )

  console.log("queryparams in stock chart, loaded =  ", queryParams, loaded)
  // if(symbol != undefined)
  //   isOpt = symbol.startsWith("O_")

  console.log("isOpt = ", isOpt)

  //state ref is reuired to keep track of lastLoadTime value in the state.
  const stateRef = useRef(state)
  const queryParamsRef = useRef(queryParams)
  const location = useLocation()
  const { search } = useLocation()

  const [msgState, setMsgState] = useState({
    open: false,
    msg: "",
    severity: "info",
  })

  /**
   * autoclosing of the snackbar msg bar
   */
  const handleClose = (event, reason) => {
    setMsgState({ ...msgState, open: false })
  }

  const { open, msg, severity } = msgState

  //execute the data fetch on the component mount
  useEffect(() => {
    if (queryParams.type == "SNAKES") {
      loadStrikesData()
    }
    // },[]);
  }, [queryParams.type, queryParams.symbol])

  const loadStrikesData = (forceReload = false) => {
    if (expiryList == undefined || forceReload == true) {
      let data = {
        symbol: "NIFTY",
        instruments: 1,
        snakechart_data: 1,
      }
      executeAPIUIData(URL.MPCHART_UIDATA, "POST", data)
    } else {
      console.log(uiDataResponse)
      if (queryParams.symbol == "FINNIFTY")
        setExpiryList(uiDataResponse.active_fnf_expiries)
      else if (queryParams.symbol == "MIDCPNIFTY")
        setExpiryList(uiDataResponse.active_mcpn_expiries)
      else if (queryParams.symbol == "BANKNIFTY")
        setExpiryList(uiDataResponse.active_bnf_expiries)
      else setExpiryList(uiDataResponse.active_expiries)
    }
  }

  useEffect(() => {
    if (symbol) document.title = "Line Charts - " + symbol.replace("O_", "")
    // var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    // document.head.appendChild(link);
    // var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
    // meta.setAttribute('name', 'description');
    // meta.setAttribute('content', 'Market Profile charts. Futures and Options - individual, straddle, strangle. Nifty. BankNifty. FinNifty.');
    // document.head.appendChild(meta);
  }, [])

  /**
   * API response handler
   */
  useEffect(() => {
    if (loadedUIData) {
      if (responseDataUIData != null) {
        console.log("MPCHart UI Data response=", responseDataUIData)
        console.log(queryParams.symbol)
        setUIDataResponse(responseDataUIData)
        if (queryParams.symbol == "FINNIFTY")
          setExpiryList(responseDataUIData.active_fnf_expiries)
        else if (queryParams.symbol == "MIDCPNIFTY")
          setExpiryList(responseDataUIData.active_mcpn_expiries)
        else if (queryParams.symbol == "BANKNIFTY")
          setExpiryList(responseDataUIData.active_bnf_expiries)
        else setExpiryList(responseDataUIData.active_expiries)
        // console.log(queryParamsRef.current)
        setActiveStrikes(responseDataUIData.active_strikes)
        if (selectedStrikeList && selectedStrikeList.length > 0) {
          const result = selectedStrikeList.every((element) =>
            responseDataUIData.active_strikes[queryParamsRef.current.symbol]
              .map(String)
              .includes(element)
          )
          console.log(
            "Result Strikes=",
            result,
            selectedStrikeList,
            responseDataUIData.active_strikes[queryParamsRef.current.symbol]
          )

          //in case few strikes is not present in the updated strikes list, remove and reoload the chart.
          if (!result) {
            const newStrikes = selectedStrikeList.filter((strike) =>
              responseDataUIData.active_strikes[queryParamsRef.current.symbol]
                .map(String)
                .includes(strike)
            )
            console.log("new strikes=", newStrikes)
            handleSelectedStrikesChange(newStrikes)
          }
        }
      } else if (errorUIData !== null) {
        console.log("Error data=", errorUIData)
        // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if (
          errorUIData?.response?.status === 401 ||
          errorUIData?.response?.status === 403
        ) {
          console.log("status received =", errorUIData?.response?.status)
          navigate(from, { replace: true })
        }
      }
      resetUIData()
    }
  }, [loadedUIData, responseDataUIData])

  useEffect(() => {
    console.log("Location changed======>", location, symbol, props.stateData)
    // ReloadChart(location.search);
    //load the chart with url change only when it is already present for the previous instrument
    // if (lastLoadTime == -1 || location.pathname.includes("market-profile"))
    //   return

    // loadChartStateData()
  }, [location])

  // useEffect(() => {
  //   // console.log("useEffect is called for props.stateData Line chart=",props.stateData)
  //   console.log("searchparams test line chart outside=",props.stateData,symbol,location)
  //   if (props.stateData==undefined && props.val==0 && location.pathname.includes("line-chart")) {
  //     console.log("searchparams test line chart =",props.stateData,symbol)
  //     setSearchParams({"symbol":symbol})
  //   }
  //   loadChartStateData()
  // }, [props.stateData])

  useEffect(() => {
    // console.log("useEffect is called for props.stateData Line chart=",props.stateData)
    console.log("LCREload statedata line chart new timeframe searchparams test line chart outside=",props.stateData,symbol,location)
    if (props.stateData==undefined && props.val==0 && location.pathname.includes("line-chart")) {
      console.log("searchparams test line chart =",props.stateData,symbol)
      setSearchParams({"symbol":symbol})
    }
    if(initialData==undefined){
      loadChartStateData()
    }else{
      // loadChartStateData()
      // console.log("IssueLC initiataldata=",initialData)
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isIncrementalData: false,
        isPollingAllowed: false,
        isStrikesPollingAllowed: false,
      }))
      let paramObject=JSON.parse(JSON.stringify(initialData))
      console.log("IssueLC initiataldata and paramobject=",initialData,paramObject,paramObject?.selectedTimeFrame)
      if (paramObject.symbol != undefined)
        setIsOpt(paramObject.symbol.startsWith("O_"))

      if (paramObject.plot === "all_doi" || paramObject.plot === "all_doix" || paramObject.plot === "all_vol" || paramObject.plot === "all_volx" ||  paramObject.plot === "all_dvolx")
        setIsOtherFut(true)
      else setIsOtherFut(false)

      setParamsLoaded(true)
      console.log("Line charts queryparams 2")
      console.log("Reload queryparams set 3=");
      setQueryParams(paramObject)
     
      setInitialData(undefined)
      
    }
    
  }, [props.stateData,props.toggleState])

  const loadChartStateData = () => {
    setLiveDataDelayMsgShown(false)
    if (props.stateData != undefined) {
      if (props.stateData?.title) document.title = props.stateData.title

      console.log("props.state data======>", props.stateData)
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isIncrementalData: false,
        isPollingAllowed: false,
        isStrikesPollingAllowed: false,
      }))
      let isOpt = props.stateData.data.symbol.startsWith("O_")

      const paramObject = {
        type: props.stateData.data.type ? props.stateData.data.type : "SINGLE",
        symbol: props.stateData.data.symbol
          ? props.stateData.data.symbol
          : "NIFTY",
        startDate: props.stateData.data.startDate
          ? props.stateData.data.startDate
          : "",
        expiry: props.stateData.data.expiry ? props.stateData.data.expiry : "",
        // nDays:  props.stateData.data.nDays ?  props.stateData.data.nDays : 0,
        nDays: userSettings.data.default_lc_ndays,
        prem: props.stateData.data.prem ? props.stateData.data.prem : "",
        plot: props.stateData.data.plot
          ? props.stateData.data.plot
          : isOpt
          ? "opt"
          : "fut",
        gf: props.stateData.data.gf ? props.stateData.data.gf === "1" : false,
        gr: props.stateData.data.gr ? props.stateData.data.gr === "1" : false,
        gm: props.stateData.data.gm ? props.stateData.data.gm === "1" : false,
        doix: props.stateData.data.doix
          ? props.stateData.data.doix === "1"
          : false,
        tb: props.stateData.data.tb ? props.stateData.data.tb === "1" : false,
        trp: props.stateData.data.trp
          ? props.stateData.data.trp === "1"
          : false,
        fvol: props.stateData.data.fvol
          ? props.stateData.data.fvol === "1"
          : false,
        oiPlot: props.stateData.data.oiPlot ? props.stateData.data.oiPlot : "",
        selectedStrikes: props.stateData.data.selectedStrikes
          ? props.stateData.data.selectedStrikes
          : "",
        midStrike: props.stateData.data.midStrike
          ? props.stateData.data.midStrike
          : "",
        jump: props.stateData.data.jump ? props.stateData.data.jump : "",
      }
      console.log("query params statedata=======", paramObject, queryParams)
      if (paramObject.symbol != undefined)
        setIsOpt(paramObject.symbol.startsWith("O_"))

      if (paramObject.plot === "all_doi" || paramObject.plot === "all_doix" || paramObject.plot === "all_vol" || paramObject.plot === "all_volx" || paramObject.plot === "all_dvolx")
        setIsOtherFut(true)
      else setIsOtherFut(false)

      setParamsLoaded(true)
      console.log("Line charts queryparams 1")
      setQueryParams(paramObject)
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isIncrementalData: false,
        isPollingAllowed: false,
        isStrikesPollingAllowed: false,
      }))
      const paramObject = {
        type: type ? type : "SINGLE",
        symbol: symbol ? symbol : "NIFTY",
        startDate: startDate ? startDate : "",
        expiry: expiry ? expiry : "",
        nDays: 1,
        // nDays: userSettings.data.default_lc_ndays,
        prem: premType ? premType : "",
        plot: plot
          ? plot
          : symbol != undefined && symbol.startsWith("O_")
          ? "opt"
          : "fut",
        gf: gf ? gf === "1" : false,
        gr: gr ? gr === "1" : false,
        gm: gm ? gm === "1" : false,
        doix: doix ? doix === "1" : false,
        tb: tb ? tb === "1" : false,
        trp: trp ? trp === "1" : false,
        fvol: fvol ? fvol === "0" : true,
        oiPlot: oiPlot ? oiPlot : "",
        selectedStrikes: selectedStrikes ? selectedStrikes : "",
        midStrike: midStrike ? midStrike : "",
        jump: jump ? jump : "",
      }
      if (paramObject.symbol != undefined)
        setIsOpt(paramObject.symbol.startsWith("O_"))

      if (paramObject.plot === "all_doi" || paramObject.plot === "all_doix" || paramObject.plot === "all_vol" || paramObject.plot === "all_volx" || paramObject.plot === "all_dvolx")
        setIsOtherFut(true)
      else setIsOtherFut(false)

      setParamsLoaded(true)
      console.log("Line charts queryparams 2")
      setQueryParams(paramObject)
    }
  }

  const saveChartState=()=>{
    if(props.saveChartState)
      props.saveChartState(getChartStateData());
  }
  const getChartStateData = () => {
   
    let data = {
      "key":props.val,
      "type": CHART_TYPE.LINE_CHART,
      "data":queryParamsRef.current, 
    }
    return data;
  }
  //strikes polling in case of SNAKES chart
  useEffect(() => {
    //set the ref to current state
    stateRef.current = state

    console.log(
      "Test use effect called strikes =",
      stateRef.current,
      strikesReloadInterval,
      stateRef.current.isStrikesPollingAllowed,
      queryParamsRef.current.type
    )

    const timeout = setInterval(() => {
      if (
        stateRef.current.isInstrumentLive &&
        stateRef.current.isStrikesPollingAllowed &&
        queryParamsRef.current.type === "SNAKES"
      ) {
        // console.log("Loading strikes")
        loadStrikesData(true)
      }
    }, strikesReloadInterval)

    return () => {
      // just to clear the timeout when component unmounts
      clearInterval(timeout)
    }
  }, [state])

  useEffect(() => {
    //set the ref to current state
    stateRef.current = state

    console.log("Test use effect called=", stateRef.current, reloadInerval)

    const timeout = setInterval(() => {
      if (
        stateRef.current.isInstrumentLive &&
        stateRef.current.isPollingAllowed
      ) {
        if (queryParamsRef.current.type === "SNAKES")
          executeAPI(
            URL.LINE_CHART_DATA +
              `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&incremental=1&midStrike=${queryParamsRef.current.midStrike}&jump=${queryParamsRef.current.jump}&selectedStrikes=${queryParamsRef.current.selectedStrikes}&lastLoad=${stateRef.current.lastLoadTime}`,
            "GET",
            {}
          )
        else
          executeAPI(
            URL.LINE_CHART_DATA +
              `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&doix=${queryParamsRef.current.doix}&incremental=1&lastLoad=${stateRef.current.lastLoadTime}`,
            "GET",
            {}
          )
      } else {
        console.log("instrument not live = ", stateRef.current.isInstrumentLive)
      }
    }, reloadInerval)

    //market status polling
    const timeoutStatusCheck = setInterval(() => {
      if (
        stateRef.current.isStatusCheckAllowed &&
        !stateRef.current.isInstrumentLive
      ) {
        executeAPIStatus(URL.CHART_STATUS, "POST", {
          symbol: queryParamsRef.current.symbol,
        })
      }
    }, stateRef.current.statusCheckPollingInterval)

    return () => {
      // just to clear the timeout when component unmounts
      clearInterval(timeout)
      clearInterval(timeoutStatusCheck)
    }
  }, [state])

  /**
   * Market live status check response handler
   */
  useEffect(() => {
    if (loadedStatus) {
      if (responseDataStatus != null) {
        //if instrument is live then load the live data else set the status as per the response

        if (
          responseDataStatus.isInstrumentLive != undefined &&
          responseDataStatus.isInstrumentLive
        ) {
          if (queryParamsRef.current.type === "SNAKES")
            executeAPI(
              URL.LINE_CHART_DATA +
                `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&incremental=1&midStrike=${queryParamsRef.current.midStrike}&jump=${queryParamsRef.current.jump}&selectedStrikes=${queryParamsRef.current.selectedStrikes}&lastLoad=${stateRef.current.lastLoadTime}`,
              "GET",
              {}
            )
          else
            executeAPI(
              URL.LINE_CHART_DATA +
                `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&doix=${queryParamsRef.current.doix}&incremental=1&lastLoad=${stateRef.current.lastLoadTime}`,
              "GET",
              {}
            )
        } else {
          //reload the chart once tradinng session goes from active to inactive
          if (
            isPollingAllowed &&
            isTradingSessionActive &&
            !responseDataStatus.isTradingSessionActive
          ) {
            setState((prevState) => ({
              ...prevState,
              isLoading: true,
              isIncrementalData: false,
              isPollingAllowed: false,
              isStrikesPollingAllowed: false,
            }))

            if (queryParamsRef.current.type === "SNAKES")
              executeAPI(
                URL.LINE_CHART_DATA +
                  `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&incremental=1&midStrike=${queryParamsRef.current.midStrike}&jump=${queryParamsRef.current.jump}&selectedStrikes=${queryParamsRef.current.selectedStrikes}&lastLoad=${stateRef.current.lastLoadTime}`,
                "GET",
                {}
              )
            else
              executeAPI(
                URL.LINE_CHART_DATA +
                  `?type=${queryParamsRef.current.type}&symbol=${queryParamsRef.current.symbol}&startDate=${queryParamsRef.current.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParamsRef.current.nDays}&plot=${queryParamsRef.current.plot}&doix=${queryParamsRef.current.doix}&incremental=1&lastLoad=${stateRef.current.lastLoadTime}`,
                "GET",
                {}
              )
          } else
            setState((prevState) => ({
              ...prevState,
              isStatusCheckAllowed: !responseDataStatus.isInstrumentLive,
              statusCheckPollingInterval:
                responseDataStatus.isTradingSessionActive
                  ? CHART_RELOAD_TIME.FAST_POLLING
                  : CHART_RELOAD_TIME.POLLING_TIME,
              isTradingSessionActive: responseDataStatus.isTradingSessionActive,
              isInstrumentLive: responseDataStatus.isInstrumentLive,
            }))
        }
      } else if (errorStatus !== null) {
        console.log("Error data status check=", errorStatus)

        // setIndexState((prevState) => ({
        //   ...prevState,
        //   indexLoaded:true}));

        if (
          errorStatus?.response?.status === 401 ||
          errorStatus?.response?.status === 403
        ) {
          console.log("status received =", errorStatus?.response?.status)
          // navigate(from, { replace: true })
          setLoginMsg(errorStatus?.response?.data?.message);
          setShowLoginPopup(true);
        }
      }
      resetStatus()
    }
  }, [loadedStatus, responseDataStatus])

  //execute the data fetch on the component mount
  useEffect(() => {
    // executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:"NIFTY"});
    queryParamsRef.current = queryParams
    console.log("queryparams changed=", queryParams)

    //prevent any api call before the state is laoded properly
    if (!paramsLoaded) return

    //executeAPI(URL.LINE_CHART_DATA+"?type=SINGLE&symbol=NIFTY&startDate=&nDays=0","GET",{});
    console.log("Line charts api called")
    if (queryParams.type === "SNAKES")
      executeAPI(
        URL.LINE_CHART_DATA +
          `?type=${queryParams.type}&symbol=${queryParams.symbol}&startDate=${queryParams.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParams.nDays}&plot=${queryParams.plot}&midStrike=${queryParams.midStrike}&jump=${queryParams.jump}&selectedStrikes=${queryParams.selectedStrikes}`,
        "GET",
        {}
      )
    else
      executeAPI(
        URL.LINE_CHART_DATA +
          `?type=${queryParams.type}&symbol=${queryParams.symbol}&startDate=${queryParams.startDate}&expiry=${queryParamsRef.current.expiry}&nDays=${queryParams.nDays}&plot=${queryParams.plot}&doix=${queryParams.doix}`,
        "GET",
        {}
      )
      saveChartState();
  }, [queryParams])

  const handlenDaysChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      //  isLoading: true,
      chartLoaded: false,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
    console.log("Line charts queryparams 3")
    setQueryParams((prevState) => ({
      ...prevState,
      nDays: val,
    }))
  }

  const handleStartDateChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      // isLoading: true,
      chartLoaded: false,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
    console.log("Line charts queryparams 4")
    setQueryParams((prevState) => ({
      ...prevState,
      startDate: val,
    }))
  }

  const changePlotType = (val) => {
    setState((prevState) => ({
      ...prevState,
      // isLoading: true,
      chartLoaded: false,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
    console.log("Line charts queryparams 5")
    setQueryParams((prevState) => ({
      ...prevState,
      plot: val,
    }))
  }

  const handleExpiryChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      // isLoading: true,
      chartLoaded: false,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
    console.log("Line charts queryparams 6")
    setQueryParams((prevState) => ({
      ...prevState,
      expiry: val,
    }))
  }

  const handleSelectedStrikesChange = (data) => {
    setState((prevState) => ({
      ...prevState,
      chartLoaded: false,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
    setSelectedStrikeList(data)
    let newStrikes = ""

    if (data.length == 1) {
      newStrikes = data[0]
    } else {
      newStrikes = data.join("_")
    }
    console.log("newStrikes=", newStrikes)
    console.log("Line charts queryparams 7")
    setQueryParams((prevState) => ({
      ...prevState,
      selectedStrikes: newStrikes,
    }))
  }

  function toggleFullScreen() {
    let chartdiv = document.querySelector("#" + props.id)
    console.log("chartdiv", props.id, chartdiv)
    if (!document.fullscreenElement) {
      chartdiv.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  /**
   * API response handler
   */
  useEffect(() => {
    console.log("Testing000")
    if (loaded) {
      // console.log(2)
      if (responseData != null) {
        console.log(
          "^^isInstrumentLive, isIncremental ",
          responseData.isInstrumentLive,
          isIncrementalData
        )

        plotParams.set("displayInstr", queryParams.symbol)
        plotParams.set("expiry", expiry + " Expiry")
        
        console.log("Testing000 1", queryParams.symbol,isOpt,queryParams.plot)
        if (isOpt) {
          plotParams.set("plot", "opt")
          plotParams.set("prem", "doi")
          plotParams.set("displayInstr", queryParams.symbol.replace("O_", ""))
        } else {
          plotParams.set("plot", queryParams.plot)
          plotParams.set("prem", queryParams.prem)
        }
        plotParams.set("gf", queryParams.gf)
        plotParams.set("gr", queryParams.gr)
        plotParams.set("gm", queryParams.gm)
        plotParams.set("doix", queryParams.doix)
        plotParams.set("tb", queryParams.tb)
        plotParams.set("trp", queryParams.trp)
        plotParams.set("oiPlot", queryParams.oiPlot)
        plotParams.set("selectedStrikes", queryParams.selectedStrikes)
        plotParams.set("midStrike", queryParams.midStrike)
        plotParams.set("jump", queryParams.jump)
        if (queryParams.plot === "spt") {
          plotParams.set("fvol", false)
          plotParams.set("chartPlotType", "Spot Spectrum")
        } else if (
          isOpt ||
          queryParams.plot === "oi" ||
          queryParams.plot === "iv_wcash" ||
          queryParams.plot === "pair_price" ||
          queryParams.plot === "synth_fut"
        ) {
          // plotParams.set("fvol", false);
          if (queryParams.plot === "oi")
            plotParams.set("chartPlotType", "Options OI Snakes")
          else if (queryParams.plot === "iv_wcash")
            plotParams.set("chartPlotType", "Options IV Snakes and WCash")
          else if (queryParams.plot === "pair_price")
            plotParams.set("chartPlotType", "Options Straddle Snakes")
          else if (queryParams.plot === "synth_fut")
            plotParams.set(
              "chartPlotType",
              "Options Synthetic Futures wrt Spot"
            )
          else plotParams.set("chartPlotType", "Options")
        } else {
          plotParams.set("fvol", queryParams.fvol)
          if(plotParams.doix || queryParams.doix)
            plotParams.set("chartPlotType", "Futures Spectrum")
          else
            plotParams.set("chartPlotType", "Futures")
        }
        console.log("plotParams = ", plotParams)
        // console.log("chart_data = ", responseData.chart_data);

        if (queryParams.type === "SNAKES") {
          if (
            responseData.chart_data != undefined &&
            responseData.selected_strikes != undefined
          ) {
            let strikes = responseData.selected_strikes.split("_")
            setSelectedStrikeList(strikes)
          }
        }

        if (
          responseData.chart_data !== undefined &&
          responseData.chart_data?.length > 0
        ) {
          // let jsonData = some_processing(responseData.chart_data)
          if (!isIncrementalData)
            setState((prevState) => ({
              ...prevState,
              isPollingAllowed: true,
              isStrikesPollingAllowed: true,
              chartLoaded: true,
              isError: false,
              isTradingSessionActive: responseData.isTradingSessionActive,
              isStatusCheckAllowed: !responseData.isInstrumentLive,
              statusCheckPollingInterval: responseData.isTradingSessionActive
                ? CHART_RELOAD_TIME.FAST_POLLING
                : CHART_RELOAD_TIME.POLLING_TIME,
              isLoading: false,
              isIncrementalData: true,
              selected_strikes: responseData.selected_strikes,
              chartData: responseData.chart_data,
              isInstrumentLive: responseData.isInstrumentLive,
              lastLoadTime: responseData.last_ts,
              plotParams: plotParams,
            }))
          else {
            setState((prevState) => ({
              ...prevState,
              chartLoaded: true,
              isStrikesPollingAllowed: true,
              isError: false,
              isTradingSessionActive: responseData.isTradingSessionActive,
              isStatusCheckAllowed: !responseData.isInstrumentLive,
              statusCheckPollingInterval: responseData.isTradingSessionActive
                ? CHART_RELOAD_TIME.FAST_POLLING
                : CHART_RELOAD_TIME.POLLING_TIME,
              isLoading: false,
              isIncrementalData: responseData.isInstrumentLive,
              selected_strikes: responseData.selected_strikes,
              chartData: responseData.chart_data,
              isInstrumentLive: responseData.isInstrumentLive,
              lastLoadTime: responseData.last_ts,
              plotParams: plotParams,
            }))
          }
          if (
            !liveDataDelayMsgShown &&
            responseData.isInstrumentLive &&
            responseData.liveData &&
            responseData.chart_data[
              responseData.chart_data.length - 1
            ].time.indexOf(responseData.liveData) == -1
          ) {
            setMsgState({
              open: true,
              msg: TEXT_MSGS.LINE_CHART_LIVE_DATA_DELAY,
              severity: "info",
            })
            setLiveDataDelayMsgShown(true)
          }
        } else if (
          responseData.chart_data !== undefined &&
          responseData.chart_data?.length == 0
        ) {
          //show no data msg only if it a incremental data
          if (!isIncrementalData)
            setMsgState({
              open: true,
              msg: TEXT_MSGS.CHART_DATA_NOT_AVAILABLE,
              severity: "info",
            })

          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            chartLoaded: true,
            isPollingAllowed: responseData.isInstrumentLive,
            isStrikesPollingAllowed: responseData.isInstrumentLive,
            isTradingSessionActive: responseData.isTradingSessionActive,
            isStatusCheckAllowed: !responseData.isInstrumentLive,
            statusCheckPollingInterval: responseData.isTradingSessionActive
              ? CHART_RELOAD_TIME.FAST_POLLING
              : CHART_RELOAD_TIME.POLLING_TIME,
            isInstrumentLive: responseData.isInstrumentLive,
            isError: !isIncrementalData,
          }))

          if (
            !liveDataDelayMsgShown &&
            responseData.isInstrumentLive &&
            responseData.liveData &&
            responseData.chart_data[
              responseData.chart_data.length - 1
            ].time.indexOf(responseData.liveData) == -1
          ) {
            setMsgState({
              open: true,
              msg: TEXT_MSGS.LINE_CHART_LIVE_DATA_DELAY,
              severity: "info",
            })
            setLiveDataDelayMsgShown(true)
          }
        } else if (responseData.chart_data == undefined) {
          setMsgState({
            open: true,
            msg: TEXT_MSGS.CHART_DATA_NOT_AVAILABLE,
            severity: "info",
          })
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            chartLoaded: true,
            isPollingAllowed: responseData.isInstrumentLive,
            isStrikesPollingAllowed: responseData.isInstrumentLive,
            isTradingSessionActive: responseData.isTradingSessionActive,
            isStatusCheckAllowed: !responseData.isInstrumentLive,
            statusCheckPollingInterval: responseData.isTradingSessionActive
              ? CHART_RELOAD_TIME.FAST_POLLING
              : CHART_RELOAD_TIME.POLLING_TIME,
            isInstrumentLive: responseData.isInstrumentLive,
            isError: !isIncrementalData,
          }))
        }
      } else if (error !== null) {
        console.log("Line chart Error data=", error,isIncrementalData)
        if (!isIncrementalData){
          setMsgState({
            open: true,
            msg:
              error?.response?.data?.message ??
              `${TEXT_MSGS.NETWORK_ERROR_MSG}`,
            severity: "info",
          })
        }else{
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            console.log("status received =", error?.response?.status)
            // navigate(from, { replace: true })
            setLoginMsg(error?.response?.data?.message);
            setShowLoginPopup(true);
          }
        }

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          chartLoaded: true,
          isError: !isIncrementalData,
        }))
      }
      reset()
    }
  }, [loaded, responseData])

  // console.log([(queryParams.type == "SINGLE"), queryParams.type, chartData])

  // useEffect(() => {
  //   let data = {
  //     symbol: "NIFTY",
  //     instruments: 1,
  //     tpoList: 1,
  //     additional_instruments: [props.symbol],
  //     dateList: 1,
  //   }
  //   executeAPIUiData(URL.MPCHART_UIDATA, "POST", data)
  // }, [])

  // /**
  //  * API response handler
  //  */
  // useEffect(() => {
  //   if (loadedUiData) {
  //     if (responseDataUiData != null) {
  //       responseDataUiData.instruments = responseDataUiData.instruments.filter(
  //         (e) => e.line_chart === true
  //       )
  //       let index = 0
  //       if (symbol != undefined && symbol != "") {
  //         index = responseDataUiData.instruments.findIndex(
  //           (item) => item.instr === symbol
  //         )
  //         if (index == -1) index = 0
  //       } else if (
  //         userSettings != undefined &&
  //         userSettings.data != undefined
  //       ) {
  //         index = responseDataUiData.instruments.findIndex(
  //           (item) => item.name === userSettings.data.default_instrument
  //         )
  //         if (index == -1) index = 0
  //       }
  //       // setOptions(responseDataUiData.instruments)
  //       setInstrumentsData(responseDataUiData.instruments) // all data

  //       setSelectedInstrument(responseDataUiData.instruments[index].instr) // only name of selected data
  //       setSelectedInstrumentData(responseDataUiData.instruments[index]) // current selected all data

  //       // setTradeDates(responseData.tradeDates);
  //       // props.instrumentChange(
  //       //   responseData.instruments[index],
  //       //   responseData.tpo_groups[responseData.instruments[index].tpo_group]
  //       //     ? responseData.tpo_groups[responseData.instruments[index].tpo_group]
  //       //     : responseData.tpo_groups["DEFAULT"],
  //       //   tempTPO,
  //       //   responseData.tradeDates
  //       // );
  //       // setInstrumentLoaded(true);
  //     } else if (error !== null) {
  //       console.log("Error data=", error)
  //       reset()
  //     }
  //   }
  // }, [loadedUiData, responseDataUiData])

  const instrumentChange = (data) => {
    setSelectedInstrument(data.instr)
    if(props.instrumentChanged)
      props.instrumentChanged(data.instr);
    
    let isOpt=data.instr.startsWith("O_")
    setIsOpt(isOpt);
  
    // setSelectedInstrumentData(data)
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isIncrementalData: false,
      isPollingAllowed: false,
      isStrikesPollingAllowed: false,
    }))
   
    setQueryParams({
      ...queryParamsRef.current,
      symbol:data.instr,
      plot:isOpt?"opt":"fut"
    })
   
    if (props.stateDate==undefined && props.val==0 && location.pathname.includes("line-chart")) {
      console.log("searchparams test line chart instrument change =",props.stateData,symbol,location)
      setSearchParams({"symbol":data.instr})
    }
    // loadChartStateData();
  }

  if (queryParams.type === "SINGLE") {
    console.log("single isIncrementalData", isIncrementalData)
    if (isOpt) {
      return (
        <>
          {/* <LineChartHeader /> */}
          {isLoading ? (
            <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
              <CircularProgress sx={{ marginTop: "20%" }} />
            </div>
          ) : (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "100%",
                  minWidth: `${MIN_CHART_WIDTH}`,
                  backgroundColor: "#111111",
                }}
              >
                <Container sx={{ width: "80%", mt: 2 }}>
                  <Typography
                    variant="h4"
                    color="primary"
                    sx={{ alignSelf: "center" }}
                  >
                    {plotParams.get("displayInstr")}{" "}
                    {plotParams.get("chartPlotType")} Line Chart
                  </Typography>
                </Container>
                <Tooltip title="Full Screen">
                  <IconButton
                    onClick={() => toggleFullScreen()}
                    sx={{ mr: 4, mt: 1 }}
                    size="large"
                  >
                    <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              {chartLoaded ? (
                <OptLineChartContainer
                  id={props.id}
                  chartData={chartData}
                  symbol={symbol}
                  isIncrementalData={isIncrementalData}
                  plotParams={plotParams}
                  nDays={queryParams.nDays}
                  changenDays={handlenDaysChange}
                  startDate={queryParams.startDate}
                  handleStartDateChange={handleStartDateChange}
                  selectedInstrument={queryParamsRef.current.symbol}
                  selectedInstrumentData={props.selectedInstrumentData}
                  instrumentsData={props.instrumentsData}
                  instrumentChange={instrumentChange}
                  params={queryParamsRef.current}
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#111111",
                  }}
                >
                  <CircularProgress sx={{ marginTop: "20%" }} />
                </div>
              )}
            </>
          )}
          <Snackbar
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ top: "48px" }}
            open={open}
            autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
          >
            <Alert
              severity={severity}
              sx={{ width: { mobile: "80%", tablet: "70%", laptop: "40%" } }}
            >
              {msg}
            </Alert>
          </Snackbar>
          {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
        </>
      )
    } else {
      if (isOtherFut)
        return (
          <>
            {/* <LineChartHeader /> */}
            {isLoading ? (
              <div
                style={{ textAlign: "center", width: "100%", height: "100%" }}
              >
                <CircularProgress sx={{ marginTop: "20%" }} />
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                    minWidth: `${MIN_CHART_WIDTH}`,
                    backgroundColor: "#111111",
                  }}
                >
                  <Container sx={{ width: "80%", mt: 2 }}>
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ alignSelf: "center" }}
                    >
                      {plotParams.get("displayInstr")} All Options{" "}
                      {plotParams.get("plot") == "all_doi"
                        ? "OI Change"
                        : plotParams.get("plot") == "all_doix"
                        ? "NTM OI Change"
                        : plotParams.get("plot") == "all_vol"
                        ? "Volumes"
                        : plotParams.get("plot") == "all_dvolx"
                        ? "NTM Volumes"
                        : plotParams.get("plot") == "all_volx"
                        ? "NTM Volx"
                        : ""}{" "}
                      Chart
                    </Typography>
                  </Container>
                  <Tooltip title="Full Screen">
                    <IconButton
                      onClick={() => toggleFullScreen()}
                      sx={{ mr: 4, mt: 1 }}
                      size="large"
                    >
                      <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {chartLoaded ? (
                  <FutSpotOptLineChartContainer
                    id={props.id}
                    chartData={chartData}
                    symbol={symbol}
                    isIncrementalData={isIncrementalData}
                    plotParams={plotParams}
                    nDays={queryParams.nDays}
                    changenDays={handlenDaysChange}
                    startDate={queryParams.startDate}
                    handleStartDateChange={handleStartDateChange}
                  />
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#111111",
                    }}
                  >
                    <CircularProgress sx={{ marginTop: "20%" }} />
                  </div>
                )}
              </>
            )}
            <Snackbar
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{ top: "48px" }}
              open={open}
              autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
            >
              <Alert
                severity={severity}
                sx={{ width: { mobile: "80%", tablet: "70%", laptop: "40%" } }}
              >
                {msg}
              </Alert>
            </Snackbar>
            {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
          </>
        )
      else
        return (
          <>
            {/*  */}
            {/* <LineChartHeader /> */}
            {isLoading ? (
              <div
                style={{ textAlign: "center", width: "100%", height: "100%" }}
              >
                <CircularProgress sx={{ marginTop: "20%" }} />
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    width: "100%",
                    minWidth: `${MIN_CHART_WIDTH}`,
                    backgroundColor: "#111111",
                  }}
                >
                  <Container sx={{ width: "80%", mt: 2 }}>
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ alignSelf: "center" }}
                    >
                      {plotParams.get("displayInstr")}{" "}
                      {plotParams.get("chartPlotType")} Chart
                    </Typography>
                  </Container>
                  <Tooltip title="Full Screen">
                    <IconButton
                      onClick={() => toggleFullScreen()}
                      sx={{ mr: 4, mt: 1 }}
                      size="large"
                    >
                      <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {chartLoaded ? (
                  // Default Main
                  <>
                    <FuturesLineChartContainer
                      id={props.id}
                      chartData={chartData}
                      symbol={symbol}
                      isIncrementalData={isIncrementalData}
                      plotParams={plotParams}
                      nDays={queryParams.nDays}
                      changenDays={handlenDaysChange}
                      startDate={queryParams.startDate}
                      handleStartDateChange={handleStartDateChange}
                      // selectedInstrument={selectedInstrument}
                      selectedInstrument={queryParamsRef.current.symbol}
                      selectedInstrumentData={props.selectedInstrumentData}
                      instrumentsData={props.instrumentsData}
                      instrumentChange={instrumentChange}
                      params={queryParamsRef.current}
                    />
                    {/* <h1>Line chart Footers</h1> */}
                  </>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#111111",
                    }}
                  >
                    <CircularProgress sx={{ marginTop: "20%" }} />
                  </div>
                )}
              </>
            )}
            <Snackbar
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{ top: "48px" }}
              open={open}
              autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
            >
              <Alert
                severity={severity}
                sx={{ width: { mobile: "80%", tablet: "70%", laptop: "40%" } }}
              >
                {msg}
              </Alert>
            </Snackbar>
            {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
          </>
        )
    }
  } else if (queryParams.type === "SNAKES") {
    console.log("snakes charts")
    return (
      <>
        {/* <LineChartHeader /> */}
        {isLoading ? (
          <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
            <CircularProgress sx={{ marginTop: "20%" }} />
          </div>
        ) : (
          <>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
                minWidth: `${MIN_SNAKE_CHART_WIDTH}`,
                backgroundColor: "#111111",
              }}
            >
              <Container sx={{ width: "80%", mt: 2 }}>
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ alignSelf: "center" }}
                >
                  {plotParams.get("displayInstr")}{" "}
                  {plotParams.get("chartPlotType")} Line Chart{" "}
                  {queryParams.expiry}
                </Typography>
              </Container>
              <Tooltip title="Full Screen">
                <IconButton
                  onClick={() => toggleFullScreen()}
                  sx={{ mr: 4, mt: 1 }}
                  size="large"
                >
                  <FullscreenIcon sx={{ width: "36px", height: "36px" }} />
                </IconButton>
              </Tooltip>
            </Box>
            {chartLoaded ? (
              <OptSnakesLineChartContainer
                id={props.id}
                isError={isError}
                chartData={chartData}
                symbol={queryParams.symbol}
                isIncrementalData={isIncrementalData}
                plotParams={plotParams}
                selectedStrikes={selected_strikes}
                nDays={queryParams.nDays}
                changenDays={handlenDaysChange}
                startDate={queryParams.startDate}
                handleStartDateChange={handleStartDateChange}
                plotType={queryParams.plot}
                changePlotType={changePlotType}
                expiry={queryParams.expiry}
                expiryList={expiryList}
                handleExpiryChange={handleExpiryChange}
                activeStrikes={activeStrikes}
                selectedStrikeList={selectedStrikeList}
                handleSelectedStrikesChange={handleSelectedStrikesChange}
              />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#111111",
                }}
              >
                <CircularProgress sx={{ marginTop: "20%" }} />
              </div>
            )}
          </>
        )}
        <Snackbar
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ top: "48px" }}
          open={open}
          autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        >
          <Alert
            severity={severity}
            sx={{ width: { mobile: "80%", tablet: "70%", laptop: "40%" } }}
          >
            {msg}
          </Alert>
        </Snackbar>
        {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}
        {/* <h2>LineChartFooter</h2> */}
      </>
    )
  }
}

export default LineChart