import Item from "antd/es/list/Item";
import { useLocation } from "react-router-dom";
import {getDrawerList} from './configData';
import { BOTTOM_PANEL_HEIGHT_VAL, CHART_SCREEN_TYPE } from "./mp_chart_constants";
import { OF_TIME_FRAME, OF_TIME_FRAME_OTHER_D3_CHARTS } from "./constant";

export const useQuery = (param) => {
    return new URLSearchParams(useLocation().search).get(param);
  };
  
export const getAccessToken = () => {
    return JSON.parse(localStorage.getItem("accessToken"));
  };
  
  export const setAccessToken = (token) => {
    localStorage.setItem("accessToken", JSON.stringify(token));
  };
  
  export const updateAccessToken = (token) => {
    let accessToken = JSON.parse(localStorage.getItem("accessToken"));
    accessToken = token;
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
  };

  // export const getIndexOfRoute=(route,search,auth)=>{
  //   // console.log("getIndexOfRoute: route and search",route,search,route+search,auth)
  //   let index=-1;
  //   const drawerListData=getDrawerList(auth)
  //   // console.log("getIndexOfRoute: route and search",route,search,route+search,drawerListData)
  //   if(route!=undefined || route!=null)
  //     {
  //       for(let i = 0; i < drawerListData.length; i++){ 
  //         if(drawerListData[i].route==route+search){
  //           console.log("getIndexOfRoute found: route and search",i)
  //           return i;
            
  //         }
  //       }
  //       for(let i = 0; i < drawerListData.length; i++){ 
  //         if(drawerListData[i].route==route){
  //           console.log("getIndexOfRoute found: route",i)
  //           return i;
  //         }
  //       }
  //   }
  //   console.log("getIndexOfRoute not found: ",index)
  //   return index;
  // }

  export const getIndexOfRoute=(route,search,auth)=>{
    // console.log("getIndexOfRoute: route and search",route,search,route+search,auth)
    let index=-1;
    const drawerListData=getDrawerList(auth)
    // console.log("getIndexOfRoute: route and search",route,search,route+search,drawerListData)
    if(route!=undefined || route!=null)
      {
        
        for(let i = 0; i < drawerListData.length; i++){ 
         if(drawerListData[i].items==undefined){
            if(drawerListData[i].route==route+search){
              console.log("getIndexOfRoute found: route and search",i)
              return {parentIndex:-1,childIndex:i}
              
            }
          }else{
            for(let j=0;j<drawerListData[i].items.length;j++)
            if(drawerListData[i].items[j].route==route+search){
              console.log("getIndexOfRoute found: route and search",i)
              return {parentIndex:i,childIndex:j}
              
            }
          }
        }

        for(let i = 0; i < drawerListData.length; i++){ 
          if(drawerListData[i].items==undefined){
             if(drawerListData[i].route==route){
               console.log("getIndexOfRoute found: route and search",i)
               return {parentIndex:-1,childIndex:i}
               
             }
           }else{
             for(let j=0;j<drawerListData[i].items.length;j++)
             if(drawerListData[i].items[j].route==route){
               console.log("getIndexOfRoute found: route and search",i)
               return {parentIndex:i,childIndex:j}
               
             }
           }
         }
        // for(let i = 0; i < drawerListData.length; i++){ 
        //   if(drawerListData[i].route==route){
        //     console.log("getIndexOfRoute found: route",i)
        //     return i;
        //   }
        // }
    }
    console.log("getIndexOfRoute not found: ",index)
    return {parentIndex:-1,childIndex:-1}
  }

 

  //TODO: handle no last name otherwise it will crash the app
  export const stringAvatar=(name)=> {
    
    return {
      sx: {
        bgcolor:'primaryTheme.shade01',
        color:'white',
        width: 36, height: 36, fontSize:"16px", cursor:"pointer"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } 
  

  export const createDictList=(startTime, endTime, interval,gap)=> {
    // Define starting time, value and label
    let time = startTime;
    let value = 0;
    let label = "A";
    let lastLabel="A"
  
    // Define an empty list to store the dictionaries
    let dictList = [];
  
    // Loop through the time range from start to end
    while (time <= endTime) {
      // Create a new dictionary with the current value, label and time
      let dict = {
        value: value,
        label: label,
        time: time
      };
      // Add the dictionary to the list
      dictList.push(dict);
      // Increment the value
      value++;
      // Increment the label every interval minutes
      // if (value % (60 / interval) === 0) {
        if(value%gap==0){
        label = String.fromCharCode(lastLabel.charCodeAt(0) + 1);
        lastLabel=label;
        }
        else
        label=""
      // }
      // Increment the time by interval minutes
      let [hours, minutes] = time.split(":").map(Number);
      minutes += interval;
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }
      time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }
    // console.log("time interval list= ",dictList);
    return dictList;
  }

  export const createIncrementDictList=(startV, endV, gap)=> {
    // Define starting time, value and label
    let value = startV;
    let label = "0";
    let lastLabel="0"
  
    // Define an empty list to store the dictionaries
    let dictList = [];
  
    // Loop through the time range from start to end
    while (value <= endV) {
      
      if(value%gap==0){
        label = value;
        lastLabel=label;

        // Create a new dictionary with the current value, label and time
        let dict = {
          value: value,
          label: label,
        };
        // Add the dictionary to the list
        dictList.push(dict);
      }
      else
        label=""

      // Increment the value
      value++;
      
    }
    // console.log("time interval list= ",dictList);
    return dictList;
  }

  export const getRandomHexColor=()=>{
    // console.log("random getRandomHexColor","#"+ Math.floor(Math.random()*16777215).toString(16))
    return "#"+ Math.floor(Math.random()*16777215).toString(16);
  }
  export  const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    // console.log("random rgbToHex=",r,g,b,hex.length === 1 ? '0' + hex : hex)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')

  export const breakDSTR=(dstr,maxProfile,tradeDates,symbol,startDate,instrumentData,jsonData)=>{
    // let dstr="_4.12._11"
    console.log("breakDSTR=",dstr,maxProfile,tradeDates,symbol)
    // dstr="5.5"
    // startDate="30-06-2023"
  let count=0;
  let total=0;
  let nDays=0;
  // let maxProfile=15;
  let result=dstr.split(".")
  result = result.filter((val) => {
      return val!=""
  });

  let tempDstr=""
  let finalData=[];
  let startDateIndex=tradeDates.findIndex(item => item == startDate);
  let totalDays=0;
  for(let i=result.length-1;i>=0;i--){
    if(result[i].length>0){
      if(result[i].startsWith("_")){
        let value=parseInt(result[i].slice(1))
        totalDays=totalDays+value;
      }else{
        let value=parseInt(result[i])
        totalDays=totalDays+value;
      }
    }
  }

  let length=tradeDates.length-startDateIndex-totalDays;  //1500-1-1306-35
  console.log("breakDSTR 1=",startDateIndex,length,tradeDates.length,totalDays,dstr);
  if(instrumentData && instrumentData.series && instrumentData.series!=""){
  //  instrumentData.series="16-10-2023"
    let seriesIndex=tradeDates.findIndex(item => item == instrumentData.series);
    console.log("breakDSTR series=",startDateIndex,length,tradeDates.length,totalDays,dstr,instrumentData.series,seriesIndex,startDate);
    if(seriesIndex!=-1 && seriesIndex!=tradeDates.length-1){
      length=seriesIndex+1-startDateIndex-totalDays;
      console.log("breakDSTR 2=",startDateIndex,seriesIndex,totalDays,length);
    }

  }
  if(length>0 && jsonData.tf=="daily"){
    result.push("_"+length)
  }
  let seriesIndex=tradeDates.findIndex(item => item == instrumentData.series);
  console.log("breakDSTR 3=",length,result,seriesIndex);

  for(let i=result.length-1;i>=0;i--){
      console.log("i=",i,result)
      if(result[i].length>0){
          if(result[i].startsWith("_")){
              let value=parseInt(result[i].slice(1))
              console.log("If 1 value=",value,total,count,nDays,result[i])
              if(count+value<maxProfile){
                if(tempDstr!="")
                tempDstr="_"+value+"."+tempDstr;
                else
                tempDstr="_"+value
                  count=count+value;
                  total=total+value;
                  nDays=nDays+value;
                  if(i==0){
                              finalData.push({
                                  dstr:tempDstr,
                                  nDays:nDays,
                                  index:total
                              })
                  }
                  console.log("If 2 value=",value,total,count,nDays,result[i])
              }else{
                if(tempDstr!="")
                tempDstr="_"+(maxProfile-count)+"."+tempDstr;
                else
                tempDstr="_"+(maxProfile-count)
                  let rem=value-(maxProfile-count);
                  console.log("Else 2 value=",value,total,count,nDays,result[i])
                  nDays=nDays+(maxProfile-count);
                  total=total+(maxProfile-count);
                  count=count+(maxProfile-count);
                  
                  console.log("Else 3 value=",value,total,count,nDays,result[i])
                  finalData.push({
                      dstr:tempDstr,
                      nDays:nDays,
                      index:total
                  })
                  nDays=0;
                  count=0;
                  tempDstr="";
                  while(rem>0){
                      if(rem<maxProfile){
                          tempDstr="_"+rem+tempDstr;
                          
                          total=total+rem;
                          nDays=rem;
                          count=rem;
                          if(i==0){
                              finalData.push({
                                  dstr:tempDstr,
                                  nDays:nDays,
                                  index:total
                              })
                              tempDstr=""
                          }else{
                              
                          }
                          rem=0;
                          
                          
                          
                          
                      }else{
                          tempDstr="";
                          tempDstr="_"+maxProfile+tempDstr;
                          rem=rem-maxProfile;
                          total=total+maxProfile;
                          nDays=maxProfile;
                          finalData.push({
                              dstr:tempDstr,
                              nDays:nDays,
                              index:total
                          })
                          nDays=0;
                          tempDstr="";
                          
                      }
                  }
              }
          }
          else{
              console.log("ELSE ",i)
              let value=parseInt(result[i])
              if(tempDstr!="")
                tempDstr=value+"."+tempDstr;
            else
                tempDstr=value;
                  count=count+1;
              nDays=nDays+value; 
              total=total+value;
              if(count>=maxProfile || i==0){
                  finalData.push({
                      dstr:tempDstr,
                      nDays:nDays,
                      index:total
                  })
                  tempDstr="";
                  count=0;
                  nDays=0;
              }    
              
          }
      }
      console.log("tempDstr=",tempDstr)
  }
  console.log("breakDSTR Output=",finalData)
  
  for(let i=finalData.length-1;i>=0;i--){
    finalData[i].startDate=tradeDates[startDateIndex];
      startDateIndex=startDateIndex+finalData[i].nDays;
    
      finalData[i].loaded=false;
  
  }
  console.log("breakDSTR Output=",finalData)
  return finalData;
  }

  export const findMaxInRangeGeneric=(data, leftIndex, rightIndex,fieldName)=> {
    if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
      throw new Error('Invalid index range');
    }
  
    const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
  
    // Initialize variables to hold max and min values
    let max = Number.MIN_VALUE;
    let min=Number.MAX_VALUE;
    
  
    // Iterate through the subset to find max and min values
    for (let i = 1; i < subset.length; i++) {
      if (subset[i][fieldName] > max) {
        max = subset[i][fieldName];
      }
      if (subset[i][fieldName] < min) {
        min = subset[i][fieldName];
      }
     
    }
  
    return { max, min };
  }
  
  export const getBottomPanelViewName=(plotType)=>{
    if(plotType=="fut")
      return "panel1_view_futures";
    else if(plotType=="spt")
      return "panel1_view_spectrum";
    else if(plotType=="opt")
      return "panel1_view_options";
    else if(plotType=="all_doix")
      return "panel1_view_ntm_oi_change";
    else if(plotType=="all_doi")
      return "panel1_view_oi_change";
    else return "unknown_bottom_view"
  }

  export const getBottomPanelHeightName=(plotType)=>{
    if(plotType=="fut")
      return "panel1_height_futures";
    else if(plotType=="spt")
      return "panel1_height_spectrum";
    else if(plotType=="opt")
      return "panel1_height_options";
    else if(plotType=="all_doix")
      return "panel1_height_ntm_oi_change";
    else if(plotType=="all_doi")
      return "panel1_height_oi_change";
    else return "unknown_bottom_height"
  }

  export const getBottomPanelDefaultHeight=(plotType)=>{
    if(plotType=="fut")
      return BOTTOM_PANEL_HEIGHT_VAL["FUTURE_CHART"]
    else if(plotType=="spt")
      return BOTTOM_PANEL_HEIGHT_VAL["SPECTRUM_CHARTS"]
    else if(plotType=="opt")
      return  BOTTOM_PANEL_HEIGHT_VAL["OPTIONS_CHART"]
    else if(plotType=="all_doix")
      return BOTTOM_PANEL_HEIGHT_VAL["NTM_OI_CHANGE_CHART"]
    else if(plotType=="all_doi")
      return  BOTTOM_PANEL_HEIGHT_VAL["OI_CHANGE_CHART"]
    else return 10;
  }

  export const getInfoPanelViewName=(plotType)=>{
    if(plotType=="candle")
      return "info_panel_view_candle";
    else if(plotType=="fut")
      return "info_panel_view_futures";
    else if(plotType=="spt")
      return "info_panel_view_spectrum";
    else if(plotType=="opt")
      return "info_panel_view_options";
    else if(plotType=="all_doix")
      return "info_panel_view_ntm_oi_change";
    else if(plotType=="all_doi")
      return "info_panel_view_oi_change";
    else return "unknown_info_panel_view"
  }

  export const isD3BasedLineChart=(plot,doix=false)=>{
    if((plot=="fut" || plot=="opt" || plot=="spt" || plot=="all_doi"|| plot=="all_doix")&& doix==false){
      return true
    }
    else 
      return false;
  }

  export const isCOTChangeAllowed=(plot,doix=false)=>{
    if((plot=="fut" || plot=="opt")&& doix==false){
      return true
    }
    else 
      return false;
  }

  export const getLineChartSettingsName=(plot,doix=false)=>{
    if((plot=="spt" )&& doix==false){
      return "Spectrum Chart"
    }
    else if((plot=="all_doi")&& doix==false){
      return "OI Change Chart"
    }
    else if((plot=="all_doix")&& doix==false){
      return "NTM OI Change Chart"
    }
    else 
      return "Line Chart";
  }

  export const getTimeFrameForLineChart=(plot,doix=false)=>{
    if((plot=="fut" || plot=="opt" )&& doix==false){
      return OF_TIME_FRAME
    }else if((plot=="spt" || plot=="all_doi"|| plot=="all_doix")&& doix==false){
      return OF_TIME_FRAME_OTHER_D3_CHARTS;
    }else{
      return [];
    }
  }

  //chart state for reload the browser tab in multichart container view
  export const getChartSavedState = (savedData,newObject, type = undefined,chartScreenType) => {
    let updatedData = [];
    let updatedSavedData = { ...savedData }; // Make a copy of savedData
  
    if (type !== undefined) {
      // console.log("Reload OFMain saveChartState if=", type, newObject, chartScreenType, type, savedData, savedData?.data?.length);
      
      let chartData = JSON.stringify(savedData); // Serialize savedData
      if (chartData) {
        chartData = JSON.parse(chartData); // Parse to make a copy
        updatedData = chartData.data || [];
      }
  
      // Logic to update data based on CHART_SCREEN_TYPE
      if (type === CHART_SCREEN_TYPE.FULL_CHART) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[1]) updatedData[1] = { key: 1 };
          if (updatedData[2]) updatedData[2] = { key: 2 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.TWO_COLUMNS) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[2]) updatedData[2] = { key: 2 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.TWO_ROWS) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[1]) updatedData[1] = { key: 1 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.GRID) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS) {
        if (updatedData && updatedData.length > 0) {
          // You can modify the data array here if needed
        }
      }
  
      // Update the savedData copy
      updatedSavedData = {
        ...savedData,
        data: updatedData,
        type: type,
      };
    } else {
     
      // Check if the object with the same key already exists in the data array
      const index = savedData.data.findIndex((item) => item.key === newObject.key);
  
      if (index !== -1) {
        // If found, replace the object at the specific index
        updatedData = savedData.data.map((item, i) => (i === index ? newObject : item));
      } else {
        // If not found, add the new object to the data array
        updatedData = [...savedData.data, newObject];
      }
  
      // Update the savedData copy
      updatedSavedData = {
        ...savedData,
        data: updatedData,
        type: chartScreenType, // Update the type to the current chartScreenType
      };
    }
  
    console.log("Updated savedData:", updatedSavedData);
    
    // Return the updated savedData instead of setting it
    return updatedSavedData;
  };