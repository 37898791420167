import { useEffect,useRef,useState } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import {Snackbar, Box,Select,Typography,Container,CircularProgress,Alert,Button,MenuItem,FormControl,InputLabel,Tooltip, Grid, TextField} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import OptionsFooter from './components/options_footer';
import dayjs from 'dayjs';
// import { DatePicker} from 'antd';
import { TEXT_MSGS,USER_ROLE_FIELDS,SNACKBAR_AUTO_HIDE_DURATION,MenuProps,SNACKBAR_AUTO_HIDE_DURATION_SHORT } from '../../common/utility/constant';
import {styled, useTheme} from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { ToolbarFixed } from '../../layouts/component.js';
import useAuth from '../../common/hooks/useAuth';
import ConfirmActionDialog from './components/confirm_dialog';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getUserListColumnDef } from '../../common/utility/user_list_columnDefs';

const ROW_HEIGHT=46;
const HEADER_HEIGHT=44;
const GROUP_HEADER_HEIGHT=30;
// import { CallCheckBoxRenderer,PutCheckBoxRenderer } from './components/options_table_renderer';
const gridOptions = {
  suppressColumnVirtualisation:true,
  suppressMovableColumns:true,
  suppressColumnMoveAnimation:true,
  // suppressRowVirtualisation:true,  // not more than 500 rows display because of this
  enableCellTextSelection:true,
  ensureDomOrder:true,
}
const frameworkComponents= {
  // userComponent: UserRenderer,
} 

const DateFormat = 'DD-MM-YYYY';
const FooterToolbar=styled(ToolbarFixed)(
    ({theme})=> `
    bottom:0px;
    justify-content:center;
    text-align:center;
    `
  )
export default function UserAdmin() {
  
    const navigate = useNavigate(); 
    const theme=useTheme();
    const from = "/login";
    const { auth } = useAuth();
    const [gridAPIOptions,setGridAPIOptions]=useState();
    const [columnAPIOptions,setColumnAPIOptions]=useState();
    const [orderId,setOrderId]=useState();
    const [refNo,setRefNo]=useState();
    const [emailId,setEmailId]=useState();
    const [planName,setPlanName]=useState();
    const [filterPlanName,setFilterPlanName]=useState();
    const [filterRoleName,setFilterRoleName]=useState();
    const [filterResourceName,setFilterResourceName]=useState();
    const [payType,setPayType]=useState();
    const [amount,setAmount]=useState();
    const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
    const [cancelOrderReq,responseOrderReq,errorOrderReq,loadedOrderReq,resetOrderReq,getOrderStatus] = useAxiosWithAuth();
    const [cancelPlanSubReq,responsePlanSubReq,errorPlanSubReq,loadedPlanSubReq,resetPlanSubReq,getPlanSubStatus] = useAxiosWithAuth();
    const gridRef = useRef();

    const [state, setState] = useState({
      isLoading: true,
      data: undefined,
      user_stats: undefined,
      // order_id: undefined,
      // reloadInerval: 5*60*1000, // 5 sec
    });

    const { isLoading, data, user_stats } = state

    const [msgState, setMsgState] = useState({
        open: false,
        msg:"" ,
        severity:"info"
    });
    const { open, msg, severity } = msgState;

    const [orderState, setOrderState] = useState({
        order_available: false,
        order_data: undefined,
        cca_data: undefined
    });
    const { order_available, order_data, cca_data } = orderState;

    const [planState, setPlanState] = useState({
      is_valid: false,
      user_data: undefined,
      sub_data: undefined,
      // planList: ["PREMIUM_MONTHLY_1", "PREMIUM_MONTHLY_6", "PREMIUM_MONTHLY_12", "PREMIUM_PLUS_MONTHLY_1", "PREMIUM_PLUS_MONTHLY_6", "PREMIUM_PLUS_MONTHLY_12"],
      planList: ['NEW_PREMIUM_MONTHLY_1', 'NEW_PREMIUM_MONTHLY_3', 'NEW_PREMIUM_MONTHLY_6', 'NEW_PREMIUM_MONTHLY_12', 'NEW_PREMIUM_PLUS_MONTHLY_1', 'NEW_PREMIUM_PLUS_MONTHLY_3', 'NEW_PREMIUM_PLUS_MONTHLY_6', 'NEW_PREMIUM_PLUS_MONTHLY_12', 'NEW_PREMIUM_INDEX_STOCKS_MONTHLY_1', 'NEW_PREMIUM_INDEX_STOCKS_MONTHLY_3', 'NEW_PREMIUM_INDEX_STOCKS_MONTHLY_6', 'NEW_PREMIUM_INDEX_STOCKS_MONTHLY_12', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6', 'NEW_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12', 'VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1', 'VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3', 'VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12', 'VMR_ECOURSE', 'VMR_VTRENDER_ECOURSE', 'VMR_PREMIUM_PLUS_MONTHLY_1', 'VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_DAILY_8', 'NFP_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12'],
      roleList: ["basic", "fast_data", "pro_1", "pro_2"],
      resourceList: ["vmr_ecourse", "orderflow_both", "orderflow_index", "orderflow_stocks"],
      payList: ["UPI", "CCAVENUE", "PAYNIMO"],
    });
    const { is_valid, user_data, sub_data, planList, roleList, resourceList, payList } = planState;

    const [rowData,setRowData]=useState(data);
    const [userColumnDef,setUserColumnDef]=useState(getUserListColumnDef())

    const stateRef = useRef(state);
    const orderStateRef = useRef(orderState);
    const planStateRef = useRef(planState);

    const handleClose = (event, reason) => {
      setMsgState({...msgState,open:false});
    };
          
    const onGridReady = params => {
      setGridAPIOptions(params.api);
      setColumnAPIOptions(params.columnApi);
    };

    const handleOrderIdChange=(event)=>{
      setOrderId(event.target.value);
    }

    const handleRefNoChange=(event)=>{
      setRefNo(event.target.value);
    }

    const handleEmailIdChange=(event)=>{
      setEmailId(event.target.value);
    }

    const handlePlanNameChange=(event)=>{
      setPlanName(event.target.value);
    }

    const handlePayTypeChange=(event)=>{
      setPayType(event.target.value);
    }

    const handleAmountChange=(event)=>{
      setAmount(event.target.value);
    }

    const handleFilterPlanNameChange=(event)=>{
      setFilterPlanName(event.target.value);
      setFilterRoleName("");
      setFilterResourceName("");
      console.log(event.target.value, filterPlanName)
      executeAPI(`${URL.USER_DATA}`,"POST",{"plan_name": event.target.value}) ;
    }

    const handleFilterRoleNameChange=(event)=>{
      setFilterRoleName(event.target.value);
      setFilterPlanName("");
      setFilterResourceName("");
      executeAPI(`${URL.USER_DATA}`,"POST",{"role_name": event.target.value}) ;
    }

    const handleFilterResourceNameChange=(event)=>{
      setFilterResourceName(event.target.value);
      setFilterPlanName("");
      setFilterRoleName("");
      executeAPI(`${URL.USER_DATA}`,"POST",{"resource_name": event.target.value}) ;
    }

    const GetOrderStatus=()=>{
      let data={"order_id": orderId}
      if(refNo!=undefined && refNo!="")
        data.ref_no = refNo;
      getOrderStatus(`${URL.ORDER_DATA}`,"POST",data);
    }

    const ClearOrderForm=()=>{
      setOrderId("");
      setRefNo("");
      setOrderState((prevState)=>({
        ...prevState,
        order_data:undefined,
        cca_data:undefined,
        order_available:false,
      }))
    }

    const CheckUserDetails=()=>{
      let data={"email_id": emailId};
      getPlanSubStatus(`${URL.USER_SUB_CHECK}`,"POST",data);
    }

    const CheckUserPlanDetails=(exec)=>{
      let data={"email_id": emailId, "plan_name": planName, "type": payType, "exec_cmd": exec};
      if(orderId!=undefined && orderId!="")
        data.order_id = orderId;
      if(refNo!=undefined && refNo!="")
        data.ref_no = refNo;
      if(amount!=undefined && amount!="")
        data.amount = amount;
      getPlanSubStatus(`${URL.USER_SUB_CHECK}`,"POST",data);
    }

    const ClearPlanForm=()=>{
      setEmailId("");
      setPlanName("");
      setPayType("");
      setAmount("");
      setPlanState((prevState)=>({
        ...prevState,
        is_valid: false,
        user_data: undefined,
        sub_data: undefined,
      }))
      ClearOrderForm();
    }

    const ClearSearchForm=()=>{
      setFilterPlanName("");
      setFilterRoleName("");
      setFilterResourceName("");
      executeAPI(`${URL.USER_DATA}`,"POST",{}) ;
    }
    
    useEffect(()=>{
        executeAPI(`${URL.USER_DATA}`,"POST",{}) ;
    },[])

  useEffect(() => {
      if(loaded){
        console.log("loaded data ",responseData);
        if(responseData!=null){
              setState((prevState)=>({
                ...prevState,
                data:responseData.data,
                user_stats:responseData.stats,
                isLoading:false  
              }))
              setRowData(responseData.data);
        }
        else if(error!==null){
          console.log("Error data=",error);
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            //if unauthorized then redirec it to login page
            if(error?.response?.status === 401 || error?.response?.status === 403){
              console.log("status received =",error?.response?.status)
              navigate(from, { replace: true });
            }
        }
        reset();
      }
  },[loaded,responseData]);


  useEffect(() => {
    orderStateRef.current = orderState;

    if(loadedOrderReq){
      console.log("loaded data ",responseOrderReq);
      if(responseOrderReq!=null){
           setOrderState((prevState)=>({
              ...prevState,
              order_data:responseOrderReq.data,
              cca_data:responseOrderReq.cca_data,
              order_available:responseOrderReq.success,
           }))
      }
      else if(errorOrderReq!==null){
        console.log("Error data=",errorOrderReq);
          setMsgState({open:true,msg:errorOrderReq?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorOrderReq?.response?.status === 401 || errorOrderReq?.response?.status === 403){
            console.log("status received =",errorOrderReq?.response?.status)
            navigate(from, { replace: true });
          }
      }
      resetOrderReq();
    }
  },[loadedOrderReq,responseOrderReq]);

  useEffect(() => {
    planStateRef.current = planState;

    if(loadedPlanSubReq){
      console.log("loaded data ",responsePlanSubReq);
      if(responsePlanSubReq!=null){
           setPlanState((prevState)=>({
              ...prevState,
              is_valid: responsePlanSubReq.success,
              user_data: responsePlanSubReq.user_data,
              sub_data: responsePlanSubReq.sub_data,
              // planList: ["PREMIUM_MONTHLY_1", "PREMIUM_MONTHLY_6", "PREMIUM_MONTHLY_12", "PREMIUM_PLUS_MONTHLY_1", "PREMIUM_PLUS_MONTHLY_6", "PREMIUM_PLUS_MONTHLY_12"],
              // payList: ["UPI", "CCAVENUE"],
           }));
           if(payType == "CCAVENUE"){
            setOrderState((prevState)=>({
                ...prevState,
                order_data:responsePlanSubReq.data,
                cca_data:responsePlanSubReq.cca_data,
                order_available:responsePlanSubReq.success,
            }))
           }
          //  if(responsePlanSubReq.success && responsePlanSubReq.message != undefined)
          //    setMsgState({open:true, msg:responsePlanSubReq.message, severity:"info"});
      }
      else if(errorPlanSubReq!==null){
        console.log("Error data=",errorPlanSubReq);
          setMsgState({open:true,msg:errorPlanSubReq?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorPlanSubReq?.response?.status === 401 || errorPlanSubReq?.response?.status === 403){
            console.log("status received =",errorPlanSubReq?.response?.status)
            navigate(from, { replace: true });
          }
      }
      resetPlanSubReq();
    }
  },[loadedPlanSubReq,responsePlanSubReq]);

  useEffect(() => {
    document.title = "User Admin";
  }, []);

    return(
        <>
          
          <div style={{width:"100%",height:  `calc(100% - 64px)`,marginTop:"4px",marginLeft:"4px",marginRight:"10px"}}>
            {rowData!=undefined?
              <div className="ag-theme-alpine-dark" style={{height:  `calc(100% - 8px)`,marginLeft:"4px",marginRight:"4px", marginTop:"4px", marginBottom:"20px"}}>
                  <br/>
                  <TextField id="outlined-basic" size="small" label="Order ID" variant="outlined" 
                      value={orderId}
                      type="text"
                      onChange={handleOrderIdChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:280,height:40,minHeight:30,ml:3}} />
                  
                  <TextField id="outlined-basic" size="small" label="Reference No" variant="outlined" 
                      value={refNo}
                      type="text"
                      onChange={handleRefNoChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:200,height:40,minHeight:30,ml:3}} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button  variant="contained" onClick={()=>GetOrderStatus()} sx={{width:"180px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
                    Get Order Status Details
                  </Button>
                  <Button  variant="contained" onClick={()=>ClearOrderForm()} sx={{width:"80px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
                    Clear
                  </Button>
                  <br/><br/>
                  <Box sx={{display:"flex",justifyContent:'left',alignItems:'left'}}>
                    <Box sx={{mt:2,ml:4,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column",height:"650px"}}>
                    <Typography>There are currently {user_stats.active_users} Verified and Active Users out of {user_stats.total_users} total Users. 
                    <br/>Number of users active in last 3 months: {user_stats.recently_active_users}.
                    <br/>Number of users active in last 30 days: {user_stats.active_users_30days}.
                    <br/>Plan wise subscriptions breakup for {user_stats.total_subscribers} users ({user_stats.premium_count} Premium & {user_stats.premium_plus_count} Premium + & {user_stats.premium_plus_orderflow_count} have Orderflow):
                    <br/>Premium Plan subscriptions:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Month: OLD / INDEX / INDEX_STOCKS: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_MONTHLY_1}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_MONTHLY_1}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_STOCKS_MONTHLY_1}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;3 Months: OLD / INDEX / INDEX_STOCKS: &nbsp;&nbsp;0&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_MONTHLY_3}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_STOCKS_MONTHLY_3}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;6 Months: OLD / INDEX / INDEX_STOCKS: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_MONTHLY_6}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_MONTHLY_6}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_STOCKS_MONTHLY_6}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;12 Months: OLD / INDEX / INDEX_STOCKS: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_MONTHLY_12}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_MONTHLY_12}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_INDEX_STOCKS_MONTHLY_12}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;Higher Plan with ADD-ON_STOCKS: &nbsp;&nbsp;{user_stats.plan_specific_count.STOCKS_MONTHLY_1}
                    <br/>Premium+ Plan subscriptions:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Month: No_OF / INDEX_OF / INDEX_STOCKS_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_MONTHLY_1}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;3 Months: No_OF / INDEX_OF / INDEX_STOCKS_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_MONTHLY_3}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;6 Months: No_OF / INDEX_OF / INDEX_STOCKS_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_MONTHLY_6}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;12 Months: No_OF / INDEX_OF / INDEX_STOCKS_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_MONTHLY_12}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12}&nbsp;/&nbsp;{user_stats.plan_specific_count.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12+user_stats.plan_specific_count.NFP_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;Higher Plan with 1 Month ADD-ON INDEX_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.ORDERFLOW_INDEX_MONTHLY_1}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;Higher Plan with 1 Month INDEX_STOCKS_OF: &nbsp;&nbsp;{user_stats.plan_specific_count.ORDERFLOW_INDEX_STOCKS_MONTHLY_1}
                    <br/>ECourse subscriptions (paid via Vtrender Charts): &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_ECOURSE}
                    <br/><br/>VMR subscriptions (Total: {user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1+user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3+user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12}):
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Month: &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;3 Months: &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;12 Months: &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12}
                    <br/>Complementary with ECourse, VMR_PREMIUM_PLUS_MONTHLY_1 (active count): &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_PREMIUM_PLUS_MONTHLY_1}
                    <br/>Complementary with ECourse, VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_DAILY_8 (active count): &nbsp;&nbsp;{user_stats.plan_specific_count.VMR_PREMIUM_PLUS_ORDERFLOW_INDEX_DAILY_8}
                    </Typography>
                    </Box>
                    
                    {order_available? 
                      <>
                      <Box sx={{ml:8,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column",height:"400px",overflow:'auto'}}>
                        <Typography>
                        <pre>Order Details in DB:<br/>{JSON.stringify(order_data, null, 2)}</pre>
                        </Typography>
                      </Box>
                      <Box sx={{ml:4,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column",height:"400px",overflow:'auto'}}>
                      <Typography>
                        <pre>Order Details from CCAvenues server:<br/>{JSON.stringify(cca_data, null, 2)}</pre> 
                      </Typography>
                      </Box>
                      </>
                      : <></>}
                    
                  </Box>
                  <br/><br/>
                  <TextField required id="outlined-basic" size="small" label="Email ID" variant="outlined" 
                      value={emailId}
                      type="text"
                      onChange={handleEmailIdChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:260,height:40,minHeight:30,ml:3}} />
                  <Button  variant="contained" onClick={()=>CheckUserDetails()} sx={{width:"200px",fontSize:12,fontColor:"#ffffff",ml:4,height:30,mr:2,pl:1,pr:1,mt:1}}  >
                    Check User Details
                  </Button>
                  <FormControl required size="medium" sx={{ width:"420px",height:"40px",minWidth:'420px',ml:3,mt:0 }}>
                  <InputLabel id="demo-simple-select-label" sx={{ width:"420px",height:"40px",minWidth:'420px',ml:0,mt:-1 }}>Plan Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={planList.find(item=>item==planName)||''}
                    label="Plan Name"
                    placeholder="Select a Plan..."
                    MenuProps={MenuProps}
                    sx={{width:420,height:"40px",ml:0,mb:1}}
                    onChange={(event)=>handlePlanNameChange(event)}
                  >
                    {planList.map((item,index) => (
                      <MenuItem key ={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <FormControl required size="medium" sx={{ width:"200px",height:"40px",minWidth:'200px',ml:3,mt:0 }}>
                  <InputLabel id="demo-simple-select-label" sx={{ width:"120px",height:"40px",minWidth:'120px',ml:0,mt:-1 }}>Pay Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={payList.find(item=>item==payType)||''}
                    label="Pay Type"
                    placeholder="Select a Type..."
                    MenuProps={MenuProps}
                    sx={{width:200,height:"40px",ml:0,mb:1}}
                    onChange={(event)=>handlePayTypeChange(event)}
                  >
                    {payList.map((item,index) => (
                      <MenuItem key ={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  {payType=="UPI" || payType=="PAYNIMO"?
                    <>
                    <TextField required id="outlined-basic" size="small" label="Amount" variant="outlined" 
                      value={amount}
                      type="text"
                      onChange={handleAmountChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:260,height:40,minHeight:30,ml:3}} />
                    </>
                  : payType=="CCAVENUE"?
                    <>
                    <TextField required id="outlined-basic" size="small" label="Order ID" variant="outlined" 
                      value={orderId}
                      type="text"
                      onChange={handleOrderIdChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:280,height:40,minHeight:30,ml:3}} />
                    <TextField required id="outlined-basic" size="small" label="Reference No" variant="outlined" 
                      value={refNo}
                      type="text"
                      onChange={handleRefNoChange}
                      inputProps={{
                          style: {
                            padding:'0 4px 0 12px',  
                            height: "40px",
                            inputMode: 'numeric', 
                          },
                        }}
                      sx={{width:200,height:40,minHeight:30,ml:3}} />
                    </>
                    : <></>
                  }
                  
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button  variant="contained" onClick={()=>CheckUserPlanDetails(false)} sx={{width:"200px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
                    Check Order and User Details
                  </Button>
                  <Button  variant="contained" onClick={()=>ClearPlanForm()} sx={{width:"80px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
                    Clear
                  </Button>
                  <br/><br/>
                  {is_valid?
                    <>
                    <Box sx={{display:"flex",justifyContent:'left',alignItems:'left'}}>
                      <Box sx={{mt:2,ml:4,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column",height:"400px",overflow:'auto'}}>
                      <Typography>
                        <pre>User Details in DB:<br/>{JSON.stringify(user_data, null, 2)}</pre>
                      </Typography>
                      </Box>
                      <Box sx={{mt:2,ml:8,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column",height:"400px",overflow:'auto'}}>
                      <Typography>
                        <pre>Plan Details in DB:<br/>{JSON.stringify(sub_data, null, 2)}</pre>
                      </Typography>
                      </Box>
                      <Box sx={{mt:4,ml:4,display:'flex',justifyContent:'left',alignItems:'left',flexDirection:"column"}}>
                      <Typography>
                      <Button  variant="contained" onClick={()=>CheckUserPlanDetails(true)} sx={{width:"80px",fontSize:12,fontColor:"#ffffff",height:30,mr:2,pl:1,pr:1,mt:1}}  >
                        Subscribe
                      </Button>
                      </Typography>
                      </Box>
                    </Box>

                    
                    <br/><br/>
                    </>
                    :
                    <></>

                  }
                  <br/><br/>
                  <Box>
                  <Typography>Select option to filter table (only the value from latest dropdown change is picked):
                  <FormControl size="medium" sx={{ width:"220px",height:"40px",minWidth:'220px',ml:3,mt:0 }}>
                  <InputLabel id="demo-simple-select-label" sx={{ width:"220px",height:"40px",minWidth:'220px',ml:0,mt:-1 }}>Role Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roleList.find(item=>item==filterRoleName)||''}
                    label="Role Name"
                    placeholder="Select a Role..."
                    MenuProps={MenuProps}
                    sx={{width:220,height:"40px",ml:0,mb:1}}
                    onChange={(event)=>handleFilterRoleNameChange(event)}
                  >
                    {roleList.map((item,index) => (
                      <MenuItem key ={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>

                  <FormControl size="medium" sx={{ width:"220px",height:"40px",minWidth:'220px',ml:3,mt:0 }}>
                  <InputLabel id="demo-simple-select-label" sx={{ width:"220px",height:"40px",minWidth:'220px',ml:0,mt:-1 }}>Resource Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    value={resourceList.find(item=>item==filterResourceName)||''}
                    label="Resource Name"
                    placeholder="Select a Resource..."
                    MenuProps={MenuProps}
                    sx={{width:220,height:"40px",ml:0,mb:1}}
                    onChange={(event)=>handleFilterResourceNameChange(event)}
                  >
                    {resourceList.map((item,index) => (
                      <MenuItem key ={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>

                  <FormControl size="medium" sx={{ width:"420px",height:"40px",minWidth:'420px',ml:3,mt:0 }}>
                  <InputLabel id="demo-simple-select-label" sx={{ width:"420px",height:"40px",minWidth:'420px',ml:0,mt:-1 }}>Plan Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={planList.find(item=>item==filterPlanName)||''}
                    label="Plan Name"
                    placeholder="Select a Plan..."
                    MenuProps={MenuProps}
                    sx={{width:420,height:"40px",ml:0,mb:1}}
                    onChange={(event)=>handleFilterPlanNameChange(event)}
                  >
                    {planList.map((item,index) => (
                      <MenuItem key ={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <Button  variant="contained" onClick={()=>ClearSearchForm()} sx={{width:"80px",fontSize:12,fontColor:"#ffffff",height:30,ml:2,pl:1,pr:1,mt:1}}  >
                    Reset
                  </Button>
                  </Typography>
                  </Box>
                  <br/><br/>
                  <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    components={frameworkComponents}
                    rowHeight={ROW_HEIGHT}
                    headerHeight={HEADER_HEIGHT}
                    groupHeaderHeight={GROUP_HEADER_HEIGHT}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    columnDefs={userColumnDef}/>
                  <br/><br/>

              </div>:
              <div style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
              </div>
            }
      
          </div>
          
        
        <FooterToolbar sx={{borderTop: `1px solid ${theme.palette.primaryTheme.shade03}` }} >
        <Container >
        <Typography  variant="copyRight" align="center" >
          {WATERMARK_CONFIG.TABLE_TEXT}
          </Typography>
        </Container>
      </FooterToolbar>
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  
    </> 
      )
}